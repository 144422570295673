import { FC, useEffect, useState, ReactNode } from 'react';

import { SpinnerLoaderWrapper, SpinnerLoader } from '../../../shared/styles/dotsLoader';

export interface Props {
  page: number;
  totalPages: number;
  scrollOffset: number;
  onScroll: () => Promise<any>;
  onPageChange: () => void;
  children: ReactNode;
}

export const InfinityScroll: FC<Props> = ({
  page,
  totalPages,
  scrollOffset,
  onScroll,
  onPageChange,
  children,
}) => {
  const [fetching, setFetching] = useState(false);

  const scrollHandler = e => {
    const scrollBottom =
      e.target.documentElement.scrollHeight -
      (e.target.documentElement.scrollTop + window.innerHeight);

    if (scrollBottom < scrollOffset && page + 1 < totalPages) {
      if (!fetching) {
        setFetching(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [page, totalPages, fetching]);

  useEffect(() => {
    if (fetching) {
      onPageChange();
      onScroll().finally(() => setFetching(false));
    }
  }, [fetching]);

  return (
    <>
      {children}
      {fetching && (
        <SpinnerLoaderWrapper style={{ background: 'none' }}>
          <SpinnerLoader data-test-id="infinity-scroll-loader">
            <span />
          </SpinnerLoader>
        </SpinnerLoaderWrapper>
      )}
    </>
  );
};
