import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AgroidRoutes } from '../../routes';
import { InvitesPage } from '../InvitesPage';

import { OrgsPage } from './OrgsPage';
import { OrgRouter } from './OrgRouter';

export const OrgsRouter: FC<any> = () => {
  return (
    <Switch>
      <Route path={AgroidRoutes.Invites} component={InvitesPage} />
      <Route path={AgroidRoutes.Organization} component={OrgRouter} />
      <Route path={AgroidRoutes.Organizations} component={OrgsPage} />
    </Switch>
  );
};
