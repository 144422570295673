import styled from 'styled-components';

type ModalWrapperType = {
  isVerticalCentered?: boolean;
  paddings?: 'default' | 'seasons' | 'culture-zone';
};

type ModalActionsFooterType = {
  isVerticalAligment?: boolean;
};

export const Overlay = styled.div`
  filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
`;

export const ModalWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 608px) {
    overflow: auto;
    justify-content: flex-start;
  }
`;

const getPaddings = (value: ModalWrapperType['paddings']) => {
  const variants: Record<
    ModalWrapperType['paddings'],
    `${string}px ${string}px ${string}px ${string}px`
  > = {
    default: '40px 60px 40px 60px',
    seasons: '40px 60px 50px 60px',
    'culture-zone': '70px 60px 60px 60px',
  };
  return variants[value];
};

type ModalType = {
  size?: 'small' | 'medium' | 'large';
};

const ModalWidthMap = {
  small: '417px',
  medium: '440px',
  large: '600px',
};

export const Modal = styled.div<ModalWrapperType & ModalType>`
  background: #ffffff;
  height: auto;
  width: calc(100% - 24px);

  padding: ${({ paddings }) => getPaddings(paddings)};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  display: ${({ isVerticalCentered }) => (isVerticalCentered ? 'flex' : 'block')};
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  align-items: center;

  margin: 12px;

  @media (max-width: 440px) {
    border-radius: 0;
  }

  @media (min-width: 768px) {
    width: 440px;
  }
`;

export const ModalActionsFooter = styled.div<ModalActionsFooterType>`
  width: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: ${({ isVerticalAligment }) => (isVerticalAligment ? '1fr' : '1fr 1fr')};
  margin-top: 24px;
  text-align: center;
  & > button {
    display: inline-block;
  }
`;
