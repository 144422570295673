import styled, { StyledComponentBase } from 'styled-components';
import { Link } from 'react-router-dom';
import { LG } from '@zendeskgarden/react-typography';

interface IServiceCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
  Link?: any;
  Action?: any;
}

export const ServiceCardStyled: IServiceCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 767px) {
    padding: 20px 16px;
  }

  @media (min-width: 768px) {
    padding: 24px;
  }

  .logo {
    float: right;
    padding: 8px;
    border-radius: 12px;
    background: ${({ theme: { colors: c } }) => c.secondaryGray};
    width: 48px;
    height: 48px;
    box-sizing: border-box;
  }
`;

ServiceCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 20px;
  font-weight: 600;
  height: 60px;
`;

ServiceCardStyled.Text = styled.p`
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  padding-bottom: 20px;
  min-height: 64px;
  margin-bottom: auto;
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
`;

ServiceCardStyled.Link = styled(Link)`
  text-decoration: none !important;
  & > button {
    width: 100%;
    @media (min-width: 768px) {
      width: 160px;
    }
  }
`;

ServiceCardStyled.Action = styled.div`
  text-decoration: none !important;
  & > button {
    width: 100%;
    @media (min-width: 768px) {
      width: 160px;
    }
  }
`;
