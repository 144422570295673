import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useStore } from '../../../../utils/IoC';
import { CheckAccessStore } from '../../stores/checkAccess.store';
import { AccessRule } from '../../../../api/models/user.model';
import { AccessRuleVisibility } from '../../types';

import { ControlAccessRulesWrapperEl } from './style';

export interface AccessRulesWrapperProps {
  actionName?: string | string[];
  mode: AccessRuleVisibility;
  children: any;
  isParentNode?: boolean;
  dataTestId?: string;
  findAll?: boolean;
}

export const ControlAccessRulesWrapper: FC<AccessRulesWrapperProps> = observer(
  (props: AccessRulesWrapperProps) => {
    const checkAccessStore = useStore(CheckAccessStore);
    const { accessRules, globalAccessRules, getUserHasAccessRulesArray } = checkAccessStore;

    const accessRulesToCompare = props.findAll
      ? [...accessRules, ...globalAccessRules]
      : [...accessRules];

    const visibility = useMemo(() => {
      if (!props.actionName) {
        return AccessRuleVisibility.View;
      }

      if (!props.actionName || !props.mode || !props.actionName.length) {
        return AccessRuleVisibility.View;
      }

      return getUserHasAccessRulesArray(
        Array.isArray(props.actionName) ? props.actionName : [props.actionName]
      )
        ? AccessRuleVisibility.View
        : props.mode;
    }, [accessRulesToCompare, props.actionName]);

    if (visibility === AccessRuleVisibility.Hide) {
      return null;
    }

    if (!props.isParentNode) {
      return props.children;
    }

    return (
      <ControlAccessRulesWrapperEl
        className="control-access-rules-wrapper-el"
        mode={visibility}
        data-test-id={'control-access-rules-wrap'}
      >
        {props.children}
      </ControlAccessRulesWrapperEl>
    );
  }
);

ControlAccessRulesWrapper.defaultProps = {
  isParentNode: true,
  dataTestId: '',
};
