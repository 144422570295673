import styled from 'styled-components';
import { Body } from '@zendeskgarden/react-modals';

export const BodyStyled = styled(Body)`
  margin: 20px 0 0 0;
  padding: 0;
  overflow: hidden;

  & [data-garden-id='forms.field'] {
    margin-bottom: 12px;
  }
`;
