import { FC, useEffect, useState } from 'react';
import { Button, PhoneInput } from '@farmlink/farmik-ui';

import PhoneIco from '../../assets/phone.svg';
import { TypeApiRequest, TypeApiResponse } from '../../../../../../utils/axios2';
import { getError } from '../../../../../../utils/getError';
import { SmsCode } from '../../../SmsCode/SmsCode';

import {
  ModalAction,
  ModalButtonWrapper,
  ModalDescription,
  ModalTitle,
  Image,
  InputWrapper,
  CancelButton,
} from './style';

type Props = {
  phoneNumberCode: string;
  phoneNumber: string;
  setChangePhoneNumberModal: (val: boolean) => void;
  confirmPhoneNumber: (
    phoneNumber: string,
    countryCode: string
  ) => Promise<TypeApiResponse<'sendSmsCode'>>;
  checkSMSCode: (
    data: TypeApiRequest<'verifySmsCode'>
  ) => Promise<TypeApiResponse<'verifySmsCode'>>;
  onCommitPhone: (phoneNumberCode: string, phoneNumber: string) => void;
};

export const ChangePhoneNumberWizard: FC<Props> = ({
  setChangePhoneNumberModal,
  phoneNumberCode,
  phoneNumber,
  confirmPhoneNumber,
  checkSMSCode,
  onCommitPhone,
}) => {
  console.log({
    phoneNumberCode,
    phoneNumber,
  });
  const [phoneInput, setPhoneInput] = useState(phoneNumber || '');
  const [countryCode, setCountryCode] = useState(phoneNumberCode);
  const [operIdState, setOperIdState] = useState('');
  const [phoneInputError, setPhoneInputError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [code, setCode] = useState('');
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (operIdState) {
      setSeconds(seconds - 1);
    }
  }, [operIdState]);

  useEffect(() => {
    if (operIdState) {
      if (seconds > 0 && seconds < 60) {
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      }
    }
  }, [seconds]);

  const handleConfirmPhoneNumber = () => {
    if (phoneInput.length) {
      setPhoneInputError('');
      setSeconds(60);
      confirmPhoneNumber(phoneInput, countryCode)
        .then(({ operId }) => {
          setOperIdState(operId);
        })
        .catch(response => {
          if (response.response.data.error && response.response.data.error.title) {
            setPhoneInputError(getError(response.response.data.error.title));
          }
        });
    } else {
      setPhoneInputError('Поле обязательно для заполнения');
    }
  };

  const handleCheckSMSCode = () => {
    checkSMSCode({
      operId: operIdState,
      code,
    })
      .then(response => {setCodeError('');
        setCheckSuccess(true);
      })

      .catch(response => {
        if (response.response.data.error && response.response.data.error.title) {
          setCodeError(getError(response.response.data.error.title));
          // setOperIdState('');
          setCode('');
        }
      });
  };

  const handleChangePhone = (val: string, _code: string) => {
    setPhoneInput(val);
    setCountryCode(_code);
  };

  const handleChangeSmsCode = (value: string) => {
    setCode(value);
    setCodeError('');
  };

  if (checkSuccess) {
    return (
      <>
        <Image src={PhoneIco} alt="" />
        <ModalTitle className="success" data-test-id="change-phone-number-wizard-success-title">
          Телефон успешно изменен
        </ModalTitle>
        <ModalDescription
          className="success"
          data-test-id="change-phone-number-wizard-success-description"
        >
          Новый номер {phoneInput}
        </ModalDescription>
        <ModalButtonWrapper data-test-id="change-phone-number-wizard-success-button-wrapper">
          <Button
            dataTestId={'profile-edit-phone-ok'}
            type="button"
            color="primary"
            onClick={() => {
              onCommitPhone(countryCode, phoneInput);
              console.log({ countryCode, phoneInput });
              console.log(phoneInput.match(/\d/g).join('').slice(1));
              setChangePhoneNumberModal(false);
            }}
          >
            Хорошо
          </Button>
        </ModalButtonWrapper>
      </>
    );
  }

  return (
    <>
      <ModalTitle data-test-id="change-phone-number-wizard-title">
        Изменить номер телефона
      </ModalTitle>
      <ModalDescription data-test-id="change-phone-number-wizard-description">
        На указанный Вами номер придет смс-сообщение с кодом подтверждения
      </ModalDescription>
      <InputWrapper data-test-id="change-phone-number-wizard-input-wrapper">
        <PhoneInput
          dataTestId={'profile-edit-phone-input-modal'}
          label="Телефон"
          error={phoneInputError}
          defaultCountryCode={phoneNumberCode as '+7' | '+380' | undefined}
          defaultValue={undefined}
          onChange={handleChangePhone}
        />
      </InputWrapper>
      {operIdState && (
        <SmsCode
          error={codeError}
          data-test-id={'profile-edit-sms-input-modal'}
          label="Код подтверждения"
          placeholder="000000"
          value={code}
          onChange={handleChangeSmsCode}
          onSendCodeAgain={handleConfirmPhoneNumber}
          seconds={seconds}
        />
      )}
      <ModalAction data-test-id="change-phone-number-wizard-action">
        <ModalButtonWrapper data-test-id="change-phone-number-wizard-button-continue-wrapper">
          {operIdState ? (
            <Button
              type="button"
              color="primary"
              onClick={handleCheckSMSCode}
              dataTestId={'profile-edit-phone-continue'}
            >
              Продолжить
            </Button>
          ) : (
            <Button
              type="button"
              color="primary"
              onClick={handleConfirmPhoneNumber}
              dataTestId={'profile-edit-phone-send-code'}
            >
              Отправить код
            </Button>
          )}
        </ModalButtonWrapper>
        <ModalButtonWrapper data-test-id="change-phone-number-wizard-button-cancel-wrapper">
          <CancelButton
            data-test-id={'profile-edit-phone-cancel'}
            onClick={() => {
              setChangePhoneNumberModal(false);
            }}
          >
            Отмена
          </CancelButton>
        </ModalButtonWrapper>
      </ModalAction>
    </>
  );
};
