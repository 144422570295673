import styled from 'styled-components';

export const MainLayout = styled.div`
  @media (max-width: 767px) {
    padding: 0 8px;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const WellBodyStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  @media (max-width: 767px) {
    padding: 20px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;
