import styled, { StyledComponentBase } from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';

interface IWellHeaderStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Actions?: any;
}

export const WellHeaderStyled: IWellHeaderStyled = styled.div<{ isAlone?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ isAlone }) =>
    isAlone &&
    `
    padding-bottom: 0!important;
    border-bottom: none;
  `}

  svg path {
    fill: currentColor;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
  }

  @media (min-width: 768px) {
    padding: 0 24px;
  }
`;

WellHeaderStyled.Actions = styled.div<{ isPrimary?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  [data-garden-id='buttons.anchor'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;
    font-weight: 400;
    margin-left: 27px;

    svg {
      width: 12px;
      height: 12px;
    }

    svg ~ span {
      top: 0;
    }

    svg path {
      fill: currentColor;
    }

    > span {
      margin-right: 4px;
      white-space: nowrap;
      + svg {
        margin-right: 0;
      }
    }

    > svg {
      margin-right: 4px;
      + span {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      margin-left: 16px;
    }
  }
`;

WellHeaderStyled.Title = styled.div`
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  font-weight: 600;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin-right: auto;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledAccordeonAction = styled.img`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-left: 10px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const TitleArrowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
