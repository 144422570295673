import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../utils';

interface IItem {
  readonly id: string;
  organizationId?: string;
  [key: string]: any;
}

type TFilter = {
  [key: string]: string;
};

@provide.singleton()
class ListingPageStore {
  private _uniqueKeyToItemList: Map<string, any[]> = new Map<string, any[]>();

  private _filter: TFilter | null = null;

  private _searchValue: string | null = null;

  private _currentPageNumber = 0;

  private _totalPageNumber = 0;

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  getItemList = (uniqueKey: string): any[] => {
    return this._uniqueKeyToItemList.get(uniqueKey) || [];
  };

  get filter() {
    return this._filter;
  }

  get searchValue() {
    return this._searchValue;
  }

  get currentPageNumber() {
    return this._currentPageNumber;
  }

  get totalPageNumber() {
    return this._totalPageNumber;
  }

  // Сеттеры

  setItemList = <T extends IItem>(uniqueKey: string, itemList: T[]): void => {
    this._uniqueKeyToItemList.set(uniqueKey, itemList);
  };

  setFilter = (filter): void => {
    this._filter = filter;
  };

  setSearchValue = (searchValue: string): void => {
    this._searchValue = searchValue;
  };

  setCurrentPageNumber = (pageNumber: number): void => {
    this._currentPageNumber = pageNumber;
  };

  setTotalPageNumber = (pageNumber: number): void => {
    this._totalPageNumber = pageNumber;
  };

  // Методы для приведения свойств к дефолтному состоянию

  clearUniqueKeyToItemList = (): void => {
    this._uniqueKeyToItemList.clear();
  };

  clearFilter = (): void => {
    this._filter = null;
  };

  clearSearchValue = (): void => {
    this._searchValue = null;
  };

  clearCurrentPageNumber = (): void => {
    this._currentPageNumber = 0;
  };

  clearTotalPageNumber = (): void => {
    this._totalPageNumber = 0;
  };

  clearStore = (): void => {
    this.clearUniqueKeyToItemList();
    this.clearFilter();
    this.clearSearchValue();
    this.clearCurrentPageNumber();
    this.clearTotalPageNumber();
  };
}

export default ListingPageStore;
