import { TModalConfig } from '@farmlink/farmik-ui';

// TODO вынести enum'ы в union фармика

enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

enum EModalSize {
  Small = '417px',
  Medium = '440px',
  Large = '500px',
}

export const unsavedDataModal = (onSaveHandler, onCancelHandler): TModalConfig => {
  return {
    name: 'unsavedDataModal',
    title: 'Есть несохраненные настройки',
    type: EModalType.Warning,
    id: 'unsavedDataModal',
    dataTestId: 'unsavedDataModal',
    styledProps: {
      $modalPaddings: '60px 60px',
      $size: EModalSize.Medium,
    },
    denyButton: {
      title: 'Не сохранять',
      handler: onCancelHandler,
    },
    successButton: {
      title: 'Сохранить',
      handler: onSaveHandler,
    },
  };
};
