import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../utils/IoC';
import { TypeApiRequest, Axios, TypeApiResponse } from '../../../utils/axios2';
import { UserInfoType } from '../../../api/models/user.model';
import { getPurePhone } from '../../../utils';

export const NEED_ADDITIONAL_INFO = 'NEED_ADDITIONAL_INFO';
@provide.singleton()
export class ProfileStore {
  @lazyInject(Axios)
  axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  _isLoading = true;

  _user: UserInfoType | null;

  userForProfileEdit: UserInfoType | null;

  setUserForProfileEdit = (data: UserInfoType | null) => {
    this.userForProfileEdit = { ...data };
  };

  avatarHash = '';
  avatarImgId = '';
  avatarFile: File | null;

  fetchProfile = async () => {
    try {
      this.isLoading = true;
      this.user = await this.axios.api.getProfile({});
    } catch (e) {
      if (this.axios.needAdditionalInfo && localStorage.getItem('accessToken')) {
        throw new Error(NEED_ADDITIONAL_INFO);
      }
      // console.warn(e);
    } finally {
      this.isLoading = false;
    }
  };

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  set user(user: UserInfoType | null) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  validateUserData = (/* doc: Partial<UserInfoType> */) => {
    return true;
  };

  onAvatarReset = () => {
    this.userForProfileEdit = {
      ...this.userForProfileEdit,
      avatar: null,
    };
    this.avatarImgId = '';
    this.avatarFile = null;
    this.avatarHash = '';
  };

  updatePersonalData = (doc: Partial<UserInfoType>) => {
    if (doc && this.validateUserData()) {
      const { firstName, lastName, secondName, gender, dateOfBirth, avatar } = doc;
      const updatedUserData: TypeApiRequest<'updateProfile'> = {
        firstName,
        lastName,
        secondName,
        dateOfBirth,
        gender: typeof gender === 'object' ? gender?.genderEnum : gender,
        avatar: this.avatarHash || avatar?.id || '',
      };

      return this.axios.api.updateProfile(updatedUserData).then(async () => {
        await this.fetchProfile();
      });
    }
  };

  uploadFile = (file: File) => {
    this.avatarFile = file;
    this.axios.api
      .uploadFile({
        ownerId: this.user?.id!,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  onCommitAvatar = () => {
    this.avatarHash = this.avatarImgId;
  };

  resetUserPassword = () => {
    return this.axios.api.sendEmailPasswordReset({
      email: this.user.email?.email,
    });
  };

  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.avatarImgId = response.id;
    this.axios.api
      .uploadFileFinish({ data: this.avatarFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
    // this.fetchProfile();
  };

  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };

  confirmPhoneNumber = async (phoneNumber: string, countryCode: string) => {
    let result = null;
    try {
      result = await this.axios.api.userSendSmsCode({
        phoneNumber: getPurePhone(phoneNumber, countryCode),
        countryCode,
      });
    } catch (r) {
      return Promise.reject(r);
    }
    return result;
  };

  getPendingContracts = () => {
    return this.axios.api.getPendingContracts({});
  };

  checkSMSCode = (data: TypeApiRequest<'verifySmsCode'>) => this.axios.api.checkUserSmsCode(data);

  confirmEmail = (email: string) =>
    this.axios.api.sendEmailVerification({ email }, { omit: ['email'] });

  isScout = () => {
    return this._user?.scout || false;
  };
}
