import { IGetContract } from '../../../models/as-contracts/contract/contract.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TRequest = {
  organizationId: string;
  searchName?: string;
  size?: string;
};

type TResponse = { content: IGetContract[] };

export const getContractList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => '/api/as-contracts/contracts/list',
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
