import { FC } from 'react';
import { isEmpty } from 'lodash';

import Checkbox from '../assets/images/checkbox.svg';
import CheckboxGreen from '../assets/images/checkbox-green.svg';
import { AgroidRoutes } from '../../routes';
import { ProfileWidgetWrapper } from '../ProfileWidgetWrapper/ProfileWidgetWrapper';
import { UserExpertiseType } from '../../../../api/models/user.model';

import { Competition, WrapperCompetition, CompetentionEmptyStyled } from './styled';
import { ReactComponent as StarIcon } from './assets/star.svg';

type CompetentionWidgetProps = {
  items: Array<UserExpertiseType>;
};

export const CompetentionWidget: FC<CompetentionWidgetProps> = ({ items }) => (
  <ProfileWidgetWrapper
    title="Компетенции"
    editLink={AgroidRoutes.EditCompetitionsData}
    editCaption={isEmpty(items) ? 'Добавить' : 'Редактировать'}
    dataTestIdAction={
      isEmpty(items)
        ? 'profile-widget-expertise-action-add'
        : 'profile-widget-expertise-action-edit'
    }
    dataTestId="profile-widget-expertise"
  >
    <WrapperCompetition data-test-id="profile-widget-expertise-content">
      {items?.length ? (
        items.map(item => (
          <Competition key={item.expertiseId} data-test-id="profile-widget-expertise-item">
            <p data-test-id="profile-widget-expertise-item-name">{item.expertiseName}</p>{' '}
            <img
              src={item.confirmations ? CheckboxGreen : Checkbox}
              alt=""
              data-test-id="profile-widget-expertise-item-checkbox"
            />{' '}
            <p data-test-id="profile-widget-expertise-item-confirmations">{item.confirmations}</p>
          </Competition>
        ))
      ) : (
        <CompetentionEmptyStyled data-test-id="profile-widget-expertise-content-empty">
          <CompetentionEmptyStyled.Icon data-test-id="profile-widget-expertise-content-empty-icon-wrapper">
            <StarIcon data-test-id="profile-widget-expertise-content-empty-icon" />
          </CompetentionEmptyStyled.Icon>
          <CompetentionEmptyStyled.Text data-test-id="profile-widget-expertise-content-empty-text">
            У вас ещё нет компетенций,
            <br />
            но вы можете их добавить прямо сейчас
          </CompetentionEmptyStyled.Text>
        </CompetentionEmptyStyled>
      )}
    </WrapperCompetition>
  </ProfileWidgetWrapper>
);
