import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px;
  background: ${props => props.theme.Colors.generalLight};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (max-width: 375px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 320px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const SuccessHeading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  margin-top: 25px;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  color: ${props => props.theme.Colors.generalDark};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;
