import { useMemo } from 'react';

import { ISidebarMenuItem } from '../components';
import { AgroidRoutes } from '../routes';

export const useAccessWallMenu = () => {
  const menuItems: ISidebarMenuItem[] = useMemo(
    () => [
      {
        caption: 'Главная',
        link: AgroidRoutes.Index,
        slug: 'profile',
      },
    ],
    []
  );

  return menuItems;
};
