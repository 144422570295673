import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Anchor } from '@zendeskgarden/react-buttons';

import { formatPhoneFromStr, num2str, useStore } from '../../../../../../../../utils';
import { ProfileStore } from '../../../../../../../auth/stores/profile.store';
import {
  NoData,
  OrgLogo,
  ServicesList,
  TypeUserRoleEnumString,
  WellHeader,
} from '../../../../../../components';
import {
  DetailsWrapper,
  OrgLogoWrapper,
  UserRole,
  WellBodyStyled,
  WellStyled,
  DetailsColumn,
} from '../../../../../../pages/OrgsPage/styled';
import { OrganizationStore } from '../../../../../../stores';
import { ReactComponent as NoLogo } from '../../../../../../../../assets/images/agroid/logo-template.svg';
import { ReactComponent as EditSvg } from '../../../../../../../../assets/images/agroid/edit.svg';
import { IAction } from '../../../../../../pages/OrgsPage/pages/OrgProfile';
import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';

import { TProfilePageContainerProps } from './ProfilePageContainer.types';

const ProfilePageContainer: FC<TProfilePageContainerProps> = observer(() => {
  const { user } = useStore(ProfileStore);

  const {
    organization,
    setFilter,
    fetchOrganization,
    getOrganizationUsers,
    usersList,
    isLoading,
  } = useStore(OrganizationStore);

  const params = useParams<{ [key: string]: string }>();

  const history = useHistory();

  const [role, setRole] = useState<boolean>(false);

  useEffect(() => {
    fetchOrganization(params?.contractorId);
    setFilter({
      organizationID: params?.contractorId,
    });
    getOrganizationUsers();
  }, []);

  useEffect(() => {
    if (user) {
      const userInList = usersList.find(({ userId }) => userId === user.id);
      if (userInList) {
        // todo Когда старые роли исчезнуть оставить userInList.roleInfo?.name !!!!!!!
        setRole(userInList.roleInfo?.name || TypeUserRoleEnumString[userInList.role]);
      }
    }
  }, [user, usersList]);

  const orgActions: IAction[] = [
    {
      icon: EditSvg,
      caption: isMobileOnly ? '' : 'Редактировать',
      component: Anchor,
      dataTestIdAction: 'organization-profile-edit-action',
      componentProps: {
        isBasic: true,
      },
      onClick: () => {
        history.push(
          generatePath(EAgroidRoute.ScoutingContractorRequisites, {
            contractorId: params?.contractorId,
          })
        );
      },
    },
  ];

  const getDataTestId = (name: string): { 'data-test-id': string } => {
    return { 'data-test-id': `organization-profile-${name}` };
  };

  console.log('organization shit ', organization);

  return (
    <>
      <WellStyled className="profile">
        {isLoading ? (
          <>
            <Skeleton height="24px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
          </>
        ) : organization ? (
          <>
            <WellHeader
              title={'Данные организации'}
              actions={orgActions}
              dataTestId="organization-profile"
            />
            <WellBodyStyled>
              <Grid>
                <Row>
                  <OrgLogoWrapper>
                    {organization.logo ? (
                      <OrgLogo {...organization.logo} dataTestId="organization-profile-logo" />
                    ) : (
                      <NoLogo {...getDataTestId('no-logo')} />
                    )}
                  </OrgLogoWrapper>
                  <Col>
                    <Grid>
                      <Row>
                        <DetailsWrapper>
                          <div className="name" {...getDataTestId('name')}>
                            {`${get(organization, 'type.OrgType', '')} ${organization.name}`}
                          </div>
                          <div className="employeersCount" {...getDataTestId('employers-count')}>
                            {`${usersList.length} `}
                            {num2str(usersList.length, ['сотрудник', 'сотрудника', 'сотрудников'])}
                          </div>
                        </DetailsWrapper>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <DetailsWrapper {...getDataTestId('contacts')}>
                            <DetailsColumn>
                              <div className="contacts">
                                {organization?.phone && (
                                  <div className="phone" {...getDataTestId('contacts-phone')}>
                                    {formatPhoneFromStr(organization?.phone)}
                                  </div>
                                )}
                                {organization?.email && (
                                  <div className="email" {...getDataTestId('contacts-email')}>
                                    {organization.email}
                                  </div>
                                )}
                              </div>
                            </DetailsColumn>
                          </DetailsWrapper>
                        </Col>
                        <Col md={3}>
                          <DetailsWrapper {...getDataTestId('representative')}>
                            {organization.contact && (
                              <DetailsColumn>
                                <div className="label" {...getDataTestId('representative-label')}>
                                  Представитель
                                </div>
                                <p {...getDataTestId('representative-value')}>
                                  {organization.contact}
                                </p>
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                        <Col md={3}>
                          <DetailsWrapper {...getDataTestId('position')}>
                            {organization.representativePosition && (
                              <DetailsColumn>
                                <div className="label" {...getDataTestId('position-label')}>
                                  Должность
                                </div>
                                <p {...getDataTestId('position-value')}>
                                  {organization.representativePosition}
                                </p>
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                        <Col md={3}>
                          <DetailsWrapper {...getDataTestId('responsible')}>
                            {organization.manager && organization.manager?.fullName && (
                              <DetailsColumn>
                                <div className="label" {...getDataTestId('responsible-label')}>
                                  Ответственный
                                </div>
                                <p {...getDataTestId('responsible-value')}>
                                  {organization.manager?.fullName}
                                </p>
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                      </Row>
                    </Grid>
                  </Col>
                </Row>
              </Grid>
            </WellBodyStyled>
          </>
        ) : (
          <NoData
            message="Данные организации недоступны"
            dataTestId="organization-card-data-unavailable"
          />
        )}
      </WellStyled>
      <ServicesList />
    </>
  );
});

ProfilePageContainer.displayName = 'ProfilePageContainer';

export default ProfilePageContainer;
