import { makeAutoObservable, runInAction } from 'mobx';

import { provide } from '../../../utils';

@provide.singleton()
export class ConfirmStore {
  constructor() {
    makeAutoObservable(this);
  }

  visibleConfirm = false;
  isChanged = false;
  _dirtyInputSet = new Set<string>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goToNextPage = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  blockRouting = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unBlockRouting = () => {};

  get dirtyInputSet() {
    return this._dirtyInputSet;
  }

  addItemToDirtyInputSet = (inputName: string) => {
    this._dirtyInputSet.add(inputName);
  };

  createConfirm = (goToNextPage, blockRouting) => {
    runInAction(() => {
      this.goToNextPage = goToNextPage;
      this.blockRouting = blockRouting;
      this.visibleConfirm = true;
    });
  };

  deleteConfirm = () => {
    runInAction(() => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.goToNextPage = () => {};
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.blockRouting = () => {};
      this.visibleConfirm = false;
    });
  };

  runUnBlockRouting = fn => {
    runInAction(() => {
      this.unBlockRouting = fn;
    });
  };

  stopUnBlockRouting = () => {
    runInAction(() => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.unBlockRouting = () => {};
    });
  };

  enableRouting = () => {
    this.unBlockRouting();
    this.deleteConfirm();
  };

  blockAndHide = () => {
    this.blockRouting();
    this.deleteConfirm();
  };

  setIsChanged = (changed: boolean) => {
    runInAction(() => {
      this.isChanged = changed;
    });
  };

  clearDirtyInputSet = () => {
    this._dirtyInputSet.clear();
  }
}
