import styled from 'styled-components';

export const DropdownWrapper = styled.div<{ isOpen?: boolean }>`
  * {
    ${({ isOpen }) => {
      return isOpen
        ? `
        [data-garden-id='forms.faux_input'] {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none !important;
          border-color: #d8dcde !important;
          box-shadow: none !important;
        }
        [data-garden-id='dropdowns.menu'] {
          margin-top: -4px !important;
          border-top: none !important;
          border-radius: 0;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          background: #FAF9FD !important;
        }
    `
        : '';
    }}
  }
  ~ div[id|='uniforms'] {
    outline: 4px solid blue;
    color: ${({ theme: { colors: c } }) => c.accentPink};

    margin-top: -${({ theme: { space: s } }) => s.sm};
    margin-bottom: ${({ theme: { space: s } }) => s.md};
    font-size: 12px;
    padding-left: ${({ theme: { borderRadii: b } }) => b.button};
  }
`;
