import { FC, useState } from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';

import { useStore, getInclineUser } from '../../../../utils';
import { EmployeeStore } from '../../stores';
import Cancel from '../../../../assets/images/cancel.svg';

import { ModalStyled, ModalBodyStyled, ModalTitleStyled, IconScg } from './styled';

export interface IBlockConfirmModalProps {
  userId: string;
  orgId: string;
  onClose: () => void;
}

const MODAL_STATE = {
  IDLE: 'idle',
  BLOCKED: 'blocked',
};

export const BlockConfirmModal: FC<IBlockConfirmModalProps> = observer(
  ({ onClose, userId, orgId }) => {
    const { employee: user, deleteEmployee } = useStore(EmployeeStore);
    const [state, setState] = useState(MODAL_STATE.IDLE);

    const handleClick = async () => {
      try {
        await deleteEmployee(userId, orgId);
        setState(MODAL_STATE.BLOCKED);
      } catch (e) {
        console.error('remove role error', e);
      }
    };

    return (
      <ModalStyled onClose={onClose} style={{ width: '440px' }}>
        <ModalBodyStyled>
          {state === MODAL_STATE.IDLE ? (
            <>
              <IconScg src={Cancel} alt="" />
              <ModalTitleStyled data-test-id="modal-dialog-title">
                Вы уверены, что хотите заблокировать {getInclineUser(user.userName)}?
              </ModalTitleStyled>

              <Grid columns={2}>
                <Row justifyContent="center">
                  <Col textAlign="center" xs={1}>
                    <Button isStretched isBasic onClick={onClose} size="large">
                      Отменить
                    </Button>
                  </Col>
                  <Col textAlign="center" xs={1}>
                    <Button isStretched isPrimary onClick={handleClick} isDanger size="large">
                      Заблокировать
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Row>
                  <Col textAlign="center">
                    <IconScg src={Cancel} alt="" />
                    <ModalTitleStyled data-test-id="modal-dialog-title">
                      Сотрудник заблокирован
                    </ModalTitleStyled>

                    <Button isStretched isPrimary size="large" onClick={onClose}>
                      Продолжить
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </>
          )}
        </ModalBodyStyled>
      </ModalStyled>
    );
  }
);
