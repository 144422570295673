import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  code: string;
};

type TypeResponse = {
  needAdditionalInfo: boolean;
  'access-token': string;
  'access-token-type': string;
  'refresh-token': string;
  'rt-expires-on': string | Date;
};

export const erqToken: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/externalRequests/token`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
