import { TCreateParamsAsPayload } from './createParamsAsPayload.helper.types';

const createParamsAsPayload: TCreateParamsAsPayload = ({ apiParams, params }) => {
  if (!apiParams || !params) return {};

  const apiParamsEntries = Object.entries(apiParams);

  return apiParamsEntries.reduce((payload, [query, paramName]) => {
    if (params?.[paramName]) {
      payload[query] = params[paramName];
    }

    return payload;
  }, {});
};

export default createParamsAsPayload;
