import { useEffect, useMemo, useState } from 'react';
import { Button, PhoneInput, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useQuery, useStore } from '../../../../../../utils';
import { SignInViaPhoneStore } from '../../stores/sign-in.via-phone.store';
import { AuthStore } from '../../../../stores/auth.store';
import { AuthorizationRoutes } from '../../../../routes';
import { SmsCode } from '../../../../components/SmsCode';
import { BackButton } from '../../../../components/BackButton';

import {
  Wrapper,
  HeaderWrapper,
  Heading,
  FormContent,
  FieldWrapper,
  ButtonWrapper,
  FooterWrapper,
  FooterText,
} from './style';

type PhoneSignInProps = {
  onChangeType: () => void;
};

export const PhoneSignIn = observer(({ onChangeType }: PhoneSignInProps) => {
  const {
    currentState,
    setFieldValue,
    reset,
    sendCodeToPhone,
    code,
    phone,
    seconds,
    verifyCode,
    needAdditionalInfo,
    isAllowedToRequestCode,
    isCodeWasValid,
    isVerifyCodeButtonAvailable,
    setCurrentState,
  } = useStore(SignInViaPhoneStore);
  const { erqRedirectData, authKey, redirectUrl, redirectWithAuth } = useStore(AuthStore);
  const history = useHistory();
  const query = useQuery(history.location.search);

  const setters = useMemo(
    () => ({
      setPhone: setFieldValue('phone'),
      setCode: setFieldValue('code', Boolean(query.get('externalSystemAuth'))),
    }),
    []
  );

  const [phoneResetRnd, setPhoneResetRnd] = useState<null | undefined | number>();

  const handleSignUp = () => {
    history.push(AuthorizationRoutes.signUp);
  };

  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );

  useEffect(() => {
    if (currentState === 'first_step') {
      reset();
    }
  }, [currentState]);

  useEffect(() => {
    if (isCodeWasValid && !needAdditionalInfo) {
      erqRedirectData();
    } else if (isCodeWasValid && needAdditionalInfo) {
      history.push('/sign_up/?step=2');
    }
  }, [isCodeWasValid, needAdditionalInfo]);

  useEffect(() => {
    if (authKey && redirectUrl && !needAdditionalInfo) {
      redirectWithAuth();
    }
  }, [authKey, redirectUrl]);

  const handleSendCode = (e: any) => {
    e.preventDefault();
    if (!isAllowedToRequestCode) {
      return;
    }
    sendCodeToPhone();
  };

  const handleVerifyCode = (e: any) => {
    e.preventDefault();
    verifyCode(Boolean(query.get('externalSystemAuth')));
  };

  const renderFormContent = (v: 'first_step' | 'second_step') =>
    v === 'first_step' ? (
      <form onSubmit={handleSendCode}>
        <FieldWrapper>
          <PhoneInput
            dataTestId="login-phone-input"
            onChange={setters.setPhone}
            label="Номер телефона"
            mask="(999) 999 99 99"
            placeholder="+7 (123) 456 78 90"
            error={phone.error}
            resetRnd={phoneResetRnd}
          />
        </FieldWrapper>
        <ButtonWrapper>
          <Button
            dataTestId="login-next-button"
            color="primary"
            type="submit"
            disabled={!isAllowedToRequestCode}
          >
            Далее
          </Button>
        </ButtonWrapper>
      </form>
    ) : (
      <form onSubmit={handleVerifyCode}>
        <FieldWrapper>
          <PhoneInput
            dataTestId="login-phone-input"
            onChange={setters.setPhone}
            label="Номер телефона"
            mask="999 999 99 99"
            error={phone.error}
            disabled={true}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SmsCode
            error={code.error}
            label="Код подтверждения"
            placeholder="000000"
            value={code.value}
            seconds={seconds}
            onSendCodeAgain={sendCodeToPhone}
            onChange={setters.setCode}
          />
        </FieldWrapper>

        <ButtonWrapper>
          <Button
            color="primary"
            type="submit"
            disabled={!isVerifyCodeButtonAvailable}
            dataTestId="login-sms-next-button"
          >
            Войти
          </Button>
        </ButtonWrapper>
      </form>
    );

  const handleBackButton = () => {
    setPhoneResetRnd(Math.random());
    reset();
    setCurrentState('first_step');
  };

  const renderBackButton = (v: boolean) => v && <BackButton onClick={handleBackButton} />;

  return (
    <Wrapper>
      {renderBackButton(currentState === 'second_step')}
      <HeaderWrapper>
        <Heading>Вход</Heading>
        <LinkFarmik
          dataTestId="login-loginByEmail-button"
          onClick={onChangeType}
          label="Войти по e-mail"
          color="primary"
          underlined
        />
      </HeaderWrapper>

      <FormContent>{renderFormContent(currentState)}</FormContent>

      <FooterWrapper>
        <FooterText>Не зарегистрированы?</FooterText>
        <LinkFarmik
          dataTestId="login-registration-button"
          label="Регистрация"
          color="primary"
          onClick={handleSignUp}
          postfixIcon="arrow_right"
        />
      </FooterWrapper>
    </Wrapper>
  );
});
