enum EContractFileContentType {
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Pdf = 'application/pdf',
}

interface IGetContract {
  id: string;
  name: string;
  organizationId: string;
  contractFileInfo: {
    id: string;
    fileName: string;
    fileContentType: EContractFileContentType;
    fileSize: number;
    downloadUrl: string;
  };
  createdAt: string;
}

export type { IGetContract };

export { EContractFileContentType };
