import queryString from 'querystring';

import { FC, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { has } from 'lodash';
import { Modal, EModalType, useModal } from '@farmlink/farmik-ui';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { useStore, getLink, TypeApiRequest } from '../../../../../../../utils';
import { getValuesEdit, getPayload } from '../../../utils';
import { ScoutsStore, ConfirmStore } from '../../../../../stores';
import { InitialValues } from '../../../../../../../api/models/contractor.model';
import EAgroidRoute from '../../../../../routes/AgroidRoute.enum';
import { ContractorForm } from '../../../components';
import { EModalSize } from '../../../../../modules/common/containers/configs/modals/Modal/styles';
import { AgroidRoutes } from '../../../../../routes';

export interface IParams {
  contractorId: string;
}

export interface ILocation {
  saveButtonTitle: string;
}

export const ContractorRequisites: FC = observer(() => {
  const {
    fetchScout,
    updateScout,
    fetchOptions,
    clearScout,
    fetchScoutApplicationList,
    scout,
    isLoading,
    isLoaded,
    scoutsOptions,
    orgTypesOptions,
    isOptionsLoading,
    logoId,
    logoUrl,
    logoFileName,
  } = useStore(ScoutsStore);

  const {
    runUnBlockRouting,
    stopUnBlockRouting,
    goToNextPage,
    blockAndHide,
    enableRouting,
    visibleConfirm,
    isChanged,
  } = useStore(ConfirmStore);

  const [showEditSuccessModal, setEditSuccessModal] = useState(false);

  const [isScoutingBecameDisabled, setIsScoutingBecameDisabled] = useState(false);

  const history = useHistory();
  const { search, state } = useLocation<ILocation>();
  const { contractorId } = useParams<IParams>();

  const { registerModalList, openModalByModalId, closeModal, getModalPayload } = useModal();

  const searchParams = useMemo(() => queryString.parse(search.slice(1)), []);
  const isScout = useMemo(
    () => (has(searchParams, 'isScout') ? Boolean(searchParams.isScout) : false),
    []
  );

  const initialValues = useMemo(() => getValuesEdit(scout, isScout), [scout, isScout]);

  useEffect(() => {
    fetchOptions();
    fetchScout({ contractorId });

    fetchScoutApplicationList({
      organizationId: contractorId,
      type: 'OWNER',
      status: 'SENT',
    });

    registerModalList(
      [
        {
          name: 'cancellationScoutingModal',
          title: 'Модуль скаутинга отключен. Если вы продолжите, то потеряете все данные',
          type: EModalType.Warning,
          id: 'cancellationScoutingModal',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Продолжить',
            color: 'secondary',
            handler: () => getModalPayload().handleSave(),
          },
          denyButton: {
            title: 'Отменить',
            color: 'default',
            handler: () => {
              console.log('2235 отменям');
              closeModal();
            },
          },
        },
      ],
      'cancellationScoutingModal'
    );

    return () => {
      clearScout();
      enableRouting();
    };
  }, []);

  useEffect(() => {
    if (isChanged) {
      const fn = history.block('Блокировка роута');
      runUnBlockRouting(fn);
    } else {
      enableRouting();
      stopUnBlockRouting();
    }
  }, [isChanged]);

  const handleSubmit = (values: InitialValues) => {
    const data = getPayload(
      initialValues,
      {
        ...values,
        logo: {
          id: values.logo.id || logoId,
          downloadUrl: values.logo.downloadUrl || logoUrl,
          fileName: values.logo.fileName || logoFileName,
        },
      },
      isScout
    );
    if (initialValues.isScout && !values.isScout) {
      setIsScoutingBecameDisabled(true);
    }

    const handleSave = () => {
      const payload = {
        contractorId,
        ...data,
      };

      updateScout(payload)
        .then(() => {
          enableRouting();
          handleOpenEditSuccessModal();
        })
        .catch(e => {
          enableRouting();
          showUpdateContractorErrorModal();
          console.error(e);
        });
    };
    if (initialValues.isScout && !values.isScout) {
      openModalByModalId('cancellationScoutingModal', {
        handleSave,
      });
    } else {
      handleSave();
    }
  };

  const handleCancel = () => {
    history.push(EAgroidRoute.Scouting);
  };

  const handleOpenEditSuccessModal = () => {
    setEditSuccessModal(true);
  };

  const handleCloseEditSuccessModal = () => {
    setEditSuccessModal(false);
  };

  const showUpdateContractorErrorModal = () => {
    registerModalList(
      [
        {
          name: 'updateContractorErrorModal',
          title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
          type: EModalType.Warning,
          id: 'updateContractorErrorModal',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Продолжить',
            color: 'primary',
            handler: closeModal,
          },
        },
      ],
      'updateContractorErrorModal'
    );
    openModalByModalId('updateContractorErrorModal');
  };

  const handleConfirmModalClose = () => {
    blockAndHide();
  };

  if (isLoading || !isLoaded || isOptionsLoading) {
    return (
      <Well>
        <Skeleton height="12px" />
        <Skeleton height="12px" />
      </Well>
    );
  }

  return (
    <>
      {isLoaded && (
        <ContractorForm
          title="Реквизиты организации"
          values={initialValues}
          orgTypesOptions={orgTypesOptions}
          scoutsOptions={[...(scoutsOptions || []), { label: null, value: null }]}
          buttonSubmitTitle={state?.saveButtonTitle}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          type="edit"
          dataTestId="scout-edit"
        />
      )}

      {showEditSuccessModal && (
        <Modal
          modal={{
            name: 'scout-edit-modal',
            title: 'Изменения успешно сохранены',
            type: EModalType.Success,
            id: 'scout-edit-modal-id',
            successButton: {
              title: 'Продолжить',
              handler: () => {
                if (isScoutingBecameDisabled) {
                  history.push(AgroidRoutes.Contractors);
                } else {
                  history.push(`${AgroidRoutes.Contractors}/${contractorId}`);
                }
              },
              dataTestId: 'scout-edit-modal-success-button',
            },
            dataTestId: 'scout-edit-modal-data-test-id',
          }}
          closeModalHandler={handleCloseEditSuccessModal}
        />
      )}

      {visibleConfirm && (
        <>
          <Modal
            modal={{
              name: 'scout-confirm-modal',
              title: 'Уверены, что хотите выйти?',
              description: {
                value:
                  'У вас есть несохраненные данные. Если покинуть экран сейчас, все данные потеряются',
                $styles: {
                  textAlign: 'center',
                  letterSpacing: '-0.15px',
                },
              },
              type: EModalType.Warning,
              id: 'scout-confirm-modal-id',
              denyButton: {
                title: 'Да, выйти',
                handler: () => {
                  goToNextPage();
                },
                dataTestId: 'scout-confirm-modal-deny-button',
              },
              successButton: {
                title: 'Нет, вернуться',
                handler: () => {
                  blockAndHide();
                },
                dataTestId: 'scout-confirm-modal-success-button',
              },
              styledProps: {
                $size: EModalSize.Medium,
              },
              styles: {
                title: {
                  $size: 'large',
                },
              },

              dataTestId: 'scout-confirm-modal-data-test-id',
            }}
            closeModalHandler={handleConfirmModalClose}
          />
        </>
      )}
    </>
  );
});
