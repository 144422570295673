export const getError = (v: string) => {
  const variants = {
    PHONE_NUMBER_IS_BUSY: 'Номер телефона занят',
    VERIFICATION_FAILED: 'Неправильный код',
    INCORRECT_FORMAT: 'Неправильный формат номера',
  };

  // @ts-ignore
  return variants[v] || v;
};
