import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trigger } from '@zendeskgarden/react-dropdowns';
import { observer } from 'mobx-react';

import { ReactComponent as UserIcon } from '../../../../assets/images/agroid/user.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/images/agroid/profile.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/images/agroid/logout.svg';
import { AuthorizationRoutes } from '../../../auth/routes';
import { AgroidRoutes } from '../../routes';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { useStore } from '../../../../utils/IoC';

import AvatarPlaceholder from './avatarPlaceholder.svg';
import { ItemProps } from './HeaderMenu';
import { DropdownStyled, MenuStyled, IconButtonStyled, AvatarStyled, ItemStyled } from './styled';

export const menuAuthItems: Record<string, ItemProps> = {
  index: {
    name: 'Управление профилем',
    link: AgroidRoutes.Profile,
    icon: ProfileIcon,
    slug: 'profile',
  },
  orgs: {
    name: 'Выйти',
    link: '/logout',
    icon: LogoutIcon,
    accent: 'accentPink',
    slug: 'logout',
  },
};

export const menuLoginItems: Record<string, ItemProps> = {
  login: {
    name: 'Войти',
    link: AuthorizationRoutes.signIn,
    icon: LogoutIcon,
    accent: 'accentGreen',
    slug: 'sign-in',
  },
};

const AuthUser = observer(() => {
  const { user } = useStore(ProfileStore);
  return <img src={user?.avatar?.downloadUrl || AvatarPlaceholder} alt={'User Name'} />;
});

const NewUser = () => (
  <UserIcon role="img" aria-label="User" style={{ width: '20px', height: '20px' }} />
);

export const UserMenu: FC = () => {
  const [isLogged] = useState(true);

  const [menuItems, Toggler] = useMemo(() => {
    if (isLogged) {
      return [menuAuthItems, AuthUser];
    } else {
      return [menuLoginItems, NewUser];
    }
  }, [isLogged]);

  return (
    <DropdownStyled>
      <Trigger>
        <IconButtonStyled aria-label="plant" data-test-id="header-menu-item-user-icon-button">
          <AvatarStyled size="large" data-test-id="header-menu-item-user-avatar">
            <Toggler />
          </AvatarStyled>
        </IconButtonStyled>
      </Trigger>
      <MenuStyled hasArrow placement="bottom-end" data-test-id="header-user-menu">
        {Object.entries(menuItems).map(([key, { name, link, icon: Icon, accent, slug }]) => (
          <ItemStyled
            value={link}
            accent={accent}
            key={key}
            data-test-id={`header-user-menu-item-${slug}`}
          >
            <Link to={link} data-test-id={`header-user-menu-item-${slug}-link`}>
              {Icon && <Icon />}
              {name}
            </Link>
          </ItemStyled>
        ))}
      </MenuStyled>
    </DropdownStyled>
  );
};
