import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios } from '../../../../../utils/axios2';
import { INVITE_URL } from '../../../../agroid/constants/invites';

export interface InviteInfo {
  invitefullUrl: string;
  userId?: string;
  inviteEmail?: string;
  token?: string;
}

@provide.singleton()
export class InviteStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  organizationName = '';
  isLoading = true;
  isAccept = true;
  inviteEmail = '';
  status;
  userId;
  logoUrl;
  invitationId;
  orgType;
  inviteToken;

  fetchOrganizationInfo = (token: string) => {
    this.isLoading = true;
    return this.axios.api
      .getOrganizationExternal({ token }, { omit: ['token'] })
      .then(response => {
        runInAction(() => {
          this.isAccept = true;
          this.isLoading = false;
          this.organizationName = response.name;
          this.inviteEmail = response.email;
          this.status = response.status;
          this.userId = response.userId;
          this.logoUrl = response.logo?.downloadUrl;
          this.invitationId = response.invitationId;
          this.orgType = response.type?.OrgType || '';
          this.inviteToken = token;
          // console.log(this.status);
          // console.log(this.userId);

          localStorage.setItem('externalKey', response.externalKey);
          localStorage.setItem('externalEmail', response.email);
        });
        return response.status;
      })
      .catch(error => {
        console.error(error);

        this.isAccept = false;
        this.isLoading = false;
      });
  };

  setInviteInfo = (value: InviteInfo) => {
    console.log('setInviteInfo:', value);
    localStorage.setItem(INVITE_URL, JSON.stringify(value));
  }

  getInviteInfo = (): InviteInfo => JSON.parse(localStorage.getItem(INVITE_URL));

  clearInviteInfo = () => localStorage.removeItem(INVITE_URL);
  
  resetInviteStore = () => {
    this.organizationName = '';
  };
}
