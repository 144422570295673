import { TypeApiRoute } from '../../../models/type.api.request';

type TDeleteContractRequest = {
  contractId: string;
};

type TDeleteContractResponse = {};

const deleteContract: TypeApiRoute & {
  request: TDeleteContractRequest;
  response: TDeleteContractResponse;
} = {
  url: ({ contractId }) => `/api/as-contracts/contracts/${contractId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TDeleteContractRequest,
  response: {} as TDeleteContractResponse,
};

export type { TDeleteContractRequest, TDeleteContractResponse };

export { deleteContract };
