import { TypeService } from '../models/service.model';
import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

type TypeRequest = {
  size: number;
  page: number;
  search: string;
  categoryId: string;
};

type TypeResponse = {
  content: Array<TypeService>;
} & TypeResponsePageable;

export const getServices: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-services/services/list?size=${request.size}&page=${request.page}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
