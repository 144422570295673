import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect } from 'react';

import { useStore } from '../../../../../../../../utils';
import { useModal } from '../../../../../../hooks';
import { ModalsStore } from '../../../../stores/modals/Modals.store';
import Modal from '../Modal/Modal';

import { PropsStyledModal, StyledModalWrapper } from './styles';

type PropsModalContainer = {
  // uniqueKey: string;
} & PropsStyledModal;

const ModalContainer: FC<PropsModalContainer> = observer(({ ...styled }) => {
  const { getModal, clearModal } = useStore(ModalsStore);
  const { activeModal, closeModal } = useModal();

  const closeModalHandler = useCallback((): void => {
    closeModal();
    clearModal(activeModal);
  }, []);

  const modal = getModal(activeModal);

  return (
    <StyledModalWrapper>
      {modal && (
        <Modal
          modal={modal}
          uniqueKey={activeModal}
          closeModalHandler={closeModalHandler}
          {...styled}
        />
      )}
    </StyledModalWrapper>
  );
});

export default ModalContainer;
