import { useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const IS_MOBILE_BREAKPOINT = 767;

/**
 * @using const [width, height] = useWindowSize();
 * @returns [number, number]
 */
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([isMobile ? IS_MOBILE_BREAKPOINT : window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
