import { FC } from 'react';
import { observer } from 'mobx-react';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import { ReactComponent as EndIcon } from '../../../../../../assets/images/agroid/clearIcon.svg';

import { bridge as searchSchema } from './search.schema';
import { bridge as contractorSchema } from './contractor.schema';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  dataTestId: string;
  title: string;
}

export const ModalSearch: FC<Props> = observer(
  ({ value, onChange, onClear, dataTestId, title }) => {
    const searchSchemaForUse = title == 'Контрагенты' ? contractorSchema : searchSchema;

    return (
      <AutoForm schema={searchSchemaForUse} data-test-id={`${dataTestId}-form`}>
        <Grid>
          <Row>
            <Col xs={12} textAlign="start">
              <AutoField
                name="search"
                value={value}
                onChange={onChange}
                end={EndIcon}
                onClickEndIcon={onClear}
                hideEndIconEmptyValue
                dataTestId={dataTestId}
              />
              <ErrorField name="search" />
            </Col>
          </Row>
        </Grid>
      </AutoForm>
    );
  }
);
