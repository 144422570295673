import { EModalType, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { EContractFileContentType } from '../../../../../../../api/models/as-contracts/contract/contract.model';
import { useStore } from '../../../../../../../utils';
import { ListingPageController } from '../../../controllers/configs/pages';
import { EModalSize } from '../../configs/modals/ModalContainer/styles';
import { TListingPageParams } from '../../configs/pages/ListingPageContainer/ListingPageContainer.types';

import ListingPageContractsContainerController from './controllers/ListingPageContractsContainer.controller';
import Styled from './ListingPageContractsContainer.styles';
import { TListingPageContractsContainer } from './ListingPageContractsContainer.types';

const ALLOWED_FILE_LIST = [
  EContractFileContentType.Doc,
  EContractFileContentType.Docx,
  EContractFileContentType.Pdf,
];

const ListingPageContractsContainer: FC<TListingPageContractsContainer> = observer(
  ({ isClickedOnActionButton, handleUpdateData, handleHidingCustomComponent }) => {
    const { uploadFile } = useStore(ListingPageController);

    const { createContract } = useStore(ListingPageContractsContainerController);

    const { orgId, contractorId } = useParams<TListingPageParams>();

    const { registerModalList, getModalPayload, closeModal, openModalByModalId } = useModal();

    const fileUploaderRef = useRef(null);

    useEffect(() => {
      registerModalList(
        [
          {
            name: 'loadingContractResultModal',
            title: getModalPayload()?.message,
            type: getModalPayload()?.status,
            id: 'cancellationScoutingModal',
            styledProps: {
              $size: EModalSize.Medium,
            },
            successButton: {
              title: 'Продолжить',
              color: getModalPayload()?.status === EModalType.Success ? 'primary' : 'secondary',
              handler: () => closeModal(),
            },
          },
        ],
        'loadingContractResultModal'
      );
    }, []);

    const handleChangeFileUploader = useCallback(
      async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const [file] = event.target.files;

        if (!file) {
          return;
        }

        if (file.size > 1e8) {
          registerModalList(
            [
              {
                name: 'loadingContractResultModal',
                title: 'Загружаемый файл превышает размер 100Мб',
                type: EModalType.Warning,
                id: 'loadingContractResultModal',
                styledProps: {
                  $size: EModalSize.Medium,
                },
                successButton: {
                  title: 'Продолжить',
                  color: 'secondary',
                  handler: () => closeModal(),
                },
              },
            ],
            'loadingContractResultModal'
          );
          openModalByModalId('loadingContractResultModal');
          console.error('THIS_UPLOADED_FILE_IS_TOO_BIG');
          handleHidingCustomComponent();

          return;
        }

        if (!(ALLOWED_FILE_LIST as string[]).includes(file.type)) {
          registerModalList(
            [
              {
                name: 'loadingContractResultModal',
                title: 'Загружаемый файл должен быть формата doc, docx, pdf',
                type: EModalType.Warning,
                id: 'loadingContractResultModal',
                styledProps: {
                  $size: EModalSize.Medium,
                },
                successButton: {
                  title: 'Продолжить',
                  color: 'secondary',
                  handler: () => closeModal(),
                },
              },
            ],
            'loadingContractResultModal'
          );
          openModalByModalId('loadingContractResultModal');
          console.error('Неподходящий формат файла!');
          handleHidingCustomComponent();

          return;
        }


        const uploadedFileId = await uploadFile(file);

        if (!uploadedFileId) {
          return;
        }

        await createContract({
          organizationId: orgId || contractorId,
          fileId: uploadedFileId,
        });

        handleUpdateData();
        handleHidingCustomComponent();

        registerModalList(
          [
            {
              name: 'loadingContractResultModal',
              title: 'Файл загружен',
              type: EModalType.Success,
              id: 'loadingContractResultModal',
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Продолжить',
                color: 'primary',
                handler: () => closeModal(),
              },
            },
          ],
          'loadingContractResultModal'
        );
        openModalByModalId('loadingContractResultModal');
      },
      []
    );

    return (
      <>
      <Styled.Input
        type="file"
        name="file"
        id="contract-file-input"
        ref={fileUploaderRef}
        accept={ALLOWED_FILE_LIST.join(', ')}
        onChange={handleChangeFileUploader}
        onClick={event => event.stopPropagation()}
      />
      </>
    );
  }
);

ListingPageContractsContainer.displayName = 'ListingPageContractsContainer';

export default ListingPageContractsContainer;
