import EAgroidRoute from '../../../../routes/AgroidRoute.enum';
import { ReactComponent as ScoutingSvg } from '../../../../../../assets/images/agroid/scouting.svg';
import { ScoutingRouter } from '../../../../pages';
import { createPage } from '../../../common/utils/helpers/configs/pages/cretePage';
import { EAccessRuleScoutingName } from '../../../../types';

const scoutingPage = createPage({
  link: {
    label: 'Скаутинг',
    path: EAgroidRoute.Scouting,
    labelLogo: ScoutingSvg,
    accessRule: EAccessRuleScoutingName.workWithContractors,
  },
  Component: ScoutingRouter,
});

export default scoutingPage;
