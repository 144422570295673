import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AutoField, AutoForm, ErrorField } from 'uniforms-unstyled';
import SimpleSchema from 'simpl-schema';
import SimpleSchema2Bridge from 'uniforms-bridge-simple-schema-2';
import { ReactComponent as StartIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { DeepPartial } from 'uniforms';
import { Dropdown } from '@farmlink/farmik-ui';

import { ReactComponent as EndIcon } from '../../../../../../../../assets/images/agroid/clearIcon.svg';
import { useStore } from '../../../../../../../../utils';
import { RolesStore } from '../../../../../../stores';
import { MediaInputField } from '../../../../../../components/uniforms';

import { TListingPageFilterContainerProps } from './ListingPageFilterContainer.types';

const createFilterSchema = (
  config: TListingPageFilterContainerProps['filter']['filterBy']
): any => {
  const schema: any = {
    [config.searchQuery.key]: {
      type: String,
      label: config.searchQuery.placeholder || 'Поиск',
      required: config.searchQuery.required,
      uniforms: {
        component: MediaInputField,
        start: StartIcon,
        end: EndIcon,
        label: false,
        placeholder: config.searchQuery.placeholder || 'Поиск',
      },
    },
  };

  if (config?.status) {
    schema[config.status.key] = {
      type: String,
      label: 'Статусы',
      uniforms: {
        label: false,
        placeholder: 'Статусы',
      },
    };
  }

  if (config?.role) {
    schema[config.role.key] = {
      type: String,
      label: 'Роли',
      uniforms: {
        label: false,
        placeholder: 'Роли',
      },
    };
  }

  return schema;
};

const getSearchQuerySize = (
  config: TListingPageFilterContainerProps['filter']['filterBy']
): number => {
  const filterByList = Object.keys(config);

  if (filterByList.length === 1) return 12;

  if (filterByList.length === 2) return 9;

  return 6;
};

const ListingPageFilterContainer: FC<TListingPageFilterContainerProps> = observer(
  ({ searchQueryValue, onChange, filter, setClearableFieldKey }) => {
    const { roles } = useStore(RolesStore);

    /**
     * Данный костыль необходим для того, чтобы при очистке формы,
     * когда мы нажали на крестик в инпуте, очищались данные в самом компоненте "AutoForm"
     */
    const [formModel, setFormModel] = useState({});

    // const roleOptionList = [
    //   { value: 'Все роли', label: 'Все роли' },
    //   ...roles.map(role => ({
    //     value: role.id,
    //     label: role.name,
    //   })),
    // ];

    const roleOptionList = useMemo(() => {
      const _roleOptionList = [{ value: 'Все роли', label: 'Все роли' }];

      if (roles) {
        _roleOptionList.push(
          ...roles.map(role => ({
            value: role.id,
            label: role.name,
          }))
        );
      }

      return _roleOptionList;
    }, [roles]);

    const statusOptionList = useMemo(() => {
      const _statusOptionList = [{ value: 'Все статусы', label: 'Все статусы' }];

      if (filter.filterBy?.status?.optionList) {
        _statusOptionList.push(
          ...Object.keys(filter.filterBy?.status?.optionList).map(key => ({
            value: key,
            label: filter.filterBy?.status?.optionList[key],
          }))
        );
      }

      return _statusOptionList;
    }, [filter?.filterBy]);

    const schema = new SimpleSchema2Bridge(new SimpleSchema(createFilterSchema(filter.filterBy)));

    const statusDropdownProps = {
      field: {
        isRequired: false,
        placeholder: '',
        id: filter.filterBy?.status?.key,
        defaultValue: statusOptionList[0],
        onChange: args => {
          const newFormModel = { ...formModel, [filter.filterBy?.status?.key]: args };
          console.log('shit statusDropdownProps', newFormModel, formModel);
          setFormModel(newFormModel);
          onChange?.(newFormModel);
        },
      },
      body: {
        optionList: statusOptionList,
      },
      visual: {
        isBlocked: false,
      },
      other: {
        dataTestId: `filter-dropdown-${filter.filterBy?.status?.key}`,
      },
    };

    const roleDropdownProps = {
      field: {
        isRequired: false,
        placeholder: '',
        id: filter.filterBy?.role?.key,
        defaultValue: roleOptionList[0],
        onChange: args => {
          const newFormModel = { ...formModel, [filter.filterBy?.role?.key]: args };
          console.log('shit roleDropdownProps', newFormModel, formModel);
          // setFormModel(newFormModel);
          onChange?.(newFormModel);
        },
        icons: {
          clear: {
            options: {
              valueAfterCleaning: { value: 'Все роли', label: 'Все роли' },
            },
          },
        },
      },
      body: {
        optionList: roleOptionList,
      },
      visual: {
        isBlocked: false,
      },
      other: {
        dataTestId: `filter-dropdown-${filter.filterBy?.role?.key}`,
      },
    };

    const isClearable = useMemo(() => filter.filterBy.searchQuery.isClearable, [filter]);

    const onClickEndIcon = useCallback(() => {
      const clearedModel = { ...formModel };
      clearedModel[filter?.filterBy?.searchQuery?.key] = '';
      setFormModel(clearedModel);

      setClearableFieldKey(filter.filterBy.searchQuery.key);
    }, []);

    const handleFilterChange = useCallback((modelVal: DeepPartial<unknown>) => {
      // setFormModel(modelVal);

      onChange?.(modelVal);
    }, []);

    return (
      <AutoForm schema={schema} onChangeModel={handleFilterChange} model={formModel}>
        <Grid>
          <Row>
            <Col
              sm={getSearchQuerySize(filter.filterBy)}
              xs={12}
              textAlign="start"
              data-test-id={'filter-input-search-wrap'}
            >
              <AutoField
                value={searchQueryValue}
                name={filter.filterBy.searchQuery.key}
                end={EndIcon}
                onClickEndIcon={onClickEndIcon}
                hideEndIconEmptyValue
                isWithoutValidation
                isHideClearIcon={!isClearable}
                dataTestId={`filter-input-${filter.filterBy.searchQuery.key}`}
              />
            </Col>
            {filter.filterBy?.status ? (
              <Col sm={3}>
                <AutoField
                  name={filter.filterBy?.status?.key}
                  component={Dropdown}
                  options={statusOptionList}
                  isOpenAbove={true}
                  marginBottomForSelectBody={'-20px'}
                  placeholder={'Статусы'}
                  config={statusDropdownProps}
                />
                <ErrorField name={filter.filterBy?.status?.key} />
              </Col>
            ) : null}
            {filter.filterBy?.role ? (
              <Col sm={3}>
                <AutoField
                  name={filter.filterBy?.role?.key}
                  component={Dropdown}
                  options={roleOptionList}
                  isOpenAbove={true}
                  marginBottomForSelectBody={'-20px'}
                  placeholder={'Роли'}
                  config={roleDropdownProps}
                />
                <ErrorField name={filter.filterBy?.role?.key} />
              </Col>
            ) : null}
          </Row>
        </Grid>
      </AutoForm>
    );
  }
);

export default ListingPageFilterContainer;
