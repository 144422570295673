import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  id: string;
  name: string;
  actionCodes: Array<string>;
};

type TypeResponse = {};

export const updateRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-profile/access/role/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
