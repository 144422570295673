import {
  FC,
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import { isMobileOnly, isMobile } from 'react-device-detect';
import Toggle from 'react-toggle';
import { Label, Input } from '@zendeskgarden/react-forms';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { generatePath, useHistory } from 'react-router-dom';

import { baseSchema as RoleSchema } from '../NewRoleModal/schema';
import { RolesStore } from '../../stores/roles.store';
import { useStore } from '../../../../utils';
import { PromptModal } from '../';
import { TypeRoleSettings } from '../../../../api/models/role.model';

import { ReactComponent as ArrowSvg } from './assets/arrow.svg';
import { ReactComponent as CancelSvg } from './assets/cancel.svg';
import {
  ColsWrapper,
  ModuleRow,
  ActionRow,
  RoleFormWrapper,
  FieldStyled,
  LoadderWrapper,
} from './styled';
import { unsavedDataModal } from './modals/unsavedDataModal';

export interface RoleFormProps extends HTMLAttributes<HTMLDivElement> {
  onChange?: any;
  modules?: any;
  roleSettings?: Partial<TypeRoleSettings>;
  roleId: string;
  disableAll?: boolean;
  isDisabledAction?: (action: any) => boolean;
}

const getErrorMessage = ({ title }) => {
  switch (title) {
    case 'DUPLICATED_VALUE':
      return 'Роль с таким названием уже существует';
    case 'FIELD_IS_EMPTY':
      return 'Название роли не может быть пустым';

    default:
      return 'Ошибка редактирования роли';
  }
};

const DEFAULT_NAVIGATION_VALUE = { path: null, allowToNavigate: false };

export const RoleForm: FC<RoleFormProps> = observer(
  ({ roleId, onChange, modules, roleSettings, isDisabledAction }) => {
    const colsRef = useRef<HTMLDivElement>();
    const [calculatedHeight, setCalculatedHeight] = useState('initial');
    const [settings, setNewSetings] = useState<any>(resetSettings);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState('');
    const { isLoading, updateRole } = useStore(RolesStore);
    const [navigation, setNavigation] = useState(DEFAULT_NAVIGATION_VALUE);
    const inputRef = useRef<HTMLInputElement>();
    const { registerModalList } = useModal();
    const history = useHistory();

    const getDataTestId = (
      name: string,
      dynamicValue?: string | number
    ): { 'data-test-id': string } => {
      return {
        'data-test-id': `role-page-role-settings-${name}${dynamicValue ? `-${dynamicValue}` : ''}`,
      };
    };

    const handleUpdateSelectedLocation = (locationPath: string) => {
      setNavigation({ path: locationPath, allowToNavigate: false });
    };

    const validationContext = useMemo(() => RoleSchema.newContext(), []);

    const validateValue = useCallback(
      (value: string) => {
        validationContext.validate({
          roleName: value,
        });
        const whiteSpace = new RegExp(/^\s+$/);

        setError('');

        if (whiteSpace.test(value)) {
          setError('Название роли не может быть пустым');
        }

        if (!validationContext.isValid()) {
          const errors = validationContext.validationErrors();
          const keyErrorMessage = validationContext.keyErrorMessage(errors[0].name);

          setError(keyErrorMessage);

          if (value === '') {
            setError('Название роли не может быть пустым');
            return true;
          }

          return false;
        }

        return true;
      },
      [validationContext]
    );

    const callUpdateRole = useCallback(
      e => {
        if (e) {
          e.preventDefault();
        }

        setError('');
        validationContext.validate({
          roleName: settings.name,
        });

        if (!validationContext.isValid()) {
          const errors = validationContext.validationErrors();
          const keyErrorMessage = validationContext.keyErrorMessage(errors[0].name);

          setError(keyErrorMessage);
        } else {
          updateRole(settings)
            .then(() => {
              setChanged(false);
              onChange([]);
              if (navigation.path) {
                setNavigation({ path: generatePath(navigation.path), allowToNavigate: true });
              }
            })
            .catch(responseError => {
              const errorMessage = getErrorMessage(responseError.response.data.error);
              setError(errorMessage);
            });
        }
      },
      [navigation.path, settings, validationContext]
    );

    const cancelRoleUpdate = useCallback(
      e => {
        if (e) {
          e.preventDefault();
        }

        setError('');
        setNewSetings(resetSettings());
        setChanged(false);
        onChange([]);

        if (navigation.path) {
          setNavigation({ path: generatePath(navigation.path), allowToNavigate: true });
        }
      },
      [navigation.path]
    );

    const modalConfig = unsavedDataModal(callUpdateRole, cancelRoleUpdate);

    useEffect(() => {
      if (navigation.allowToNavigate) {
        history.push(navigation.path);
      }
    }, [navigation.allowToNavigate, navigation.path]);

    useEffect(() => {
      registerModalList([modalConfig], 'roleFormModals');
    }, [modalConfig]);

    useEffect(() => {
      setChanged(false);
      onChange([]);
    }, [roleId]);

    useEffect(() => setNewSetings(resetSettings), [JSON.stringify(roleSettings)]);

    const roleActions = [
      {
        caption: 'Отменить',
        component: Anchor,
        icon: !isMobileOnly && CancelSvg,
        positionIcon: 'right',
        componentProps: {
          isBasic: true,
          onClick: e => cancelRoleUpdate(e),
        },
      },
      {
        caption: 'Сохранить',
        icon: ArrowSvg,
        component: Anchor,
        positionIcon: 'right',
        componentProps: {
          isPrimary: true,
          onClick: e => callUpdateRole(e),
        },
      },
    ];

    function resetSettings() {
      const newSettings: any = {};
      newSettings.name = roleSettings.name;
      newSettings.id = roleSettings.id;
      newSettings.isExperimentForOrganization = roleSettings.isExperimentForOrganization;
      newSettings.isExperimentForUser = roleSettings.isExperimentForUser;
      newSettings.isScoutForOrganization = roleSettings.isScoutForOrganization;
      newSettings.isScoutForUser = roleSettings.isScoutForUser;
      newSettings.order = 0;
      newSettings.actionCodes = roleSettings.actions
        ? roleSettings.actions.map(item => item.code)
        : [];

      return newSettings;
    }

    useEffect(() => {
      if (changed) {
        onChange(Object.values(roleActions));
      }
    }, [changed, JSON.stringify(settings)]);

    useLayoutEffect(() => {
      if (!isMobile) {
        if (colsRef && colsRef.current) {
          const { y: offset = 277 } = colsRef?.current.getBoundingClientRect();
          setCalculatedHeight(`calc(100vh - ${offset}px - 16px)`);
        }
      }
    }, [colsRef.current, inputRef]);

    const checkedRole = code =>
      (settings.actionCodes || []).findIndex(action => action === code) > -1;

    const handleChangeText = event => {
      const { value } = event.target;

      if (validateValue(value)) {
        setNewSetings(prev => ({ ...prev, name: event.target.value }));
        setChanged(true);
      }
    };

    const toggleAction = rCode => {
      let newCheckedList = [...settings.actionCodes];
      const checkedIndex = newCheckedList.findIndex(i => i === rCode);
      if (checkedIndex > -1) {
        newCheckedList = newCheckedList.filter(i => i !== rCode);
      } else {
        newCheckedList.push(rCode);
      }
      setNewSetings(prev => ({ ...prev, actionCodes: newCheckedList }));
      setChanged(true);
    };

    if (isLoading) {
      return (
        <LoadderWrapper>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </LoadderWrapper>
      );
    }

    return (
      <RoleFormWrapper className="role-form-wrapper">
        <PromptModal changed={changed} setSelectedLocation={handleUpdateSelectedLocation} />
        <FieldStyled {...getDataTestId('role-name')}>
          <Label>Название роли</Label>
          <Input
            value={settings.name}
            onChange={handleChangeText}
            disabled={!roleSettings.canEdit}
            ref={inputRef}
            maxLength={50}
            data-test-id={`role-page-role-settings-${settings.name}`}
          />
        </FieldStyled>
        {Boolean(error) && <div id="uniforms-name">{error}</div>}
        <ColsWrapper ref={colsRef} className={'cols-wrapper'} {...getDataTestId('options-wrap')}>
          {modules.map(({ name, id, code, actions = [] }) => (
            <ModuleRow data-code={code} key={id}>
              <ModuleRow.Name
                {...getDataTestId('module-name', name.toLowerCase().replaceAll(' ', '-'))}
              >
                {name}
              </ModuleRow.Name>
              {actions.map(action => (
                <ActionRow key={action.id}>
                  <ActionRow.Label
                    disabled={!roleSettings.canEdit ? true : checkedRole(action.code)}
                    data-test-id={`role-form-checkbox-label-${action.name
                      .toLowerCase()
                      .replaceAll(' ', '-')}`}
                  >
                    {action.name}
                  </ActionRow.Label>
                  <ActionRow.CheckboxWrapper>
                    <Toggle
                      checked={checkedRole(action.code)}
                      icons={false}
                      disabled={!roleSettings.canEdit ? true : isDisabledAction(action)}
                      onChange={() => toggleAction(action.code)}
                      data-test-id={`role-form-checkbox-input-${action.name
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}
                    />
                  </ActionRow.CheckboxWrapper>
                </ActionRow>
              ))}
            </ModuleRow>
          ))}
        </ColsWrapper>
      </RoleFormWrapper>
    );
  }
);
