import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';
import { Breakpoints } from '../../../../../../constants/sizes';

export const InputWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Image = styled.img`
  display: block;
  margin: 42px auto;

  @media (max-width: ${Breakpoints.small}) {
    margin-top: 22px;
  }
`;

export const EditPersonalDataEditFormAction = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: flex-end;
  @media (max-width: ${Breakpoints.small}) {
    margin-top: 24px;
    justify-content: center;
  }
  & > div:first-child {
    margin-left: 0;
  }
`;

export const EditPersonalDataEditFormActionButton = styled.div`
  width: 160px;
  margin-left: 20px;

  & button {
    justify-content: center;
    cursor: pointer;
  }
`;

export const ModalButtonWrapper = styled(EditPersonalDataEditFormActionButton)`
  width: 100%;
  margin-left: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
  text-align: left;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  &.success {
    text-align: center;
    @media (max-width: ${Breakpoints.small}) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
`;

export const ModalDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.lightnessGray};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  @media (max-width: ${Breakpoints.small}) {
    font-size: 12px;
    line-height: 16px;
  }

  &.success {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 36px;
    padding: 0;
    border: none;

    @media (max-width: ${Breakpoints.small}) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 32px;
    }
  }
`;

export const ModalAction = styled.div`
  margin-top: 24px;

  @media (max-width: ${Breakpoints.small}) {
    margin-top: 32px;
  }
`;

export const CancelButton = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  cursor: pointer;
  margin-top: 20px;
`;
