import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Password, Separator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../utils';
import { ResetStore } from '../../stores/reset.store';
import { AuthorizationRoutes } from '../../../../routes';
import { LoadingWrapper } from '../VerifyEmail/style';
import { IncorrectQuery } from '../IncorrectQuery';
import { AgroidRoutes } from '../../../../../agroid/routes';

import PasswordIcon from './assets/password.png';
import {
  ButtonWrapper,
  FieldWrapper,
  Header,
  ImageWrapper,
  LineWrapper,
  SuccessHeading,
  SuccessText,
  Wrapper,
} from './style';

export const SetNewPassword = withRouter(
  observer(({ location, history }: RouteComponentProps) => {
    const {
      password,
      passwordRepeat,
      setNewPassword,
      setPassword,
      isChangePasswordAvailable,
      // isLoadingVerifyLink,
      verifyResetToken,
      updatePassword,
      resetSteps,
      resetLoadingForVerifyPasswordLink,
      isLinkSetNewPasswordValid,
      isLoadingSetNewPassword,
    } = useStore(ResetStore);

    useEffect(() => {
      const params: any = new URLSearchParams(location.search.substr(1));
      verifyResetToken(params.get('resetToken'));
      return () => {
        resetLoadingForVerifyPasswordLink();
      };
    }, [location, verifyResetToken]);

    const handleSubmit = (e: any) => {
      e.preventDefault();
      updatePassword();
    };

    const handleSignIn = () => {
      history.push(AgroidRoutes.Index);
    };

    if (isLoadingSetNewPassword) {
      return <LoadingWrapper>загрузка</LoadingWrapper>;
    }

    if (!isLinkSetNewPasswordValid) {
      return <IncorrectQuery text="Ссылка устарела" to={AuthorizationRoutes.signIn} />;
    }

    if (resetSteps === 'final') {
      return (
        <Wrapper>
          <ImageWrapper>
            <img src={PasswordIcon} alt="password recovered" />
          </ImageWrapper>
          <SuccessHeading>Пароль успешно изменен</SuccessHeading>
          <SuccessText>Вы можете войти с новыми данными</SuccessText>
          <ButtonWrapper>
            <Button type="button" color="primary" onClick={handleSignIn}>
              Войти
            </Button>
          </ButtonWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>Восстановление пароля</Header>
        <LineWrapper>
          <Separator />
        </LineWrapper>
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <Password
              label="Придумайте пароль"
              placeholder=""
              value={password.value}
              onChange={setPassword}
              dataTestId="setNewPassword-password-input"
            />
          </FieldWrapper>
          <FieldWrapper>
            <Password
              label="Повторите пароль"
              placeholder=""
              value={passwordRepeat.value}
              onChange={setNewPassword}
              dataTestId="setNewPassword-repeatPassword-input"
            />
          </FieldWrapper>
          <ButtonWrapper>
            <Button
              dataTestId="setNewPassword-change-button"
              type="submit"
              color="primary"
              disabled={!isChangePasswordAvailable}
            >
              Изменить
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  })
);
