import { FC, memo } from 'react';
import { Button, ButtonProps } from '@farmlink/farmik-ui';

import { IModalConfig } from '../modalConfig';
import SuccessSvg from '../../../../../../../../assets/images/success.svg';
import WarningSvg from '../../../../../../../../assets/images/cancel.svg';

import * as Styled from './styles';

enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

type PropsModal = {
  modal: IModalConfig;
  uniqueKey: string;
  closeModalHandler: () => void;
} & Styled.PropsStyledModal;

const Modal: FC<PropsModal> = ({
  modal: { title, type, successButton, denyButton, dataTestId, Component, componentProps },
  uniqueKey,
  closeModalHandler,
  ...styled
}) => {
  const denyButtonHandler = async (): Promise<void> => {
    await denyButton?.handler?.();
    closeModalHandler();
  };

  console.log('in Modal tsx', Component);

  const successButtonHandler = async (): Promise<void> => {
    await successButton?.handler?.();
    closeModalHandler();
  };

  return (
    <Styled.Overlay>
      <Styled.Modal {...styled} data-test-id={dataTestId ? dataTestId : `${uniqueKey}-modal`}>
        {type !== EModalType.Custom ? (
          type === EModalType.Success ? (
            <Styled.MarkImg src={SuccessSvg} />
          ) : (
            <Styled.MarkImg src={WarningSvg} />
          )
        ) : type === EModalType.Custom && Component ? (
          <Component {...componentProps} />
        ) : null}
        {type !== EModalType.Custom ? <Styled.LabelTitle>{title}</Styled.LabelTitle> : null}
        <Styled.ButtonActionWrapper>
          {denyButton && (
            <Button
              color={denyButton?.color || 'default'}
              type={'button'}
              disabled={denyButton?.disabled}
              dataTestId={
                denyButton.dataTestId ? denyButton.dataTestId : `${uniqueKey}-modal-deny-button`
              }
              onClick={denyButtonHandler}
            >
              {denyButton.title}
            </Button>
          )}
          {successButton && (
            <Button
              color={successButton?.color || 'primary'}
              type={'button'}
              disabled={successButton?.disabled}
              dataTestId={
                successButton.dataTestId
                  ? successButton.dataTestId
                  : `${uniqueKey}-modal-accept-button`
              }
              onClick={successButtonHandler}
            >
              {successButton.title}
            </Button>
          )}
        </Styled.ButtonActionWrapper>
      </Styled.Modal>
    </Styled.Overlay>
  );
};

export default memo(Modal);
