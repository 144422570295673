import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';

import { useStore } from '../../../../../../utils';
import { ResetStore } from '../../stores/reset.store';
import { IncorrectQuery } from '../IncorrectQuery';
import { AuthorizationRoutes } from '../../../../routes';

import { LoadingWrapper } from './style';

export const VerifyEmail = withRouter(
  observer(({ location }: RouteComponentProps) => {
    const {
      emailVerify,
      isEmailVerificationSuccess,
      isEmailVerificationLoading,
      resetEmailVerification,
      isEmailRegistred,
    } = useStore(ResetStore);

    useEffect(() => {
      const params: any = new URLSearchParams(location.search.substr(1));
      emailVerify(params.get('verificationHash'));
      return () => {
        resetEmailVerification();
      };
    }, [emailVerify, location.search]);

    if (isEmailVerificationLoading) {
      return <LoadingWrapper>загрузка</LoadingWrapper>;
    }

    if (isEmailVerificationSuccess) {
      return <IncorrectQuery to={AuthorizationRoutes.signIn} text="Почта подтверждена" />;
    }
    return (
      <IncorrectQuery
        to={AuthorizationRoutes.signIn}
        text={
          isEmailRegistred
            ? 'В система зарегистрирован пользователь с этим email'
            : 'Ссылка устарела'
        }
      />
    );
  })
);
