import { Input, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { FC, useEffect, useState } from 'react';

import {
  CodeDescription,
  CodeError,
  CodeRepeatMock,
  CodeRepeatWrapper,
  CodeSendAgain,
  Wrapper,
} from './style';

type SmsCodeProps = {
  label: string;
  placeholder: string;
  error: string;
  value: string;
  onChange: (v: string) => void;
  onSendCodeAgain: () => void;
  seconds: number;
  disabled?: boolean;
};

export const SmsCode: FC<SmsCodeProps> = ({
  value,
  error,
  label,
  onChange,
  seconds,
  onSendCodeAgain,
  disabled,
}) => {
  const [smsCode, setSmsCode] = useState<string>('');
  const [isErrorShown, setIsErrorShown] = useState<boolean>(false);
  const isDigits = (str: string) => /^\d+$/.test(str) || str === '';

  const handleChange = (v: string) => {
    if (!isDigits(v) || disabled) {
      return;
    }
    setIsErrorShown(false);
    setSmsCode(v);
    onChange(v);
  };

  useEffect(() => {
    if (error) {
      setIsErrorShown(true);
    }
  }, [error]);

  useEffect(() => {
    if (value) {
      setSmsCode(value);
    }
  }, [value]);

  return (
    <Wrapper data-test-id="sms-code-wrapper">
      <Input
        label={label}
        focus={false}
        error=""
        value={smsCode}
        type="text"
        onChange={handleChange}
        maxLength={6}
        disabled={disabled}
        dataTestId="sms-code-input"
        className="smsCodeInput"
      />
      <CodeRepeatWrapper data-test-id="sms-code-repeat-wrapper">
        {isErrorShown ? (
          <CodeError data-test-id="sms-code-input-error">{error}</CodeError>
        ) : (
          <CodeDescription data-test-id="sms-code-description">Не получили код?</CodeDescription>
        )}
        {seconds <= 0 ? (
          <CodeSendAgain data-test-id="sms-code-send-again">
            <LinkFarmik
              label="Отправить еще раз"
              color="primary"
              onClick={onSendCodeAgain}
              dataTestId="sms-code-send-another"
              className="smsCodeSendAnother"
            />
          </CodeSendAgain>
        ) : (
          <CodeRepeatMock data-test-id="sms-code-send-another">
            Отправить еще раз ({seconds})
          </CodeRepeatMock>
        )}
      </CodeRepeatWrapper>
    </Wrapper>
  );
};
