import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  background: ${Colors.white};
  padding: 40px;
  margin: 20px auto;
  text-align: justify;
  overflow-y: scroll;
  ul {
    list-style: none;
  }
`;
