import { TypeApiRoute } from '../models/type.api.request';
import { TypePromo } from '../models/service.model';

type TypeRequest = {};

type TypeResponse = Array<TypePromo>;

export const getServicePromo: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/services/promo`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-services',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
