import React, { FC, HTMLAttributes } from 'react';

import { WellHeader } from '../../components';
import { EditCompetitions } from '../../components/EditCompetitions/EditCompetitions';

import { MainLayout, WellStyled, WellBodyStyled } from './styled';

export interface CompetenciesPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const CompetenciesPage: FC<CompetenciesPageProps> = () => {
  return (
    <MainLayout>
      <WellStyled data-test-id="edit-competitions-page-wrapper">
        <WellHeader title="Компетенции" dataTestId="edit-competitions-page" />
        <WellBodyStyled data-test-id="edit-competitions-page-body">
          <EditCompetitions />
        </WellBodyStyled>
      </WellStyled>
    </MainLayout>
  );
};
