import { FC } from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';

import Cancel from '../../../../assets/images/cancel.svg';
import { useStore } from '../../../../utils';
import { RolesStore } from '../../stores';

import { ModalStyled, ModalBodyStyled, ModalTitleStyled, IconScg } from './styled';

export interface IDelRoleModalProps {
  onClose: () => void;
  roleId: string;
  anotherRoleName: string;
}

export const DelRoleModal: FC<IDelRoleModalProps> = observer(
  ({ onClose, roleId, anotherRoleName }) => {
    const { removeRole, fetchRoles } = useStore(RolesStore);

    const handleClick = async () => {
      try {
        await removeRole(roleId);
        await fetchRoles();
      } catch (e) {
        console.error('remove role error', e);
      }
      onClose();
    };

    return (
      <ModalStyled onClose={onClose} style={{ width: '440px' }}>
        <ModalBodyStyled>
          <IconScg src={Cancel} alt="" />
          <ModalTitleStyled data-test-id="modal-dialog-title">
            {`Вы уверены, что хотите удалить роль? Все сотрудники будут перемещены в роль «${anotherRoleName}»`}
          </ModalTitleStyled>

          <Grid columns={2}>
            <Row justifyContent="center">
              <Col textAlign="center" xs={1}>
                <Button isStretched isBasic onClick={onClose} size="large">
                  Отменить
                </Button>
              </Col>

              <Col textAlign="center" xs={1}>
                <Button isStretched isPrimary isDanger size="large" onClick={handleClick}>
                  Удалить
                </Button>
              </Col>
            </Row>
          </Grid>
        </ModalBodyStyled>
      </ModalStyled>
    );
  }
);
