import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios, TypeApiResponse } from '../../../../../utils/axios2';
import { checkIsEmail } from '../../../../../utils/checkIsEmail';
import { LOGIN_PAIR_NOT_FOUND } from '../../../../../constants/error';
import { Field } from '../../../../../constants/field';
import { SessionStore } from '../../../stores/session.store';

@provide.singleton()
export class SignInViaEmailStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  constructor() {
    makeAutoObservable(this);
  }

  email: Field = {
    value: '',
    error: '',
  };
  password: Field = {
    value: '',
    error: '',
  };

  isLoggedIn = false;

  transferEmail = '';

  setFieldValue = (fieldName: 'email' | 'password') => (value: string) => {
    this[fieldName].value = value;
    this[fieldName].error = '';
  };

  attemptLogin = async () => {
    let response: TypeApiResponse<'login'>;

    try {
      response = await this.axios.api.login({
        email: this.email.value,
        password: this.password.value,
      });

      if (response.needAdditionalInfo) {
        this.transferEmail = this.email.value;
      }

      if ((response as any).error) {
        throw new Error('incorrect-pair');
      }

      this.successLogin(response['refresh-token'], response['access-token']);

      return response;
    } catch (e) {
      this.email.error = LOGIN_PAIR_NOT_FOUND;
    }
  };

  successLogin = (refreshToken, accessToken) => {
    // this.successLogin(refreshToken, accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('accessToken', accessToken);
    this.sessionStore.isLoggedIn = true;
  };

  get isFormButtonAvailable() {
    return checkIsEmail(this.email.value) && this.password.value.length >= 6;
  }

  reset = () => {
    this.email = {
      value: '',
      error: '',
    };
    this.password = {
      value: '',
      error: '',
    };
    this.isLoggedIn = false;
  };
}
