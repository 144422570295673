import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Password, Separator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../utils';
import { AuthorizationRoutes } from '../../../../routes';
import { LoadingWrapper } from '../VerifyEmail/style';
import { IncorrectQuery } from '../IncorrectQuery';
import { ResetEmailAndPasswordStore } from '../../stores/reset.email_and_password.store';
import { AgroidRoutes } from '../../../../../agroid/routes';

import PasswordIcon from './assets/password.png';
import {
  ButtonWrapper,
  FieldWrapper,
  Header,
  ImageWrapper,
  LineWrapper,
  SuccessHeading,
  SuccessText,
  Wrapper,
} from './style';

type Props = RouteComponentProps;

export const SetNewPasswordAndEmail = withRouter(
  observer(({ location, history }: Props) => {
    const {
      step,
      repeatPassword,
      verifyEmail,
      isVerifyEmailLoading,
      password,
      setPassword,
      sendNewPassword,
      setRepeatPassword,
      isSetPasswordAvailable,
      isSetPasswordAllowed,
      reset,
    } = useStore(ResetEmailAndPasswordStore);

    useEffect(() => {
      const params: any = new URLSearchParams(location.search);
      verifyEmail(params.get('verificationHash') || params.get('resetToken'));
      return () => {
        reset();
      };
    }, [location, verifyEmail]);

    const handleSubmit = (e: any) => {
      e.preventDefault();
      sendNewPassword();
    };

    const handleSignIn = () => {
      history.push(AgroidRoutes.Index);
    };

    if (isVerifyEmailLoading) {
      return <LoadingWrapper>Загрузка</LoadingWrapper>;
    }

    if (!isSetPasswordAvailable) {
      return <IncorrectQuery text="Ссылка устарела" to={AuthorizationRoutes.signIn} />;
    }

    if (step === 'second') {
      return (
        <Wrapper>
          <ImageWrapper>
            <img src={PasswordIcon} alt="password recovered" />
          </ImageWrapper>
          <SuccessHeading>Пароль успешно установлен</SuccessHeading>
          <SuccessText>Вы можете войти с новыми данными</SuccessText>
          <ButtonWrapper>
            <Button
              type="button"
              color="primary"
              onClick={handleSignIn}
              dataTestId="setNewPasswordAndEmail-toComeIn-button"
            >
              Войти
            </Button>
          </ButtonWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>Установка пароля</Header>
        <LineWrapper>
          <Separator />
        </LineWrapper>
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <Password
              label="Придумайте пароль"
              placeholder=""
              value={password}
              onChange={setPassword}
              dataTestId="setNewPasswordAndEmail-password-input"
            />
          </FieldWrapper>
          <FieldWrapper>
            <Password
              label="Повторите пароль"
              placeholder=""
              value={repeatPassword}
              onChange={setRepeatPassword}
              dataTestId="setNewPasswordAndEmail-repeatPassword-input"
            />
          </FieldWrapper>
          <ButtonWrapper>
            <Button
              dataTestId="setNewPasswordAndEmail-setPassword-button"
              type="submit"
              color="primary"
              disabled={!isSetPasswordAllowed}
            >
              Установить
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  })
);
