import { FC, useState, useEffect, useMemo } from 'react';
import { Modal, Body } from '@zendeskgarden/react-modals';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Label } from '@zendeskgarden/react-forms';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Dropdown, Item, Trigger } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { Tooltip } from '@farmlink/farmik-ui';

import { TypeUserStatusLabels } from '../../../../api/models/user.model';
import { ModalHeader, NoData, IModalHeaderAction } from '../';
import { useStore, formatDate, formatPhone } from '../../../../utils';
import { EmployeeStore } from '../../stores/employee.store';
import { RolesStore } from '../../stores/roles.store';
import { AccessRuleVisibility, AccessRuleUserActionName } from '../../types';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { CheckAccessStore } from '../../stores/checkAccess.store';
import { EAccessRuleScoutingName } from '../../types/AccessRule';

import {
  StyledRoleButton,
  StyledStatus,
  StyledUserName,
  StyledUserNameField,
  TooltipWrapper,
  MenuWrapper,
  RoleNameWrapper,
} from './styled';

type TSelectedItem = {
  value: any;
  label: string;
};
export interface IUserDetailsModalProps {
  userId: string;
  orgId: string;
  title: string;
  onClose: (result?: any) => void;
  onSuccessedRoleChanging?: () => void;
}

const NOITEM = { label: 'Нет роли', value: '' };
const MAX_ROLE_LENGTH = 20;
const MAX_DROPDOWN_ROLE_LENGTH = 25;

export const UserDetailsModal: FC<IUserDetailsModalProps> = observer(
  ({ userId, onClose, title, onSuccessedRoleChanging }) => {
    const { isLoading, employee: user, setEmployeeId, fetchEmployee } = useStore(EmployeeStore);
    const { roles, assignUsers, assignScouts } = useStore(RolesStore);
    const profileStore = useStore(ProfileStore);
    const { getUserHasAccessRule } = useStore(CheckAccessStore);

    const [rotated, setRotated] = useState<boolean | undefined>();
    const [selectedItem, setSelectedItem] = useState<TSelectedItem>(NOITEM);

    useEffect(() => {
      setEmployeeId(userId);
      fetchEmployee();
    }, [userId]);

    useEffect(() => {
      let userRole = NOITEM;
      if (user && user.roleInfo) {
        userRole = {
          value: user.roleInfo.id,
          label: user.roleInfo.name,
        };
      }
      setSelectedItem(userRole);
    }, [user]);

    const hasManageScouting = useMemo(() => {
      return getUserHasAccessRule(EAccessRuleScoutingName.manageScouting);
    }, []);

    const hasEditEmployee = useMemo(() => {
      return getUserHasAccessRule(AccessRuleUserActionName.editEmployee);
    }, []);

    const handleSelectRole = async item => {
      try {
        const role = roles.find(r => r.id === item);
        if (role) {
          setSelectedItem({
            value: role.id,
            label: role.name,
          });

          if (role.isScoutForUser) {
            await assignScouts(role.id, [user.employeeId]);
          } else {
            await assignUsers(role.id, [user.id]);
          }
          if (onSuccessedRoleChanging) {
            onSuccessedRoleChanging();
          }
        } else {
          setSelectedItem(NOITEM);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleBlockUser = () => {
      onClose('blockConfirm');
    };

    const handleUnBlockUser = () => {
      onClose('unblockConfirm');
    };

    const modalActionsActive: IModalHeaderAction[] = useMemo(() => {
      return [
        {
          caption: 'Заблокировать ✕',
          component: Anchor,
          dataTestIdAction: 'ban-user',
          componentProps: {
            onClick: handleBlockUser,
            isDanger: true,
          },
          mode: AccessRuleVisibility.Hide,
          actionName: user.roleInfo?.isScoutForUser
            ? EAccessRuleScoutingName.manageScouting
            : AccessRuleUserActionName.deleteEmployee,
        },
      ];
    }, [user.roleInfo]);

    const modalActionsFired: IModalHeaderAction[] = useMemo(() => {
      return [
        {
          caption: 'Разблокировать ✕',
          component: Anchor,
          dataTestIdAction: 'unban-user',
          componentProps: {
            onClick: handleUnBlockUser,
            isDanger: true,
          },
          mode: AccessRuleVisibility.Hide,
          actionName: user.roleInfo?.isScoutForUser
            ? EAccessRuleScoutingName.manageScouting
            : AccessRuleUserActionName.deleteEmployee,
        },
      ];
    }, [user.roleInfo]);

    let modalActions = [];
    if (!isLoading && user?.employeeId !== profileStore?.user?.id) {
      if (user && user.status === 'ACTIVE') {
        modalActions = modalActionsActive;
      } else {
        modalActions = modalActionsFired;
      }
    }

    return (
      <Modal onClose={onClose} data-test-id="userDetails-modal">
        <ModalHeader
          title={title}
          actions={selectedItem.label !== 'Владелец' ? modalActions : []}
        />
        <Body style={{ paddingBottom: 0 }}>
          {isLoading ? (
            <>
              <Skeleton height="24px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </>
          ) : user ? (
            <>
              {' '}
              <StyledUserName data-id={userId} isBold data-test-id="userDetails-name">
                {user.userName}
              </StyledUserName>
              <Grid>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label data-test-id="userDetails-status-label">Статус</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col>
                    <StyledStatus status={user.status} data-test-id="userDetails-status">
                      {Boolean(user.status) && TypeUserStatusLabels[user.status]}
                    </StyledStatus>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label data-test-id="userDetails-role-label">Роль</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col size={5} data-test-id="dropdown-wrapper">
                    {selectedItem.label === 'Владелец' ||
                    user.employeeId === profileStore?.user?.id ||
                    (user?.roleInfo?.isScoutForUser && !hasManageScouting) ||
                    (!user?.roleInfo?.isScoutForUser && !hasEditEmployee) ? (
                      <TooltipWrapper>
                        <Tooltip
                          id="user-details-modal-role"
                          getContent={() =>
                            selectedItem.label.length >= MAX_ROLE_LENGTH ? selectedItem.label : ''
                          }
                        >
                          <span data-test-id="userDetails-role">{selectedItem.label}</span>
                        </Tooltip>
                      </TooltipWrapper>
                    ) : (
                      <Dropdown
                        selectedItem={selectedItem.value}
                        onSelect={handleSelectRole}
                        onStateChange={options => 'isOpen' in options && setRotated(options.isOpen)}
                      >
                        <TooltipWrapper>
                          <Tooltip
                            id="user-details-modal-role"
                            getContent={() =>
                              selectedItem.label.length >= MAX_ROLE_LENGTH ? selectedItem.label : ''
                            }
                          >
                            <Trigger>
                              <StyledRoleButton
                                data-test-id="userDetails-role-dropdown"
                                data-is-open={rotated}
                              >
                                <span data-test-id="userDetails-role">{selectedItem.label}</span>
                                <Button.EndIcon
                                  isRotated={rotated}
                                  data-test-id="userDetails-role-button"
                                >
                                  <ChevronIcon />
                                </Button.EndIcon>
                              </StyledRoleButton>
                            </Trigger>
                          </Tooltip>
                        </TooltipWrapper>

                        <MenuWrapper
                          isCompact
                          maxHeight={'300px'}
                          data-test-id="userDetails-role-menu"
                        >
                          {roles
                            .filter(
                              r =>
                                r.name !== 'Владелец' &&
                                r?.isScoutForUser === user?.roleInfo?.isScoutForUser
                            )
                            .map(({ id, name }) =>
                              name.length > MAX_DROPDOWN_ROLE_LENGTH ? (
                                <Item value={id} key={id} data-test-id="userDetails-role-menu-item">
                                  <RoleNameWrapper>{name}</RoleNameWrapper>
                                </Item>
                              ) : (
                                <Item value={id} key={id} data-test-id="userDetails-role-menu-item">
                                  {name}
                                </Item>
                              )
                            )}
                        </MenuWrapper>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Телефон</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col data-test-id="userDetails-phone">{formatPhone(get(user, 'phone'))}</Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>E-mail</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Anchor
                      href={`mailto:${user.email}`}
                      style={{ color: 'inherit' }}
                      data-test-id="userDetails-email"
                    >
                      {user.email}
                    </Anchor>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Дата вступления</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col data-test-id="userDetails-date">
                    <>{formatDate(user.organizationJoinDate, 'dd.MM.yyyy')}</>
                  </Col>
                </Row>
              </Grid>
              <Grid style={{ paddingTop: '20px' }}>
                <Row>
                  <Col size={1}></Col>
                  <Col>
                    <Button
                      isPrimary
                      size="large"
                      isStretched
                      onClick={onClose}
                      data-test-id="userDetails-close"
                    >
                      Закрыть
                    </Button>
                  </Col>
                  <Col size={1}></Col>
                </Row>
              </Grid>
            </>
          ) : (
            <NoData message="Данные пользователя не найдены" />
          )}
        </Body>
      </Modal>
    );
  }
);
