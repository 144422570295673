import { lazyInject, provide } from '../../../../utils';
import { CheckAccessStore, RolesStore } from '../../stores';
import { AccessRuleUserActionName, EAccessRuleScoutingName } from '../../types';

@provide.transient()
export class RolePageController {
  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(RolesStore)
  protected roleStore: RolesStore;

  isDisabledAction = (action: any) => {
    // console.log(action.name, {
    //   isScoutForUser: this.roleStore.roleSettings.isScoutForUser,
    //   manageScouting: this.checkAccessStore.getUserHasAccessRule(
    //     EAccessRuleScoutingName.manageScouting
    //   ),
    //   editRole: this.checkAccessStore.getUserHasAccessRule(AccessRuleUserActionName.editRole),
    //   'action.isScoutForUser': action.isScoutForUser,
    // });

    if (this.roleStore.roleSettings.isOwner === undefined) {
      return this.roleStore.roleSettings.isOwner;
    }

    // Если выбранная роль это владелец, блочим все свитчи
    if (this.roleStore.roleSettings.isOwner) {
      return true;
    }

    if (this.roleStore.roleSettings.isScoutForUser) {
      if (this.checkAccessStore.getUserHasAccessRule(EAccessRuleScoutingName.manageScouting)) {
        // Если у роли isScoutForUser:true и есть локальный экшен scouting.manageScouting - даём доступ ко всем свитчам
        return false;
      }
    } else {
      const userHasEditRole = this.checkAccessStore.getUserHasAccessRule(
        AccessRuleUserActionName.editRole
      );

      if (userHasEditRole && action.isScoutForUser === false) {
        return false;
      }

      return true;
    }
  };
}
