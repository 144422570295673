import { TypeApiRoute } from '../models/type.api.request';
import { AccessRule } from '../models/user.model';

type TypeRequest = {
  organizationId: string;
  codePrefix?: string;
};

type TypeResponse = Array<AccessRule>;

export const checkAccessByCodePrefix: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/access/action/byCodePrefix`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
