import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${props => props.theme.Colors.generalLight};
  overflow: hidden;
  padding-top: 44px;
  padding-bottom: 28px;

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #e9e6f0;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  }
`;

export const TopLine = styled.div`
  height: 13px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: ${props => props.theme.Colors.accentPositive};
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 0.5px solid #e9e6f0;

  padding-bottom: 24px;
  margin-bottom: 20px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const TwoFieldsLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const TwoFieldWrapper = styled.div`
  width: 136px;
`;

export const CheckBoxWrapper = styled.div`
  width: 100%;
  margin-bottom: 29px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;
