import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

type TypeRequest = {
  size: number;
  page: number;
  search: string;
};

type TypeResponse = {
  content: Array<{ expertiseId: string; expertiseName: string }>;
} & TypeResponsePageable;

export const getExpertises: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/expertise/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
