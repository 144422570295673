import React, { FC, HTMLAttributes } from 'react';
import { trim, toString } from 'lodash';

import { ReactComponent as NoLogo } from '../../../../assets/images/agroid/logo-template.svg';
import { AgroidRoutes } from '../../routes';
import { OrgLogo, TypeUserRoleEnumString } from '../';

import { OrgCardStyled, LogoOrgNameWrapper, OrgCardLogoWrap } from './styled';

export interface OrgCardProps extends HTMLAttributes<HTMLDivElement> {
  organizationId: string;
  name: string;
  employeeRole: string;
  logo?: any;
  role: any;
  type?: {
    orgTypeId?: number;
    OrgType?: string;
  };
}

const trimName = str => {
  if (str.length <= 50) {
    return str;
  }
  return `${str.slice(0, str.indexOf(' ', 30))}...`;
};

const getDataTestId = (name: string): { 'data-test-id': string } => {
  return { 'data-test-id': `organization-card-${name}` };
};

export const OrgCard: FC<OrgCardProps> = props => {
  const { organizationId, name, employeeRole, logo, role, type: { OrgType } = {} } = props;

  return (
    <OrgCardStyled.Link
      to={`${AgroidRoutes.Organizations}/${organizationId}`}
      {...getDataTestId('link')}
    >
      <OrgCardStyled key={organizationId} {...getDataTestId('wrap')}>
        <LogoOrgNameWrapper {...getDataTestId('logo-name-wrap')}>
          {logo ? (
            <OrgCardLogoWrap {...getDataTestId('logo-wrap')}>
              <OrgLogo {...logo} {...getDataTestId('logo')} />
            </OrgCardLogoWrap>
          ) : (
            <NoLogo
              className="logo"
              style={{ margin: '0', width: '60px', height: '60px' }}
              {...getDataTestId('no-logo')}
            />
          )}
          <OrgCardStyled.Title {...getDataTestId('name')}>
            {trimName(trim(`${toString(OrgType)} ${name}`))}
          </OrgCardStyled.Title>
        </LogoOrgNameWrapper>
        <OrgCardStyled.Text {...getDataTestId('text')}>
          {
            // todo Когда старые роли исчезнуть оставить role?.name !!!!!!!
            role?.name || TypeUserRoleEnumString[employeeRole]
          }
        </OrgCardStyled.Text>
      </OrgCardStyled>
    </OrgCardStyled.Link>
  );
};
