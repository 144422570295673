import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@farmlink/farmik-ui';

import { ButtonWrapper, SuccessHeading, Wrapper } from './style';

type IncorrectQueryProps = {
  text: string;
  to: string;
} & RouteComponentProps;

export const IncorrectQuery = withRouter(({ history, text, to }: IncorrectQueryProps) => {
  const handleButton = () => {
    history.push(to);
  };

  return (
    <Wrapper>
      <SuccessHeading data-test-id={'heading-text'}>{text}</SuccessHeading>
      <ButtonWrapper>
        <Button onClick={handleButton} type="button" color="primary" dataTestId={'button-back'}>
          Вернуться
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
});
