import React, {
  useState,
  SVGProps,
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { ModelTransformMode } from 'uniforms';
import { isMobileOnly } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NoData } from '../NoData';
import { AvatarUploader } from '../AvatarUploader/AvatarUploader';
import { MergedInputGroup } from '../MergedInputGroup/MergedInputGroup';
import { useStore } from '../../../../utils';
import { OrganizationStore } from '../../stores/organization.store';
import { TChangeOrganizationPayload } from '../../../../api/models/organization.model';
import { getPayload, getValuesEdit } from '../../pages/ScoutingPage/utils';
import { InitialValues } from '../../../../api/models/contractor.model';
import { AgroidRoutes } from '../../routes';

import { ReactComponent as ArrowSvg } from './assets/arrow.svg';
import { ReactComponent as CancelSvg } from './assets/cancel.svg';
import {
  AddressFactWrapper,
  AddressJurWrapper,
  ContactWrapper,
  EmailWrapper,
  InnWrapper,
  KPPWrapper,
  LogoRequisitesWrapper,
  LogoWrapper,
  OGRNWrapper,
  OKPOWrapper,
  OKVEDWrapper,
  OrgNameWrapper,
  OrgTypeWrapper,
  PhoneWrapper,
  RequisitesWrapper,
  ErrorsFieldWrapper,
} from './styled';
import { bridge as schema } from './schema';

export interface IRequisitesAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
  dataTestIdAction?: string;
}

export interface OrgFormProps extends HTMLAttributes<HTMLDivElement> {
  onChange?: any;
  orgId: string;
  actions: IRequisitesAction[];
}

type ModelProps = {
  [key: string]: any;
};

const getErrorMessage = ({ title }) => {
  if (title === 'ENTITY_EXISTS') {
    return 'Организация уже существует';
  } else {
    return 'Ошибка сохранения организации';
  }
};

export const OrgForm: FC<OrgFormProps> = observer(({ orgId, onChange, actions }) => {
  const {
    setLoading,
    organization,
    fetchOrganization,
    isLoading,
    getOrgTypes,
    updateOrganization,
    logoUrl,
    logoImgId,
    logoName,
    uploadFile,
    onCommitLogo,
    onResetLogo,
    markFieldAsDirty,
    clearStore,
  } = useStore(OrganizationStore);
  const [options, setOptions] = useState([]);
  const [commonError, setCommonError] = useState<boolean | string>(false);
  const [formRef, setRef] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const history = useHistory();

  const [errorOGRN, setErrorOGRN] = useState(false);
  const [errorOKVED, setErrorOKVED] = useState(false);
  const [errorOKPO, setErrorOKPO] = useState(false);

  const organizationInitialValues = useMemo(() => {
    return getValuesEdit(organization, false);
  }, [organization]);

  useEffect(() => {
    onResetLogo();
    fetchOrganization(orgId);

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      setOptions([{ label: 'Загрузка', value: 0 }]);
      try {
        const list = await getOrgTypes();
        setOptions(
          list.map(item => ({
            label: item.OrgType,
            value: item.orgTypeId,
          }))
        );
      } catch (e) {
        throw e;
      } finally {
        setIsDataLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async (initialValues: InitialValues): Promise<void> => {
    const payload = getPayload(
      organizationInitialValues,
      {
        ...initialValues,
        logo: {
          id: logoImgId,
          downloadUrl: logoUrl,
          fileName: logoName || '',
        },
      },
      false
    ) as Partial<TChangeOrganizationPayload>;

    if (!isLoading) {
      try {
        const isSuccess = await updateOrganization(payload, orgId);
        await fetchOrganization(orgId);
        onChange([]);

        if (isSuccess) {
          history.push(generatePath(AgroidRoutes.Organization, { orgId }));
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e.response.data.error);
        setCommonError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleModelTransform = (mode: ModelTransformMode, modelMobx: ModelProps) => {
    const model = toJS(modelMobx);

    setErrorOGRN(Boolean(model.OGRN.length) && model.OGRN.length !== 13);
    setErrorOKVED(
      Boolean(model.OKVED.length) && (model.OKVED.length < 2 || model.OKVED.length > 8)
    );
    setErrorOKPO(Boolean(model.OKPO.length) && model.OKPO.length !== 8);

    if (mode === 'form') {
      if (model.type) {
        model.orgTypeId = organization.type.orgTypeId;
        delete model.type;
      }
      if ('INN' in model && model.INN !== null && model.INN.trim() === '') {
        model.INN = null;
      }
    }
    return model;
  };

  const closed = e => {
    e.preventDefault();
    onChange([]);
    fetchOrganization(orgId);
  };

  const handleClose = useCallback(
    () => e => {
      closed(e);
    },
    []
  );

  const requisitesActions: IRequisitesAction[] = useMemo(
    () => [
      {
        caption: 'Отменить',
        component: Anchor,
        icon: CancelSvg,
        positionIcon: 'right',
        dataTestIdAction: 'organization-form-cancel-action',
        componentProps: {
          isBasic: true,
          onClick(e) {
            closed(e);
          },
        },
      },
      {
        caption: 'Сохранить',
        icon: ArrowSvg,
        component: Anchor,
        positionIcon: 'right',
        dataTestIdAction: 'organization-form-save-action',
        componentProps: {
          async onClick(e) {
            e.preventDefault();

            if (formRef) {
              const validate = await formRef.validate();

              if (validate === null) {
                formRef.submit();
              }
            }
          },
        },
      },
    ],
    [formRef]
  );

  const handleChange = useCallback(
    (e: string) => {
      if (!isDataLoading) {
        if (e.includes('.')) {
          const substring = e.substring(0, e.indexOf('.'));
          markFieldAsDirty(substring);
        } else {
          markFieldAsDirty(e);
        }
      }

      onChange(requisitesActions);
    },
    [isDataLoading, options, requisitesActions]
  );

  const handleTransform = useCallback(
    (v: string) => ({ value: v, label: options.find(item => item.value === v).label }),
    [options]
  );

  return (
    <Grid>
      <Row>
        <Col textAlign="center">
          {isLoading ? (
            <>
              <Skeleton height="24px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </>
          ) : organization ? (
            <AutoForm
              schema={schema}
              onSubmit={handleSubmit}
              model={organizationInitialValues}
              modelTransform={handleModelTransform}
              validate="onChange"
              onChange={handleChange}
              ref={r => setRef(r)}
            >
              <LogoRequisitesWrapper>
                <LogoWrapper className={'logo-wrapper'}>
                  <AvatarUploader
                    icon={logoUrl}
                    fileName={organization.logo?.fileName}
                    onUpload={uploadFile}
                    onCommitImg={() => {
                      handleChange('logo');
                      onCommitLogo();
                    }}
                    onLogoReset={onResetLogo}
                  />
                </LogoWrapper>
                <RequisitesWrapper>
                  <OrgNameWrapper>
                    <MergedInputGroup label="Название организации" required validateField="name">
                      <OrgTypeWrapper>
                        <AutoField
                          name="orgTypeId"
                          options={options}
                          transform={handleTransform}
                          label={false}
                          isInGroupLeft={true}
                        />
                      </OrgTypeWrapper>
                      <AutoField name="name" label={false} />
                    </MergedInputGroup>
                    <ErrorsFieldWrapper>
                      <ErrorField name="orgTypeId" />
                      <ErrorField name="name" />
                    </ErrorsFieldWrapper>
                  </OrgNameWrapper>
                  <InnWrapper>
                    <AutoField name="INN" />
                    <ErrorField name="INN" />
                  </InnWrapper>
                  <OGRNWrapper>
                    <AutoField name="OGRN" />
                    {errorOGRN && <ErrorValueField>ОГРН должен быть 13 знаков</ErrorValueField>}
                  </OGRNWrapper>
                  <OKVEDWrapper>
                    <AutoField name="OKVED" />
                    {errorOKVED && (
                      <ErrorValueField>ОКВЭД должен быть не менее 2 знаков</ErrorValueField>
                    )}
                  </OKVEDWrapper>
                  <OKPOWrapper>
                    <AutoField name="OKPO" />
                    {errorOKPO && <ErrorValueField>ОКПО должен быть 8 знаков</ErrorValueField>}
                  </OKPOWrapper>
                  <KPPWrapper>
                    <AutoField name="KPP" />
                    <ErrorField name="KPP" />
                  </KPPWrapper>
                  <AddressJurWrapper>
                    <AutoField name="addressJur" />
                    <ErrorField name="addressJur" />
                  </AddressJurWrapper>
                  <AddressFactWrapper>
                    <AutoField name="addressFact" />
                    <ErrorField name="addressFact" />
                  </AddressFactWrapper>
                  <EmailWrapper>
                    <AutoField name="email" />
                    <ErrorField name="email" />
                  </EmailWrapper>
                  <PhoneWrapper>
                    <AutoField name="phone" />
                    <ErrorField name="phone" />
                  </PhoneWrapper>
                  <ContactWrapper>
                    <AutoField name="contact" />
                    <ErrorField name="contact" />
                  </ContactWrapper>
                  <ErrorsFieldWrapper>
                    {Boolean(commonError) && (
                      <ul>
                        <li>{commonError}</li>
                      </ul>
                    )}
                  </ErrorsFieldWrapper>

                  {isMobileOnly && !isEmpty(actions) && (
                    <Grid columns={2} style={{ marginTop: '12px' }}>
                      <Row justifyContent="center">
                        <Col textAlign="center" xs={1}>
                          <Button
                            type="button"
                            isStretched
                            isBasic
                            size="large"
                            onClick={handleClose()}
                          >
                            Отменить
                          </Button>
                        </Col>
                        <Col textAlign="center" xs={1}>
                          <Button type="submit" isStretched isPrimary size="large">
                            Сохранить
                          </Button>
                        </Col>
                      </Row>
                    </Grid>
                  )}
                </RequisitesWrapper>
              </LogoRequisitesWrapper>
            </AutoForm>
          ) : (
            <NoData message="Данные организации недоступны" />
          )}
        </Col>
      </Row>
    </Grid>
  );
});

export const ErrorValueField = styled.div`
  color: ${({ theme: { colors: c } }) => c.accentPink};
  font-size: 12px;
  margin-top: -20px;
  // margin-bottom: 20px;
`;
