import styled from 'styled-components';

import { Colors } from '../../../../../constants/colors';

// Обертка для кнопок, кот пока нет
// const WrapperButtons = styled.div`
//   margin: 40px 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   gap: 20px;
// `;

const Description = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
`;

const Icon = styled.img`
  width: 62px;
  height: 62px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 40px;
`;

const Wrapper = styled.div<{ plugStyle: { mobileHeight: string; desktopHeight: string } }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${Colors.white};
  border-radius: 16px;
  height: ${({ plugStyle }) => plugStyle?.mobileHeight || '100%'};
  @media (min-width: 1024px) {
    height: ${({ plugStyle }) => plugStyle?.desktopHeight || '100%'};
  }
`;

const StyledPlug = {
  // WrapperButtons,
  Description,
  Title,
  Icon,
  Wrapper,
};

export default StyledPlug;
