import styled from 'styled-components';
import { Footer as ZenFooter, FooterItem } from '@zendeskgarden/react-chrome';

export const FooterItemWrapper = styled(FooterItem)`
  flex-grow: 1;
  flex-grow: 1;
  height: 100%;
  margin: 0;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  & > a {
    text-align: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: inherit;
    color: ${p => p.theme.Colors.secondaryDark};

    svg path {
      fill: ${p => p.theme.Colors.secondaryDark};
    }

    &.active,
    &:hover,
    &:active {
      color: ${p => p.theme.Colors.accentPositive};

      svg path {
        fill: ${p => p.theme.Colors.accentPositive};
      }
    }
  }
`;

export const MenuItemText = styled.div`
  display: block;
  margin-top: 2px;
`;

export const FooterWrapper = styled(ZenFooter)`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    justify-items: stretch;
    height: 52px;
    position: fixed;
    bottom: -1px;
    bottom: calc(env(safe-area-inset-bottom) - 1px);
    right: 0;
    left: 0;
    z-index: 100;
    border-top: 1px solid ${({ theme: { colors: c } }) => `${c.borderGray}`};
    box-shadow: 0 0 12px ${({ theme: { colors: c } }) => `${c.borderGray}`};
  }
`;
