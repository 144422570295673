import styled from 'styled-components';

export const InterestWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 28px;
  background: #faf9fd;
  border: 0.5px solid #e9e6f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
`;

export const InterestsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 126px;
`;
