import styled from 'styled-components';

export const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 56px 74px;

  @media (max-width: 767px) {
    height: calc(100vh - 196px);
  }

  @media (min-width: 768px) {
    height: calc(100vh - 188px);
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 170px);
  }

  &.with-filter {
    @media (max-width: 767px) {
      height: calc(100vh - 342px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 360px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 291px);
    }
  }

  &.with-alone {
    @media (max-width: 767px) {
      height: calc(100vh - 268px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 276px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 212px);
    }
  }
`;

export const StyledNoDataIcon = styled.img`
  width: 62px;
`;

export const NoDataTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 49px;
  margin-bottom: 20px;
  letter-spacing: -0.15px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
