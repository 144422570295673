import styled from 'styled-components';
import { Well } from '@zendeskgarden/react-notifications';

export const MainLayout = styled.div`
  @media (max-width: 767px) {
    margin: 0 8px 8px 8px;
  }

  @media (min-width: 768px) {
    margin: 0 12px 12px 12px;
  }

  @media (min-width: 1024px) {
    margin: 0 20px 20px 20px;
  }

  @media (min-width: 1366px) {
    margin: 0 0 20px 0;
  }
`;

export const WellStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 32px;
  }
`;

export const WellBodyStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  @media (max-width: 767px) {
    padding: 16px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;
