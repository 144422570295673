import { FC } from 'react';

import { ModalWrapper, Overlay, Modal, ModalActionsFooter } from './style';

type ModalType = {
  isVerticalCentered?: boolean;
  onClose?: () => void;
  paddings?: 'default' | 'seasons';
  size?: 'small' | 'medium' | 'large';
};

export const ModalComponent: FC<ModalType> = ({
  children,
  isVerticalCentered,
  onClose,
  paddings = 'default',
  size = 'small',
}) => {
  return (
    <>
      <Overlay />
      <ModalWrapper onClick={onClose} data-test-id="modal-component-wrapper">
        <Modal
          size={size}
          paddings={paddings}
          isVerticalCentered={isVerticalCentered}
          onClick={event => event.stopPropagation()}
          className="modal"
          data-test-id="modal-component"
        >
          {children}
        </Modal>
      </ModalWrapper>
    </>
  );
};

export { ModalActionsFooter };
