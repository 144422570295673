import { TypeApiRoute } from '../models/type.api.request';
import { UserInfoType } from '../models/user.model';

type TypeRequest = {
  id: string;
};

type TypeResponse = UserInfoType;

export const getEmployee: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/employee/${request.id}`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
