import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  systemId: string;
  redirectUrl: string;
  scope: string;
};

type TypeResponse = {
  redirectUrl: string;
  authKey: string;
};

export const externalRequestsRedirectData: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/externalRequests/redirectData`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
