import { FC, HTMLAttributes, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';

export interface PromptModalProps extends HTMLAttributes<HTMLDivElement> {
  changed: boolean;
  setSelectedLocation: Dispatch<SetStateAction<string | null>>;
}

export const PromptModal: FC<PromptModalProps> = ({ changed, setSelectedLocation }) => {
  const { openModalByModalId } = useModal();

  const handleBlockedRoute = nextLocation => {
    setSelectedLocation(nextLocation.pathname);

    if (changed) {
      openModalByModalId('unsavedDataModal');
      return false;
    }
    return true;
  };

  // Block non-react routes
  useEffect(() => {
    const unload = event => {
      if (changed) {
        event.returnValue = 'Unload';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [changed]);

  return <Prompt when={changed} message={handleBlockedRoute} />;
};
