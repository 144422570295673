import { FC, useMemo } from 'react';
import { Label } from '@zendeskgarden/react-forms';

import { OrgLogo } from '../OrgLogo';

import { UserToRoleRowWrapper } from './styled';

export interface UserToRoleRowProps {
  userName: string;
  id: string;
  onClick: (userId: string) => void;
  avatar?: any;
  checkedList: string[];
  roleInfo: any;
  isScouting?: boolean;
  fullName?: string;
  dataTestId?: string;
}

const getInitials = userName => {
  return userName
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .substr(0, 2);
};

const getDataTestId = (
    name: string,
    dynamicValue?: string | number
): { 'data-test-id': string } => {
  return {
    'data-test-id': `user-to-role-row-${name}${dynamicValue ? `-${dynamicValue}` : ''}`,
  };
};
export const UserToRoleRow: FC<UserToRoleRowProps> = ({
  checkedList,
  userName,
  id,
  onClick,
  avatar,
  roleInfo,
  isScouting,
  fullName,
  dataTestId = 'userToRole',
}) => {
  const checkOwnerAndScouting = () => {
    if (isScouting) {
      return true;
    }
    if (!roleInfo?.isOwner) {
      return true;
    }

    return false;
  };

  const isChecked = useMemo(() => checkedList.includes(id), [checkedList, id]);

  return checkOwnerAndScouting() ? (
    <UserToRoleRowWrapper key={id} onClick={() => onClick(id)} {...getDataTestId('wrap')}>
      <UserToRoleRowWrapper.Avatar {...getDataTestId('avatar-wrap')}>
        {avatar && avatar.downloadUrl ? (
          <OrgLogo {...avatar} dataTestId="user-to-role-row-avatar-img"/>
        ) : (
          <span data-test-id={`${dataTestId}-initials`}>
            {getInitials(isScouting ? fullName : userName)}
          </span>
        )}
      </UserToRoleRowWrapper.Avatar>
      <UserToRoleRowWrapper.Details {...getDataTestId('wrap-name')}>
        {isScouting ? fullName : userName}
      </UserToRoleRowWrapper.Details>
      <UserToRoleRowWrapper.CheckboxWrapper {...getDataTestId('checkbox-wrap')}>
        <UserToRoleRowWrapper.Checkbox
          checked={isChecked}
          onChange={() => onClick(id)}
          data-test-id="user-to-role-row-checkbox"
        >
          <Label hidden {...getDataTestId('checkbox-label')} >{isScouting ? fullName : userName}</Label>
        </UserToRoleRowWrapper.Checkbox>
      </UserToRoleRowWrapper.CheckboxWrapper>
    </UserToRoleRowWrapper>
  ) : null;
};
