import styled from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';
import { Well } from '@zendeskgarden/react-notifications';

import { AddOrgCard } from '../../components';

export const OrgLogoWrapper = styled.div`
  border-radius: ${({ theme: { borderRadii: r } }) => r.card};
  border: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
    border-radius: ${({ theme: { borderRadii: r } }) => r.card};
    ${p => mediaQuery('down', 'sm', p.theme)} {
      width: 36px;
      height: 36px;
    }

    ${p => mediaQuery('up', 'sm', p.theme)} {
      width: 60px;
      height: 60px;
    }
  }

  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    margin-left: 8px;
  }

  ${p => mediaQuery('up', 'sm', p.theme)} {
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-left: 12px;
  }

  svg {
    ${p => mediaQuery('down', 'sm', p.theme)} {
      width: 36px;
      height: 36px;
    }

    ${p => mediaQuery('up', 'sm', p.theme)} {
      width: 60px;
      height: 60px;
    }
  }
`;

export const EditWrapper = styled.div`
  text-align: right;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    position: absolute;
    top: 0;
    right: 0;

    span {
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }
  }
`;

export const UserRole = styled.div`
  margin-top: auto;
  color: ${({ theme: { colors: c } }) => c.generalBlack};

  .label {
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 4px;
    }

    ${p => mediaQuery('up', 'sm', p.theme)} {
      margin-bottom: 8px;
    }
  }
`;

export const DetailsColumn = styled.div`
  margin-top: auto;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  & p {
    overflow-wrap: anywhere;
    white-space: break-spaces;
  }
  .label {
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 4px;
    }

    ${p => mediaQuery('up', 'sm', p.theme)} {
      margin-bottom: 8px;
    }
  }

  //max-width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DetailsWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;

  .name {
    font-weight: 600;
    word-break: break-all;
    margin-bottom: 4px;
    max-width: 445px;
  }
  .employeersCount {
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 16px;
    }

    ${p => mediaQuery('up', 'sm', p.theme)} {
      margin-bottom: 24px;
    }
  }
  .contacts {
    line-height: 20px;

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 16px;
    }
  }

  .phone {
    margin-bottom: 8px;
  }

  .email {
    white-space: nowrap;
    &.hasError {
      color: ${({ theme: { colors: c } }) => c.accentPink};
      svg {
        margin-left: 4px;
        vertical-align: middle;
        path {
          fill: ${({ theme: { colors: c } }) => c.accentPink};
        }
      }
    }
  }
`;

export const MobileAddOrgButtonWrapper = styled.div`
  display: flex;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 56px 74px;

  @media (max-width: 767px) {
    height: calc(100vh - 196px);
  }

  @media (min-width: 768px) {
    height: calc(100vh - 188px);
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 170px);
  }

  &.with-filter {
    @media (max-width: 767px) {
      height: calc(100vh - 342px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 360px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 291px);
    }
  }

  &.with-alone {
    @media (max-width: 767px) {
      height: calc(100vh - 268px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 276px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 212px);
    }
  }
`;

export const StyledNoDataIcon = styled.img`
  width: 57px;

  @media (max-width: 767px) {
    width: 37px;
  }
`;

export const NoDataTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 42px;
  margin-bottom: 20px;
  letter-spacing: -0.15px;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-top: 36px;
  }
`;

export const OrgsListWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(1, auto);

  @media (max-width: 767px) {
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const StyledAddOrgCard = styled(AddOrgCard)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const ContentLayout = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  ${p => mediaQuery('up', 'lg', p.theme)} {
    width: calc(100% - 196px - 20px);
    max-width: calc(100% - 196px - 20px);
  }
`;

export const WellStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 18px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &.profile {
    padding-top: 24px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
      padding-bottom: 16px;
    }

    @media (min-width: 768px) {
      margin-bottom: 16px;
      padding-bottom: 20px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &.requisites {
    padding-top: 24px;

    @media (max-width: 767px) {
      margin: 0;
      padding-bottom: 24px;
    }

    @media (min-width: 768px) {
      margin: 0;
      padding-bottom: 32px;
    }

    @media (min-width: 1024px) {
      margin: 0;
      padding-bottom: 32px;
    }
`;

export const WellBodyStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  @media (max-width: 767px) {
    padding: 20px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;

export const WellFilterStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  @media (max-width: 767px) {
    padding: 12px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 20px 24px 0 24px;
  }

  [data-garden-id='forms.field'] {
    margin-bottom: 0;
  }
`;

export const WellRolesStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 18px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 18px;
  }

  @media (min-width: 992px) {
    margin-bottom: 20px;
    padding-bottom: 18px;
  }
`;
