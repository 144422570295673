import { Body, Content } from '@zendeskgarden/react-chrome';
import styled from 'styled-components';

export const BodyStyled = styled(Body)`
  background-color: ${({ theme: { colors: c } }) => c.secondaryBgGray};
`;

export const ContentStyled = styled(Content)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;

  @media (max-width: 767px) {
    padding-top: 64px;
  }

  @media (min-width: 768px) {
    padding-top: 92px;
  }

  @media (min-width: 1024px) {
    padding-top: 96px;
  }

  @media (min-width: 1366px) {
    padding-top: 96px;
  }
`;

export const Spacer = styled.div<{ size?: string }>`
  height: ${({ theme: { space }, size = 'md' }) => space[size]};
  overflow: auto;
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`;

export enum TypeUserRoleEnumString {
  OWNER = 'Владелец',
  EMPLOYEE = 'Сотрудник',
  MANAGER = 'Менеджер',
}
