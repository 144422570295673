import personWithComputerSvg from '../assets/images/person-with-computer-300x300.svg';
import personWithMacSvg from '../assets/images/3.svg';
import number404Big from '../assets/images/404-240x86.svg';
import number403Big from '../assets/images/403.svg';
import number400Big from '../assets/images/400.svg';
import { EErrorStatusName } from '../../../../../../constants/errorStatus';
import { IErrorInfo } from '../types/IErrorInfo';

const getErrorInfo = (type: EErrorStatusName | string): IErrorInfo => {
  let errorText = 'Кажется, такой страницы не существует';
  let errorTitle = 'Упc...!';
  let numberIcon = number404Big as any;
  let personIcon = personWithComputerSvg as any;
  let numberWidth = '240px';

  switch (type) {
    case EErrorStatusName.NotEnoughRights:
      errorText = 'Для получения обратитесь к администратору';
      errorTitle = 'Нет прав доступа';
      numberIcon = number403Big;
      personIcon = personWithMacSvg;
      numberWidth = '228px';
      break;
    case EErrorStatusName.RequestUrlError:
      errorText = 'Кажется, такой страницы не существует';
      errorTitle = 'Упc...!';
      numberIcon = number400Big;
      personIcon = personWithComputerSvg;
      numberWidth = '237px';
      break;
    default:
      break;
  }

  return {
    errorText,
    errorTitle,
    numberIcon,
    personIcon,
    numberWidth,
  };
};

export default getErrorInfo;
