import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';

export const DialogModalTitle = styled.div`
  color: ${Colors.black};
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const DialogModalDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1px;

  color: ${Colors.black};
  margin: 0;
  margin-top: 12px;

  @media (max-width: 767px) {
    margin-bottom: 8px;
  }
`;
export const IconScg = styled.img`
  width: 50px;
  height: 50px;
  display: block;
  margin: 35px auto 35px;

  @media (max-width: 767px) {
    margin-top: 23px;
  }
`;
