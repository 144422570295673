import { FC, useState } from 'react';
import { Modal, Header } from '@zendeskgarden/react-modals';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';
import { ModelTransformMode } from 'uniforms';

import { InputField, SubmitField } from '../uniforms';
import { ErrorsFieldWrapper } from '../NewOrgModal/styled';
import { useStore } from '../../../../utils';
import { RolesStore } from '../../stores';

import { BodyStyled } from './styled';
import { bridge as schema } from './schema';

export interface IRoleModalProps {
  orgId: string;
  onClose: () => void;
}

type ModelProps = {
  name: string;
};

const getErrorMessage = ({ title }) => {
  switch (title) {
    case 'DUPLICATED_VALUE':
      return 'Роль с таким названием уже существует';
    case 'FIELD_IS_EMPTY':
      return 'Название роли не может быть пустым';

    default:
      return 'Ошибка создания роли';
  }
};

export const NewRoleModal: FC<IRoleModalProps> = observer(({ orgId, onClose }) => {
  const { createRole, setOrganization, fetchRoles } = useStore(RolesStore);
  const [commonError, setCommonError] = useState<boolean | string>(false);

  const handleSubmit = async (doc: any) => {
    doc.organizationId = orgId;
    doc.order = 0;
    doc.name = doc.roleName;

    try {
      setOrganization(orgId);
      await createRole(doc);
      await fetchRoles();
      onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e.response.data.error);
      setCommonError(errorMessage);
    }
  };

  const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
    return model;
  };

  const handleValidate = (name, errorObj: Error & { details: { type: string }[] }) => {
    if (errorObj?.details[0].type === 'required') {
      return setCommonError('Название роли не может быть пустым');
    }

    setCommonError(errorObj?.message);
  };

  const getDataTestId = (name: string): { 'data-test-id': string } => {
    return { 'data-test-id': `new-role-modal-${name}` };
  };

  return (
    <Modal onClose={onClose}>
      <Header {...getDataTestId('header')}>Создание роли</Header>
      <BodyStyled>
        <AutoForm
          schema={schema}
          onSubmit={handleSubmit}
          modelTransform={handleModelTransform}
          validate="onChange"
          onValidate={handleValidate}
        >
          <Grid>
            <Row>
              <Col {...getDataTestId('name')}>
                <AutoField
                  name="roleName"
                  component={InputField}
                  dataTestId={getDataTestId('name-field')['data-test-id']}
                />
                <ErrorField name="roleName" />
              </Col>
            </Row>
            <Row>
              <Col>
                <ErrorsFieldWrapper {...getDataTestId('error-field-wrap')}>
                  {Boolean(commonError) && (
                    <ul>
                      <li {...getDataTestId(`error-item-${commonError}`)}>{commonError}</li>
                    </ul>
                  )}
                </ErrorsFieldWrapper>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col textAlign="center" xs={6}>
                <Button
                  isStretched
                  isBasic
                  onClick={onClose}
                  size="large"
                  {...getDataTestId('cancel-btn')}
                >
                  Отменить
                </Button>
              </Col>

              <Col textAlign="center" xs={6}>
                <SubmitField
                  isStretched
                  isPrimary
                  size="large"
                  dataTestId="new-role-modal-submit-btn"
                >
                  Создать
                </SubmitField>
              </Col>
            </Row>
          </Grid>
        </AutoForm>
      </BodyStyled>
    </Modal>
  );
});
