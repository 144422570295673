import { FC } from 'react';
import { Link } from 'react-router-dom';

import { getErrorInfo } from './helpers';
import Styled from './PageNotFound.styles';
import { TPageNotFoundProps } from './types/TPageNotFoundProps';

const PageNotFound: FC<TPageNotFoundProps> = ({
  link,
  type = 'Not found',
  onButtonClick = () => undefined,
}) => {
  const { numberIcon, numberWidth, personIcon, errorText, errorTitle } = getErrorInfo(type);

  return (
    <Styled.PageWrapper>
      <Styled.Header>
        <Styled.Logo />
      </Styled.Header>

      <Styled.BodyWrapper>
        <Styled.Body>
          <Styled.Content>
            <Styled.Number $icon={numberIcon} $numberWidth={numberWidth} />

            <Styled.Title>{errorTitle}</Styled.Title>
            <Styled.Description>{errorText}</Styled.Description>

            <Link to={link ?? '/'}>
              <Styled.ButtonLink type={'button'} color={'primary'} onClick={onButtonClick}>
                Перейти на главную
              </Styled.ButtonLink>
            </Link>
          </Styled.Content>

          <Styled.IconWrapper>
            <Styled.Icon $icon={personIcon} />
          </Styled.IconWrapper>
        </Styled.Body>
      </Styled.BodyWrapper>
    </Styled.PageWrapper>
  );
};

export default PageNotFound;
