import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../utils/constants/colors';

const Logo = styled.img`
  border-radius: 12px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${ECommonColor.GeneralBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 178px;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${ECommonColor.SecondaryDarkGray};
`;

const DownloadButton = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  cursor: pointer;
  color: ${ECommonColor.AccentGreen};
`;

const DeleteButton = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.Colors.accentNegative};
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

type TStyledInnerWrapperProps = {
  $flex: `${number} ${number} ${`${number}px` | `${number}%`}`;
  $isElementsMarginBottom?: boolean;
  $isColumn?: boolean;
};

const addMarginBottomToAllInnerWrapperElements = ($isElementsMarginBottom: boolean) => {
  if ($isElementsMarginBottom) {
    return css`
      & > :not(:last-child) {
        margin-bottom: 4px;
      }
    `;
  }
};

const addColumnDirectionToInnerWrapper = ($isColumn: boolean) => {
  if ($isColumn) {
    return css`
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    `;
  }
};

const InnerWrapper = styled.div<TStyledInnerWrapperProps>`
  flex: ${({ $flex }) => $flex};

  ${({ $isElementsMarginBottom }) =>
    addMarginBottomToAllInnerWrapperElements($isElementsMarginBottom)}

  ${({ $isColumn }) => addColumnDirectionToInnerWrapper($isColumn)}
`;

const Wrapper = styled.section`
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

const StyledContractCard = {
  Logo,
  Title,
  Description,
  DownloadButton,
  DeleteButton,
  InnerWrapper,
  Wrapper,
  ActionButtonsWrapper,
};

export default StyledContractCard;
