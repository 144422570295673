import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

const schema = new SimpleSchema({
  email: {
    type: String,
  },
  role: {
    type: String,
    allowedValues: ['value1', 'value2', 'value3'],
  },
  message: {
    type: String,
    optional: true,
  },
});

export const bridge = new SimpleSchema2Bridge(schema);
