import { observer } from 'mobx-react';

import { useStore } from '../../../../utils';
import { FullscreenPreloaderService } from '../../../agroid/services/fullscreenPreloader.service';
import { SpinnerLoader, SpinnerLoaderWrapper } from '../../styles/dotsLoader';

export const FullScreenPreloader = observer(() => {
  const { isPreloaderOpen } = useStore(FullscreenPreloaderService);

  if (isPreloaderOpen) {
    return (
      <SpinnerLoaderWrapper>
        <SpinnerLoader data-test-id="dots-loader">
          <span />
        </SpinnerLoader>
      </SpinnerLoaderWrapper>
    );
  }
  return null;
});
