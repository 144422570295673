import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  phoneCode: string;
  phoneNumber: string;
};

type TypeResponse = {
  operId: string;
  needAdditionalInfo: boolean;
  'access-token': string;
  'access-token-type': string;
  'refresh-token': string;
  'rt-expires-on': string;
};

export const loginByPhone: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/users/v3/login`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
