import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';
// import { HiddenField } from 'uniforms-unstyled';

import { SelectField, InputField, BooleanField } from '../uniforms';

const AddressScheme = new SimpleSchema({
  addressfull: {
    type: String,
    optional: true,
    label: false,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите адрес',
    },
  },
});

export const OrgTypeScheme = new SimpleSchema({
  orgTypeId: {
    type: Number,
    label: false,
    defaultValue: 4,
    uniforms: {
      component: SelectField,
      options: [],
    },
  },

  OrgType: {
    type: String,
    label: false,
    defaultValue: 'ИП',
    uniforms: {
      component: InputField,
      placeholder: 'Название',
    },
  },
});

const OrgSchema = new SimpleSchema({
  orgTypeId: {
    type: Number,
    required: true,
    label: 'Тип организации',
    uniforms: {
      component: SelectField,
      options: [],
    },
  },

  name: {
    type: String,
    label: 'Название организации',
    required: true,
    regEx: /^[a-zёа-я0-9.,\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указано',
      regexonchange: /(^$)|(^[а-яёa-z0-9.,\- ]+$)/i,
      dataTestId: 'org-name',
    },
  },

  INN: {
    type: String,
    optional: true,
    min: 10,
    max: 12,
    label: 'ИНН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'inn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 10;
        if (orgTypeId === 4) {
          length = 12;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthINN_IP' : 'lengthINN';
        }
      }
    },
  },

  KPP: {
    type: String,
    optional: true,
    min: 9,
    max: 9,
    label: 'КПП',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'kpp',
    },
  },

  OGRN: {
    type: String,
    optional: true,
    min: 13,
    max: 15,
    label: 'ОГРН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'ogrn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 13;
        if (orgTypeId === 4) {
          length = 15;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthOGRN_IP' : 'lengthOGRN';
        }
      }
    },
  },

  OKVED: {
    type: String,
    optional: true,
    min: 2,
    max: 8,
    label: 'ОКВЭД',
    regEx: /^[0-9.]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[0-9.]+$)/i,
      dataTestId: 'okved',
      // type: 'number',
      // mask: '99.99.99',
    },
  },

  addressJur: {
    type: String,
    optional: true,
    label: 'Юридический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-jur',
    },
  },

  addressFact: {
    type: String,
    optional: true,
    label: 'Фактический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-fact',
    },
  },

  sameAggresses: {
    type: Boolean,
    optional: true,
    label: 'Фактический и юридический адреса совпадают',
    defaultValue: true,
    uniforms: {
      component: BooleanField,
    },
  },
});

export const bridge = new SimpleSchema2Bridge(OrgSchema);

export { OrgSchema };
