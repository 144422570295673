import { FC, useEffect } from 'react';
import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import IconDelete from '../assets/images/delete.svg';
import { useStore } from '../../../../utils/IoC';
import { EditExpertiseStore } from '../../../auth/stores/EditExpertiseStore';
import { AgroidRoutes } from '../../routes';
import { Autosuggest } from '../Autosuggest/Autosuggest';
import { ProfileStore } from '../../../auth/stores/profile.store';

import {
  Wrapper,
  InputWrapper,
  FirstDiv,
  SecondDiv,
  Competition,
  DeleteIcon,
  ExpertiseWrapper,
  Footer,
  FooterButtonWrapper,
} from './style';

export const EditCompetitions: FC = observer(() => {
  const expertiseStore = useStore(EditExpertiseStore);
  const profileStore = useStore(ProfileStore);
  const history = useHistory();

  const {
    expertiseOptions,
    setExpertise,
    fetchExpertiseList,
    expertises,
    addToList,
    selectExpertise,
    deleteExpertise,
    saveExpertises,
    addToExpertises,
    reset,
    searchExpertise,
    selectedExpertise,
  } = expertiseStore;

  useEffect(() => {
    addToExpertises().then(() => {
      fetchExpertiseList();
    });
  }, []);

  const onSave = async () => {
    await saveExpertises();
    await profileStore.fetchProfile();
    reset();
    history.push(AgroidRoutes.Profile);
  };

  const onCancel = () => {
    reset();
    history.push(AgroidRoutes.Profile);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    addToList();
  };

  return (
    <Wrapper data-test-id="edit-competitions-form-wrapper">
      <InputWrapper onSubmit={submitHandler} data-test-id="edit-competitions-form">
        <FirstDiv data-test-id="edit-competitions-form-suggest-wrapper">
          <Autosuggest
            onChange={selectExpertise}
            onSearch={setExpertise}
            value={searchExpertise}
            options={expertiseOptions}
            placeholder={'Например, “Садоводство”'}
            label={'Добавление новой компетенции'}
            dataTestId="competitions-suggest"
          />
        </FirstDiv>
        <SecondDiv data-test-id="edit-competitions-form-add-wrapper">
          <Button
            type={'submit'}
            color={'primary'}
            dataTestId="edit-competitions-form-add-button"
            disabled={!Boolean(selectedExpertise?.expertiseId)}
          >
            {!isMobileOnly ? 'Добавить' : '+'}
          </Button>
        </SecondDiv>
      </InputWrapper>
      <ExpertiseWrapper data-test-id="edit-competitions-list">
        {expertises.length > 0
          ? expertises.map(item => (
              <Competition key={item.expertiseId} data-test-id="edit-competitions-item">
                <DeleteIcon
                  src={IconDelete}
                  onClick={() => deleteExpertise(item.expertiseId)}
                  data-test-id="edit-competitions-item-icon"
                />
                <p data-test-id="edit-competitions-item-caption">{item.expertiseName}</p>
              </Competition>
            ))
          : 'Не выбраны компетенции'}
      </ExpertiseWrapper>
      <Footer data-test-id="edit-competitions-footer">
        <FooterButtonWrapper data-test-id="edit-competitions-footer-button-wrapper">
          <Button
            color={'default'}
            type={'button'}
            onClick={onCancel}
            dataTestId="edit-competitions-footer-button-cancel"
          >
            Отменить
          </Button>
        </FooterButtonWrapper>
        <FooterButtonWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={onSave}
            dataTestId="edit-competitions-footer-button-save"
          >
            Сохранить
          </Button>
        </FooterButtonWrapper>
      </Footer>
    </Wrapper>
  );
});
