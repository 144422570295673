import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { ModelTransformMode } from 'uniforms';
import { Modal, Header, Body } from '@zendeskgarden/react-modals';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { isEmpty, trim } from 'lodash';

import { OrganizationsStore, OrganizationStore } from '../../stores';
import { Spacer } from '../';
import { MergedInputGroup } from '../MergedInputGroup/MergedInputGroup';
import { SubmitField, DisplayIf } from '../uniforms';
import { useStore } from '../../../../utils';
import { getValuesCreate } from '../../pages/ScoutingPage/utils';

import { bridge as schema } from './schema';
import { ErrorsFieldWrapper } from './styled';

export interface IModalProps {
  onClose: (res?: any) => void;
}

type ModelProps = {
  [key: string]: any;
};

const getErrorMessage = ({ title }) => {
  if (title === 'ENTITY_EXISTS') {
    return 'Организация уже существует';
  } else {
    return 'Ошибка создания организации';
  }
};

export const NewOrgModal: FC<IModalProps> = observer(({ onClose }) => {
  const { isLoading, createOrganization, getOrgTypes } = useStore(OrganizationStore);
  const { fetchOrganizations } = useStore(OrganizationsStore);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [commonError, setCommonError] = useState<boolean | string>(false);

  const orgInitialValues = useMemo(() => {
    return getValuesCreate(null, null, null);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const list = await getOrgTypes();
        setOptions(
          list.map(item => ({
            label: item.OrgType,
            value: item.orgTypeId,
          }))
        );
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  const handleSubmit = async (values: any) => {
    if (!isLoading) {
      const payload = {
        orgTypeId: values.orgTypeId,
        name: trim(values.name),
        INN: !isEmpty(values.INN) ? values.INN : undefined,
        addressJur: !isEmpty(values.addressJur)
          ? { addressfull: trim(values.addressJur) }
          : undefined,
        addressFact: !isEmpty(values.addressFact)
          ? { addressfull: trim(values.addressFact) }
          : undefined,
        OGRN: !isEmpty(values.OGRN) ? values.OGRN : undefined,
        OKVED: !isEmpty(values.OKVED) ? values.OKVED : undefined,
        KPP: !isEmpty(values.KPP) ? values.KPP : undefined,
        employeeRole: 'OWNER',
      };

      try {
        await createOrganization(payload as any);
        await fetchOrganizations();
        onClose(true);
      } catch (e) {
        const errorMessage = getErrorMessage(e.response.data.error);
        setCommonError(errorMessage);
      }
    }
  };

  const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
    if (mode === 'validate') {
      document.querySelector('[data-garden-id="modals.body"]').scrollTop = 0;
      if (model.orgTypeId && model.name) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (mode === 'form') {
      if (!('orgTypeId' in model) && options.length) {
        model.orgTypeId = options[0].value;
      }
      if (!('sameAggresses' in model)) {
        model.sameAggresses = true;
      }
      if (model.sameAggresses && model.addressJur) {
        model.addressFact = model.addressJur;
      }
    }
    return model;
  };

  const handleChange = (/* key: string, value: any */) => {
    setCommonError(false);
  };

  const handleTransform = useCallback(
    (v: string) => ({
      value: v,
      label: options.find(item => item.value === v).label,
    }),
    [options]
  );

  return (
    <Modal onClose={onClose}>
      <Header>Новая организация</Header>
      <Body>
        <AutoForm
          schema={schema}
          model={orgInitialValues}
          onChange={handleChange}
          onSubmit={handleSubmit}
          modelTransform={handleModelTransform}
          validate="onChange"
        >
          <Grid>
            <Row>
              <Col>
                <MergedInputGroup label="Название организации" required validateField="name">
                  <AutoField
                    name="orgTypeId"
                    options={options}
                    transform={handleTransform}
                    label={false}
                    isInGroupLeft={true}
                  />
                  <AutoField name="name" label={false} />
                </MergedInputGroup>
                <ErrorsFieldWrapper>
                  <ErrorField name="orgTypeId" />
                  <ErrorField name="name" />
                </ErrorsFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col textAlign="start">
                <Grid>
                  <Row>
                    <Col sm={6} xs={12}>
                      <AutoField name="INN" />
                      <ErrorField name="INN" />
                    </Col>
                    <Col sm={6} xs={12}>
                      <AutoField name="KPP" />
                      <ErrorField name="KPP" />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <AutoField name="OGRN" />
                      <ErrorField name="OGRN" />
                    </Col>
                    <Col sm={6} xs={12}>
                      <AutoField name="OKVED" />
                      <ErrorField name="OKVED" />
                    </Col>
                  </Row>
                </Grid>
              </Col>
            </Row>
            <Row>
              <Col>
                <AutoField name="addressJur" />
                <ErrorField name="addressJur" />
              </Col>
            </Row>
            <Row>
              <Col>
                <AutoField name="sameAggresses" />
                <ErrorField name="sameAggresses" />
              </Col>
            </Row>
            <DisplayIf condition={ctx => ctx.model.sameAggresses === false}>
              <Row>
                <Col>
                  <AutoField name="addressFact" />
                  <ErrorField name="addressFact" />
                </Col>
              </Row>
            </DisplayIf>
            <Row>
              <Col>
                <ErrorsFieldWrapper>
                  {Boolean(commonError) && (
                    <ul>
                      <li>{commonError}</li>
                    </ul>
                  )}
                </ErrorsFieldWrapper>
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col textAlign="center">
                  <Skeleton height="24px" />
                  <Skeleton height="12px" />
                  <Skeleton height="12px" />
                </Col>
              </Row>
            ) : (
              <Row justifyContent="center">
                <Col textAlign="center" size={10}>
                  <SubmitField isStretched isPrimary size="large" disabled={disabled}>
                    Продолжить
                  </SubmitField>
                  <Spacer />
                  <Anchor onClick={onClose} style={{ textDecoration: 'none' }}>
                    Отменить
                  </Anchor>
                </Col>
              </Row>
            )}
          </Grid>
        </AutoForm>
      </Body>
    </Modal>
  );
});
