import { Button } from '@farmlink/farmik-ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import Cancel from '../../../../assets/images/cancel.svg';
import { AgroidRoutes } from '../../routes';

import Styled from './AccessWall.styles';

interface IAccessWallProps {
  hideAccessWall: () => void;
}

export const AccessWall: FC<IAccessWallProps> = ({ hideAccessWall }) => {
  return (
    <Styled.Container>
      <Styled.IconSvg src={Cancel} alt="cancel-icon" />
      <Styled.Title>Вы не можете просматривать этот раздел</Styled.Title>
      <Styled.Subtitle>Для смены настройки прав доступа, обратитесь к руководству</Styled.Subtitle>
      <Styled.ButtonWrapper onClick={() => hideAccessWall()}>
        <Link to={AgroidRoutes.Index}>
          <Button color="primary" type="button">
            На главную
          </Button>
        </Link>
      </Styled.ButtonWrapper>
    </Styled.Container>
  );
};
