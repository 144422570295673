enum EInnerSize {
  Small = 1080,
  Medium = 1900,
  Large = 2700,
  ExtraLarge = 3500,
  ExtraExtraLarge = 4400,
}

const getSizeByInnerHeight = (size = 20): number => {
  const { innerHeight } = window;

  const isMoreThanStandard = size > 20;

  if (innerHeight >= EInnerSize.Small && innerHeight < EInnerSize.Medium) {
    return isMoreThanStandard ? size : size * 2;
  } else if (innerHeight >= EInnerSize.Medium && innerHeight < EInnerSize.Large) {
    return isMoreThanStandard ? size * 2 : size * 3;
  } else if (innerHeight >= EInnerSize.Large && innerHeight < EInnerSize.ExtraLarge) {
    return isMoreThanStandard ? size * 3 : size * 4;
  } else if (innerHeight >= EInnerSize.ExtraLarge && innerHeight <= EInnerSize.ExtraExtraLarge) {
    return isMoreThanStandard ? size * 4 : size * 5;
  } else if (innerHeight > EInnerSize.ExtraExtraLarge) {
    return isMoreThanStandard ? size * 5 : size * 6;
  } else {
    return size;
  }
};

export default getSizeByInnerHeight;
