import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  systemId: string;
  redirectUrl: string;
  scope?: string;
};

type TypeResponse = {};

export const erqRedirect: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/externalRequests/redirect`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
