import { memo } from 'react';

import { TWithListingPage } from './withListingPage.helper.types';

const withListingPage: TWithListingPage = listingPage => Component =>
  memo(props => {
    return <Component {...props} listingPage={listingPage} />;
  });

export default withListingPage;
