import React, { FC, HTMLAttributes } from 'react';

import { MainLayout } from './style';
export interface UsersPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const UsersPage: FC<UsersPageProps> = ({ param }) => {
  return <MainLayout title={param}>UsersPage</MainLayout>;
};
