import React, { Ref, useRef, useLayoutEffect } from 'react';
import { HTMLFieldProps, connectField /* filterDOMProps */ } from 'uniforms';
import InputMask from 'react-input-mask';
import { Label, Input } from '@zendeskgarden/react-forms';

import { RequireSign, FieldStyled, imaskStyles } from '../styled';

export type TextFieldProps = HTMLFieldProps<
  string,
  HTMLDivElement,
  { inputRef?: Ref<HTMLInputElement>; regexonchange?: RegExp; dataTestId?: string }
>;

function Text({
  autoComplete,
  disabled,
  id,
  // inputRef,
  label,
  name,
  onChange,
  placeholder,
  readOnly,
  type,
  max,
  required,
  error,
  // min,
  value,
  regexonchange,
  dataTestId,
  ...props
}: TextFieldProps) {
  const { field: { uniforms = {} } = {} } = props;
  const { mask } = uniforms;
  const ref = useRef<HTMLInputElement>();

  useLayoutEffect(() => {
    if (!mask) {
      const curRef = ref.current;
      curRef.setAttribute('maxlength', String(max));
    }
  });

  const handleChange = event => {
    let newValue = event.target.value;
    if (type === 'number' && newValue.toString().length > max) {
      newValue = value;
    }

    if (regexonchange && regexonchange.test(newValue)) {
      onChange(newValue);
      return;
    }

    if (regexonchange && !regexonchange.test(newValue)) {
      return;
    }

    onChange(newValue);
  };

  const inputProps = {
    autoComplete,
    id,
    name,
    readOnly,
    ref,
    type,
    disabled,
  };

  let validation;

  if (Boolean(error)) {
    validation = 'error';
  }

  return (
    <FieldStyled>
      {/* <div {...filterDOMProps(props)}> */}
      {label && (
        <Label data-test-id={dataTestId && `${dataTestId}-label`}>
          {label} {Boolean(required) && <RequireSign />}
        </Label>
      )}
      {mask ? (
        <InputMask
          mask={mask}
          onChange={handleChange}
          value={value ?? ''}
          placeholder={placeholder || uniforms.placeholder}
          style={imaskStyles}
          data-test-id={dataTestId && `${dataTestId}-input-mask`}
        />
      ) : (
        <Input
          {...inputProps}
          validation={validation}
          onChange={handleChange}
          placeholder={placeholder || uniforms.placeholder}
          value={value ?? ''}
          className={validation === 'error' ? 'invalid' : 'valid'}
          data-test-id={dataTestId && `${dataTestId}-input`}
        />
      )}
    </FieldStyled>
  );
}

Text.defaultProps = { type: 'text' };

export const InputField = connectField<TextFieldProps>(Text, { kind: 'leaf' });
