import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button } from '@farmlink/farmik-ui';
import { isMobileOnly } from 'react-device-detect';

import IconDelete from '../assets/images/delete.svg';
import { AgroidRoutes } from '../../routes';
import { useStore } from '../../../../utils/IoC';
import { EditInterestsStore } from '../../../auth/stores/EditInterestsStore';
import { Autosuggest } from '../Autosuggest/Autosuggest';
import { ProfileStore } from '../../../auth/stores/profile.store';

import {
  Wrapper,
  InputWrapper,
  FirstDiv,
  SecondDiv,
  Competition,
  DeleteIcon,
  ExpertiseWrapper,
  Footer,
  FooterButtonWrapper,
} from './style';

export const EditInterests: FC = observer(() => {
  const expertiseStore = useStore(EditInterestsStore);
  const history = useHistory();

  const {
    expertiseOptions,
    setExpertise,
    fetchExpertiseList,
    addToList,
    selectExpertise,
    deleteExpertise,
    saveExpertises,
    addToInterests,
    reset,
    interests,
    searchExpertise,
    selectedInterest,
  } = expertiseStore;
  const profileStore = useStore(ProfileStore);

  useEffect(() => {
    addToInterests().then(() => {
      fetchExpertiseList();
    });
  }, []);

  useEffect(() => {
    console.log(selectedInterest);
  }, [selectedInterest]);

  const onSave = async () => {
    await saveExpertises();
    await profileStore.fetchProfile();
    reset();
    history.push(AgroidRoutes.Profile);
  };

  const onCancel = () => {
    reset();
    history.push(AgroidRoutes.Profile);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    addToList();
  };

  return (
    <Wrapper data-test-id="edit-interests-form-wrapper">
      <InputWrapper onSubmit={submitHandler} data-test-id="edit-interests-form">
        <FirstDiv data-test-id="edit-interests-form-suggest-wrapper">
          <Autosuggest
            onChange={selectExpertise}
            onSearch={setExpertise}
            options={expertiseOptions}
            placeholder={'Например, “Садоводство”'}
            label={'Добавление нового интереса'}
            value={searchExpertise}
            dataTestId="interest-suggest"
          />
        </FirstDiv>
        <SecondDiv data-test-id="edit-interest-form-add-wrapper">
          <Button
            type={'submit'}
            color={'primary'}
            dataTestId="edit-interest-form-add-button"
            disabled={!Boolean(selectedInterest?.interestId)}
          >
            {!isMobileOnly ? 'Добавить' : '+'}
          </Button>
        </SecondDiv>
      </InputWrapper>
      <ExpertiseWrapper data-test-id="edit-interest-list">
        {interests.length > 0
          ? interests.map(item => (
              <Competition key={item.interestId} data-test-id="edit-interest-item">
                <DeleteIcon
                  src={IconDelete}
                  onClick={() => deleteExpertise(item.interestId)}
                  data-test-id="edit-interest-item-icon"
                />
                <p data-test-id="edit-interest-item-caption">{item.interestName}</p>
              </Competition>
            ))
          : 'Не выбраны интересы'}
      </ExpertiseWrapper>
      <Footer data-test-id="edit-interest-footer">
        <FooterButtonWrapper data-test-id="edit-interest-footer-button-wrapper">
          <Button
            color={'default'}
            type={'button'}
            onClick={onCancel}
            dataTestId="edit-interest-footer-button-cancel"
          >
            Отменить
          </Button>
        </FooterButtonWrapper>
        <FooterButtonWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={onSave}
            dataTestId="edit-interest-footer-button-save"
          >
            Сохранить
          </Button>
        </FooterButtonWrapper>
      </Footer>
    </Wrapper>
  );
});
