import styled, { StyledComponentBase } from 'styled-components';
import { LG } from '@zendeskgarden/react-typography';

interface IInviteCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
}

export const InviteCardWrapper = styled.div`
  grid-column-start: span 1;
  display: block;
  text-decoration: none !important;
  background: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  padding: 32px 16px 20px;
  height: 188px;
`;

export const OrgCardStyled: IInviteCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;

  .logo {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: ${({ theme: { space: s } }) => s.md} auto;
  }
`;

OrgCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 52px;
  @media (min-width: 768px) {
    -webkit-line-clamp: 2;
    max-height: 32px;
  }
  margin-top: 20px;
`;

OrgCardStyled.Text = styled.p<{ status?: string }>`
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;

  ${({ theme: { colors: c }, status }) => {
    if (status === 'SENT') {
      return `
        color: ${c.accentOrange};
      `;
    }

    if (status === 'ACCEPTED') {
      return `
        color: ${c.accentGreen};
      `;
    }

    if (status === 'DECLINED') {
      return `
        color: ${c.accentPink};
      `;
    }

    if (status === 'CANCELLED') {
      return `
        color: ${c.secondaryDarkGray};
      `;
    }
  }}
`;

export const LogoOrgNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const OrgLogoWrapper = styled.div`
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  margin: 0;

  svg {
    width: inherit;
    height: inherit;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;

export const OrgName = styled.div`
  max-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 15px;
  text-align: center;
`;

export const InviteStatusLabel = styled.p`
  color: ${({ theme: { colors: c } }) => c.accentGreen};
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
`;
