import { TypeApiRoute } from '../../models/type.api.request';
import { AccessRule } from '../../models/user.model';

type TRequest = {};

type TResponse = AccessRule[];

export const getAllActiveGlobalActionsForCurrentUser: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: `/api/da-profile/access/action/global/active/list`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
