import { FC, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Modal, Header, Body } from '@zendeskgarden/react-modals';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { ModelTransformMode } from 'uniforms';
import { Dropdown } from '@farmlink/farmik-ui';

import { OrganizationStore, RolesStore } from '../../stores';
import { useStore } from '../../../../utils';

import { bridge as schema } from './schema';
import {
  AlreadyMemberError,
  InputErrorColorizer,
  RoleWrapper,
  ButtonWrapper,
  ModalWrapper,
} from './style';

export interface IUserModalProps {
  onClose: (res?: any) => void;
  handleUpdateData?: () => void;
  orgId: string;
}

type ModelProps = {
  fio: string;
  email: string;
  role: string;
  comment: string;
};

export const NewUserModal: FC<IUserModalProps> = observer(
  ({ orgId, handleUpdateData, onClose }) => {
    const { inviteByEmail } = useStore(OrganizationStore);
    const { roles } = useStore(RolesStore);
    const [options, setOptions] = useState([]);

    const [isAlreadyMemberError, setIsAlreadyMemberError] = useState(false);
    const [lastEmailValue, setLastEmailValue] = useState('');

    useEffect(() => {
      setOptions(
        roles
          .filter(r => r.name !== 'Владелец' && !r.isScoutForUser)
          .map(r => ({
            value: r.id,
            label: r.name,
          }))
      );
    }, [roles]);

    const [formRef, setFormRef] = useState(null);

    const roleDropdownProps = useMemo(() => {
      formRef?.change('roleId', options[0]?.value);
      return {
        field: {
          isRequired: false,
          placeholder: '',
          id: 'roleId',
          defaultValue: options[0],
          onChange: args => {
            formRef.change('roleId', args);
          },
        },
        body: {
          optionList: options,
        },
        visual: {
          label: 'Роль',
          isBlocked: false,
        },
        other: {
          dataTestId: 'role-input-select',
        },
      };
    }, [formRef, options]);

    const handleSubmit = async (doc: any) => {
      console.log(doc);
      doc.organizationId = orgId;
      try {
        await inviteByEmail(doc);
        if (handleUpdateData) {
          handleUpdateData();
        }
        onClose(true);
      } catch (e) {
        if ((e as any).response?.data?.error?.title === 'ALREADY_MEMBER') {
          setIsAlreadyMemberError(true);
        }
        console.error('error inviteByEmail', e);
      }
    };

    const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
      if (mode === 'form' && model.email !== lastEmailValue) {
        setIsAlreadyMemberError(false);
        setLastEmailValue(model.email);
      }
      return model;
    };

    return (
      <ModalWrapper onClose={onClose} data-test-id="new-user-modal">
        <Header data-test-id={'new-user-header'}>Добавление сотрудника</Header>
        <Body>
          <AutoForm
            schema={schema}
            onSubmit={handleSubmit}
            modelTransform={handleModelTransform}
            ref={ref => {
              console.log(ref);
              if (ref && !formRef) {
                setFormRef(ref);
              }
            }}
          >
            <Grid>
              <Row>
                <Col>
                  {isAlreadyMemberError && <InputErrorColorizer />}
                  <AutoField name="email" dataTestId="email-input" />
                  <ErrorField name="email" data-test-id="email-input-error" />
                  {isAlreadyMemberError && (
                    <AlreadyMemberError data-test-id={'already-member-error'}>
                      Cотрудник с указанным e-mail уже добавлен
                    </AlreadyMemberError>
                  )}
                </Col>
              </Row>
              <RoleWrapper>
                <Row>
                  <Col>
                    <AutoField
                      name="roleId"
                      options={options}
                      isOpenAbove={true}
                      component={Dropdown}
                      marginBottomForSelectBody={'-20px'}
                      placeholder={'Не указан'}
                      config={roleDropdownProps}
                      dataTestId="role-dropdown"
                    />
                    <ErrorField name="roleId" />
                  </Col>
                </Row>
              </RoleWrapper>
              <Row>
                <Col data-test-id={'new-user-textarea-wrap'}>
                  <AutoField name="comment" dataTestId="comment-input" />
                  <ErrorField name="comment" />
                </Col>
              </Row>
            </Grid>
            <Grid columns={2}>
              <Row justifyContent="center">
                <Col textAlign="center" xs={1}>
                  <ButtonWrapper
                    isStretched
                    isBasic
                    onClick={onClose}
                    size="large"
                    data-test-id="cancel-button"
                  >
                    Отменить
                  </ButtonWrapper>
                </Col>

                <Col textAlign="center" xs={1}>
                  <ButtonWrapper
                    type={'submit'}
                    isStretched
                    isPrimary
                    size="large"
                    data-test-id="submit-button"
                  >
                    Пригласить
                  </ButtonWrapper>
                </Col>
              </Row>
            </Grid>
          </AutoForm>
        </Body>
      </ModalWrapper>
    );
  }
);
