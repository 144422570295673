import styled, { StyledComponentBase } from 'styled-components';
import { Field } from '@zendeskgarden/react-forms';
import { mediaQuery } from '@zendeskgarden/react-theming';

export const ColsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  @media (min-width: 1024px) {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

interface IModuleRow extends StyledComponentBase<any, {}> {
  Name?: any;
}

export const ModuleRow: IModuleRow = styled.div`
  width: 50%;
  padding: 0 12px;
  margin-bottom: 4px;

  ${p => mediaQuery('down', 'xs', p.theme)} {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
`;

ModuleRow.Name = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px;
`;
interface IActionRow extends StyledComponentBase<any, {}> {
  Label?: any;
  CheckboxWrapper?: any;
}

export const ActionRow: IActionRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

ActionRow.Label = styled.div<{ disabled?: boolean }>`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme: { colors: c }, disabled }) =>
    disabled ? c.generalBlack : c.secondaryDarkGray};
`;

ActionRow.CheckboxWrapper = styled.div`
  margin-left: auto;
`;

export const RoleFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  padding: 30px 24px;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding: 16px;
  }
`;

export const FieldStyled = styled(Field)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e6f0;
`;

export const LoadderWrapper = styled.div`
  margin-top: 16px;

  padding: 16px 24px;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding: 16px;
  }
`;
