import { incline } from 'lvovich';

export const getInclineUser = (user: string): string => {
  const regExp = /^[а-яА-Я\s]+$/i;

  if (!user) {
    return 'пользователя';
  }

  if (!regExp.test(user)) {
    return user;
  }

  const [last, first] = user.split(' ');
  const inclineUser = incline({ first, last }, 'genitive');

  return `${inclineUser.first} ${inclineUser.last}`;
};
