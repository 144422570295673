import '@zendeskgarden/css-bedrock';
import { css } from 'styled-components';
import { PALETTE, mediaQuery, IGardenTheme } from '@zendeskgarden/react-theming';

/**
 * Theming
 *
 * @param parentTheme
 * @returns theme
 */

export default function GardenTheme(parentTheme: IGardenTheme) {
  const space = {
    ...parentTheme.space,
    base: 4,
    xxs: '4px',
    xs: '8px',
    ssm: '12px',
    sm: '16px',
    md: '24px',
    menuPadding: '28px',
    lg: '32px',
    xl: '40px',
    xxl: '48px',
  };

  const borderRadii = {
    ...parentTheme.borderRadii,
    bullet: '4px',
    label: '8px',
    button: '12px',
    card: '16px',
    block: '24px',
  };

  const colors = {
    ...parentTheme.colors,
    generalBlack: '#151F32',
    generalWhite: '#FFFFFF',
    secondaryBgGray: '#F2F1F4',
    secondaryDarkGray: '#9AA0AD',
    secondaryGray: '#FAF9FD',
    accentGreen: '#3FB65F',
    accentOrange: '#FD7E09',
    accentPink: '#DF2E22',
    hoverDarkGray: '#707C94',
    hoverGreen: '#259A42',
    hoverPink: '#B70C00',
    brandColor: '#3FB65F',
    primaryHue: '#3FB65F',
    dangerHue: '#F29993',
    successHue: '#3FB65F',
    borderGray: '#E9E6F0',
  };

  const shadows = {
    ...parentTheme.shadows,
    dropdown: '0 0 8px rgba(0, 0, 0, 0.1)',
  };

  const fonts = {
    ...parentTheme.fonts,
    system: `'Proxima Nova', ${parentTheme.fonts.system}`,
  };

  const components = {
    ...parentTheme.components,
    'chrome.header': css``,
    'chrome.main': css`
      background: none;
      scrollbar-gutter: stable;
      ${p => mediaQuery('down', 'md', p.theme)} {
        padding-bottom: 12px;
      }
      ${p => mediaQuery('up', 'md', p.theme)} {
        padding-bottom: 0;
      }
    `,
    'buttons.anchor': css<{ isDanger: boolean; isBasic: boolean; isPrimary: boolean }>`
      font-weight: unset;
      line-height: unset;
      text-align: unset;
      border-radius: unset;
      padding-top: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-right: unset;
      font-size: 12px;
      color: ${colors.accentGreen};
      text-decoration: underline;

      &:hover {
        color: ${colors.hoverGreen};
      }

      ${({ isPrimary }) =>
        isPrimary &&
        `
        color: ${colors.accentGreen};

        svg path {
          fill: ${colors.accentGreen};
        }

        &:hover {
          color: ${colors.hoverGreen} !important;
          svg path {
            fill: ${colors.hoverGreen} !important;
          }
          }
      `}

      ${({ isDanger }) =>
        isDanger &&
        `
        color: ${colors.accentPink};
        svg path {
          fill: ${colors.accentPink};
        }

        &:hover {
          color: ${colors.hoverPink} !important;
          svg path {
            fill: ${colors.hoverPink} !important;
          }
          }
      `}

      ${({ isBasic }) =>
        isBasic &&
        `
        background: none;

        color: ${colors.secondaryDarkGray} !important;
        svg path {
          fill: ${colors.secondaryDarkGray} !important;
        }

        &:hover {
          background: none;
          color: ${colors.hoverDarkGray} !important;
          svg path {
            fill: ${colors.hoverDarkGray} !important;
          }
        }
      `}

      svg {
        margin-right: 4px;
        vertical-align: baseline;
        display: inline-block;

        path {
          color: currentColor;
        }
      }

      svg ~ span {
        position: relative;
        top: -2px;
      }

      &:hover {
        color: ${colors.hoverGreen};
        svg path {
          fill: ${colors.hoverGreen};
        }
      }
    `,
    'chrome.nav_item': css`
      opacity: 1;

      &:hover {
        background-color: ${colors.secondaryGray};
      }
    `,
    'chrome.brandmark_nav_item': css`
      outline: 1px solid red;
      opacity: 1 !important;
    `,
    'buttons.icon_button': css`
      svg {
        margin-right: 0;
      }
    `,
    'buttons.button': css<{ isPrimary?: boolean; isBasic?: boolean; isGhost?: boolean }>`
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      border-radius: ${borderRadii.button};
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: ${space.xxl};
      padding-right: ${space.xxl};

      ${({ isPrimary }) => {
        return isPrimary ? `svg { margin-right: 4px; path { fill: #fff; }}` : null;
      }}
      ${({ isBasic }) => {
        return isBasic
          ? `
          border: none !important;
          color: ${colors.generalWhite};
          background: ${colors.secondaryDarkGray};

          &:hover {
            color: ${colors.generalWhite};
            background: ${colors.hoverDarkGray};
          }
        `
          : null;
      }}
      ${({ isGhost }) => {
        return isGhost
          ? `
          border: none !important;
          background: none;
          color: ${colors.secondaryDarkGray};

          &:hover {
            color: ${colors.hoverDarkGray};
            background: ${colors.generalWhite};
          }
        `
          : null;
      }}
    `,
    'grid.grid': css`
      padding-right: 0;
      padding-left: 0;
      ${p => mediaQuery('down', 'md', p.theme)} {
        padding-right: 8px;
        padding-left: 8px;
      }
    `,
    'grid.col': css`
      ${p => mediaQuery('down', 'xs', p.theme)} {
        padding-right: 6px;
        padding-left: 6px;
      }
    `,
    'forms.input_group': css`
      > div {
        margin-top: -8px;
      }
    `,
    'forms.field': css`
      margin-bottom: ${space.md};

      ~ div[id|='uniforms'] {
        color: ${colors.accentPink};
        margin-top: -${space.sm};
        margin-bottom: ${space.md};
        font-size: 12px;
        padding-left: ${borderRadii.button};
      }
    `,
    'tooltip.tooltip': css`
      border: none;
      box-shadow: ${shadows.dropdown};
      padding: ${space.sm};

      * {
        font-size: 12px;
        line-height: 16px;
      }
    `,
    'notifications.well': css`
      ruby-align: center;
      color: ${p => p.theme.Colors.generalDark};
      border-radius: ${borderRadii.card};
      border: none;
      position: static;
      padding: ${p => p.theme.space.md};
    `,
    'chrome.header_item_wrapper': css`
      margin: 0;
      padding: 0;
      height: 100%;
    `,
    'chrome.header_item': css`
      margin-left: 0;
      margin-right: 0;
      height: 100%;
    `,
    'chrome.subnav_item': css`
      border-bottom: 1px solid ${p => p.theme.Colors.secondaryDark};
      border-radius: 0;
      padding: 6px !important;

      &:active,
      &:hover {
        background: none;
      }
    `,
    'chrome.subnav_item_text': css`
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${p => p.theme.Colors.generalDark};

      svg {
        vertical-align: bottom;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        display: inline-block;
      }

      svg path {
        fill: ${p => p.theme.Colors.generalDark};
      }
    `,
    'avatars.avatar': css`
      border-radius: ${borderRadii.button};
      transition: 0.8s background ease;

      &:hover {
        background: ${PALETTE.grey[200]};
      }
    `,
    'modals.modal': css`
      padding: 40px 60px;
      width: 440px;
      border-radius: ${borderRadii.card};
      overflow: hidden;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 6px 8px rgba(0, 0, 0, 0.05),
        0px 2px 24px rgba(0, 0, 0, 0.1);

      ${p => mediaQuery('down', 'xs', p.theme)} {
        padding: ${space.lg} ${space.md};
        left: ${space.sm} !important;
        right: ${space.sm} !important;
        width: auto !important;
        max-height: 80vh;
      }
    `,
    'modals.body': css`
      margin: 0 -60px;
      padding: 20px 60px;
      overflow: auto;
    `,
    'forms.input_label': css`
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: ${colors.secondaryDarkGray};

      ~ input,
      ~ [data-garden-id='forms.faux_input'] {
        margin-top: 4px !important;
      }
    `,
    'forms.input': css`
      border: 1px solid ${colors.borderGray};
      background-color: ${colors.secondaryGray};
      border-radius: ${borderRadii.button};
    `,
    'modals.header': css`
      border-bottom: 1px solid ${colors.borderGray};
      padding: 0 0 ${space.md};
      color: ${colors.generalBlack};
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
    `,
  };

  // return parentTheme;
  return {
    ...parentTheme,
    colors,
    borderRadii,
    fonts,
    space,
    components,
    shadows,
    testColor: '#f00',
  };
}
