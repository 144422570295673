import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import MailSvg from '../../../../assets/images/agroid/colored/Mail.svg';
import { useStore } from '../../../../utils';
import { MyApplicationStore } from '../../stores';
import { Layout, SidebarLayout, OrgsMenu, NewOrgModal } from '../../components';
import { InvitesList } from '../../components/InvitesList/InvitesList';

import { NoDataPlaceholder, NoDataTitle, StyledNoDataIcon } from './styled';

export interface OrgsPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const InvitesPage: FC<OrgsPageProps> = observer(() => {
  const [visible, setVisible] = useState(false);
  const { invitations, fetchApplications } = useStore(MyApplicationStore);

  useEffect(() => {
    // fetch Applications();
    // fetchApplications();
  }, []);

  return (
    <>
      <SidebarLayout>
        <OrgsMenu />
        <Layout>
          {invitations.length ? (
            <InvitesList />
          ) : (
            <NoDataPlaceholder>
              <StyledNoDataIcon src={MailSvg} />
              <NoDataTitle>У вас ещё нет приглашений</NoDataTitle>
            </NoDataPlaceholder>
          )}
        </Layout>
      </SidebarLayout>
      {visible && <NewOrgModal onClose={() => setVisible(false)} />}
    </>
  );
});
