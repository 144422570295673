import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios } from '../../../../../utils/axios2';
import { checkIsEmail } from '../../../../../utils/checkIsEmail';
import { history } from '../../../../../utils/history';
import { Field } from '../../../../../constants/field';
import { AuthorizationRoutes } from '../../../routes';
import { UNKNOWN_ERROR } from '../../../../../constants/error';

@provide.singleton()
export class ResetStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  email: Field = {
    value: '',
    error: '',
  };
  password: Field = {
    value: '',
    error: '',
  };
  passwordRepeat: Field = {
    value: '',
    error: '',
  };

  get isResetAvailable() {
    return checkIsEmail(this.email.value);
  }

  setPassword = (v: string) => {
    this.password.value = v;
  };

  setNewPassword = (v: string) => {
    this.passwordRepeat.value = v;
  };

  get isChangePasswordAvailable() {
    return (
      this.password.value.length >= 6 &&
      this.passwordRepeat.value.length >= 6 &&
      this.password.value === this.passwordRepeat.value
    );
  }

  resetSteps: 'initial' | 'email_sent' | 'password_set' | 'final' = 'initial';
  isLoadingVerifyLink = true;

  setEmail = (v: string) => {
    this.email.value = v;
    this.email.error = '';
  };

  resetViaEmail = () => {
    this.axios.api
      .sendEmailPasswordReset({ email: this.email.value })
      .then(() => {
        runInAction(() => {
          this.resetSteps = 'email_sent';
        });
      })
      .catch((response: { errors: Array<{ source: string; title: string }> }) => {
        this.email.error = UNKNOWN_ERROR;

        if (!response.errors) {
          return;
        }

        response.errors.forEach(field => {
          if (field.source === 'email') {
            this.email.error = field.title;
          }
        });
      });
  };

  isLoadingSetNewPassword = true;
  isLinkSetNewPasswordValid = false;

  verifyResetToken = (resetToken: string) => {
    this.isLoadingSetNewPassword = true;
    this.isLinkSetNewPasswordValid = false;
    this.changePasswordToken = resetToken;

    this.axios.api
      .verifyResetPasswordToken({ resetToken })
      .then(response => {
        runInAction(() => {
          this.isLoadingSetNewPassword = false;
          this.isLinkSetNewPasswordValid = true;
          localStorage.setItem('accessToken', response['access-token']);
        });
      })
      .catch(error => {
        console.error(error);
        this.isLoadingSetNewPassword = false;
        history.push(AuthorizationRoutes.signIn);
      });
  };

  changePasswordToken = '';

  resetLoadingForVerifyPasswordLink = () => {
    this.isLoadingSetNewPassword = false;
    this.isLinkSetNewPasswordValid = false;
  };

  updatePassword = () => {
    this.axios.api
      .updatePassword({
        token: this.changePasswordToken,
        newPassword: this.password.value,
        repeatedNewPassword: this.passwordRepeat.value,
      })
      .then(() => {
        runInAction(() => {
          this.resetSteps = 'final';
        });
      })
      .catch((response: { errors?: Array<{ source: string; title: string }> }) => {
        if (!response.errors) {
          return;
        }

        response.errors.forEach(field => {
          if (field.source === 'password') {
            this.password.error = field.title;
          }
        });
      });
  };

  isEmailVerificationLoading = true;
  isEmailVerificationSuccess = false;
  isEmailRegistred = false;

  emailVerify = (token: string) => {
    this.axios.api
      .verifyResetEmailToken({ verificationHash: token }, { omit: ['verificationHash'] })
      .then(() => {
        runInAction(() => {
          this.isEmailVerificationLoading = false;
          this.isEmailVerificationSuccess = true;
        });
      })
      .catch(error => {
        console.error(error);

        this.isEmailVerificationLoading = false;
        this.isEmailVerificationSuccess = false;
        if (
          error?.errors?.length &&
          error?.errors[0]?.title &&
          error?.errors[0]?.title === 'email is busy'
        ) {
          this.isEmailRegistred = true;
        }
      });
  };

  clear = () => {
    this.email = {
      value: '',
      error: '',
    };
    this.password = {
      value: '',
      error: '',
    };
    this.passwordRepeat = {
      value: '',
      error: '',
    };
    this.resetSteps = 'initial';
  };

  resetEmailVerification = () => {
    this.isEmailVerificationLoading = true;
    this.isEmailVerificationSuccess = false;
  };
}
