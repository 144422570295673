import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { ApplicationType } from '../../../api/models/ApplicationType';
import { ApplicationStatus } from '../../../api/models/applications.model';
import { InvitationType } from '../../../api/models/InvitationType';

const invitationsPageSize = 10;

@provide.singleton()
export class MyApplicationStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  searchQuery = '';
  isLoading = false;
  invitations = [];
  invitationsCurrentPage = 0;
  invitationsTotalPages = 0;
  invite: InvitationType | null;

  setCurrentPage = (i: number) => {
    this.invitationsCurrentPage = i;
  };

  getInviteById = (id: string) => {
    return this.axios.api.getUserInvite({ id }, { omit: ['id'] }).then(response => {
      this.invite = response;
    });
  };

  clearInvite = () => {
    this.invite = null;
  };

  setInvite = (invite: InvitationType) => {
    this.invite = { ...invite };
  };

  acceptUserInvite = (id: string) => this.axios.api.acceptUserInvite({ id });

  declineUserInvite = (id: string) => this.axios.api.declineUserInvite({ id });

  cancelUserInvite = (id: string) => this.axios.api.cancelUserInvite({ id });

  resetInvitationsPage = () => {
    this.invitationsCurrentPage = 0;
    this.invitationsTotalPages = 0;
  };

  fetchApplications = (isConcat?: boolean) => {
    this.isLoading = true;
    return this.axios.api
      .getOrganizationApplicationsMine({
        page: this.invitationsCurrentPage || 0,
        size: invitationsPageSize,
      })
      .then(response => {
        if (response) {
          runInAction(() => {
            const filteredInvitations = response.content.filter(
              (item: InvitationType) => item.status !== ApplicationStatus.CANCELLED
            );
            this.invitationsCurrentPage = isConcat ? this.invitationsCurrentPage + 1 : 1;
            this.invitations = isConcat
              ? this.invitations.concat(filteredInvitations)
              : filteredInvitations;
            this.invitationsTotalPages = response.totalPages;
            this.isLoading = false;
          });
        }
      })
      .catch(error => {
        console.error(error);
        this.isLoading = false;
      });
  };
}
