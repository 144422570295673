import { Dropdown, TDropdownProps } from '@farmlink/farmik-ui';
import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

import {
  SelectField,
  InputField,
  MediaInputField,
  BooleanField,
} from '../../../../components/uniforms';

const regExMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const managerDefaultDropdownConfig: TDropdownProps['config'] = {
  field: {
    onChange: undefined,
    isRequired: false,
    defaultValueList: [],
    placeholder: '',
    id: 'manager',
    icons: {
      clear: {
        options: {
          valueAfterCleaning: { value: null, label: null },
        },
      },
    },
  },
  body: {
    optionList: [],
  },
  visual: {
    label: 'Ответственный',
    isBlocked: false,
  },
};

export const Schema = new SimpleSchema({
  orgTypeId: {
    type: Number,
    required: true,
    label: 'Тип организации',
    uniforms: {
      component: SelectField,
      dataTestId: 'org-type-id',
      options: [],
    },
  },

  name: {
    type: String,
    label: 'Название организации',
    required: true,
    regEx: /^[a-zёа-я0-9.,\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указано',
      regexonchange: /(^$)|(^[а-яёa-z0-9.,\- ]+$)/i,
      dataTestId: 'org-name',
    },
  },

  INN: {
    type: String,
    optional: true,
    min: 10,
    max: 12,
    label: 'ИНН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'inn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 10;
        if (orgTypeId === 4) {
          length = 12;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthINN_IP' : 'lengthINN';
        }
      }
    },
  },

  addressJur: {
    type: String,
    optional: true,
    label: 'Юридический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-jur',
    },
  },

  addressFact: {
    type: String,
    optional: true,
    label: 'Фактический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-fact',
    },
  },

  OGRN: {
    type: String,
    optional: true,
    min: 13,
    max: 15,
    label: 'ОГРН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'ogrn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 13;
        if (orgTypeId === 4) {
          length = 15;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthOGRN_IP' : 'lengthOGRN';
        }
      }
    },
  },

  OKVED: {
    type: String,
    optional: true,
    min: 2,
    max: 8,
    label: 'ОКВЭД',
    regEx: /^[0-9.]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[0-9.]+$)/i,
      dataTestId: 'okved',
      // type: 'number',
      // mask: '99.99.99',
    },
  },

  OKPO: {
    type: String,
    optional: true,
    min: 8,
    max: 10,
    label: 'ОКПО',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'okpo',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 8;
        if (orgTypeId === 4) {
          length = 10;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthOKPO_IP' : 'lengthOKPO';
        }
      }
    },
  },

  KPP: {
    type: String,
    optional: true,
    min: 9,
    max: 9,
    label: 'КПП',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'kpp',
    },
  },

  contact: {
    type: String,
    optional: true,
    max: 200,
    label: ' Представитель',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[a-zа-яё\- ]+$)/i,
      dataTestId: 'contact',
    },
  },

  representativePosition: {
    type: String,
    optional: true,
    max: 50,
    label: ' Должность',
    uniforms: {
      component: InputField,
      placeholder: 'Не указана',
      regexonchange: /(^$)|(^[a-zа-яё\- ]+$)/i,
      dataTestId: 'representative-position',
    },
  },

  email: {
    type: String,
    optional: true,
    regEx: regExMail,
    label: 'E-mail',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'email',
    },
  },

  phone: {
    type: String,
    optional: true,
    max: 50,
    label: ' Телефон',
    regEx: /^[a-zёа-я0-9.()+\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[а-яёa-z0-9.()+\- ]+$)/i,
      dataTestId: 'org-phone',
    },
  },

  isScout: {
    type: Boolean,
    optional: true,
    label: 'Планируется использование модулей «Скаутинга»',
    uniforms: {
      component: BooleanField,
      dataTestId: 'scout',
    },
  },
  isExperiment: {
    type: Boolean,
    optional: true,
    label: 'Планируется использование модулей для «Опыты»',
    uniforms: {
      component: BooleanField,
      dataTestId: 'experiment',
    },
  },

  newOwner: {
    type: String,
    optional: true,
    regEx: regExMail,
    label: 'E-mail владельца',
    uniforms: {
      component: MediaInputField,
      placeholder: 'E-mail не указан',
      dataTestId: 'new-owner',
      isError: true,
    },
  },

  manager: {
    type: String,
    optional: true,
    label: 'Ответственный',
    uniforms: {
      component: SelectField,
      options: [],
      dataTestId: 'manager',
    },
  },
});

export const bridge = new SimpleSchema2Bridge(Schema);
