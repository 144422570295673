import { FC, HTMLAttributes, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Dropdown } from '@farmlink/farmik-ui';

import { useStore } from '../../../../utils';
import { RolesStore } from '../../stores';

import { bridge as schema } from './schema';

export interface UsersFilterProps extends HTMLAttributes<HTMLDivElement> {
  onChange?: any;
  statusOptions: Array<{ label: string; value: any }>;
}

export const UsersFilter: FC<UsersFilterProps> = observer(({ onChange, statusOptions = [] }) => {
  const { roles } = useStore(RolesStore);

  const rolesOptions = [{ value: '', label: 'Все роли' }].concat(
    roles.map(role => ({
      value: role.id,
      label: role.name,
    }))
  );

  const [formRef, setFormRef] = useState(null);

  const statusDropdownProps = useMemo(() => {
    formRef?.change('status', statusOptions[0]?.value);
    return {
      field: {
        isRequired: false,
        placeholder: '',
        id: 'status',
        defaultValue: statusOptions[0],
        onChange: args => {
          formRef.change('status', args);
        },
      },
      body: {
        optionList: statusOptions,
      },
      visual: {
        label: 'Роль',
        isBlocked: false,
      },
    };
  }, [formRef, statusOptions]);

  const roleDropdownProps = useMemo(() => {
    formRef?.change('roleId', rolesOptions[0]?.value);
    return {
      field: {
        isRequired: false,
        placeholder: '',
        id: 'roleId',
        defaultValue: rolesOptions[0],
        onChange: args => {
          formRef.change('roleId', args);
        },
      },
      body: {
        optionList: rolesOptions,
      },
      visual: {
        label: 'Роль',
        isBlocked: false,
      },
    };
  }, [formRef, rolesOptions]);

  return (
    <AutoForm
      schema={schema}
      onChangeModel={onChange}
      ref={ref => {
        console.log(ref);
        if (ref && !formRef) {
          setFormRef(ref);
        }
      }}
    >
      UsersFilter
      <Grid>
        <Row>
          <Col sm={isMobile ? 12 : 6} xs={12} textAlign="start">
            <AutoField name="fullname" />
            <ErrorField name="fullname" />
          </Col>
          {!isMobile && (
            <>
              <Col sm={3}>
                <AutoField
                  name="status"
                  options={statusOptions}
                  isOpenAbove={true}
                  component={Dropdown}
                  marginBottomForSelectBody={'-20px'}
                  placeholder={'Статусы'}
                  config={statusDropdownProps}
                  dataTestId="status-dropdown"
                />
                <ErrorField name="status" />
              </Col>
              <Col sm={3}>
                <AutoField
                  name="roleId"
                  options={rolesOptions}
                  isOpenAbove={true}
                  component={Dropdown}
                  marginBottomForSelectBody={'-20px'}
                  placeholder={'Роли'}
                  config={roleDropdownProps}
                  dataTestId="role-dropdown"
                />
                <ErrorField name="roleId" />
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </AutoForm>
  );
});
