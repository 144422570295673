import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import FontStyle from './assets/fonts';
import GlobalStyles from './assets/global';
import Normalize from './assets/normalize';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

export const lsAppSettingsKey = 'appSettingsKey';

export enum EAppSettingsType {
  Login = 'login',
  Organization = 'organization',
  Seazon = 'seazon',
}

ReactDOM.render(
  <StrictMode>
    <FontStyle />
    <Normalize />
    <GlobalStyles />
    <App />
  </StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring p
// to log results (for example: reportWebVitals(console
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
