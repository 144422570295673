import React, { FC, HTMLAttributes } from 'react';
import { Well } from '@zendeskgarden/react-notifications';
import { Blockquote, Paragraph, LG, MD } from '@zendeskgarden/react-typography';

import { WellHeader } from '../../components';

import { MainLayout, WellBodyStyled } from './style';
export interface SupportPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const SupportPage: FC<SupportPageProps> = () => {
  return (
    <MainLayout>
      <Well style={{ paddingLeft: 0, paddingRight: 0 }}>
        <WellHeader
          title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!"
          dataTestId="support-page"
        />
        <WellBodyStyled>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>
          <MD>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</MD>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>

          <Blockquote>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum nulla amet vero dolore
            laudantium veritatis dolores fuga, earum qui nostrum repellat laborum deserunt. Qui
            voluptas, at enim porro aut sit?
          </Blockquote>
          <p>&nbsp;</p>
          <MD>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</MD>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>
          <LG>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</LG>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>
          <MD>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</MD>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>

          <Blockquote>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum nulla amet vero dolore
            laudantium veritatis dolores fuga, earum qui nostrum repellat laborum deserunt. Qui
            voluptas, at enim porro aut sit?
          </Blockquote>
          <p>&nbsp;</p>
          <MD>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</MD>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>

          <LG>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</LG>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
          <p>&nbsp;</p>

          <LG>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa, hic!</LG>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi beatae suscipit
            excepturi, maiores, doloribus laudantium laboriosam quia fugiat debitis ex, explicabo
            atque! Dicta, quas. Tempore ut molestiae voluptatum qui modi ipsam molestias quis earum
            delectus quisquam, fugiat, sapiente quidem adipisci ullam consectetur architecto dolor
            quo! Atque voluptatibus doloremque inventore deleniti aut alias, cumque fugiat quibusdam
            tenetur libero ipsum voluptate! Ut molestiae perferendis consectetur corrupti
            reprehenderit in enim dolorem eaque odio incidunt vel odit minus necessitatibus
            exercitationem, molestias explicabo asperiores expedita culpa. Mollitia repellat fugiat
            numquam quaerat voluptas distinctio, impedit inventore ipsam minus aut. Aliquid rem
            corporis aspernatur saepe dicta vel.
          </Paragraph>
        </WellBodyStyled>
      </Well>
    </MainLayout>
  );
};
