import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  employeeId: string;
};

type TypeResponse = {};

export const removeUserFromOrg: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/organizations/user/${request.employeeId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
