import { FC, useMemo } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useModal } from '@farmlink/farmik-ui';

import pdfLogo from '../../../assets/images/logos/pdfLogo40x40.svg';
import docLogo from '../../../assets/images/logos/docLogo40x40.svg';
import docxLogo from '../../../assets/images/logos/docxLogo48x48.svg';
import fileLogo from '../../../assets/images/logos/fileLogo40x40.svg';
import { EContractFileContentType } from '../../../../../../../api/models/as-contracts/contract/contract.model';
import { useStore } from '../../../../../../../utils/IoC';
import { CheckAccessStore } from '../../../../../stores';
import { AccessRule } from '../../../../../../../api/models/user.model';
import { EAccessRuleScoutingName } from '../../../../../types';

import Styled from './ContractCard.styles';
import { TContractCardProps } from './ContractCard.types';

const ContractCard: FC<TContractCardProps> = ({
  name,
  contractFileInfo: { fileContentType, fileSize, downloadUrl, fileName },
  createdAt,
  handleRemoveItem,
  id,
}) => {
  const { openModalByModalId } = useModal();

  const { accessRules } = useStore(CheckAccessStore);

  const getImgFileLogo = (): string => {
    switch (fileContentType) {
      case EContractFileContentType.Doc:
        return docLogo;
      case EContractFileContentType.Docx:
        return docxLogo;
      case EContractFileContentType.Pdf:
        return pdfLogo;
      default:
        return fileLogo;
    }
  };

  const isDeletable = useMemo(() => {
    return Boolean(
      accessRules.find((rule: AccessRule) => rule.code === EAccessRuleScoutingName.manageScouting)
    );
  }, [accessRules]);

  const isDownloadable = useMemo(() => {
    return Boolean(
      accessRules.find(
        (rule: AccessRule) =>
          rule.code === EAccessRuleScoutingName.manageScouting ||
          EAccessRuleScoutingName.viewContract
      )
    );
  }, [accessRules]);

  const handleDownloadClick = async (): Promise<void> => {
    try {
      const { data } = await axios.get(downloadUrl, { responseType: 'blob' });

      if (!data) {
        return;
      }

      const blob = new Blob([data], {
        type: fileContentType,
      });

      saveAs(blob, fileName);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteFileClick = () => {
    handleRemoveItem(id);
  };

  return (
    <Styled.Wrapper>
      <Styled.InnerWrapper $flex="0 1 40px" data-test-id={'document-logo-wrap'}>
        <Styled.Logo
          src={getImgFileLogo()}
          alt={fileContentType || ''}
          data-test-id={'document-logo'}
        />
      </Styled.InnerWrapper>
      <Styled.InnerWrapper
        $flex="1 1 50%"
        $isElementsMarginBottom
        $isColumn
        data-test-id="document-description-wrap"
      >
        <Styled.Title data-test-id={'document-title'}>{name}</Styled.Title>
        <Styled.Description data-test-id="document-size">
          Размер: {`${(fileSize / 1000000).toFixed(2)} Мб`}
        </Styled.Description>
        <Styled.Description data-test-id="document-data">
          Дата: {moment(createdAt).format('DD.MM.YYYY')}
        </Styled.Description>
        <Styled.ActionButtonsWrapper>
          {isDownloadable && (
            <Styled.DownloadButton
              onClick={handleDownloadClick}
              data-test-id="document-download-button"
            >
              Скачать
            </Styled.DownloadButton>
          )}
          {isDeletable && handleRemoveItem && (
            <Styled.DeleteButton
              onClick={() => {
                openModalByModalId('removeItemConfirmModal', {
                  removeItemHandler: handleDeleteFileClick,
                });
              }}
              data-test-id="document-delete-button"
            >
              Удалить
            </Styled.DeleteButton>
          )}
        </Styled.ActionButtonsWrapper>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

export default ContractCard;
