import { TChangeOrganizationPayload } from '../models/organization.model';
import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = { organizationId: string } & Partial<TChangeOrganizationPayload>;

type TypeResponse = {};

export const updateOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ organizationId }) => `/api/da-profile/organizations/${organizationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
