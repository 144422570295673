import { FC, HTMLAttributes, ReactElement, SVGProps } from 'react';
import { isEmpty } from 'lodash';

import { ControlAccessRulesWrapper } from '../../../AccessRulesWrapper/ControlAccessRulesWrapper';
import { AccessRuleVisibility, AccessRuleUserActionName } from '../../types';

import { ModalHeaderStyled } from './styled';

export interface IModalHeaderAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
  mode?: AccessRuleVisibility;
  actionName?: AccessRuleUserActionName;
  dataTestIdAction?: string;
}

export interface IModalHeader extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  actions?: any[];
  scroll?: boolean;
  children?: ReactElement;
  dataTestId?: string;
}

export const ModalHeader: FC<IModalHeader> = ({
  title,
  actions = [],
  scroll,
  children,
  dataTestId = '',
}) => {
  return (
    <ModalHeaderStyled data-test-id={dataTestId && `${dataTestId}-wrapper`} scroll={scroll}>
      <ModalHeaderStyled.Container data-test-id={dataTestId && `${dataTestId}-container`}>
        <ModalHeaderStyled.Title data-test-id={dataTestId && `${dataTestId}-title`}>
          {title}
        </ModalHeaderStyled.Title>

        {!isEmpty(actions) && (
          <ModalHeaderStyled.Actions data-test-id={dataTestId && `${dataTestId}-actions`}>
            {actions.map(
              (
                { icon: Icon, caption, component: Component, componentProps, mode, actionName },
                index
              ) => (
                <ControlAccessRulesWrapper
                  mode={mode}
                  actionName={actionName}
                  key={`${caption}-${index}`}
                >
                  <Component {...componentProps}>
                    {Icon && <Icon />}
                    {caption}
                  </Component>
                </ControlAccessRulesWrapper>
              )
            )}
          </ModalHeaderStyled.Actions>
        )}
      </ModalHeaderStyled.Container>

      {!isEmpty(children) && (
        <ModalHeaderStyled.Search data-test-id={dataTestId && `${dataTestId}-content`}>
          {children}
        </ModalHeaderStyled.Search>
      )}
    </ModalHeaderStyled>
  );
};
