import { IModalConfig } from '../../../modules/common/containers/configs/modals/modalConfig';
import scoutingContractorEmployeesModalGroup from '../../../modules/scouting/configs/modals/listingPage/scouting.contractor.employees.modals.config';
import { scoutingModalGroupArr } from '../../../modules/scouting/configs/modals/modalsScouting.config';

export interface IModalListConfig {
  [K: string]: IModalConfig[];
}

const modalConfigList: IModalListConfig = {
  ...scoutingModalGroupArr,
  ...scoutingContractorEmployeesModalGroup,
};

export const displayModalWindow = (
  uniqueKey: string,
  name: string,
  successHandler?: IModalConfig['successButton']['handler'],
  denyHandler?: IModalConfig['denyButton']['handler']
): IModalConfig => {
  const modal = modalConfigList?.[uniqueKey]?.find(configModal => configModal.name === name);

  if (modal) {
    return {
      ...modal,
      successButton: modal?.successButton && {
        ...modal.successButton,
        handler: successHandler || modal?.successButton?.handler,
      },
      denyButton: modal?.denyButton && {
        ...modal.denyButton,
        handler: denyHandler || modal?.denyButton?.handler,
      },
    };
  }
};
