import { FC, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { isEmpty, trim } from 'lodash';
import { Modal, EModalType, useModal } from '@farmlink/farmik-ui';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { useStore, getLink } from '../../../../../../utils';
import { getValuesCreate } from '../../utils';
import { ScoutsStore, ConfirmStore } from '../../../../stores';
import { ProfileStore } from '../../../../../auth/stores/profile.store';
import { InitialValues } from '../../../../../../api/models/contractor.model';
import EAgroidRoute from '../../../../routes/AgroidRoute.enum';
import { Layout, SidebarLayout, SidebarMenu } from '../../../../components';
import { ContractorForm } from '../../components';
import { useContractorsCreateMenu } from '../../useContractorsCreateMenu';
import { EModalSize } from '../../../../modules/common/containers/configs/modals/Modal/styles';

export const ContractorCreatePage: FC = observer(() => {
  const {
    createScout,
    fetchOptions,
    clearScout,
    scoutsOptions,
    orgTypesOptions,
    isOptionsLoading,
    clearScoutApplications,
  } = useStore(ScoutsStore);
  const { user, isLoading } = useStore(ProfileStore);

  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const {
    runUnBlockRouting,
    stopUnBlockRouting,
    goToNextPage,
    blockAndHide,
    enableRouting,
    visibleConfirm,
    isChanged,
  } = useStore(ConfirmStore);

  const history = useHistory();
  const [contractorId, setContractorId] = useState<string>('');

  const initialValues = useMemo(() => getValuesCreate(orgTypesOptions, scoutsOptions, user), [
    scoutsOptions,
    orgTypesOptions,
    user,
  ]);
  const menuItems = useContractorsCreateMenu();

  useEffect(() => {
    fetchOptions();
    clearScoutApplications();

    return () => {
      clearScout();
      enableRouting();
    };
  }, []);

  useEffect(() => {
    if (isChanged) {
      const fn = history.block('Блокировка роута');
      runUnBlockRouting(fn);
    } else {
      enableRouting();
      stopUnBlockRouting();
    }
  }, [isChanged]);

  const showUpdateContractorErrorModal = () => {
    registerModalList(
      [
        {
          name: 'createContractorErrorModal',
          title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
          type: EModalType.Warning,
          id: 'createContractorErrorModal',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Продолжить',
            color: 'primary',
            handler: closeModal,
          },
        },
      ],
      'createContractorErrorModal'
    );
    openModalByModalId('createContractorErrorModal');
  };

  const handleSubmit = (values: InitialValues) => {
    const payload = {
      logo: !isEmpty(values.logo.id) ? values.logo.id : undefined,
      orgTypeId: values.orgTypeId,
      name: trim(values.name),
      INN: !isEmpty(values.INN) ? values.INN : undefined,
      addressJur: !isEmpty(values.addressJur)
        ? { addressfull: trim(values.addressJur) }
        : undefined,
      addressFact: !isEmpty(values.addressFact)
        ? { addressfull: trim(values.addressFact) }
        : undefined,
      OGRN: !isEmpty(values.OGRN) ? values.OGRN : undefined,
      OKVED: !isEmpty(values.OKVED) ? values.OKVED : undefined,
      OKPO: !isEmpty(values.OKPO) ? values.OKPO : undefined,
      KPP: !isEmpty(values.KPP) ? values.KPP : undefined,
      contact: !isEmpty(values.contact) ? trim(values.contact) : undefined,
      representativePosition: !isEmpty(values.representativePosition)
        ? trim(values.representativePosition)
        : undefined,
      email: !isEmpty(values.email) ? values.email : undefined,
      phone: !isEmpty(values.phone) ? trim(values.phone) : undefined,
      isScout: values.isScout,
      isExperiment: values.isExperiment,
      newOwner: !isEmpty(values.newOwner) ? values.newOwner : undefined,
      manager: values.manager || null,
    };

    createScout(payload)
      .then((id: string) => {
        enableRouting();
        handleOpenCreateSuccessModal(id);
      })
      .catch(error => {
        showUpdateContractorErrorModal();
        console.error(error);
      });
  };

  const handleCancel = () => {
    history.push(EAgroidRoute.Scouting);
  };

  const handleOpenCreateSuccessModal = (id: string) => {
    setContractorId(id);
  };

  const handleCloseCreateSuccessModal = () => {
    setContractorId('');
  };

  const handleConfirmModalClose = () => {
    blockAndHide();
  };

  return (
    <>
      <SidebarLayout>
        <SidebarMenu items={menuItems} />
        <Layout>
          {isLoading || isOptionsLoading ? (
            <Well>
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </Well>
          ) : (
            <ContractorForm
              title="Создание контрагента"
              values={initialValues}
              orgTypesOptions={orgTypesOptions}
              scoutsOptions={scoutsOptions}
              onSubmit={handleSubmit}
              dataTestId="scout-create"
              onCancel={handleCancel}
              buttonSubmitTitle="Создать контрагента"
            />
          )}

          {contractorId && (
            <Modal
              modal={{
                name: 'scout-create-modal',
                title: 'Контрагент успешно добавлен',
                type: EModalType.Success,
                id: 'scout-create-modal-id',
                successButton: {
                  title: 'Продолжить',
                  handler: () => {
                    history.push(
                      getLink(EAgroidRoute.ScoutingContractor, {
                        [contractorId]: ':contractorId',
                      })
                    );
                  },
                  dataTestId: 'scout-create-modal-success-button',
                },
                dataTestId: 'scout-create-modal-data-test-id',
              }}
              closeModalHandler={handleCloseCreateSuccessModal}
            />
          )}

          {visibleConfirm && (
            <>
              <Modal
                modal={{
                  name: 'scout-confirm-modal',
                  title: 'Уверены, что хотите выйти?',
                  description: {
                    value:
                      'У вас есть несохраненные данные. Если покинуть экран сейчас, все данные потеряются',
                    $styles: {
                      textAlign: 'center',
                      letterSpacing: '-0.15px',
                    },
                  },
                  type: EModalType.Warning,
                  id: 'scout-confirm-modal-id',
                  denyButton: {
                    title: 'Да, выйти',
                    handler: () => {
                      goToNextPage();
                    },
                    dataTestId: 'scout-confirm-modal-deny-button',
                  },
                  successButton: {
                    title: 'Нет, вернуться',
                    handler: () => {
                      blockAndHide();
                    },
                    dataTestId: 'scout-confirm-modal-success-button',
                  },
                  styledProps: {
                    $size: EModalSize.Medium,
                  },
                  styles: {
                    title: {
                      $size: 'large',
                    },
                  },

                  dataTestId: 'scout-confirm-modal-data-test-id',
                }}
                closeModalHandler={handleConfirmModalClose}
              />
            </>
          )}
        </Layout>
      </SidebarLayout>
    </>
  );
});
