import styled, { StyledComponentBase } from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';
import { Anchor } from '@zendeskgarden/react-buttons';

interface ITagsGroup extends StyledComponentBase<any, {}> {
  Row?: any;
  Title?: any;
  Tags?: any;
  Tag?: any;
  More?: any;
}

interface ITag {
  isVisible?: string;
}

export const TagsGroupStyled: ITagsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${({ theme: { space: s } }) => s.md};

  ${p => mediaQuery('down', 'sm', p.theme)} {
    margin-bottom: 14px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

TagsGroupStyled.Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  margin-bottom: 6px;
  &:after {
    content: ':';
  }
`;

TagsGroupStyled.Tags = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  flex-grow: 1;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

TagsGroupStyled.Row = styled.div`
  display: flex;
  width: 100%;
`;

TagsGroupStyled.Tag = styled.div<ITag>`
  white-space: nowrap;
  visibility: ${({ isVisible }) => isVisible};
  &:before {
    content: ', ';
  }
  &:first-child {
    &:before {
      color: red !important;
      content: '';
    }
  }
`;

TagsGroupStyled.More = styled(Anchor)`
  background: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  font-size: 12px;
  border-radius: 4px;
  color: ${({ theme: { colors: c } }) => c.generalWhite};
  padding: 2px 6px;
  min-width: 30px;
  text-align: center;
  margin-left: 4px;
  text-overflow: clip;
  text-decoration: none !important;
  cursor: default;

  &:hover {
    color: ${({ theme: { colors: c } }) => c.generalWhite};
    /* text-shadow: 1px 1px ${({ theme: { colors: c } }) => c.generalBlack}; */
    text-decoration: none;
  }
`;
