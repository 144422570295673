import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { WellStyled, WellBodyStyled } from '../styled';
import { WellHeader, OrgForm } from '../../../components';

export const OrgRequisites: FC = observer(() => {
  const { orgId } = useParams<{ orgId: string }>();
  const [requisitesActions, setRequisitesActions] = useState([]);

  return (
    <>
      <WellStyled className="requisites">
        <WellHeader
          title="Реквизиты организации"
          actions={requisitesActions}
          dataTestId="organization-requisites"
        />
        <WellBodyStyled>
          <OrgForm orgId={orgId} onChange={setRequisitesActions} actions={requisitesActions} />
        </WellBodyStyled>
      </WellStyled>
    </>
  );
});
