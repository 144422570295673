import { FC } from 'react';
import { CardItem, ECardItemSize } from '@farmlink/farmik-ui';
import { Label } from '@zendeskgarden/react-forms';
import { isEmpty, trim } from 'lodash';

import { ContractorType, ContractorLogo } from '../../../../../../api/models/contractor.model';

import { ContractorRowWrapper } from './styled';

export interface Props {
  name: string;
  type?: ContractorType;
  INN: string;
  logo?: ContractorLogo;
  organizationId: string;
  onClick: (userId: string) => void;
  checkedId: string;
  isScout: boolean;
}

const getOrgType = type => {
  if (!type || isEmpty(type.OrgType)) {
    return '';
  }

  return type.OrgType;
};

export const ContractorRow: FC<Props> = ({
  name,
  type,
  INN,
  organizationId,
  onClick,
  logo,
  checkedId,
  isScout,
}) => {
  const orgType = getOrgType(type);
  const fullname = trim(`${orgType} ${name}`);

  return (
    <ContractorRowWrapper
      key={organizationId}
      onClick={() => !isScout && onClick(organizationId)}
      data-test-id="contractor-row-wrapper"
    >
      <ContractorRowWrapper.Details data-test-id="contractor-row-details">
        <CardItem
          card={{
            avatar: {
              src: logo && logo.downloadUrl,
              title: name,
            },

            content: {
              title: fullname,
              subTitle: INN && `ИНН: ${INN}`,
            },

            styledProps: {
              size: ECardItemSize.Large,
            },

            dataTestId: `contractor-row`,
          }}
        />
      </ContractorRowWrapper.Details>

      <ContractorRowWrapper.RadioWrapper isScout={isScout} data-test-id="contractor-row-action">
        {!isScout ? (
          <ContractorRowWrapper.Radio
            checked={checkedId === organizationId}
            onChange={() => onClick(organizationId)}
            data-test-id="contractor-row-action-radio"
          >
            <Label hidden data-test-id="contractor-row-action-radio-lable">
              {name}
            </Label>
          </ContractorRowWrapper.Radio>
        ) : (
          <ContractorRowWrapper.Status data-test-id={`contractor-row-status`}>
            Добавлен
          </ContractorRowWrapper.Status>
        )}
      </ContractorRowWrapper.RadioWrapper>
    </ContractorRowWrapper>
  );
};
