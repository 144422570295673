import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';
import { Breakpoints } from '../../../../constants/sizes';
import { PaperWrapper } from '../PaperBlock/style';

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  z-index: 101;
  overflow: auto;
`;

export const ModalWrapper = styled(PaperWrapper)`
  width: calc(100% - 24px);
  position: fixed;
  z-index: 102;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow: auto;
  padding: 32px 16px;
  
  @media (min-width: 768px) {
    width: 440px;
    padding: 40px 60px;
  }
}

  
`;

export const ModalHeader = styled.div`
  padding-bottom: 16px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${Colors.grayBorder};
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
`;

export const ModalFooter = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${Colors.grayBorder};
  display: flex;
  justify-content: space-between;

  & button:nth-child(2) {
    margin-left: 16px;
  }

  & button {
    max-width: 180px;
    justify-content: center;
  }
`;
