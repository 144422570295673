import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useStore } from '../../../../utils';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { ProtectedRoute } from '../../modules/common/components/routing/ProtectedRoute';
import { AgroidRoutes } from '../../routes';
import { EAccessRuleScoutingName } from '../../types';

import { ContractorsPage, ContractorRouter, ContractorCreatePage } from './pages';

export const ScoutingRouter: FC<any> = observer(() => {
  return (
    <Switch>
      <ProtectedRoute
        path={AgroidRoutes.ContractorCreate}
        component={ContractorCreatePage}
        redirect={AgroidRoutes.Index}
        accessRule={[EAccessRuleScoutingName.workWithContractors]}
        accessWall
      />
      <ProtectedRoute
        path={AgroidRoutes.Contractor}
        component={ContractorRouter}
        redirect={AgroidRoutes.Index}
        accessRule={[EAccessRuleScoutingName.workWithContractors]}
        accessWall
        findAccessAll
      />
      <ProtectedRoute
        path={AgroidRoutes.Contractors}
        component={ContractorsPage}
        redirect={AgroidRoutes.Index}
        accessRule={[EAccessRuleScoutingName.workWithContractors]}
        accessWall
        findAccessAll={true}
      />
      <Redirect to={AgroidRoutes.Contractors} />
    </Switch>
  );
});
