import { isMobile } from 'react-device-detect';

import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';
import { createListingPage } from '../../../../../common/utils/helpers/configs/pages/createListingPage';
import noDataSvg from '../../../../../../../../assets/images/agroid/document100x100.svg';
import { ContractCard } from '../../../../../common/components/card/ContractCard';
import { ListingPageContractsContainer } from '../../../../../common/containers/ListingPage/ListingPageContractsContainer';
import { AccessRuleVisibility, EAccessRuleScoutingName } from '../../../../../../types';

const organizationsOrganizationContractsListingPage = createListingPage({
  link: {
    label: 'Договоры',
    path: EAgroidRoute.OrganizationsOrganizationContracts,
  },
  plugStyle: {
    mobileHeight: 'calc(100vh - 336px)',
    desktopHeight: 'calc(100vh - 302px)',
  },
  header: {
    title: 'Договоры',
    filter: {
      filterBy: {
        searchQuery: {
          key: 'searchName',
          required: true,
          noSearchedDataText:
            'Договор с названием "?:" не найден. Попробуйте изменить условия поиска.',
          isClearable: true,
        },
      },
    },
    actionButtons: [
      {
        type: 'button',
        caption: isMobile ? false : 'Добавить',
        componentProps: { isPrimary: true, isBasic: false },
        mode: AccessRuleVisibility.Hide,
        actionName: EAccessRuleScoutingName.manageScouting,
        dataTestIdAction: 'contracts-add-btn',
      },
    ],
  },
  dataList: {
    noDataText: 'Здесь будут отображаться ваши документы',
    item: {
      Component: ContractCard,
    },
    noDataIconUrl: noDataSvg,
    itemsWrapper: {
      removeItemHandler: {
        apiName: 'deleteContract',
        paramAsPayload: 'contractId',
      },
    },
  },
  handlerList: {
    fetchItemList: {
      apiName: 'getContractList',
      paramsAsPayload: {
        organizationId: 'orgId',
      },
      rawParams: {
        size: 20,
      },
    },
  },
  customData: {
    CustomComponent: ListingPageContractsContainer,
  },
});

export default organizationsOrganizationContractsListingPage;
