import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 48px 60px 28px;
  background: ${props => props.theme.Colors.generalLight};
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 375px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 320px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  width: 60px;
`;

export const OrganizationName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.Colors.generalDark};
  text-align: center;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 0.5px solid #e9e6f0;
`;

export const Heading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: ${props => props.theme.Colors.generalDark};
  margin: 0 0 20px;
  text-align: center;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.Colors.generalDark};
  text-align: center;
  margin: 0 0 32px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
  margin-bottom: 28px;
`;

export const BottomLineWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;
export const BottomTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BottomTextDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.Colors.secondaryDark};
  margin: 0;
`;
