import styled from 'styled-components';

const Input = styled.input`
  opacity: 0;
`;

const StyledListingPageContractsContainer = {
  Input,
};

export default StyledListingPageContractsContainer;
