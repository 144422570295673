import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px;
  background: ${props => props.theme.Colors.generalLight};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 375px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 320px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const SuccessHeading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  margin-top: 25px;
  margin-bottom: 16px;
  color: ${props => props.theme.Colors.generalDark};
`;

export const SuccessText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.Colors.generalDark};
  margin-top: 0;
  margin-bottom: 32px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 25px;
  color: ${props => props.theme.Colors.generalDark};
  margin-bottom: 32px;
`;

export const Header = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  margin: 0;
  color: ${props => props.theme.Colors.generalDark};
`;

export const LineWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;
