import styled from 'styled-components';

export type ObjectFit = 'fill' | 'cover' | 'scale-down';

export const OrgLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  aspect-ratio: 1/1;

  svg {
    width: inherit;
    height: inherit;
  }

  img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
`;

export const OrgLogoImg = styled.img<{ imgAspectRatio: ObjectFit }>`
  object-fit: ${({ imgAspectRatio }) => imgAspectRatio};
`;
