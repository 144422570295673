import styled from 'styled-components';
import { Well } from '@zendeskgarden/react-notifications';

export const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 56px 74px;

  @media (max-width: 767px) {
    height: calc(100vh - 196px);
  }

  @media (min-width: 768px) {
    height: calc(100vh - 188px);
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 116px);
  }

  &.with-filter {
    @media (max-width: 767px) {
      height: calc(100vh - 342px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 360px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 291px);
    }
  }

  &.with-alone {
    @media (max-width: 767px) {
      height: calc(100vh - 268px);
    }

    @media (min-width: 768px) {
      height: calc(100vh - 276px);
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 212px);
    }
  }
`;

export const StyledNoDataIcon = styled.img<{ $size?: 'normal' | 'big' }>`
  width: ${({ $size }) => ($size === 'big' ? '100px' : '57px')};

  @media (max-width: 767px) {
    width: 37px;
  }
`;

export const NoDataTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 42px;
  margin-bottom: 20px;
  letter-spacing: -0.15px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-top: 36px;
  }
`;

export const WellStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 18px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &.profile {
    padding-top: 24px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
      padding-bottom: 16px;
    }

    @media (min-width: 768px) {
      margin-bottom: 16px;
      padding-bottom: 20px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &.requisites {
    padding-top: 24px;

    @media (max-width: 767px) {
      margin: 0;
      padding-bottom: 24px;
    }

    @media (min-width: 768px) {
      margin: 0
      padding-bottom: 32px;
    }

    @media (min-width: 1024px) {
      margin: 0;
      padding-bottom: 32px;
    }
`;

export const WellFilterStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  &.listing-filter div[id|='uniforms'] {
    margin: 0;
  }

  @media (max-width: 767px) {
    padding: 12px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 20px 16px 0 16px;
  }

  [data-garden-id='forms.field'] {
    margin-bottom: 0;
  }
`;
