import { makeAutoObservable, runInAction } from 'mobx';
import _, { trim } from 'lodash';

import { lazyInject, localizeValidationTitle, provide } from '../../../utils';
import { Axios, TypeApiResponse } from '../../../utils/axios2';
import {
  TypeOrganization,
  OrgType,
  TChangeOrganizationPayload,
} from '../../../api/models/organization.model';
import { TypeRequest as UserTypeRequest } from '../../../api/endpoints/getOrganizationUsers';
import { ApplicationsTypeRequest } from '../../../api/endpoints/getOrganizationApplications';
import { formatOKVEDtoSent } from '../../../utils/formatOKVEDtoSent';
import { FormValueError } from '../../../api/models/contractor.model';
import { deepTrim } from '../../../utils/deepTrim';

interface IGetOrganizationFilteredUsersFilter {
  organizationID: string;
  role?: string;
  roleId?: string;
  notRoleId?: string;
  status?: string;
  fullname?: string;
  isCurrentUser?: boolean;
  isOwner?: boolean;
  isScout?: boolean;
  notCurrentUser?: boolean;
}

@provide.singleton()
export class OrganizationStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  organizationId: string;
  organization: (TypeOrganization & { phone?: string; email?: string }) | undefined = undefined;
  _isLoading = false;
  usersList = [];
  applicationsList = [];
  usersFilter: Partial<UserTypeRequest> = {
    status: 'ACTIVE',
  };

  dirtyValues = new Set();

  logoHash = '';
  logoImgId = '';
  logoUrl = '';
  logoName = '';
  logoFile: File;

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  setLogoData = (id: string, url: string, fileName: string): void => {
    this.logoImgId = id;
    this.logoUrl = url;
    this.logoName = fileName;
  };

  setOrganizationId = (organizationId: string) => {
    runInAction(() => {
      this.organizationId = organizationId;
    });
    this.usersFilter.organizationID = this.organizationId;
  };

  setLoading = (flag: boolean) => {
    this.isLoading = flag;
  };

  setFilter = (filterProps: Partial<UserTypeRequest>, replace = true) => {
    runInAction(() => {
      if (replace) {
        this.usersFilter.status = 'ACTIVE';
        this.usersFilter = filterProps;
      } else {
        Object.assign(this.usersFilter, filterProps);
      }
    });
  };

  fetchOrganization = async (organizationId: string) => {
    this.isLoading = true;
    try {
      const org = await this.axios.api.getOrganization(
        { organizationId },
        { omit: ['organizationId'] }
      );
      runInAction(() => {
        this.organization = org;

        if (org?.logo) {
          this.setLogoData(org?.logo?.id, org?.logo?.downloadUrl, org?.logo?.fileName);
        }
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  getOrganizationUsers = async () => {
    try {
      this.isLoading = true;
      const response = await this.axios.api.getOrganizationUsers(
        this.usersFilter as UserTypeRequest
      );
      runInAction(() => {
        this.usersList = response.content;
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  getOrganizationApplications = async () => {
    const appsFilter: ApplicationsTypeRequest = {
      organizationId: this.usersFilter.organizationID,
    };
    if (this.usersFilter.status) {
      appsFilter.status = this.usersFilter.status;
      if (!this.usersFilter.status || this.usersFilter.status === 'ACTIVE') {
        appsFilter.status = 'SENT';
      }
    }
    if (this.usersFilter.fullname) {
      appsFilter.fullname = this.usersFilter.fullname;
    }
    if (this.usersFilter.roleId) {
      appsFilter.roleId = this.usersFilter.roleId;
    }
    try {
      this.isLoading = true;
      const response = await this.axios.api.getOrganizationApplications(appsFilter);
      runInAction(() => {
        this.applicationsList = response.content;
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  // TODO its temporaty working decision - after will be changed
  getOrganizationFilteredUsers = async (filter: IGetOrganizationFilteredUsersFilter) => {
    let list = [];
    try {
      const response = await this.axios.api.getOrganizationUsers(filter);
      list = response.content;
    } catch (e) {
      throw e;
    }
    return list;
  };

  createOrganization = async (model: TypeOrganization) => {
    try {
      this.isLoading = true;
      await this.axios.api.createOrganization(model || ({} as any));
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  updateOrganization = async (
    payload: Partial<TChangeOrganizationPayload>,
    organizationId: string
  ): Promise<boolean> => {
    try {
      this.isLoading = true;

      await this.axios.api.updateOrganization(
        {
          ...payload,
          organizationId,
        },
        { omit: ['organizationId'] }
      );

      return true;
    } catch (e) {
      throw e;
    } finally {
      this.cleanDirtyValues();
      this.isLoading = false;
    }
  };

  removeOrg = async (orgId: string) => {
    try {
      await this.axios.api.removeOrg({
        organizationId: orgId,
      });
    } catch (e) {
      throw e;
    }
  };

  inviteByEmail = async inviteDetails => {
    try {
      await this.axios.api.sendEmailInvite(inviteDetails);
    } catch (e) {
      throw e;
    }
  };

  getOrgTypes = async () => {
    let list: OrgType[] = [];
    try {
      list = await this.axios.api.getDictOrgTypes({});
    } catch (e) {
      throw e;
    }
    return list;
  };

  onCommitLogo = () => {
    this.logoHash = this.logoImgId;
  };

  onResetLogo = () => {
    this.logoHash = '';
    this.logoImgId = '';
    this.logoUrl = '';
    this.logoName = '';
    this.logoFile = null;
  };

  uploadFile = (file: File) => {
    this.logoFile = file;
    this.axios.api
      .uploadFile({
        ownerId: this.organizationId,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.logoImgId = response.id;
    this.logoUrl = response.uploadUrl;
    this.axios.api
      .uploadFileFinish({ data: this.logoFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
    // this.fetchProfile();
  };

  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };

  markFieldAsDirty = (fieldName: string) => {
    this.dirtyValues.add(fieldName);
  };

  cleanDirtyValues = () => {
    this.dirtyValues.clear();
  };

  clearStore = (): void => {
    this.logoName = '';
  };
}
