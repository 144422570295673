import React, { FC, useLayoutEffect, useState, useRef } from 'react';

import { TagsGroupStyled } from './styled';

const TagItem: FC<any> = ({ name, parent, countInvisible, dataTestId }) => {
  const [visibility, setVisibility] = useState('hidden');
  const ref = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    let pRight;
    let right;

    if (ref.current && parent) {
      pRight = Number(parent.offsetLeft) + Number(parent.offsetWidth);
      right = Number(ref.current.offsetLeft) + Number(ref.current.offsetWidth);

      if (right < pRight) {
        countInvisible(1);
        setVisibility('visible');
      } else {
        setVisibility('hidden');
      }
    }
  }, [parent]);

  return (
    <TagsGroupStyled.Tag ref={ref} style={{ visibility }} data-test-id={dataTestId}>
      {name}
    </TagsGroupStyled.Tag>
  );
};

interface ITagsGroup {
  items: any[];
  title: string;
  field: 'expertises' | 'interests';
}

export const TagsGroup: FC<ITagsGroup> = ({ items, title, field }) => {
  const [hasMore, setHasMore] = useState(0);
  const [parent, setParent] = useState<any>();
  const groupRef = useRef();

  useLayoutEffect(() => {
    setParent(groupRef.current);
  }, []);

  const hasInvisible = delta => {
    setHasMore(prev => prev + delta);
  };

  return (
    <TagsGroupStyled data-test-id={`${field}-tags-group-wrapper`}>
      {title && (
        <TagsGroupStyled.Title data-test-id={`${field}-tags-group-title`}>
          {title}
        </TagsGroupStyled.Title>
      )}
      <TagsGroupStyled.Row>
        <TagsGroupStyled.Tags>
          <TagsGroupStyled.Tags
            ref={groupRef}
            id={title}
            data-test-id={`${field}-tags-group-container`}
          >
            {items.length > 0 ? (
              items.map((item, index) => (
                <TagItem
                  name={item}
                  parent={parent}
                  key={`${item}-${index}`}
                  countInvisible={hasInvisible}
                  dataTestId={`${field}-tags-group-tag-item`}
                />
              ))
            ) : (
              <div data-test-id={`${field}-tags-group-stub`}>Не выбраны</div>
            )}
          </TagsGroupStyled.Tags>
        </TagsGroupStyled.Tags>
        {items.length - hasMore > 0 ? (
          <TagsGroupStyled.More size="small" data-test-id={`${field}-tags-group-more`}>
            +{items.length - hasMore}
          </TagsGroupStyled.More>
        ) : null}
      </TagsGroupStyled.Row>
    </TagsGroupStyled>
  );
};
