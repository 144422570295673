import React, { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Typography } from '@farmlink/farmik-ui';

import { formatPhone } from '../../../../utils';
import { AgroidRoutes } from '../../routes';
import { OrgLogo } from '../OrgLogo';
import { ApplicationStatus } from '../../../../api/models/applications.model';

import { AvatarWrapper, AppCardStyled } from './styled';

export interface AppCardProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  invitationId: string;
  userName: string;
  userAvatar?: any;
  role?: any;
  userEmail?: any;
  phone?: any;
  text: string;
  roleInfo?: any;
  status?: ApplicationStatus;
  dataTestId?: string;
  onClick: () => void;
}

const getInitials = userName => {
  if (!userName) return '';
  return userName
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .substr(0, 2);
};

export const AppCard: FC<AppCardProps> = props => {
  const {
    invitationId,
    value,
    userName,
    userEmail,
    phone,
    userAvatar,
    role,
    status,
    dataTestId,
    onClick,
  } = props;

  return (
    <AppCardStyled.Link
      to={`${AgroidRoutes.Users}/${invitationId}`}
      onClick={onClick}
      status={status}
      data-test-id={dataTestId && `${dataTestId}-card-item`}
    >
      <AppCardStyled key={value}>
        <Row>
          <Col size={isMobile ? 2 : 2}>
            <AvatarWrapper data-test-id={dataTestId && `${dataTestId}-card-avatar-container`}>
              {userAvatar && userAvatar.downloadUrl ? (
                <OrgLogo {...userAvatar} dataTestId={dataTestId && `${dataTestId}-card-avatar`} />
              ) : (
                getInitials(userName)
              )}
            </AvatarWrapper>
          </Col>
          <Col size={isMobile ? 10 : 10}>
            <AppCardStyled.Title tag="h4" data-test-id={dataTestId && `${dataTestId}-card-title`}>
              {userName || 'Фамилия и имя не указаны'}
            </AppCardStyled.Title>
            {Boolean(role) && (
              <Typography
                dataTestId={dataTestId && `${dataTestId}-card-name`}
                variant="note"
                color="secondaryDark"
                component="p"
              >
                <span>{role.name || '\u00A0'}</span>
              </Typography>
            )}
            <Typography
              dataTestId={dataTestId && `${dataTestId}-card-phone`}
              variant="note"
              color="secondaryDark"
              component="p"
            >
              {' '}
              {formatPhone(phone)}{' '}
            </Typography>
            {userEmail ? (
              <Typography
                dataTestId={dataTestId && `${dataTestId}-card-email`}
                variant="note"
                color="secondaryDark"
                component="p"
              >
                {userEmail}
              </Typography>
            ) : (
              <Typography
                dataTestId={dataTestId && `${dataTestId}-card-not-email`}
                variant="note"
                color="secondaryDark"
                component="p"
              >
                E-mail не указан
              </Typography>
            )}
          </Col>
        </Row>
      </AppCardStyled>
    </AppCardStyled.Link>
  );
};
