import styled, { Interpolation, CSSProperties } from 'styled-components';
import { Field } from '@zendeskgarden/react-forms';
import { Modal, Body } from '@zendeskgarden/react-modals';

export interface IModalStyles {
  modal?: {} & Interpolation<CSSProperties>;
  body?: {} & Interpolation<CSSProperties>;
}

export const ModalStyled = styled(Modal)<{
  height?: string;
  heightFor768?: string;
  styles?: IModalStyles['modal'];
}>`
  backdrop-filter: blur(10px);
  left: auto;
  padding: 0;

  height: ${({ height }) => (height ? `${height}` : '588px')};
  ${({ styles }) => styles};

  @media (max-width: 767px) {
    height: 468px;
  }

  @media (min-width: 768px) {
    height: ${({ heightFor768 }) => (heightFor768 ? `${heightFor768}` : 'auto')};
  }
`;

export const ModalBodyStyled = styled(Body)<{
  scroll?: boolean;
  isNotFollowScroll?: boolean;
  styles?: IModalStyles['body'];
}>`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  margin: 0;

  ${({ scroll, isNotFollowScroll }) =>
    scroll && !isNotFollowScroll
      ? `
          padding-right: 44px;
        `
      : `
          padding-right: 60px;
      `}
`;

export const ModalContent = styled.div`
  padding: 16px 0;
  flex-grow: 1;
`;

export const ModalActions = styled.div<{ scroll?: boolean }>`
  padding: 16px 60px 40px 60px;

  @media (max-width: 380px) {
    padding: 16px 12px;
  }

  ${({ scroll, theme }) =>
    scroll
      ? `
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          border-top: 1px solid ${theme.colors.borderGray};
        `
      : ''}
`;

export const ModalSearchStyle = styled(Field)`
  margin-bottom: 0;
`;

export const ModalFooterStyled = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;
