import styled, { StyledComponentBase } from 'styled-components';
import { Link } from 'react-router-dom';
import { Anchor } from '@zendeskgarden/react-buttons';
import { LG } from '@zendeskgarden/react-typography';

import DelSvg from '../../../../assets/images/agroid/delWithBack.svg';

interface IRoleCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
  Link?: any;
  LinkWrapper?: any;
  Header?: any;
  DeleteSVG?: any;
}

export const DelAnchor = styled(Anchor)`
  border: 1px solid ${({ theme: { colors: c } }) => c.generalWhite};
  border-radius: ${({ theme: { borderRadii: b } }) => b.label};
  float: right;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-left: 12px;
  flex-basis: 24px;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    margin-right: 0;
    path {
      fill: ${({ theme: { colors: c } }) => c.accentPink};
    }
  }

  &:hover {
    color: ${({ theme: { colors: c } }) => c.borderGray} !important;

    svg path {
      fill: ${({ theme: { colors: c } }) => c.hoverPink} !important;
    }
  }
`;

export const RoleLabelWrapper = styled.div`
  margin-left: 12px;
`;

export const RoleCardStyled: IRoleCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  @media (max-width: 767px) {
    padding: 12px 16px 20px 16px;
  }

  @media (min-width: 768px) {
    padding: 16px;
  }
`;

RoleCardStyled.Header = styled.div`
  display: flex;
`;

RoleCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 767px) {
    margin-bottom: 4px;
  }

  @media (min-width: 768px) {
    margin-bottom: 8px;
  }
`;

RoleCardStyled.Text = styled.p`
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  font-size: 12px;
  line-height: 16px;
`;

RoleCardStyled.Link = styled(Link)`
  border-bottom: 1px solid;
  &:hover {
    text-decoration: none;
  }
`;

RoleCardStyled.LinkWrapper = styled.div`
  height: 16px;
  margin-top: auto;
  &:hover {
    text-decoration: none;
  }
`;

RoleCardStyled.DeleteSVG = styled.div`
  width: 24px;
  height: 24px;
  background: url(${DelSvg});
`;
