import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px;
  background: ${props => props.theme.Colors.generalLight};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;

export const LoadingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuccessHeading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  margin-top: 25px;
  margin-bottom: 16px;
  color: ${props => props.theme.Colors.generalDark};
`;
