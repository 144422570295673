import React, { Ref, useEffect, useState } from 'react';
import { HTMLFieldProps, connectField /* filterDOMProps */ } from 'uniforms';
import { Select as FramikSelect } from '@farmlink/farmik-ui';

import { DropdownWrapper } from './styled';

export type SelectFieldProps = HTMLFieldProps<
  string | string[],
  HTMLDivElement,
  {
    options: Array<any>;
    allowedValues?: string[];
    checkboxes?: boolean;
    disableItem?: (value: string) => boolean;
    inputRef?: Ref<HTMLSelectElement>;
    transform?: (value: string) => string;
    isInGroupLeft?: boolean;
    dataTestId?: string;
    isOpenAbove?: boolean;
    marginBottomForSelectBody?: string;
    placeholder?: string;
    icons?: {
      clear?: {
        options?: {
          valueAfterCleaning?: any;
        };
      };
    };
  }
>;

const SelectFieldComponent = props => {
  const {
    label,
    options = [],
    required,
    onChange,
    value,
    isInGroupLeft,
    dataTestId,
    isOpenAbove,
    marginBottomForSelectBody,
    icons,
    placeholder,
  } = props;

  const [dIsOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue(value));

  function defaultValue(val) {
    const df = options.find(option => option.value === val);
    if (!df && options[0]) {
      onChange(options[0].value);
    }
    return df || options[0] || {};
  }

  const handleSelectItem = (newValue, item) => {
    setSelectedItem(item);
    onChange(item.value);
  };

  useEffect(() => {
    setSelectedItem(defaultValue(value));
  }, [options]);

  return (
    <DropdownWrapper isOpen={dIsOpen}>
      <FramikSelect
        defaultValue={selectedItem}
        onChange={handleSelectItem}
        label={label}
        options={[...options, { label: null, value: null }]}
        isOpenAbove={isOpenAbove}
        marginBottomForSelectBody={marginBottomForSelectBody}
        isInGroupLeft={isInGroupLeft}
        dataTestId={dataTestId && `${dataTestId}-select`}
        icons={icons}
        placeholder={placeholder}
      />
    </DropdownWrapper>
  );
};

export const SelectField = connectField<SelectFieldProps>(SelectFieldComponent, {
  kind: 'leaf',
});
