import { createGlobalStyle } from 'styled-components';

import reactToggleCss from './react-toggle';

export default createGlobalStyle`
  a {
    color: #3FB65F;

    &:hover {
      color: #1d7234;
    }
  }

  :focus {
    outline:none;
  }

  .isLoading {
    filter: blur(20px);
    transition: 1.2s filter linear;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    * {
                                    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
  }

  label {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #9AA0AD;
  }

  ${reactToggleCss}
`;
