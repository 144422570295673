import { FC } from 'react';
import { Link as LinkFarmik } from '@farmlink/farmik-ui';

import { BackButtonWrapper } from '../style';

type BackButtonProps = {
  onClick: () => void;
};

export const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <BackButtonWrapper>
    <LinkFarmik
      dataTestId="back-button"
      color="default"
      label="Назад"
      onClick={onClick}
      prefixIcon="arrow_left"
    />
  </BackButtonWrapper>
);
