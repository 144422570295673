import { FC, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import get from 'lodash/get';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { isMobileOnly } from 'react-device-detect';

import { formatDate, formatPhone, useStore } from '../../../../utils';
import { ProfileStore, NEED_ADDITIONAL_INFO } from '../../../auth/stores/profile.store';
import { ReactComponent as InfoSvg } from '../../../../assets/images/agroid/info.svg';
import { ReactComponent as EditSvg } from '../../../../assets/images/agroid/edit.svg';
import { AgroidRoutes } from '../../routes';
import { TagsGroup } from '../';

import imagePlaceholder from './imagePlaceholder.svg';
import {
  WellStyled,
  WelcomeBlock,
  HeroUserAvatarWrapper,
  HeroUserAvatar,
  HeroUserWrapper,
  DetailsWrapper,
  EditWrapper,
  GreetingText,
} from './styled';
import { useGreeting } from './useGreeting';

const ErrorMessage: FC<{ email: string; message: string; dataTestId: string }> = observer(
  ({ email, message, dataTestId }) => {
    const [sendStatus, setSentStatus] = useState('');
    const { confirmEmail } = useStore(ProfileStore);

    const handleClick = event => {
      event.preventDefault();
      confirmEmail(email);
      setSentStatus(' Отправлено');
    };

    return (
      <div data-test-id={`${dataTestId}-container`}>
        <div data-test-id={`${dataTestId}-text`}>{message}</div>
        <p>&nbsp;</p>
        <p data-test-id={`${dataTestId}-link`}>
          <Anchor
            href="#"
            className="tooltip-action"
            onClick={handleClick}
            data-test-id={`${dataTestId}-action`}
          >
            Отправить еще раз
          </Anchor>
          <span data-test-id={`${dataTestId}-action-text`}>{sendStatus}</span>
        </p>
      </div>
    );
  }
);

const formatedBirthday = str => {
  if (str) {
    return formatDate(str, 'dd.MM.yyyy г.');
  } else {
    return str;
  }
};

export const HeroUser: FC = observer(() => {
  const { user, getPendingContracts, isLoading } = useStore(ProfileStore);
  const [pendings, setPendings] = useState('');
  const greeting = useGreeting();
  const message =
    'Необходимо подтвердить свой электронный адрес. На указанный адрес отправлено письмо.';

  useEffect(() => {
    (async () => {
      try {
        const contracts = await getPendingContracts();
        if (contracts && contracts.emails && contracts.emails.length > 0) {
          setPendings(contracts.emails[0]);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const renderGroup = useCallback(
    (title: string, field: 'expertises' | 'interests', nameKey: string) => {
      if (user) {
        let items = [];
        if (user.hasOwnProperty(field) && user[field].length) {
          items = user[field].map((item: { [x: string]: any }) => item[nameKey]);
        }
        return <TagsGroup title={title} items={items} field={field} />;
      }
    },
    [user]
  );

  const emailConfirmed =
    user && get(JSON.parse(JSON.stringify(user)), 'email.emailStatus.statusId') === 'CONFIRMED';

  return (
    <WellStyled>
      <WelcomeBlock data-test-id="user-dashboard-welcome-block">
        <GreetingText data-test-id="user-dashboard-greeting-text">
          {greeting}
          {user && 'firstName' in user ? (
            <>
              ,
              <br />
              {user.firstName}!
            </>
          ) : (
            '!'
          )}
        </GreetingText>
      </WelcomeBlock>
      {isLoading ? (
        <>
          <Skeleton height="12px" />
        </>
      ) : (
        <Grid style={{ position: 'relative' }}>
          <Row>
            <Col md={6}>
              <Grid>
                <Row>
                  <HeroUserAvatarWrapper data-test-id="user-dashboard-avatar-wrapper">
                    <HeroUserAvatar
                      src={user.avatar?.downloadUrl || imagePlaceholder}
                      alt={user.firstName}
                      data-test-id="user-dashboard-avatar"
                    />
                  </HeroUserAvatarWrapper>
                  <HeroUserWrapper>
                    <DetailsWrapper
                      className="details-wrapper"
                      data-test-id="user-dashboard-details-wrapper"
                    >
                      <div className="name" data-test-id="user-dashboard-details-name">
                        {user.lastName || ''}
                        <br />
                        {`${user.firstName || ''} ${user.secondName || ''}`}
                      </div>
                      <div
                        className="dateOfBirth"
                        data-test-id="user-dashboard-details-date-of-birth"
                      >
                        {formatedBirthday(user.dateOfBirth)}
                      </div>
                      <div className="contacts" data-test-id="user-dashboard-details-contacts">
                        <div className="phone" data-test-id="user-dashboard-details-phone">
                          {formatPhone(user.phone)}
                        </div>
                        {pendings ? (
                          <div
                            className="email hasError"
                            data-test-id="user-dashboard-details-email-error"
                          >
                            <span data-test-id="user-dashboard-details-email-error-text">
                              {pendings}{' '}
                            </span>
                            <Tooltip
                              content={
                                <ErrorMessage
                                  message={message}
                                  email={pendings}
                                  dataTestId="email-error-message"
                                />
                              }
                              placement={isMobileOnly ? 'bottom' : 'end'}
                              size="large"
                              type="light"
                            >
                              <InfoSvg data-test-id="user-dashboard-details-email-error-icon" />
                            </Tooltip>
                          </div>
                        ) : (
                          user?.email && (
                            <div
                              className={`email ${!emailConfirmed && 'hasError'}`}
                              data-test-id="user-dashboard-details-email-confirmed"
                            >
                              {user.email.email}
                              {!emailConfirmed ? (
                                <Tooltip
                                  content={
                                    <ErrorMessage
                                      message={message}
                                      email={user.email.email}
                                      dataTestId="email-confirmed-message"
                                    />
                                  }
                                  placement={isMobileOnly ? 'bottom' : 'end'}
                                  size="large"
                                  type="light"
                                >
                                  <InfoSvg data-test-id="user-dashboard-details-email-confirmed-icon" />
                                </Tooltip>
                              ) : null}
                            </div>
                          )
                        )}
                      </div>
                    </DetailsWrapper>
                  </HeroUserWrapper>
                </Row>
              </Grid>
            </Col>
            <Col md={6}>
              <EditWrapper data-test-id="user-dashboard-edit-wrapper">
                <Anchor
                  href={AgroidRoutes.Profile}
                  data-test-id="user-dashboard-edit-profile-action"
                >
                  <EditSvg data-test-id="user-dashboard-edit-profile-action-icon" />
                  <span data-test-id="user-dashboard-edit-profile-action-caption">
                    Редактировать
                  </span>
                </Anchor>
              </EditWrapper>
              {renderGroup('Компетенции', 'expertises', 'expertiseName')}
              {renderGroup('Интересы', 'interests', 'interestName')}
            </Col>
          </Row>
        </Grid>
      )}
    </WellStyled>
  );
});
