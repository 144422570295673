import { Button, PhoneInput, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStore } from '../../../../../../utils';
import { Heading } from '../../../../style';
import { Step } from '../Step';
import { SingUpStore } from '../../stores/SingUpStore';
import { AuthStore } from '../../../../stores/auth.store';
import { SmsCode } from '../../../../components/SmsCode';
import { BackButton } from '../../../../components/BackButton';
import { AuthorizationRoutes } from '../../../../routes';

import {
  Wrapper,
  TopLine,
  HeadingWrapper,
  PhoneWrapper,
  CodeWrapper,
  ButtonWrapper,
  FormContent,
  FooterWrapper,
  FooterText,
} from './style';

export const FirstStepSignUp: FC = observer(() => {
  const {
    setFieldValue,
    phone,
    code,
    sendCodeToPhone,
    verifyCode,
    needAdditionalInfo,
    isFirstStepDisabled,
    seconds,
    resetFirstStep,
    isCodeFieldOpened,
    isVerifyCodeLoading,
    isAvailableToVerifyCode,
    isCodeWasValid,
  } = useStore(SingUpStore);
  const { erqRedirectData, authKey, redirectUrl, redirectWithAuth } = useStore(AuthStore);
  const location = useLocation();
  const params = new URLSearchParams(location.search.substr(1));
  const history = useHistory();
  const setters = useMemo(
    () => ({
      setPhone: setFieldValue('phone'),
      setCode: setFieldValue('code', Boolean(params.get('externalSystemAuth'))),
    }),
    []
  );

  const handleSignIn = () => {
    history.push(AuthorizationRoutes.signIn);
  };

  useEffect(() => {
    if (isCodeWasValid && !needAdditionalInfo) {
      erqRedirectData();
    }
  }, [isCodeWasValid]);

  useEffect(() => {
    if (authKey && redirectUrl && !needAdditionalInfo) {
      redirectWithAuth();
    }
  }, [authKey, redirectUrl]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (isCodeFieldOpened) {
      verifyCode(Boolean(params.get('externalSystemAuth')));
    } else {
      sendCodeToPhone();
    }
  };

  const handleBackButton = () => {
    resetFirstStep();
  };

  return (
    <Wrapper>
      <BackButton onClick={() => history.push(AuthorizationRoutes.signIn)} />
      {isCodeFieldOpened && <BackButton onClick={handleBackButton} />}
      <TopLine />

      <FormContent>
        <form onSubmit={onSubmit}>
          <HeadingWrapper>
            <Heading>Регистрация</Heading>
            <Step current={1} total={2} />
          </HeadingWrapper>

          <PhoneWrapper className="signUp_phoneInput">
            <PhoneInput
              // defaultValue={phone.value}
              error={phone.error}
              onChange={setters.setPhone}
              label="Номер телефона"
              disabled={isCodeFieldOpened}
              dataTestId="signUp-phone-input"
            />
          </PhoneWrapper>
          {isCodeFieldOpened && (
            <CodeWrapper>
              <SmsCode
                error={code.error}
                label="Код подтверждения"
                placeholder="0000"
                onChange={setters.setCode}
                value={code.value}
                seconds={seconds}
                onSendCodeAgain={sendCodeToPhone}
                disabled={isVerifyCodeLoading}
              />
            </CodeWrapper>
          )}
          <ButtonWrapper className="signUp_nextButton">
            {isCodeFieldOpened ? (
              <Button
                type="submit"
                color="primary"
                disabled={!isAvailableToVerifyCode}
                dataTestId="signUp-next-button"
              >
                Продолжить
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                disabled={isFirstStepDisabled}
                dataTestId="signUp-next-button"
              >
                Продолжить
              </Button>
            )}
          </ButtonWrapper>
        </form>
      </FormContent>

      <FooterWrapper>
        <FooterText>Есть аккаунт?</FooterText>
        <LinkFarmik
          dataTestId="login-registration-button"
          label="Войти"
          color="primary"
          onClick={handleSignIn}
          postfixIcon="arrow_right"
        />
      </FooterWrapper>
    </Wrapper>
  );
});
