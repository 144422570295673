import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../utils/IoC';
import { Axios } from '../../../utils/axios2';

@provide.singleton()
export class SessionStore {
  @lazyInject(Axios)
  axios: Axios;

  constructor() {
    makeAutoObservable(this);
    document.addEventListener('logout', () => {
      this.logout();
    });
  }

  isLoading = false;
  _isLoggedIn = false;

  set isLoggedIn(flag: boolean) {
    this._isLoggedIn = flag;
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  passVerifyToken = () => {
    this.isLoggedIn = true;
    this.isLoading = false;
  };

  // todo deprecated
  checkIsLoggedIn = async () => {
    this.isLoading = true;

    const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

    if (!accessToken) {
      return this.logout();
    }

    try {
      await this.axios.api.verifyAccessToken({});
      this.passVerifyToken();
    } catch (e) {
      this.logout();
    } finally {
      this.isLoading = false;
    }
    return this.isLoggedIn;
  };

  checkIsUserLoginned = async () => {
    this.isLoading = true;

    const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

    if (!accessToken) {
      this.isLoading = false;
      return false;
    }

    try {
      await this.axios.api.verifyAccessToken({});
      this.passVerifyToken();
    } catch (e) {
      this.logout();
    } finally {
      this.isLoading = false;
    }
    return this.isLoggedIn;
  };

  setLoggedIn = () => {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoggedIn = true;
      this.isLoading = false;
    }, 2000);
  };

  logout = () => {
    this.isLoading = false;
    this.isLoggedIn = false;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
  };
}
