import { TypeApiRoute } from '../../../models/type.api.request';

type TCreateContractRequest = {
  organizationId: string;
  fileId: string;
  name?: string;
};

type TCreateContractResponse = {};

const createContract: TypeApiRoute & {
  request: TCreateContractRequest;
  response: TCreateContractResponse;
} = {
  url: `/api/as-contracts/contracts/create`,
  method: 'POST',
  headers: {},
  request: {} as TCreateContractRequest,
  response: {} as TCreateContractResponse,
};

export type { TCreateContractRequest, TCreateContractResponse };

export { createContract };
