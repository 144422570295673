import format from 'date-fns/format';
import { isEmpty } from 'lodash';

export const formatDate = (date, ft) => {
  if (!date) {
    return '';
  }

  const isISOStringDate = !isEmpty(
    date.match(/(\d{4}-\d{2}-\d{2})[A-Z]+(\d{2}:\d{2}:\d{2}).([0-9+-:]+)/g)
  );

  if (isISOStringDate) {
    return format(new Date(date), ft);
  }

  return `${date} г.`;
};
