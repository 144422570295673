import { observer } from 'mobx-react';
import { FC, useState } from 'react';

import { InviteCard } from '../InviteCard';
import { InvitationType } from '../../../../api/models/InvitationType';
import { useStore } from '../../../../utils/IoC';
import { MyApplicationStore } from '../../stores/my.applicaions.store';
import { InviteCommitForm } from '../InviteCommitForm';

import { InvitesListWrapper, Spinner, SpinnerWrapper } from './style';

export const InvitesList: FC = observer(() => {
  const {
    invitations,
    invitationsTotalPages,
    invitationsCurrentPage,
    fetchApplications,
    isLoading,
  } = useStore(MyApplicationStore);
  const [selectedInviteId, setSelectedInviteId] = useState('');

  const loadAdditionalInvitations = async () => {
    await fetchApplications(true);
  };

  let lastInterval;

  const updateScrollRef = ref => {
    clearInterval(lastInterval);
    lastInterval = setInterval(() => {
      if (
        ref &&
        !isLoading &&
        invitationsCurrentPage < invitationsTotalPages &&
        window.innerHeight - ref.getBoundingClientRect().top > 50
      ) {
        loadAdditionalInvitations();
      }
    }, 500);
  };

  return (
    <>
      <InvitesListWrapper className={'invites-list-wrapper'}>
        {invitations.map((invitationItem: InvitationType) => (
          <InviteCard
            key={invitationItem.invitationId}
            {...invitationItem}
            onClick={() => setSelectedInviteId(invitationItem.invitationId)}
          />
        ))}

        {selectedInviteId && (
          <InviteCommitForm inviteId={selectedInviteId} onClose={() => setSelectedInviteId('')} />
        )}
      </InvitesListWrapper>
      {invitationsCurrentPage < invitationsTotalPages ? (
        <SpinnerWrapper>
          <Spinner ref={ref => updateScrollRef(ref)} />
        </SpinnerWrapper>
      ) : null}
    </>
  );
});
