import { FC } from 'react';

import { TypeOrganization } from '../../../../api/models/organization.model';
import { OrgCard } from '../../components';

import { OrgsListWrapper, StyledAddOrgCard } from './styled';

export interface OrgsListProps {
  list: Array<TypeOrganization>;
  onAddOrgClick: () => void;
}

export const OrgsList: FC<OrgsListProps> = ({ list, onAddOrgClick }) => (
  <OrgsListWrapper>
    <StyledAddOrgCard onClick={onAddOrgClick} />
    {list.map((orgItem: TypeOrganization) => (
      <OrgCard key={orgItem.organizationId} {...(orgItem as any)} />
    ))}
  </OrgsListWrapper>
);
