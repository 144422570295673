import { FC, useEffect } from 'react';
import { Switch, Route, useParams, useRouteMatch, generatePath } from 'react-router-dom';

import { useStore } from '../../../../../../utils';
import { useRolesMenu } from '../../useRolesMenu';
import { CheckAccessStore, RolesStore } from '../../../../stores';
import { Layout, SidebarLayout, SidebarMenu } from '../../../../components';
import { useContractorMenu } from '../../useContractorMenu';
import scoutingContractorEmployeesListingPage from '../../../../modules/scouting/configs/pages/listingPage/employees/scouting.contractor.employees.listingPage.config';
import scoutingContractorInvitesListingPage from '../../../../modules/scouting/configs/pages/listingPage/invites/scouting.contractor.invites.listingPage.config';
import scoutingContractorContractsListingPage from '../../../../modules/scouting/configs/pages/listingPage/contracts/scouting.contractor.contracts.listingPage.config';
import EAgroidRoute from '../../../../routes/AgroidRoute.enum';
import { ProfilePageContainer } from '../../../../modules/common/containers/configs/pages/ProfilePageContainer';
import { AgroidRoutes } from '../../../../routes';
import { AccessRuleUserActionName, EAccessRuleScoutingName } from '../../../../types';
import { ProtectedRoute } from '../../../../modules/common/components/routing/ProtectedRoute';
import { ScoutsStore } from '../../../../stores/scouts.store';

import { ContractorRequisites, ContractorRoles, ContractorRole } from './pages';

export interface IParams {
  contractorId?: string;
}

export const ContractorRouter: FC<any> = () => {
  const { contractorId } = useParams<IParams>();
  const { roles, setOrganization, fetchRoles } = useStore(RolesStore);
  const { getAccessRulesByModules, globalAccessRules } = useStore(CheckAccessStore);

  const { getScoutWithOwnerEmail, scout } = useStore(ScoutsStore);

  const match = useRouteMatch(AgroidRoutes.ContractorRole);
  const contractorMenuItems = useContractorMenu(contractorId);
  const roleItems = useRolesMenu(contractorId, roles);
  const menuItems = match && match.isExact ? roleItems : contractorMenuItems;

  useEffect(() => {
    if (!scout?.name) {
      getScoutWithOwnerEmail({ contractorId });
    }
  }, []);

  useEffect(() => {
    setOrganization(contractorId);
    fetchRoles();
    getAccessRulesByModules(contractorId);
  }, [contractorId]);

  const {
    link: employeesLink,
    Component: EmployeesComponent,
  } = scoutingContractorEmployeesListingPage;

  const { link: invitesLink, Component: InvitesComponent } = scoutingContractorInvitesListingPage;

  const {
    link: contractsLink,
    Component: ContractsComponent,
  } = scoutingContractorContractsListingPage;

  return (
    <SidebarLayout>
      <SidebarMenu items={menuItems} actionList={globalAccessRules} />
      <Layout>
        <Switch>
          <Route exact path={employeesLink.path} component={EmployeesComponent} />
          <ProtectedRoute
            exact
            path={invitesLink.path}
            component={InvitesComponent}
            accessRule={[AccessRuleUserActionName.manageInvites]}
            redirect={generatePath(EAgroidRoute.ScoutingContractor, { contractorId })}
            accessWall
          />
          <ProtectedRoute
            exact
            path={contractsLink.path}
            component={ContractsComponent}
            accessRule={[
              EAccessRuleScoutingName.viewContract,
              EAccessRuleScoutingName.manageScouting,
              EAccessRuleScoutingName.workWithContractors,
            ]}
            redirect={generatePath(EAgroidRoute.ScoutingContractor, { contractorId })}
            accessWall
          />
          {/* <Route exact path={contractsLink.path} component={ContractsComponent} /> */}
          <Route
            exact
            path={EAgroidRoute.ScoutingContractorRequisites}
            component={ContractorRequisites}
          />
          <Route exact path={EAgroidRoute.ScoutingContractorRoles} component={ContractorRoles} />
          <ProtectedRoute
            path={EAgroidRoute.ScoutingContractorRole}
            component={ContractorRole}
            accessRule={[EAccessRuleScoutingName.manageScouting]}
            redirect={generatePath(EAgroidRoute.ScoutingContractor, { contractorId })}
            accessWall
            findAccessAll
          />
          <Route path={EAgroidRoute.ScoutingContractor} component={ProfilePageContainer} />
        </Switch>
      </Layout>
    </SidebarLayout>
  );
};
