import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { useStore } from '../../utils';

import { Layout, LayoutInner } from './style';
import { AuthorizationRoutes } from './routes';
import { Agreement } from './pages/agreement/Agreement';
import { SignInPage } from './pages/sign-in/SignInPage';
import { SignUpPage } from './pages/sign-up/SignUpPage';
import { ResetPage } from './pages/reset/ResetPage';
import { InvitePage } from './pages/invite/InvitePage';
import { AuthStore } from './stores/auth.store';
import { AuthPreload } from './containers/AuthPreload';

export const AuthorizationRouter: FC<{ history?: any }> = observer(() => {
  const {
    location: { pathname, search },
  } = useHistory();

  const { systemId, setSystemId } = useStore(AuthStore);
  const [isLogout, setIsLogout] = useState(false);
  const [userDefined, setUserDefined] = useState(false);

  useEffect(() => {
    const searchParam = new URLSearchParams(search.slice(1));
    const newSystemId = searchParam.get('systemId');

    if (newSystemId) {
      setSystemId(newSystemId);
    }

    if (pathname === AuthorizationRoutes.logout) {
      setIsLogout(true);
    }
  }, [pathname, search]);

  return (
    <Layout systemId={systemId}>
      {userDefined ? (
        <LayoutInner>
          <Switch>
            <Route
              path={AuthorizationRoutes.signUp}
              component={p => <SignUpPage {...p} systemId={systemId} />}
            />
            <Route path={AuthorizationRoutes.invite} component={InvitePage} />
            <Route path={AuthorizationRoutes.reset} component={ResetPage} />
            <Route path={AuthorizationRoutes.Agreement} component={Agreement} />
            <Route
              path={AuthorizationRoutes.signIn}
              component={p => <SignInPage {...p} systemId={systemId} />}
            />
            <Route
              path={AuthorizationRoutes.logout}
              component={() => <Redirect to={AuthorizationRoutes.signIn} />}
            />
            <Redirect to={AuthorizationRoutes.signIn} />
          </Switch>
        </LayoutInner>
      ) : (
        <AuthPreload hideAuthPreload={() => setUserDefined(true)} />
      )}
    </Layout>
  );
});
