import { makeAutoObservable, runInAction } from 'mobx';
import { trim } from 'lodash';

import { TypeApiResponse, lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeContractor, EmptyType } from '../../../api/models/contractor.model';

@provide.singleton()
export class ContractorsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;
  contractorName = '';
  emptyType: EmptyType = EmptyType.LIST;
  contractors: TypeContractor[] = [];

  formValuesErrors = {} as any;

  page = 0;
  totalPages = 0;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setEmptyType = (type: EmptyType) => {
    runInAction(() => {
      this.emptyType = type;
    });
  };

  setContractorName = (value: string) => {
    if (this.emptyType !== EmptyType.SEARCH) {
      this.setEmptyType(EmptyType.SEARCH);
    }

    runInAction(() => {
      if (this.page !== 0) {
        this.setPage(0);
      }

      this.contractorName = value;
    });
  };

  addContractors = (contractors: TypeContractor[]) => {
    runInAction(() => {
      this.contractors = contractors;
    });
  };

  clearContractors = () => {
    this.addContractors([]);
    this.setContractorName('');
    this.setEmptyType(EmptyType.LIST);
    this.setLoaded(false);
    this.setLoading(false);
  };

  fetchContractors = (): Promise<TypeApiResponse<'getContractors'>> => {
    this.setLoading(true);

    const payload = {
      nameOrInn: trim(this.contractorName),
      size: 1000,
      page: this.page,
    };

    return this.axios.api
      .getContractors(payload)
      .then(response => {
        this.addContractors(response.content);

        return response;
      })
      .catch(e => {
        console.error('fetchContractors e.message', e.message);
        this.addContractors([]);

        return Promise.reject(e);
      })
      .finally(() => {
        this.setLoading(false);
        this.setLoaded(true);
      });
  };
}
