import { TypeUser } from '../models/user.model';
import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

export type TypeRequest = {
  organizationID: string;
  role?: string;
  roleId?: string;
  notRoleId?: string;
  status?: string;
  fullname?: string;
  isCurrentUser?: boolean;
  isOwner?: boolean;
  isScout?: boolean;
  notCurrentUser?: boolean;
};

type TypeResponse = {
  content: Array<TypeUser>;
} & TypeResponsePageable;

export const getOrganizationUsers: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/userList/`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
