import { InvitationType } from '../models/InvitationType';
import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = InvitationType;

export const acceptUserInvite: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/organizations/user/inviteAccept/${request.id}`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
