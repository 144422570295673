import { useField } from 'uniforms';

import { RequireSign } from '../uniforms/styled';

import { MergedInputGroupStyled } from './styled';

interface IMergedInputGroup {
  children: React.ReactNode;
  label?: string;
  validateField: string;
  required?: boolean;
}

export const MergedInputGroup = ({
  children,
  label = '',
  required = false,
  validateField = '',
}: IMergedInputGroup) => {
  const [fieldProps] = useField(validateField, {});

  return (
    <>
      <label className="StyledLabel-sc-2utmsz-0 eXnfDn">
        {label}
        {required && (
          <>
            {' '}
            <RequireSign />
          </>
        )}
      </label>
      <MergedInputGroupStyled>{children}</MergedInputGroupStyled>
    </>
  );
};
