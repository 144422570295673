import { makeAutoObservable, runInAction } from 'mobx';

import { Axios, lazyInject, provide } from '../../../utils';
import { forbiddenEvent } from '../../auth/events/forbidden';
import { CheckAccessStore } from '../stores';

@provide.singleton()
export class CheckAccessService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  constructor() {
    makeAutoObservable(this);

    document.addEventListener(forbiddenEvent.type, () => {
      // this.displayAccessWall();
    });
  }

  _isDisplayAccessWall = false;

  displayAccessWall = () => {
    this._isDisplayAccessWall = true;
  };

  hideAccessWall = () => {
    this._isDisplayAccessWall = false;
  };

  get isAccessWallDisplay() {
    return this._isDisplayAccessWall;
  }

  getAllActiveGlobalActionsForCurrentUser = (): Promise<boolean> => {
    return this.axios.api
      .getAllActiveGlobalActionsForCurrentUser({})
      .then(response => {
        runInAction(() => {
          this.checkAccessStore.globalAccessRules = response;
        });
        return true;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  };

  getUserIsOwner = req => {
    return this.axios.api
      .getUserInfoByOrganization(req)
      .then(resp => {
        this.checkAccessStore.setIsOrganizationOwner(resp.roleInfo.isOwner);
        return resp.roleInfo.isOwner;
      })
      .catch(() => {
        this.checkAccessStore.setIsOrganizationOwner(false);
        return false;
      });
  };
}
