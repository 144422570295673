import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  name: string;
  order: number;
  actionCodes?: Array<string>;
};

type TypeResponse = {};

export const createRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/access/role/?organizationId=${request.organizationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
