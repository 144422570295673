import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemText } from '@zendeskgarden/react-chrome';

import { ISidebarMenuItem } from '../../components';
import { AgroidRoutes } from '../../routes';
import { AccessRuleUserActionName, EAccessRuleScoutingName } from '../../types';

export const useOrgMenu = (orgId: string) => {
  const orgPath = `${AgroidRoutes.Organizations}/${orgId}`;

  const menuItems: ISidebarMenuItem[] = [
    {
      caption: (
        <NavItemText>
          <NavLink to={AgroidRoutes.Organizations} isActive={() => false}></NavLink>
        </NavItemText>
      ),
      isBack: true,
      slug: 'orgs-back',
    },
    {
      caption: 'Профиль компании',
      link: `${orgPath}`,
      slug: 'profile',
    },
    {
      caption: 'Сотрудники',
      link: `${orgPath}/employes`,
      slug: 'employes',
    },
    {
      caption: 'Приглашения',
      link: `${orgPath}/invites`,
      slug: 'invites',
      accessActions: [AccessRuleUserActionName.manageInvites],
    },
    {
      caption: 'Роли',
      link: `${orgPath}/roles`,
      slug: 'roles',
      accessActions: [
        AccessRuleUserActionName.editRole,
        AccessRuleUserActionName.editEmployee,
        EAccessRuleScoutingName.manageScouting,
      ],
    },
    {
      caption: 'Реквизиты',
      link: `${orgPath}/requisites`,
      slug: 'requisites',
    },
    {
      caption: 'Договоры',
      link: `${orgPath}/contracts`,
      slug: 'contracts',
      accessActions: [EAccessRuleScoutingName.viewContract, EAccessRuleScoutingName.manageScouting],
    },
  ];
  return menuItems;
};
