import { Button, Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import {
  ApplicationStatus,
  ApplicationInviteType,
} from '../../../../api/models/applications.model';
import { useStore } from '../../../../utils/IoC';
import { MyApplicationStore } from '../../stores/my.applicaions.store';
import { DialogModal } from '../DialogModal/DialogModal';

import {
  ActionsBlock,
  CancelInviteButton,
  CommentWrapper,
  Content,
  InnWrapper,
  InviteCommitFormModal,
  InviteCommitFormModalWraper,
  OrgNameWrapper,
  TextAreaLabel,
  Title,
} from './style';

export interface InviteCommitFormProps {
  inviteId: string;
  onClose: () => void;
}

export const InviteCommitForm: FC<InviteCommitFormProps> = observer(({ inviteId, onClose }) => {
  const {
    getInviteById,
    invite,
    acceptUserInvite,
    declineUserInvite,
    fetchApplications,
    clearInvite,
    resetInvitationsPage,
  } = useStore(MyApplicationStore);

  const [type, setType] = useState<ApplicationInviteType>(ApplicationInviteType.INVITE);

  useEffect(() => {
    getInviteById(inviteId);
    return () => {
      clearInvite();
    };
  }, []);

  const handleClick = async () => {
    try {
      resetInvitationsPage();
      await fetchApplications();
      onClose();
    } catch (e) {
      console.error('InviteCommitForm error', e);
    }
  };

  const getDataTestId = (name: string): { 'data-test-id': string } => {
    return { 'data-test-id': `invite-commit-form-${name}` };
  };

  if (!invite) {
    return null;
  }

  if (type !== ApplicationInviteType.INVITE) {
    const title =
      type === ApplicationInviteType.ACCEPT ? 'Приглашение принято' : 'Приглашение отклонено';

    const status = type === ApplicationInviteType.ACCEPT ? 'success' : 'warning';

    return (
      <DialogModal
        title={title}
        status={status}
        onClose={handleClick}
        accept={{
          handler: handleClick,
          name: 'Продолжить',
          color: 'primary',
        }}
      />
    );
  }

  return (
    <InviteCommitFormModalWraper className="my-modal-wrapper" {...getDataTestId('wrap')}>
      <InviteCommitFormModal onClose={onClose}>
        <Title {...getDataTestId('title')}>Приглашение в организацию</Title>
        <Content {...getDataTestId('content')}>
          <OrgNameWrapper {...getDataTestId('org-name-wrap')}>
            <Input
              value={invite?.organization?.name}
              label="Название организации"
              disabled={true}
              dataTestId="input-org-name"
            />
          </OrgNameWrapper>
          <InnWrapper {...getDataTestId('inn-wrap')}>
            <Input
              value={invite?.organization?.inn}
              label="ИНН"
              placeholder="ИНН не указан"
              disabled={true}
              dataTestId="input-org-inn"
            />
          </InnWrapper>
          <CommentWrapper {...getDataTestId('comment-wrap')}>
            <TextAreaLabel {...getDataTestId('comment-label')}>Сообщение</TextAreaLabel>
            <textarea
              value={invite?.comment}
              placeholder="Сообщение отсутствует"
              {...getDataTestId('comment-area')}
            />
          </CommentWrapper>
        </Content>
        <ActionsBlock>
          <Button
            type="button"
            color="primary"
            dataTestId="invite-commit-form-accept-invitation-btn"
            onClick={() => {
              acceptUserInvite(inviteId).then(() => setType(ApplicationInviteType.ACCEPT));
            }}
            disabled={invite?.status !== ApplicationStatus.SENT}
          >
            Принять приглашение
          </Button>

          <CancelInviteButton
            data-test-id={'invite-commit-form-cancel-invitation-btn'}
            onClick={() => {
              declineUserInvite(inviteId).then(() => setType(ApplicationInviteType.DECLINE));
            }}
            disabled={invite?.status !== ApplicationStatus.SENT}
          >
            Отклонить
          </CancelInviteButton>
        </ActionsBlock>
      </InviteCommitFormModal>
    </InviteCommitFormModalWraper>
  );
});
