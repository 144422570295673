import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeApplication } from '../../../api/models/applications.model';

@provide.singleton()
export class InvitationStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  invitationId = '';
  invitation: Partial<TypeApplication> = {};
  isLoading = true;

  fetchInvitation = (invitationId: string) => {
    this.isLoading = true;
    return this.axios.api
      .getInvitation({ invitationId }, { omit: ['invitationId'] })
      .then(response => {
        runInAction(() => {
          this.invitation = (response as unknown) as TypeApplication;
        });
      })
      .catch(e => {
        runInAction(() => {
          this.invitation = {};
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  assignInvitationRole = async (roleId: string, invitationId: string) => {
    this.isLoading = true;
    await this.axios.api.assignRoleToInvitation({ roleId, invitationId }).finally(() => {
      this.isLoading = false;
    });
  };
}
