import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { useStore } from '../../../../utils';
import { CheckAccessService } from '../../services/checkAccess.service';
import { AccessWall } from '../AccessWall';

import Styled from './SidebarLayout.styles';

export const SidebarLayout = observer(({ children }) => {
  const { isAccessWallDisplay, hideAccessWall } = useStore(CheckAccessService);

  useEffect(() => {
    return () => {
      hideAccessWall();
    };
  }, []);

  if (isAccessWallDisplay) {
    return (
      <Styled.SidebarLayout data-test-id="access-wall">
        <AccessWall hideAccessWall={hideAccessWall} />
      </Styled.SidebarLayout>
    );
  }

  return <Styled.SidebarLayout>{children}</Styled.SidebarLayout>;
});
