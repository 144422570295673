import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';
import { ReactComponent as StartIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

import { MediaInputField } from '../../../../components/uniforms';

const schema = new SimpleSchema({
  search: {
    type: String,
    optional: true,
    label: 'Поиск',
    uniforms: {
      component: MediaInputField,
      start: StartIcon,
      label: false,
      placeholder: 'Поиск',
      regexonchange: /(^$)|(^[a-zA-Zа-яА-ЯёЁ0-9-., ]+$)/i,
    },
  },
});

export const bridge = new SimpleSchema2Bridge(schema);
