import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Avatar } from '@zendeskgarden/react-avatars';
import { Dropdown, Menu, Item } from '@zendeskgarden/react-dropdowns';
import { Header, HeaderItem } from '@zendeskgarden/react-chrome';
import { IconButton } from '@zendeskgarden/react-buttons';
import { mediaQuery } from '@zendeskgarden/react-theming';

import { ReactComponent as LogoSvg } from '../../../../assets/images/agroid/AGROID.svg';

export const DropdownStyled = styled(Dropdown)``;

export const HeaderContainerStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;

  background-color: #f2f1f4;

  @media (max-width: 767px) {
    padding-bottom: 8px;
  }

  @media (min-width: 768px) {
    padding-bottom: 16px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 20px;
  }

  @media (min-width: 1366px) {
    padding-bottom: 20px;
  }
`;

export const HeaderStyled = styled(Header)`
  border-radius: ${({ theme: { borderRadii: b } }) => `0 0 ${b.card} ${b.card}`};
  box-shadow: none;
  border: none;
  height: 76px;

  @media (max-width: 767px) {
    height: 56px;
  }
`;

export const LogoHeaderItem = styled(HeaderItem)`
  width: auto;
  border-right: none;
  margin-left: 8px;
  margin-right: 79px;

  @media (min-width: 768px) {
    margin-left: 36px;
  }
`;

export const StyledHeaderItem = styled(HeaderItem)<{ isActive?: boolean }>`
  margin-right: 48px;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: ${p => p.theme.Colors.generalDark} !important;
  font-weight: 600;

  * {
    color: unset !important;
  }

  svg {
    width: 14px;
    path {
      fill: ${p => p.theme.Colors.generalDark} !important;
    }

    &.agroid-scouting-contractors {
      path {
        stroke: ${p => p.theme.Colors.generalDark} !important;
        fill: none !important;
      }
    }
  }

  &:hover {
    color: ${p => p.theme.Colors.accentPositive} !important;
    svg path {
      fill: ${p => p.theme.Colors.accentPositive} !important;
    }

    svg.agroid-scouting-contractors path {
      fill: none !important;
      stroke: ${p => p.theme.Colors.accentPositive} !important;
    }
  }
  ${({ isActive, theme: { colors: c } }) =>
    isActive &&
    `
    border-bottom: 2px solid ${c.accentGreen};
    color: ${c.accentGreen} !important;
    svg {
      width: 14px;
      path {
        fill: ${c.accentGreen} !important;
      }
    }

    svg.agroid-scouting-contractors {
      width: 14px;
      path {
        fill: none !important;
        stroke: ${c.accentGreen} !important;
      }
    }

  `}
`;

export const LogoSvgStyled = styled(LogoSvg)`
  width: 82px;
  height: 24px;

  margin-top: 10px !important;
`;

export const MenuItemLink = styled(NavLink)`
  text-decoration: none !important;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  margin-right: 48px;
  svg {
    margin-top: -4px;
    width: 14px;
    path {
    }
  }

  &.active {
    border-bottom: 2px solid;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  background: none;
  margin-right: 16px;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: 40px;
    height: 40px;
  }

  ${p => mediaQuery('up', 'sm', p.theme)} {
    width: 48px;
    height: 48px;
  }

  &:hover,
  &:focus {
    background: none;
  }
`;

export const AvatarStyled = styled(Avatar)`
  margin-right: ${({ theme: { space: s } }) => s.md};
  margin-left: ${({ theme: { space: s } }) => s.md};

  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
  min-height: 48px !important;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }

  img {
    width: 100% !important;
    max-width: auto !important;
    height: auto !important;
    max-height: 100% !important;
    object-fit: cover;
    padding: 1px;
    vertical-align: middle !important;
  }
`;

export const ItemStyled = styled(Item)<{ accent?: string }>`
  ${({ accent, theme: { colors: c } }) =>
    accent && `color: ${c[accent]} !important; a { color: inherit !important; }`}
`;

export const MenuStyled = styled(Menu)`
  padding: 0 ${({ theme: { space: s } }) => s.sm} !important;
  border-radius: ${({ theme: { borderRadii: b } }) => b.card} !important;
  border-color: ${({ theme: { colors: c } }) => c.borderGray} !important;

  [data-garden-id='dropdowns.item'] {
    padding: 0 !important;
    min-width: 202px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
    a {
      display: block;
      margin-left: -${({ theme: { space: s } }) => s.sm};
      margin-right: -${({ theme: { space: s } }) => s.sm};
      padding: ${({ theme: { space: s } }) => s.sm} ${({ theme: { space: s } }) => s.sm} !important;

      &:hover {
        background: ${({ theme: { colors: c } }) => c.secondaryGray};
      }
    }
  }

  li {
    border-bottom: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

    &:last-child {
      border-bottom: none;
    }

    a {
      padding: ${p => p.theme.space.xs};

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      text-decoration: none !important;

      white-space: nowrap;

      svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        width: 16px;
        height: 16px;
      }
    }
  }
`;
