import { TPageListData } from '../../../../../../../configs/types/pages/page.config';

import { TCreatePageListData } from './createPageListData.helper.types';

const createPageListData: TCreatePageListData = pageList => {
  const pageListData: TPageListData = {
    pageLinkList: [],
    pageList: [],
  };

  return pageList.reduce<TPageListData>((data, page) => {
    return {
      ...data,
      pageLinkList: [...data.pageLinkList, page.link],
      pageList: [...data.pageList, page],
    };
  }, pageListData);
};

export default createPageListData;
