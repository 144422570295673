// import { NewUserModal } from '../../../../../../components';
import { NewUserModal } from '../../../../../components';
import { IModalListConfig } from '../../../../../configs/data/modalsList/modalsListData.config';
import { IModalConfig } from '../../../../common/containers/configs/modals/modalConfig';
import { withCustomModalComponent } from '../../../../common/utils/helpers/configs/pages/withCustomModalComponent';

const SCOUTING_CONTRACTOR_EMPLOYEES_MODAL_UNIQUE_KEY = 'SCOUTING_CONTRACTOR_EMPLOYEES_MODAL';

const enum EScountingContractorEmployeesModalName {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  NewUser = 'NEW_USER_MODAL',
}

enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

const newUserModalConfig: IModalConfig = {
  name: EScountingContractorEmployeesModalName.NewUser,
  title: 'Тестовое модальное окно c данными',
  Component: NewUserModal,
  type: EModalType.Custom,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Сохранить',
    color: 'secondary',
  },
};

const scoutingContractorEmployeesModalGroup: IModalListConfig = {
  [SCOUTING_CONTRACTOR_EMPLOYEES_MODAL_UNIQUE_KEY]: [newUserModalConfig],
};

export { EScountingContractorEmployeesModalName, SCOUTING_CONTRACTOR_EMPLOYEES_MODAL_UNIQUE_KEY };

export default scoutingContractorEmployeesModalGroup;
