import { TCreateContractRequest } from '../../../../../../../../api';
import { lazyInject, provide } from '../../../../../../../../utils';
import ListingPageContractsContainerService from '../services/ListingPageContractsContainer.service';

@provide.singleton()
class ListingPageContractsContainerController {
  @lazyInject(ListingPageContractsContainerService)
  protected listingPageContractsContainerService: ListingPageContractsContainerService;

  createContract = async (payload: TCreateContractRequest): Promise<boolean> => {
    const result = await this.listingPageContractsContainerService.createContract(payload);

    return result;
  };
}

export default ListingPageContractsContainerController;
