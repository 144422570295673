import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';
import { BorderRadius, FontSizes } from '../../../../constants/sizes';

const Container = styled.div`
  background-color: #fff;
  height: 100%;
  display: flex;
  border-radius: ${BorderRadius.default};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 767px) {
    height: calc(100vh - 196px);
  }

  @media (min-width: 768px) {
    height: calc(100vh - 188px);
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 116px);
  }
`;

const IconSvg = styled.img`
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto 35px auto;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const Title = styled.h3`
  ${FontSizes.largeBold};
  color: ${Colors.black};
`;

const Subtitle = styled.p`
  ${FontSizes.medium}
  color: ${Colors.secondaryDarkGray};
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  width: 240px;
  margin-top: 40px;

  & button {
    justify-content: center;
    height: 40px;
    border-radius: ${BorderRadius.small};
  }

  & a:hover {
    text-decoration: none;
  }
`;

const Styled = {
  Container,
  IconSvg,
  ButtonWrapper,
  Title,
  Subtitle,
};

export default Styled;
