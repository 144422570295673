import styled from 'styled-components';
import { Modal, Body } from '@zendeskgarden/react-modals';

import { ModalComponent } from '../Modal/Modal';
import { Colors } from '../../../../constants/colors';

export const InviteCommitFormModal = styled(ModalComponent)`
  & .modal {
    width: 440px;
    padding: 40px 60px;
    border-radius: 16px;

    @media (max-width: 767px) {
      padding: 24px 16px;
      width: calc(100% - 24px);
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #151f32;
  width: 100%;
  text-align: left;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #e9e6f0;
  letter-spacing: -0.15px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 24px;
    letter-spacing: -0.5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  pointer-events: none;
`;

export const OrgNameWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  input {
    margin-top: 4px;
  }
`;

export const InnWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  input {
    margin-top: 4px;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  & > textarea {
    background: #ffffff;
    color: #9aa0ad;
    border: 1px solid;
    border-radius: 14px;
    font-size: 12px;
    width: 100%;
    max-height: 80px;
    height: 80px;
    padding: 12px 16px;
    box-sizing: border-box;
    outline: none;
    border-color: #e9e6f0;
    resize: none;
    margin-top: 4px;
  }
  margin-top: 24px;
`;

export const TextAreaLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & > button {
    justify-content: center;
    height: 56px;
    margin-bottom: 20px;
  }
  margin-top: 24px;
`;

export const CancelInviteButton = styled.div<{ disabled?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9aa0ad;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const InviteCommitFormModalWraper = styled.div`
  & .modal {
    width: 440px;
    padding: 40px 60px;
    border-radius: 16px;

    @media (max-width: 767px) {
      padding: 24px 16px;
      width: calc(100% - 24px);
    }
  }
`;

export const ModalStyled = styled(Modal)`
  width: '440px';
  left: auto;
`;

export const ModalBodyStyled = styled(Body)``;

export const IconScg = styled.img`
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto 35px auto;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const ModalTitleStyled = styled.div`
  color: ${Colors.black};
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 36px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;
