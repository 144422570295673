// @ts-nocheck

import { FC, HTMLAttributes, ReactChild, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemText } from '@zendeskgarden/react-chrome';
import { observer } from 'mobx-react';

import { AccessRule } from '../../../../api/models/user.model';
import { ControlAccessRulesWrapper } from '../AccessRulesWrapper/ControlAccessRulesWrapper';
import { AccessRuleVisibility, TActionNameUnion } from '../../types';
import { useStore } from '../../../../utils';
import { CheckAccessService } from '../../services/checkAccess.service';
import { useAccessWallMenu } from '../../hooks';

import { SidebarStyled, SidebarWell, SidebarNav, SidebarNavItem } from './styled';

export interface ISidebarMenuItem {
  caption: string | ReactChild;
  onClick?: (event?: any) => void;
  link?: string;
  hasBrandmark?: boolean;
  isBack?: boolean;
  slug: string;
  accessAction?: TActionNameUnion;
  accessActions?: TActionNameUnion[];
  findAllAccesses?: boolean;
  forceHighlight?: boolean;
}
export interface ISidebarMenuProps extends HTMLAttributes<HTMLDivElement> {
  items: ISidebarMenuItem[];
  actionList?: AccessRule[];
}

export const SidebarMenuItem: FC<ISidebarMenuItem> = ({
  caption,
  onClick,
  link,
  hasBrandmark,
  isBack,
  slug,
  forceHighlight,
}) => {
  return (
    <SidebarNavItem
      hasBrandmark={hasBrandmark}
      isBack={isBack}
      data-test-id={`sidebar-nav-item-${slug}`}
      forceHighlight={forceHighlight}
    >
      {link ? (
        <NavItemText data-test-id={`sidebar-nav-item-${slug}-text`}>
          <NavLink
            exact
            to={link}
            onClick={onClick}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            data-test-id={`sidebar-nav-item-${slug}-link`}
          >
            {caption}
          </NavLink>
        </NavItemText>
      ) : (
        caption
      )}
    </SidebarNavItem>
  );
};

const renderSidebarMenuItem = (item: ISidebarMenuItem, actionList) => {
  if (item.accessAction || item.accessActions) {
    return (
      <ControlAccessRulesWrapper
        mode={AccessRuleVisibility.Hide}
        actionName={item.accessAction || item.accessActions}
        isParentNode={false}
        key={`${item.link}-${item.caption}`}
        findAll={item.findAllAccesses}
      >
        <SidebarMenuItem {...item} />
      </ControlAccessRulesWrapper>
    );
  }

  return <SidebarMenuItem key={`${item.link}-${item.caption}`} {...item} />;
  // return item.caption === 'Роли' ? (
  //   <ControlAccessRulesWrapper
  //     mode={AccessRuleVisibility.Hide}
  //     actionName={actionName}
  //     isParentNode={false}
  //     key={`${item.link}-${item.caption}`}
  //   >
  //     <SidebarMenuItem {...item} />
  //   </ControlAccessRulesWrapper>
  // ) : (
  //   <SidebarMenuItem key={`${item.link}-${item.caption}`} {...item} />
  // );
};

export const SidebarMenu: FC<ISidebarMenuProps> = observer(({ items, actionList }) => {
  const { isAccessWallDisplay } = useStore(CheckAccessService);
  const menuItems = useAccessWallMenu();

  const hasBack = items.findIndex(item => item.isBack) > -1;
  return (
    <SidebarStyled data-test-id="sidebar">
      <SidebarWell data-test-id="sidebar-wrapper">
        <SidebarNav hasBack={hasBack} data-test-id="sidebar-nav">
          {isAccessWallDisplay
            ? menuItems.map(item => renderSidebarMenuItem(item, actionList))
            : items.map(item => renderSidebarMenuItem(item, actionList))}
        </SidebarNav>
      </SidebarWell>
    </SidebarStyled>
  );
});
