import { TypeApiRoute } from '../models/type.api.request';
import { TypeRole } from '../models/role.model';

type TypeRequest = {
  organizationId?: string;
};

type TypeResponse = Array<TypeRole>;

export const getRolesList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  // url: (request: TypeRequest) => `/api/access/role/list/${request.organizationId}`,
  url: `/api/access/role/list`, // TODO use line upper
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
