import { Button, Checkbox } from '@farmlink/farmik-ui';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ModalComponent, ModalActionsFooter } from '../Modal/Modal';

import Success from './assets/success.svg';
import Cancel from './assets/cancel.svg';
import { DialogModalDescription, DialogModalTitle, IconScg } from './style';

type DialogModalType = {
  title: string;
  status: 'success' | 'warning';
  onClose?: () => void;
  description?: string;
  cancel?: {
    handler: any;
    name: string;
    color?: 'primary' | 'secondary' | 'default';
  };
  checkbox?: {
    handler: any;
    name: string;
    checked: boolean;
  };
  accept: {
    handler: any;
    name: string;
    color?: 'primary' | 'secondary' | 'default';
  };
};

export const DialogModal: FC<DialogModalType> = ({
  title,
  status,
  onClose,
  accept,
  cancel,
  checkbox,
  description,
}) => {
  const renderIcon = (v: DialogModalType['status']) => {
    const variants: Record<DialogModalType['status'], any> = {
      success: Success,
      warning: Cancel,
    };
    return variants[v];
  };

  return (
    <ModalComponent isVerticalCentered onClose={onClose}>
      <IconScg src={renderIcon(status)} alt="" data-test-id="modal-dialog-icon" />
      <DialogModalTitle data-test-id="modal-dialog-title">{title}</DialogModalTitle>
      {description && (
        <DialogModalDescription data-test-id="modal-dialog-description">
          {description}
        </DialogModalDescription>
      )}
      <ModalActionsFooter
        isVerticalAligment={!Boolean(cancel)}
        data-test-id="modal-dialog-actions-footer"
      >
        {cancel && (
          <Button
            alignment="center"
            color={cancel.color ? cancel.color : 'secondary'}
            type="button"
            onClick={cancel.handler}
            dataTestId="modal-dialog-cancel"
          >
            {cancel.name}
          </Button>
        )}
        <Button
          alignment="center"
          color={accept.color ? accept.color : 'primary'}
          type="button"
          onClick={accept.handler}
          dataTestId="modal-dialog-accept"
        >
          {accept.name}
        </Button>
      </ModalActionsFooter>
      {checkbox && (
        <StyledCheckbox
          onChange={checkbox.handler}
          value={checkbox.checked}
          label={checkbox.name}
          dataTestId="dialog-modal-checkbox"
        />
      )}
    </ModalComponent>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;
`;
