import { FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Modal, Body } from '@zendeskgarden/react-modals';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';

import { NoData, Spacer } from '../';
import { ReactComponent as AlertSvg } from '../../../../assets/images/agroid/alert.svg';
import { useStore } from '../../../../utils';
import { OrganizationStore } from '../../stores';
import { AgroidRoutes } from '../../routes';

export interface IDelOrgModalProps {
  orgId: string;
  onClose: () => void;
}

export const DelOrgModal: FC<IDelOrgModalProps> = observer(({ onClose, orgId }) => {
  const { removeOrg } = useStore(OrganizationStore);
  const history = useHistory();
  const handleConfirm = async () => {
    try {
      await removeOrg(orgId);
      onClose();
      history.push(AgroidRoutes.Organizations);
    } catch (e) {
      console.error('cant del', e);
    }
  };
  return (
    <Modal onClose={onClose}>
      <Body>
        <NoData
          height="10rem"
          message="Вы уверены, что хотите удалить организацию?"
          icon={AlertSvg}
        />
        <Spacer size="lg" />
        <Grid columns={2}>
          <Row justifyContent="center">
            <Col textAlign="center">
              <Button
                isStretched
                isBasic
                onClick={onClose}
                size="large"
                style={{ paddingLeft: '24px', paddingRight: '24px' }}
              >
                Отменить
              </Button>
            </Col>

            <Col textAlign="center">
              <Button
                isStretched
                isPrimary
                isDanger
                size="large"
                onClick={handleConfirm}
                style={{ paddingLeft: '24px', paddingRight: '24px' }}
              >
                Да, удалить
              </Button>
            </Col>
          </Row>
        </Grid>
      </Body>
    </Modal>
  );
});
