import React, { FC, HTMLAttributes } from 'react';

import { WellHeader } from '../../components';
import { EditInterests } from '../../components/EditInterest/EditInterests';

import { MainLayout, WellStyled, WellBodyStyled } from './styled';

export interface InterestsPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const InterestsPage: FC<InterestsPageProps> = () => {
  return (
    <MainLayout>
      <WellStyled data-test-id="edit-interests-page-wrapper">
        <WellHeader title="Интересы" dataTestId="edit-interests-page" />
        <WellBodyStyled data-test-id="edit-interests-page-body">
          <EditInterests />
        </WellBodyStyled>
      </WellStyled>
    </MainLayout>
  );
};
