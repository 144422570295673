import { FC } from 'react';

import { Wrapper, Step as StepStyled } from './style';

type StepProps = {
  current: number;
  total: number;
};

export const Step: FC<StepProps> = ({ current, total }) => (
  <Wrapper>
    <StepStyled>
      {current}
      <span>/{total}</span>
    </StepStyled>
  </Wrapper>
);
