export const getPurePhone = (phone: string, coutryCode: string) => {
  console.log('getPurePhone');
  console.log({
    coutryCode,
    phone,
  });
  let pureString = phone.includes(coutryCode) ? phone.substr(coutryCode.length) : phone;
  pureString = pureString.replace(/\s/g, '');
  pureString = pureString.replace(/[(_)]/g, '');
  return pureString;
};
