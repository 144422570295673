import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';

export const InviteNotAuthStatusSentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 32px 16px;
  @media (min-width: 1024px) {
    padding: 60px;
  }
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 30px 0 0;
  margin-bottom: 32px;
`;

export const OrgLogo = styled.img`
  max-height: 60px;
  max-width: 60px;
`;

export const OrgName = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
`;

export const Title1 = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }
`;

export const Title2 = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  alignt-items: center;
  width: 100%;
`;

export const GoToSignupButtonWrapper = styled.div`
  width: 100%;
  padding: 36px 0 24px;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const SeparatorLine = styled.div`
  width: 100%;
  heigth: 1px;
  border-bottom: 1px solid ${Colors.grayBorder};
`;

export const GoToSignInBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 24px;
`;

export const GoToSignInText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${Colors.darkGray};
`;

export const GotoSignInAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${Colors.green};
  cursor: pointer;
`;

export const GoToSignIn = styled.div`
  margin-right: 5px;
`;

export const RightArrow = styled.div``;

export const PlaceHolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  background-color: ${({ theme: { colors: c } }) => c.secondaryGray};
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  width: 60px;
`;
