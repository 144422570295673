import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeOrganization } from '../../../api/models/organization.model';

// @depricated
export const UserRoleMap = {
  OWNER: 'Владелец',
  EMPLOYEE: 'Сотрудник',
  MANAGER: 'Менеджер',
};

@provide.singleton()
export class OrganizationsStore {
  @lazyInject(Axios)
  axios: Axios;

  _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  idToOrganization: Map<string, TypeOrganization> = new Map();
  organizationList: Array<TypeOrganization> = [];

  fetchOrganizations = async () => {
    this.isLoading = true;
    try {
      const { content } = await this.axios.api.getOrganizationsMine({ roles: [] });
      runInAction(() => {
        this.organizationList = content;
      });
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.isLoading = false;
    }
  };

  selectedOrganizationId = 'my';

  selectOrganization = (v: string) => {
    this.selectedOrganizationId = v;
  };

  get currentOrganization() {
    return this.idToOrganization.get(this.selectedOrganizationId);
  }
}
