import { makeAutoObservable } from 'mobx';

import { TUploadFileDescriptionResponse } from '../../../../../../../../api';
import { ApiNames, Axios, lazyInject, provide } from '../../../../../../../../utils';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';

@provide.singleton()
class ListingPageService {
  @lazyInject(Axios)
  private _axios: Axios;

  private _itemListRequestStatus: ERequestStatus | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  get itemListRequestStatus() {
    return this.itemListRequestStatus;
  }

  get isLoading(): boolean {
    const hasItemListPendingStatus = this._itemListRequestStatus === ERequestStatus.Pending;

    return hasItemListPendingStatus;
  }

  // Сеттеры

  setItemListRequestStatus = (status: ERequestStatus): void => {
    this._itemListRequestStatus = status;
  };

  clearStore = (): void => {
    this._itemListRequestStatus = null;
  };

  fetchItemList = async (config: { apiMethodName: string; payload?: any }): Promise<any> => {
    this.setItemListRequestStatus(ERequestStatus.Pending);

    try {
      const fetchedData = await this._axios.api?.[config.apiMethodName]?.(config?.payload || {});

      this.setItemListRequestStatus(ERequestStatus.Fulfilled);

      return fetchedData;
    } catch (e) {
      this.setItemListRequestStatus(ERequestStatus.Rejected);
      console.error(e);
    }
  };

  uploadFileDescription = async (file: File): Promise<TUploadFileDescriptionResponse> => {
    try {
      const response = await this._axios.api.uploadFile({
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      });

      return response;
    } catch (e) {
      console.error(e);
    }
  };

  uploadFile = async (data: File, url: string): Promise<boolean> => {
    try {
      await this._axios.api.uploadFileFinish({ data, url });

      return true;
    } catch (e) {
      console.error(e);
    }
  };

  deleteItem = async (paramName: string, itemId: string, apiName: ApiNames) => {
    try {
      await this._axios.api?.[apiName]?.(
        {
          [paramName]: itemId,
        } as any,
        // @ts-ignore
        { omit: [paramName] }
      );
    } catch (e) {
      console.error(e);
    }
  };
}

export default ListingPageService;
