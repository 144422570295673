import { TypeApiRoute } from '../models/type.api.request';
import { TypeContractor } from '../models/contractor.model';
import { TypeResponsePageable } from '../models/type.response.pageable';

type TypeRequest = {
  nameOrInn: string;
  isScout?: boolean;
  size: number;
  page: number;
};

type TypeResponse = { content: TypeContractor[] } & TypeResponsePageable;

type TypeError = {};

export const getContractors: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: '/api/da-profile/organizations/list',
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
