import { TypeApiRoute } from '../models/type.api.request';
import { OrgType } from '../models/organization.model';

type TypeRequest = {};

type TypeResponse = Array<OrgType>;

export const getDictOrgTypes: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/orgType/all`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
