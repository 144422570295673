export type TypeAddress = {
  city?: string;
  flat?: string;
  index?: string;
  house?: string;
  block?: string;
  region?: string;
  street?: string;
  cityType?: string;
  flatType?: string;
  houseType?: string;
  blockType?: string;
  kladrCode?: string;
  streetType?: string;
  addressfull: string;
  cityDistrict?: string;
  countryISOCode?: string;
  cityDistrictType?: string;
};

export type ContractorRole = {
  id: string;
  isDefault: boolean;
  isOwner: boolean;
  name: string;
  organizationId: string;
};

export type ContractorType = {
  orgTypeId: number;
  OrgType: string;
};

export type Logo = {
  downloadUrl: string;
  fileContentType: string;
  fileName: string;
  fileSize: number;
  id: string;
};

export type ContractorManager = {
  id: string;
  fullName: string;
};

export type ContractorLogo = Logo;

export type TypeContractor = {
  name: string;
  manager: ContractorManager;
  addressJur: TypeAddress;
  addressFact: TypeAddress;
  organizationId: string;
  phone: string;
  email: string;
  logo: Logo;
  type: ContractorType;
  representative: ContractorType;
  role: ContractorRole;
  INN: string;
  KPP: string;
  OGRN: string;
  OKPO: string;
  OKVED: string;
  scoutingActive: boolean;
  contact: string;
  isExperiments: boolean;
  specializationId: number;
  orgTypeId: number;
  representativeFullName: string;
  representativeMail: string;
  representativePhone: string;
  representativePosition: string;
  newOwner: string | null;
  ownerEmail: string | null;
  isScout: boolean;
  isExperiment: boolean;
};

export type PhoneNumberStatus = {
  statusId: string;
  statusName: string;
};

export type Phone = {
  phoneNumberCode: string;
  phoneNumber: string;
  phoneNumberStatus: PhoneNumberStatus;
};

export type EmailStatus = {
  statusId: string;
  statusName: string;
};

export type Email = {
  email: string;
  emailStatus: EmailStatus;
};

export type Role = {
  id: string;
  organizationId: string;
  name: string;
  isOwner: boolean;
  isDefault: boolean;
  isScout: boolean;
  creationDate: string;
};

export type Gender = {
  genderEnum: string;
  genderShort: string;
  genderName: string;
};

export type Interest = {
  interestId: string;
  interestName: string;
};

export type Expertise = {
  id: string;
  expertiseId: string;
  expertiseName: string;
  confirmations: number;
  confirmedByMe: true;
};

export type UserScoutAvatar = Logo;

export type TypeUserScout = {
  id: string;
  publicId: string;
  firstName: string;
  secondName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  bio: string;
  phone: Phone;
  email: Email;
  role: Role;
  status: string;
  gender: Gender;
  dateOfBirth: string;
  regDate: string;
  interests: Interest[];
  expertises: Expertise[];
  avatar: UserScoutAvatar;
  agreement: true;
  agreementDate: string;
  includedInStats: true;
  scout: true;
};

export type FormValueError = {
  source: string;
  title: string;
};

export type LogoValue = {
  downloadUrl: string;
  fileName: string;
  id: string;
};

export interface InitialValues {
  orgTypeId: number;
  logo: LogoValue;
  name: string;
  INN: string;
  addressJur: string;
  addressFact: string;
  OGRN: string;
  OKVED: string;
  OKPO: string;
  KPP: string;
  email: string;
  phone: string;
  contact: string;
  manager: string;
  newOwner: string;
  representativePosition: string;
  isScout: boolean;
  isExperiment: boolean;
}

export enum EmptyType {
  SEARCH = 'search',
  LIST = 'list',
}

export enum FetchType {
  ADD = 'add',
  UPDATE = 'update',
}
