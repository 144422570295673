import styled from 'styled-components';

const SidebarLayout = styled.div`
  display: flex;
  min-height: 100%;
  max-height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  @media (min-width: 1366px) {
    flex-direction: row;
  }
`;

const Styled = {
  SidebarLayout,
};

export default Styled;
