import styled, { StyledComponentBase } from 'styled-components';
import { Header } from '@zendeskgarden/react-modals';

interface IModalHeaderStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Actions?: any;
  Container?: any;
  Content?: any;
}

export const ModalHeaderContainer: IModalHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ModalHeaderStyled: IModalHeaderStyled = styled(Header)``;

ModalHeaderStyled.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

ModalHeaderStyled.Content = styled.div`
  margin-top: 16px;
`;

ModalHeaderStyled.Actions = styled.div`
  a {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 36px;
    text-decoration: none;
  }
`;

ModalHeaderStyled.Title = styled.div`
  flex-grow: 1;
  font-size: 24px;
  line-height: 28px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
