import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

const schema: SimpleSchema = new SimpleSchema(
  {
    roleName: {
      type: String,
      label: 'Название роли',
      required: true,
      max: 50,
      regEx: /^[a-zа-я0-9()-\s]+$/i,
      uniforms: {
        placeholder: 'Укажите название',
        regexonchange: /(^$)|(^[a-zа-я0-9()-\s]+$)/i,
      },
      trim: true,
    },
  },
  {
    clean: {
      trimStrings: true,
      removeEmptyStrings: true,
    },
  }
);

export const baseSchema = schema;
export const bridge = new SimpleSchema2Bridge(schema);
