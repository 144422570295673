import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';
import { isMobile, isTablet } from 'react-device-detect';

import { TypeUserStatusLabels } from '../../../../../../../../api/models/user.model';
import { UserCard } from '../../../../../../components';
import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';
import { AccessRuleUserActionName, AccessRuleVisibility } from '../../../../../../types';
import { EmployeesModalContainer } from '../../../../../common/containers/ListingPage/EmployeesModalContainer';
import { createListingPage } from '../../../../../common/utils/helpers/configs/pages/createListingPage';

const filters =
  isMobile && !isTablet
    ? {
        searchQuery: {
          key: 'fullname',
          required: true,
          noSearchedDataText: 'Пользователь не найден. Попробуйте изменить условия поиска',
          placeholder: 'Поиск по ФИО',
        },
      }
    : {
        searchQuery: {
          key: 'fullname',
          required: true,
          noSearchedDataText: 'Пользователь не найден. Попробуйте изменить условия поиска',
          placeholder: 'Поиск по ФИО',
          isClearable: true,
        },
        status: {
          key: 'status',
          optionList: TypeUserStatusLabels,
        },
        role: {
          key: 'roleId',
        },
      };

const scoutingContractorEmployeesListingPage = createListingPage({
  link: {
    label: 'Сотрудники',
    path: EAgroidRoute.ScoutingContractorEmployees,
  },
  header: {
    title: 'Сотрудники контрагента',
    filter: {
      filterBy: {
        ...filters,
      },
    },
    actionButtons: [
      {
        type: 'button',
        caption: isMobile && !isTablet ? false : 'Добавить',
        icon: isMobile && !isTablet && PlusIcon,
        componentProps: { isPrimary: true, isBasic: false },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.manageInvites,
        dataTestIdAction: 'employees-add-btn',
      },
    ],
  },
  dataList: {
    noDataText: 'Вы еще никого не пригласили',
    item: {
      Component: UserCard,
      onClick: user => {
        sessionStorage.setItem('selectedUserId', user.id);
      },
      isNeededCustomComponent: true,
    },
  },
  handlerList: {
    fetchItemList: {
      apiName: 'getOrganizationUsers',
      paramsAsPayload: {
        organizationID: 'contractorId',
      },
      rawParams: {
        size: 20,
      },
    },
  },
  customData: {
    CustomComponent: EmployeesModalContainer,
  },
});

export default scoutingContractorEmployeesListingPage;
