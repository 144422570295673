import { FC, useState, useEffect } from 'react';
import { Modal, Body } from '@zendeskgarden/react-modals';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Field, Label } from '@zendeskgarden/react-forms';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Dropdown, Item, Trigger } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { Tooltip } from '@farmlink/farmik-ui';

import { OrganizationStatusLabels } from '../../../../api/models/applications.model';
import { Spacer, ModalHeader, NoData, IModalHeaderAction } from '../';
import { formatDate, formatPhone, useStore } from '../../../../utils';
import { InvitationStore } from '../../stores/invitaion.store';
import { RolesStore } from '../../stores/roles.store';

import {
  StyledRoleButton,
  StyledStatus,
  StyledUserName,
  TooltipWrapper,
  RoleNameWrapper,
  MenuWrapper,
} from './styled';

type TSelectedItem = {
  value: any;
  label: string;
};
export interface IAppDetailsModalProps {
  invitationId: string;
  title: string;
  actions: IModalHeaderAction[];
  onClose: (result?: any) => void;
  onSuccessedRoleChanging?: () => void;
}

const NOITEM = { label: 'no item', value: '' };
const MAX_ROLE_LENGTH = 20;
const MAX_DROPDOWN_ROLE_LENGTH = 25;

export const AppDetailsModal: FC<IAppDetailsModalProps> = observer(
  ({ onSuccessedRoleChanging, invitationId, onClose, actions, title }) => {
    const { isLoading, invitation, fetchInvitation, assignInvitationRole } = useStore(
      InvitationStore
    );
    const { roles } = useStore(RolesStore);
    const [rotated, setRotated] = useState<boolean | undefined>();
    const [selectedItem, setSelectedItem] = useState<TSelectedItem>(NOITEM);

    useEffect(() => {
      fetchInvitation(invitationId);
    }, [invitationId]);

    useEffect(() => {
      let userRole = NOITEM;
      if (invitation && invitation.role) {
        userRole = {
          value: invitation.role.id,
          label: invitation.role.name,
        };
      }
      setSelectedItem(userRole);
    }, [invitation]);

    const handleSelectRole = async item => {
      const role = roles.find(r => r.id === item);
      if (role) {
        setSelectedItem({
          value: role.id,
          label: role.name,
        });
        await assignInvitationRole(role.id, invitationId);
        if (onSuccessedRoleChanging) {
          onSuccessedRoleChanging();
        }
      } else {
        setSelectedItem(NOITEM);
      }
    };

    return (
      <Modal onClose={onClose}>
        <ModalHeader
          title={title}
          actions={invitation && invitation.status === 'SENT' ? actions : []}
          dataTestId={'invite-modal'}
        />
        <Body>
          {isLoading ? (
            <>
              <Skeleton height="24px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </>
          ) : invitation ? (
            <>
              <StyledUserName data-id={invitation.userId} isBold data-test-id="invitation-userName">
                {invitation.userName}
              </StyledUserName>
              <Spacer />
              <Grid>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label data-test-id="invitation-user-label-status">Статус</Label>
                    </Field>
                  </Col>
                  <Col>
                    <StyledStatus
                      status={invitation.status}
                      data-test-id="invitation-user-value-status"
                    >
                      {Boolean(invitation.status) && OrganizationStatusLabels[invitation.status]}
                    </StyledStatus>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label data-test-id="invitation-user-label-role">Роль</Label>
                    </Field>
                  </Col>
                  <Col size={5} data-test-id="dropdown-wrapper">
                    {selectedItem.label === 'Владелец' || invitation.status !== 'SENT' ? (
                      <TooltipWrapper>
                        <Tooltip
                          id="app-details-modal-role"
                          getContent={() =>
                            selectedItem.label.length >= MAX_ROLE_LENGTH ? selectedItem.label : ''
                          }
                        >
                          <span data-test-id="invitation-role">{selectedItem.label}</span>
                        </Tooltip>
                      </TooltipWrapper>
                    ) : (
                      <Dropdown
                        selectedItem={selectedItem.value}
                        onSelect={handleSelectRole}
                        onStateChange={options => 'isOpen' in options && setRotated(options.isOpen)}
                      >
                        <TooltipWrapper>
                          <Tooltip
                            id="app-details-modal-role"
                            getContent={() =>
                              selectedItem.label.length >= MAX_ROLE_LENGTH ? selectedItem.label : ''
                            }
                          >
                            <Trigger>
                              <StyledRoleButton
                                data-test-id="invitation-role-dropdown"
                                data-is-open={rotated}
                              >
                                <span data-test-id="invitation-role">{selectedItem.label}</span>
                                <Button.EndIcon
                                  isRotated={rotated}
                                  data-test-id={'invitation-role-btn'}
                                >
                                  <ChevronIcon />
                                </Button.EndIcon>
                              </StyledRoleButton>
                            </Trigger>
                          </Tooltip>
                        </TooltipWrapper>

                        <MenuWrapper
                          isCompact
                          maxHeight={'300px'}
                          data-test-id="invitation-roles-menu"
                        >
                          {roles
                            .filter(r => r.name !== 'Владелец' && !r.isScoutForUser)
                            .map(({ id, name }) =>
                              name.length > MAX_DROPDOWN_ROLE_LENGTH ? (
                                <Item value={id} key={id} data-test-id="invitation-roles-menu-item">
                                  <RoleNameWrapper>{name}</RoleNameWrapper>
                                </Item>
                              ) : (
                                <Item value={id} key={id} data-test-id="invitation-roles-menu-item">
                                  {name}
                                </Item>
                              )
                            )}
                        </MenuWrapper>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                {Boolean(invitation.phone) && (
                  <Row>
                    <Col size={5}>
                      <Field>
                        <Label data-test-id="invitation-userPhone">Телефон</Label>
                      </Field>
                    </Col>
                    <Col data-test-id="invitation-phone">{formatPhone(invitation.phone)}</Col>
                  </Row>
                )}
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label data-test-id="invitation-userEmail">E-mail</Label>
                    </Field>
                  </Col>
                  <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Anchor
                      href={`mailto:${invitation.userEmail}`}
                      style={{ color: 'inherit' }}
                      data-test-id="invitation-email"
                    >
                      {invitation.userEmail}
                    </Anchor>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label data-test-id="invitation-userDate">Дата отправки</Label>
                    </Field>
                  </Col>
                  <Col data-test-id="invitation-date">
                    <>{formatDate(invitation.createdDate, 'dd.MM.yyyy')}</>
                  </Col>
                </Row>
              </Grid>
              <Spacer />
              <Grid>
                <Row>
                  <Col size={1}></Col>
                  <Col>
                    <Button
                      isPrimary
                      size="large"
                      isStretched
                      onClick={onClose}
                      data-test-id="invitation-close"
                    >
                      Закрыть
                    </Button>
                  </Col>
                  <Col size={1}></Col>
                </Row>
              </Grid>
            </>
          ) : (
            <NoData message="Данные приглашения не найдены" />
          )}
        </Body>
      </Modal>
    );
  }
);
