import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  invitationId: string;
};

type TypeResponse = {};

export const resendOwnerInvitation: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/scouting/contractors/resendOwnerInvitation/${request.invitationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
