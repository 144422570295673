import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';

export const ChipWrapper = styled.div<{ type?: 'error' | 'success' | 'default' | 'transparent' }>`
  display: inline-block;
  background: ${Colors.lightGray};
  color: ${Colors.darkGray};
  padding: 4px 12px;
  margin: 4px;
  border: 1px solid ${Colors.lightnessGray};
  font-size: 12px;
  font-weight: 200;
  border-radius: 8px;

  ${({ type }) => {
    if (type === 'success') {
      return `
        color: ${Colors.green};
        border-color: #e0f3e5;
        background: #e0f3e5;
      `;
    }

    if (type === 'error') {
      return `
        color: ${Colors.red};
        border-color: #fdefee;
        background: #fdefee;
      `;
    }

    if (type === 'transparent') {
      return `
        color: ${Colors.white};
        border: 1px solid rgba(233, 230, 240, 0.19);
        background: rgba(255, 255, 255, 0.14);
        backdrop-filter: blur(4px);
      `;
    }
  }}
`;
