import { UserCard, UserToRoleModal } from '../../../../components';
import { IModalListConfig } from '../../../../configs/data/modalsList/modalsListData.config';
import { IModalConfig } from '../../../common/containers/configs/modals/modalConfig';
import { withCustomModalComponent } from '../../../common/utils/helpers/configs/pages/withCustomModalComponent';

export const scoutingModalUniqueKey = 'scoutingModals';

enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

export const enum EScountingModalName {
  CustomDataModal = 'CUSTOM_DATA_MODAL',
  AnotherModal = 'ANOTHER_MODAL',
  SuccessModal = 'SUCCESS_MODAL',
  ErrorModal = 'ERROR_MODAL',
}

const customDataModal: IModalConfig = {
  name: EScountingModalName.CustomDataModal,
  title: 'Тестовое модальное окно c данными',
  Component: withCustomModalComponent({ prop1: 'data' })(UserToRoleModal),
  // componentProps
  type: EModalType.Custom,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Сохранить',
    color: 'secondary',
  },
};

const anotherModal: IModalConfig = {
  name: EScountingModalName.AnotherModal,
  title: 'Другое модальное окно',
  type: EModalType.Custom,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Продолжить',
    color: 'secondary',
  },
};

const successModal: IModalConfig = {
  name: EScountingModalName.SuccessModal,
  title: 'Модальное окно с подтверждением',
  type: EModalType.Success,
  denyButton: {
    title: 'Всё отлищно, закрывай!',
    color: 'primary',
  },
};

export const scoutingModalGroupArr: IModalListConfig = {
  [scoutingModalUniqueKey]: [customDataModal, anotherModal, successModal],
};
