import { TPage } from '../../../../../../../configs/types/pages/page.config';
import { createUniqueKeyByRouteName } from '../createUniqueKeyByRouteName';

import { TCreatePage } from './createPage.helper.types';

const createPage: TCreatePage = ({ link, Component }) => {
  const uniqueKey = createUniqueKeyByRouteName(link.path);

  const page: TPage = {
    uniqueKey,
    link: { ...link, uniqueKey },
    Component,
  };

  return page;
};

export default createPage;
