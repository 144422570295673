import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';
import { Breakpoints } from '../../../../constants/sizes';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  background: ${Colors.white};

  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 100px;
  }
`;

export const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  margin-bottom: 37px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${Colors.black};
    margin: 0;
  }
`;

export const InputWrapper = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 40px;

  @media (max-width: ${Breakpoints.small}) {
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;

export const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  @media (max-width: 767px) {
    width: calc(100% - 46px);
    margin-right: 0;
  }

  @media (min-width: 768px) {
    width: 520px;
  }

  @media (min-width: 1024px) {
    width: 372px;
  }

  @media (min-width: 1366px) {
    width: 474px;
  }
`;

export const CustomButton = styled.button`
  background: #3fb65f;
  border-radius: 12px;
  width: 100%;
  outline: none;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${Colors.white};
`;

export const SecondDiv = styled.div`
  width: 153px;
  height: 42px;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
    height: 42px;
    border-radius: 12px;
  }

  @media (max-width: ${Breakpoints.small}) {
    width: 42px;
    height: 42px;
  }
`;

export const ExpertiseWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const Competition = styled.div`
  padding: 6px 12px;

  border: 0.5px solid ${Colors.grayBorder};
  color: ${Colors.darkGray};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }
  background: ${Colors.gray};
  @media (max-width: ${Breakpoints.small}) {
    margin-bottom: 12px;
    margin-right: 12px;
  }
`;

export const DeleteIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 9px;
  margin-left: 1px;
`;

export const OkIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 9px;
  margin-right: 4px;
`;

export const FooterButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
    height: 40px;
    border-radius: 12px;
  }

  @media (max-width: 767px) {
    &:first-child {
      margin-right: 8px;
    }
  }

  @media (min-width: 768px) {
    width: 160px;
  }
`;

export const Footer = styled.div`
  padding-top: 20px;
  display: flex;
  border-top: 0.5px solid ${Colors.grayBorder};

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
