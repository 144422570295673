import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeUser } from '../../../api/models/user.model';

@provide.singleton()
export class EmployeeStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  employeeId = '';
  employee: Partial<TypeUser> = {};
  isLoading = false;

  setEmployeeId = employeeId => {
    this.employeeId = employeeId;
  };

  fetchEmployee = () => {
    this.isLoading = true;
    return this.axios.api
      .getEmployee({
        id: this.employeeId,
      })
      .then(response => {
        runInAction(() => {
          this.employee = (response as unknown) as TypeUser;
        });
      })
      .catch(e => {
        console.log('fetchEmployee catch e.message:', e.message);
        runInAction(() => {
          this.employee = {};
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  deleteEmployee = (userId, orgId) => {
    this.isLoading = true;
    return this.axios.api
      .deleteOrganizationUser({ userId, organizationId: orgId })
      .then(response => {
        console.log('deleteOrganizationUser:', 'success', response);
      })
      .catch(e => {
        console.log('deleteOrganizationUser:', 'error', e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  reinstateEmployee = employeeId => {
    this.isLoading = true;
    return this.axios.api
      .reinstateOrganizationUser({ employeeId })
      .then(response => {
        console.log('reinstateOrganizationUser:', 'success', response);
      })
      .catch(e => {
        console.log('reinstateOrganizationUser:', 'error', e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}
