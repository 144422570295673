import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios } from '../../../../../utils/axios2';

@provide.singleton()
export class ResetEmailAndPasswordStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  hash = '';
  isVerifyEmailLoading = true;
  isSetPasswordAvailable = false;

  verifyEmail = (hash: string) => {
    this.isVerifyEmailLoading = true;
    this.hash = hash;
    this.axios.api
      .verifyResetEmailToken({ verificationHash: hash }, { omit: ['verificationHash'] })
      .then(response => {
        runInAction(() => {
          this.isVerifyEmailLoading = false;
          this.isSetPasswordAvailable = true;
          localStorage.setItem('accessToken', response['access-token']);
          localStorage.setItem('refreshToken', response['refresh-token']);
        });
      })
      .catch(error => {
        console.error('verifyEmail', error);

        this.isVerifyEmailLoading = false;
        this.isSetPasswordAvailable = false;
      });
  };

  password = '';
  repeatPassword = '';

  setPassword = (v: string) => {
    this.password = v;
  };

  setRepeatPassword = (v: string) => {
    this.repeatPassword = v;
  };

  get isSetPasswordAllowed() {
    return this.password.length >= 6 && this.password === this.repeatPassword;
  }

  step: 'first' | 'second' = 'first';

  sendNewPassword = () => {
    this.axios.api
      .updatePassword({
        token: this.hash,
        newPassword: this.password,
        repeatedNewPassword: this.repeatPassword,
      })
      .then(() => {
        runInAction(() => {
          this.step = 'second';
        });
      })
      .catch(error => {
        console.error('sendNewPassword', error);
      });
  };

  reset = () => {
    this.password = '';
    this.repeatPassword = '';
    this.hash = '';
  };
}
