import { UserExpertiseType } from '../../../../api/models/user.model';

import { ReactComponent as CheckboxOn } from './checkboxOn.svg';
import { ReactComponent as CheckboxOff } from './checkboxOff.svg';
import { CompetencyConfirmationsNumber, CompetencyName, CompetencyWrapper } from './style';

export const CompetencyCard = ({ competency }: { competency: UserExpertiseType }) => {
  return (
    <CompetencyWrapper>
      <CompetencyName>{competency.expertiseName}</CompetencyName>
      {competency.confirmedByMe ? <CheckboxOn /> : <CheckboxOff />}
      <CompetencyConfirmationsNumber>{competency.confirmations}</CompetencyConfirmationsNumber>
    </CompetencyWrapper>
  );
};
