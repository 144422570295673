export enum AccessRuleVisibility {
  View = 'view',
  Disabled = 'disabled',
  Hide = 'hide',
}

export enum AccessRuleUserActionName {
  manageInvites = 'user.manageInvites',
  deleteEmployee = 'user.deleteEmployee',
  editEmployee = 'user.editEmployee',
  editRole = 'user.editRole',
  testRole = 'user.testRole',
}

export enum EAccessRuleScoutingName {
  workWithContractors = 'scouting.workWithContractors',
  workWithTasks = 'scouting.workWithTasks',
  manageTasks = 'scouting.manageTasks',
  viewContract = 'scouting.viewContracts',
  manageScouting = 'scouting.manageScouting',
}

export type TActionNameUnion = AccessRuleUserActionName | EAccessRuleScoutingName;
