import { FC, useState } from 'react';

import { ReactComponent as DATitleSvg } from '../../../../assets/images/da-title.svg';

import { FormWrapper, LogoWrapper } from './style';
import { PhoneSignIn } from './components/PhoneSignIn';
import { EmailSignIn } from './components/EmailSignIn';

export type SignInType = 'email' | 'phone';

type SignInPageProps = {
  systemId?: string | undefined;
};

export const SignInPage: FC<SignInPageProps> = ({ systemId }) => {
  const [type, setType] = useState<SignInType>('phone');

  const renderSignInType = (v: SignInType) => {
    const variants: Record<SignInType, any> = {
      email: <EmailSignIn onChangeType={() => setType('phone')} />,
      phone: <PhoneSignIn onChangeType={() => setType('email')} />,
    };

    return (
      <FormWrapper>
        {systemId && systemId.includes('farmlink') ? null : (
          <LogoWrapper>
            <DATitleSvg />
          </LogoWrapper>
        )}

        {variants[v]}
      </FormWrapper>
    );
  };

  return renderSignInType(type);
};
