import styled from 'styled-components';
import { Modal, Body } from '@zendeskgarden/react-modals';

import { Colors } from '../../../../constants/colors';

export const ModalStyled = styled(Modal)`
  left: auto;
`;

export const ModalBodyStyled = styled(Body)``;

export const IconScg = styled.img`
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto 35px auto;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const ModalTitleStyled = styled.div`
  color: ${Colors.black};
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 36px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
  }
`;
