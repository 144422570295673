import { Button, Input, Separator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';

import { ResetStore } from '../../stores/reset.store';
import { AuthorizationRoutes } from '../../../../routes';
import { BackButton } from '../../../../components/BackButton';
import { useStore } from '../../../../../../utils';

import EmailSvg from './assets/email.png';
import {
  ButtonWrapper,
  FieldWrapper,
  Header,
  ImageWrapper,
  LineWrapper,
  Text,
  Wrapper,
} from './style';

export const RequestForReset = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const { email, setEmail, isResetAvailable, resetViaEmail, resetSteps, clear } = useStore(
      ResetStore
    );

    useEffect(() => {
      return () => {
        clear();
      };
    }, []);

    const handleSubmit = (e: any) => {
      e.preventDefault();
      resetViaEmail();
    };

    if (resetSteps === 'email_sent') {
      return (
        <Wrapper>
          <ImageWrapper>
            <img src={EmailSvg} alt="e-mail" />
          </ImageWrapper>
          <Text data-test-id="emailSent-message">
            На вашу почту отправлено письмо с инструкцией по восстановлению пароля.
          </Text>
          <ButtonWrapper>
            <Button
              color="primary"
              type="button"
              onClick={() => history.push(AuthorizationRoutes.signIn)}
              dataTestId="emailSent-next-button"
            >
              Продолжить
            </Button>
          </ButtonWrapper>
        </Wrapper>
      );
    }

    const backHandler = () => {
      history.push(AuthorizationRoutes.signIn);
      clear();
    };

    return (
      <Wrapper>
        <BackButton onClick={() => backHandler()} />
        <Header>Восстановление пароля</Header>
        <LineWrapper>
          <Separator />
        </LineWrapper>
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <Input
              label="Введите Ваш e-mail"
              placeholder="example@yandex.ru"
              error={email.error}
              value={email.value}
              onChange={setEmail}
              dataTestId="forgot-password-email-input"
            />
          </FieldWrapper>
          <ButtonWrapper>
            <Button
              color="primary"
              type="submit"
              disabled={!isResetAvailable}
              dataTestId="forgot-password-next-button"
            >
              Продолжить
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  })
);
