import styled from 'styled-components';
import { Well } from '@zendeskgarden/react-notifications';

export const MainLayout = styled.div`
  @media (max-width: 767px) {
    padding: 0 8px;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const ProfileStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    padding-bottom: 24px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    padding-bottom: 8px;
  }
`;

export const ProfileFormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #e9e6f0;

  @media (max-width: 767px) {
    padding: 0 16px 16px 16px;
  }

  @media (min-width: 768px) {
    padding: 0 24px 16px 24px;
  }
`;

export const ProfileBodyStyled = styled.div`
  @media (max-width: 767px) {
    padding: 20px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;

export const ProfileFormTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 768px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const ActionsBlock = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const CancelButton = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  margin-right: 36px;
  cursor: pointer;
`;

export const SaveProfile = styled.div<{ disabled?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  color: #3fb65f;
  opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: pointer;
`;

export const MobileActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid #e9e6f0;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileCancelButtonWrapper = styled.div`
  width: calc(50% - 4px);
  & > button {
    justify-content: center;
    height: 40px;
  }
  margin-right: 8px;
  margin-top: 24px;
  cursor: pointer;
`;

export const MobileSaveProfileWrapper = styled.div`
  width: calc(50% - 4px);
  & > button {
    justify-content: center;
    height: 40px;
  }
  margin-top: 24px;
  cursor: pointer;
`;

export const MobileResetPasswordButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #f29993;
  margin-top: 20px;
  cursor: pointer;
`;

export const CompetentensionsInterestsBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-gap: 8px;

  @media (min-width: 768px) {
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
`;
