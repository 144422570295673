import { isEmpty } from 'lodash';

import { InitialValues } from '../../../../../api/models/contractor.model';
import { UserInfoType } from '../../../../../api/models/user.model';

function getValuesCreate(orgTypesOptions, scoutsOptions, user: UserInfoType | null): InitialValues {
  return {
    orgTypeId: !isEmpty(orgTypesOptions) ? orgTypesOptions[0].value : 1,
    logo: {
      downloadUrl: '',
      fileName: '',
      id: '',
    },
    name: '',
    INN: '',
    addressJur: '',
    addressFact: '',
    OGRN: '',
    OKVED: '',
    OKPO: '',
    KPP: '',
    contact: '',
    manager: !isEmpty(user) ? user.id : '',
    email: '',
    phone: '',
    newOwner: '',
    representativePosition: '',
    isScout: true,
    isExperiment: true,
  };
}

export { getValuesCreate };
