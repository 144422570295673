import { Axios, lazyInject, provide } from '../../../utils';
import { SessionStore } from '../stores/session.store';
import { AuthStore } from '../stores/auth.store';

@provide.singleton()
export class AuthpreloadController {
  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  @lazyInject(AuthStore)
  authStore: AuthStore;

  @lazyInject(Axios)
  axios: Axios;

  checkIsUserLoginned = async () => {
    const response = await this.sessionStore.checkIsUserLoginned();

    if (response) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  };

  checkIsNoNeedAdditionalInfo = async () => {
    try {
      const response = await this.axios.api.getProfile({});

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.headers['x-need-additional-info']);
    }
  };

  makeRedirect = async () => {
    await this.authStore.erqRedirectData().then(() => {
      this.authStore.redirectWithAuth();
    });
  };

  logout = () => {
    this.sessionStore.logout();
  };

  setSystemId = (systemId?: string) => {
    this.authStore.setSystemId(systemId || 'agroid');
  };
}
