import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useStore } from '../../../../../../utils';
import { AgroidRoutes } from '../../../../routes';
import { CheckAccessService } from '../../../../services/checkAccess.service';
import { CheckAccessStore } from '../../../../stores';
import { TActionNameUnion } from '../../../../types';

type TProtectedRouteProps = {
  accessRule: TActionNameUnion[];
  redirect?: string;
  /** Открывать ли блокирующую заглушку вместо редиректа */
  accessWall?: boolean;
  findAccessAll?: boolean;
} & RouteProps;

export const ProtectedRoute = observer(
  ({ accessRule, redirect, accessWall, findAccessAll, ...props }: TProtectedRouteProps) => {
    const { getUserHasAccessRulesArray, globalAccessRules, accessRules } = useStore(
      CheckAccessStore
    );
    const { displayAccessWall, hideAccessWall } = useStore(CheckAccessService);
    const [isAllow, setIsAllow] = useState(true);

    useEffect(() => {
      const accessAllow = getUserHasAccessRulesArray(accessRule, findAccessAll);
      setIsAllow(accessAllow);

      if (accessAllow) {
        hideAccessWall();
      } else {
        // displayAccessWall();
      }
    }, [globalAccessRules, accessRules]);

    if (isAllow) return <Route {...props} />;

    if (accessWall) {
      // displayAccessWall();
      return <Route {...props} />;
    }

    return <Redirect to={redirect || AgroidRoutes.Index} />;
  }
);
