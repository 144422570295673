import styled from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import { Modal } from '@zendeskgarden/react-modals';

export const AlreadyMemberError = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #f29993;
  margin: -25px 0 20px;
`;

export const InputErrorColorizer = styled.div`
  & + div > input[name='email'] {
    border: 1px solid #f29993;
  }
`;

export const RoleWrapper = styled.div`
  margin-bottom: 24px;
`

export const ButtonWrapper = styled(Button)`
  border-radius: 20px;
  height: 56px;
`

export const ModalWrapper = styled(Modal)`
  padding: 40px 60px 20px;
`