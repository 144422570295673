import styled from 'styled-components';
import { Well } from '@zendeskgarden/react-notifications';

export type CardWrapperProps = {
  padding?: string;
  isAdd?: boolean;
  height?: string;
  heightFor767?: string;
};

export type CardGridWrapperProps = {
  gridGapFor1280?: string;
  gridGapFor1024?: string;
  gridGapFor768?: string;
  gridTemplateColumnsFor1024?: string;
};

export const CardWrapper = styled(Well)<CardWrapperProps>`
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0), 0px 2px 4px rgba(0, 0, 0, 0), 0px 1px 4px rgba(0, 0, 0, 0);
  transition: 0.2s box-shadow linear;
  overflow: hidden;
  background-color: ${({ isAdd }) => isAdd && '#faf9fd'};
  border: ${({ isAdd }) => isAdd && '2px dashed #eae8f2'};
  padding: 0;
  height: ${({ height }) => height ?? 'auto'};

  @media (max-width: 767px) {
    height: ${({ heightFor767 }) => (heightFor767 ? heightFor767 : 'auto')};
  }

  &:active,
  &:hover {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 6px 8px rgba(0, 0, 0, 0.05),
      0px 2px 24px rgba(0, 0, 0, 0.1);
  }
`;

export const CardsGridWrapper = styled.div<CardGridWrapperProps>`
  display: grid;
  grid-template-rows: repeat(1, auto);

  @media (max-width: 767px) {
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 768px) {
    grid-gap: ${({ gridGapFor768 }) => gridGapFor768 ?? '20px'};
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: ${({ gridGapFor1024 }) => gridGapFor1024 ?? '20px'};
    grid-template-columns: ${({ gridTemplateColumnsFor1024 }) =>
      gridTemplateColumnsFor1024 ?? 'repeat(2, 1fr)'};
  }

  @media (min-width: 1280px) {
    grid-gap: ${({ gridGapFor1280 }) => gridGapFor1280 ?? '20px'};
    grid-template-columns: repeat(3, 1fr);
  }
`;
