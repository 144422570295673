import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemText } from '@zendeskgarden/react-chrome';

import { ISidebarMenuItem } from '../../components';
import { AgroidRoutes } from '../../routes';

export const useRolesMenu = (orgId: string, roles: Array<any>) => {
  const orgPath = `${AgroidRoutes.Contractors}/${orgId}`;

  const menuItems: ISidebarMenuItem[] = [
    {
      caption: (
        <NavItemText>
          <NavLink to={`${orgPath}/roles`} isActive={() => false}></NavLink>
        </NavItemText>
      ),
      isBack: true,
      slug: 'roles-back',
    },
    ...roles.map(role => ({
      key: role.id,
      caption: role.name,
      link: `${orgPath}/roles/${role.id}`,
      slug: `role`,
    })),
  ];
  return menuItems;
};
