import React, { FC, HTMLAttributes } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Chip } from '@farmlink/farmik-ui';

import { num2str } from '../../../../utils';

import { RoleCardStyled, DelAnchor, RoleLabelWrapper } from './styled';

export interface RoleCard extends HTMLAttributes<HTMLDivElement> {
  id: string;
  name: string;
  employeeCount: number;
  isOwner: boolean;
  isDefault?: boolean;
  dataTestId?: string;
  onDelClick?: (id: string) => void;
  isOrganizationOwner?: boolean;
  isScoutForUser?: boolean;
}

export const RoleCard: FC<RoleCard> = ({
  id,
  name,
  employeeCount = 0,
  isOwner,
  isDefault,
  dataTestId,
  onDelClick,
  isOrganizationOwner,
  isScoutForUser,
}) => {
  const { url } = useRouteMatch();

  const handleDelete = event => {
    event.preventDefault();
    onDelClick(id);
  };

  const hasOrganizationOwner = typeof isOrganizationOwner !== undefined;

  return (
    <RoleCardStyled key={id} data-test-id={dataTestId && `${dataTestId}-card-item`}>
      <RoleCardStyled.Header>
        <RoleCardStyled.Title tag="h2" data-test-id={dataTestId && `${dataTestId}-card-title`}>
          {name}
        </RoleCardStyled.Title>

        <RoleLabelWrapper>
          {isScoutForUser ? (
            <Chip dataTestId="role-label" size="small" color="default">
              DigitalAgro
            </Chip>
          ) : (
            <Chip dataTestId="role-label" size="small" color="primary">
              Организация
            </Chip>
          )}
        </RoleLabelWrapper>

        {!isOwner && !isDefault && onDelClick && (
          <DelAnchor
            href={`${url}/${id}`}
            onClick={handleDelete}
            data-test-id={dataTestId && `${dataTestId}-card-delete-action`}
          >
            <RoleCardStyled.DeleteSVG />
          </DelAnchor>
        )}
      </RoleCardStyled.Header>

      <RoleCardStyled.Text data-test-id={dataTestId && `${dataTestId}-card-text`}>
        {employeeCount} {num2str(employeeCount, ['сотрудник', 'сотрудника', 'сотрудников'])}
      </RoleCardStyled.Text>

      <RoleCardStyled.LinkWrapper
        data-test-id={dataTestId && `${dataTestId}-card-action-container`}
      >
        {(hasOrganizationOwner || !isOwner) && (
          <RoleCardStyled.Link
            to={`${url}/${id}`}
            data-test-id={dataTestId && `${dataTestId}-card-action`}
          >
            Перейти
          </RoleCardStyled.Link>
        )}
      </RoleCardStyled.LinkWrapper>
    </RoleCardStyled>
  );
};
