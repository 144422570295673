import React, { Ref, useEffect, useState } from 'react';
import { Field, Label, Checkbox } from '@zendeskgarden/react-forms';
import { HTMLFieldProps, connectField /* filterDOMProps */ } from 'uniforms';

export type BoolFieldProps = HTMLFieldProps<
  boolean,
  HTMLDivElement,
  { inputRef?: Ref<HTMLInputElement>; dataTestId?: string }
>;

function Bool({
  disabled = false,
  id,
  inputRef,
  label,
  name,
  onChange,
  // readOnly,
  value,
  defaultValue,
  dataTestId,
}: // ...props
BoolFieldProps) {
  const [flag, setFlag] = useState(Boolean(value || defaultValue));

  useEffect(() => {
    if (value !== flag) {
      onChange(flag);
    }
  }, [flag, value]);

  const handleChange = () => {
    setFlag(!flag);
  };

  return (
    <Field id={id}>
      <Checkbox
        checked={flag}
        onChange={handleChange}
        disabled={disabled}
        ref={inputRef}
        name={name}
        data-test-id={dataTestId && `${dataTestId}-checkbox`}
      >
        <Label data-test-id={dataTestId && `${dataTestId}-label`}>{label}</Label>
      </Checkbox>
    </Field>
  );
}

export const BooleanField = connectField<BoolFieldProps>(Bool, { kind: 'leaf' });
