import { TypeApiRoute } from '../models/type.api.request';

type TUploadFileDescriptionRequest = {
  serviceName: string;
  ownerId?: string;
  fileName: string;
  fileSize: number;
  fileContentType: string;
};

type TUploadFileDescriptionResponse = { id: string; uploadUrl: string };

const uploadFile: TypeApiRoute & {
  request: TUploadFileDescriptionRequest;
  response: TUploadFileDescriptionResponse;
} = {
  url: `/api/da-profile/files/upload`,
  method: 'POST',
  headers: {},
  request: {} as TUploadFileDescriptionRequest,
  response: {} as TUploadFileDescriptionResponse,
};

export type { TUploadFileDescriptionRequest, TUploadFileDescriptionResponse };

export { uploadFile };
