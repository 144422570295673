const TimesOfDayHoursMap = {
  morning: [5, 6, 7, 8, 9, 10],
  dayTime: [11, 12, 13, 14, 15, 16],
  evening: [17, 18, 19, 20, 21, 22],
  night: [23, 0, 1, 2, 3, 4],
};

const TimesOfDayNameMap = {
  morning: 'morning',
  dayTime: 'dayTime',
  evening: 'evening',
  night: 'night',
};

const getTimesOfDay = (): string => {
  const hours = new Date().getHours();

  if (TimesOfDayHoursMap.evening.includes(hours)) {
    return TimesOfDayNameMap.evening;
  }

  if (TimesOfDayHoursMap.morning.includes(hours)) {
    return TimesOfDayNameMap.morning;
  }

  if (TimesOfDayHoursMap.night.includes(hours)) {
    return TimesOfDayNameMap.night;
  }

  return TimesOfDayNameMap.dayTime;
};

const greetingsForTimeOfDayMap = {
  [TimesOfDayNameMap.night]: 'Доброй ночи',
  [TimesOfDayNameMap.morning]: 'Доброе утро',
  [TimesOfDayNameMap.evening]: 'Добрый вечер',
  [TimesOfDayNameMap.dayTime]: 'Добрый день',
};

export const useGreeting = () => {
  const timeOfDay = getTimesOfDay();
  return greetingsForTimeOfDayMap[timeOfDay];
};
