import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { RequestForReset } from './components/RequestForReset';
import { ResetRoutes } from './ResetRoutes';
import { SetNewPassword } from './components/SetNewPassword';
import { VerifyEmail } from './components/VerifyEmail';
import { SetNewPasswordAndEmail } from './components/SetNewPasswordAndEmail';

export const ResetPage: FC = () => (
  <Switch>
    <Route path={ResetRoutes.verifyEmailAndSetPassword} component={SetNewPasswordAndEmail} />
    <Route path={ResetRoutes.verifyResetPassword} component={SetNewPassword} />
    <Route path={ResetRoutes.verifyEmail} component={VerifyEmail} />
    <Route path={ResetRoutes.common} component={RequestForReset} />
    <Redirect to={ResetRoutes.common} />
  </Switch>
);
