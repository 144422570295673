import { TypeApiRoute } from '../models/type.api.request';

type TUploadFileRequest = {
  data: any;
  url: any;
};

type TUploadFileResponse = {};

const uploadFileFinish: TypeApiRoute & {
  request: TUploadFileRequest;
  response: TUploadFileResponse;
} = {
  url: request => request.url,
  method: 'PUT',
  headers: { 'DA-HOST': 'da-profile' },
  request: {} as TUploadFileRequest,
  response: {} as TUploadFileResponse,
};

export type { TUploadFileRequest, TUploadFileResponse };

export { uploadFileFinish };
