import styled, { StyledComponentBase } from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';

import { NoData } from '../';
import { AvatarWrapper } from '../UserCard/styled';
import { ReactComponent as UserIcon } from '../../../../assets/images/agroid/user.svg';

interface IUserRowWrapper extends StyledComponentBase<any, {}> {
  Details?: any;
  Icon?: any;
  Avatar?: any;
}

export const UsersBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  padding: 16px 6px 16px 24px;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding: 16px 6px 16px 16px;
  }
`;

export const UserBodyStyled = styled.div`
  overflow: auto;
  padding-right: 18px;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding-right: 10px;
  }
`;

export const UserRowWrapper: IUserRowWrapper = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  min-height: 24px;
  margin-bottom: 16px;

  &:hover > div {
    color: #000;
  }
`;

UserRowWrapper.Avatar = styled(AvatarWrapper)`
  display: inline-flex;
  flex-grow: 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
`;

UserRowWrapper.Details = styled.div`
  width: calc(100% - 68px);
  flex-grow: 1;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 16px;
`;

UserRowWrapper.Icon = styled(UserIcon)`
  width: 12px;
  height: 12px;
  path {
    fill: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const NoDataStyled = styled(NoData)`
  @media (max-width: 767px) {
    height: calc(100vh - 304px) !important;
  }
`;

export const LoadderWrapper = styled.div`
  margin-top: 16px;

  padding: 16px 24px;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding: 16px;
  }
`;
