import React, { FC, HTMLAttributes, SVGProps } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from '@farmlink/farmik-ui';

import { ControlAccessRulesWrapper } from '../AccessRulesWrapper/ControlAccessRulesWrapper';
import { AccessRuleVisibility } from '../../types';
import { IAction } from '../../pages/OrgsPage/pages/OrgProfile';
import { CheckAccessStore } from '../../stores';
import { useStore } from '../../../../utils/IoC';

import ArrowSvg from './assets/arrow.svg';
import { StyledAccordeonAction, TitleArrowBlock, WellHeaderStyled } from './styled';
export interface IWellHeaderAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption?: string | boolean;
  component: FC;
  componentProps: { [key: string]: any };
  positionIcon?: 'left' | 'right';
  mode?: AccessRuleVisibility;
  actionName?: string | string[];
  dataTestIdAction?: string;
}

export interface IWellHeader extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  actions?: any[];
  isAlone?: boolean;
  hasAccordeon?: boolean;
  onToggleAccordeon?: () => void;
  dataTestId?: string;
}

const renderCaptionWithIcon = (
  Icon,
  caption,
  positionIcon = 'left',
  dataTestIdAction = '',
  onClick?
) => {
  if (positionIcon === 'left') {
    return (
      <>
        {Icon && <Icon data-test-id={dataTestIdAction && `${dataTestIdAction}-left-icon`} />}
        <Typography
          variant="body"
          color="inherit"
          dataTestId={dataTestIdAction && `${dataTestIdAction}-caption`}
          onClick={() => onClick?.()}
        >
          {caption}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography
        variant="body"
        color="secondaryDark"
        dataTestId={dataTestIdAction && `${dataTestIdAction}-caption`}
      >
        {caption}
      </Typography>
      {Icon && <Icon data-test-id={dataTestIdAction && `${dataTestIdAction}-right-icon`} />}
    </>
  );
};

export const WellHeader: FC<IWellHeader> = ({
  title,
  actions = [],
  isAlone,
  hasAccordeon,
  onToggleAccordeon,
  dataTestId = '',
}) => {
  const { getUserHasAccessRule } = useStore(CheckAccessStore);

  return (
    <WellHeaderStyled isAlone={isAlone} data-test-id={dataTestId && `${dataTestId}-well-header`}>
      {hasAccordeon ? (
        <TitleArrowBlock data-test-id={dataTestId && `${dataTestId}-well-header-arrow-block`}>
          <WellHeaderStyled.Title
            data-test-id={dataTestId && `${dataTestId}-well-header-arrow-block-title`}
          >
            {title}
          </WellHeaderStyled.Title>
          <StyledAccordeonAction
            src={ArrowSvg}
            onClick={onToggleAccordeon}
            data-test-id={dataTestId && `${dataTestId}-well-header-arrow-block-accordeon-action`}
          />
        </TitleArrowBlock>
      ) : (
        <WellHeaderStyled.Title data-test-id={dataTestId && `${dataTestId}-well-header-title`}>
          {title}
        </WellHeaderStyled.Title>
      )}

      {!isEmpty(actions) && (
        <WellHeaderStyled.Actions
          className={'well-header-actions'}
          data-test-id={dataTestId && `${dataTestId}-well-header-actions`}
        >
          {actions
            .filter(
              (action: IAction) =>
                !Boolean(action.accessRule) || getUserHasAccessRule(action.accessRule)
            )
            .map(
              (
                {
                  icon: Icon,
                  caption,
                  component: Component,
                  componentProps,
                  positionIcon,
                  mode,
                  actionName,
                  dataTestIdAction = '',
                  onClick,
                },
                index
              ) => (
                <ControlAccessRulesWrapper
                  mode={mode}
                  actionName={actionName}
                  key={`${caption}-${index}`}
                  dataTestId={dataTestIdAction && `${dataTestIdAction}-wrapper`}
                >
                  <Component {...componentProps} data-test-id={dataTestIdAction}>
                    <>
                      {!caption
                        ? Icon && (
                            <Icon
                              data-test-id={dataTestIdAction && `${dataTestIdAction}-icon`}
                              onClick={onClick}
                            />
                          )
                        : renderCaptionWithIcon(
                            Icon,
                            caption,
                            positionIcon,
                            dataTestIdAction,
                            onClick
                          )}
                    </>
                  </Component>
                </ControlAccessRulesWrapper>
              )
            )}
        </WellHeaderStyled.Actions>
      )}
    </WellHeaderStyled>
  );
};
