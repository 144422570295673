import styled from 'styled-components';
import { InputGroup } from '@zendeskgarden/react-forms';

export const MergedInputGroupStyled = styled(InputGroup)<{ hasError?: boolean }>`
  z-index: 1;
  position: relative;

  [data-garden-id='forms.faux_input'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none !important;
  }

  [data-garden-id='forms.field'] {
    color: ${({ theme: { colors: c } }) => c.accentPink}; !important;
    flex-grow: 1;
  }

  [data-garden-id='forms.input'] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #d8dcde;
  }
  :focus,
  [data-garden-focus-visible='true'] {
    box-shadow: none !important;
  }

  ${({ hasError, theme: { colors: c } }) =>
    hasError &&
    `
    [data-garden-id='forms.faux_input'] {
      border-color: ${c.accentPink};
    }

    div > div > div > div {
      border-color: ${c.accentPink};
    }

    [data-garden-id='forms.input'] {
      border-color: ${c.accentPink};
    }

  `}
`;
