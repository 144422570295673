import { FC, HTMLAttributes, useRef, useState } from 'react';

import { ObjectFit, OrgLogoImg, OrgLogoWrapper } from './styled';

export interface OrgLogoProps extends HTMLAttributes<HTMLDivElement> {
  downloadUrl: string;
  fileName: string;
  dataTestId?: string;
}

export const OrgLogo: FC<OrgLogoProps> = ({ downloadUrl, fileName, dataTestId = '' }) => {
  const imgLogo = useRef<HTMLImageElement | null>(null);

  return (
    <OrgLogoWrapper>
      <OrgLogoImg
        imgAspectRatio={'scale-down'}
        src={downloadUrl}
        alt={fileName}
        data-test-id={dataTestId}
        ref={imgLogo}
      />
    </OrgLogoWrapper>
  );
};
