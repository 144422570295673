import React, { FC, HTMLAttributes, useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import { EModalType, useModal } from '@farmlink/farmik-ui';

import { EModalSize } from '../../modules/common/containers/configs/modals/Modal/styles';

import { CardsGridWrapper, CardWrapper } from './styled';

export interface CardsListProps extends HTMLAttributes<HTMLDivElement> {
  list: any[];
  card: FC<any>;
  addCard?: FC<any>;
  cardProps?: { [key: string]: any };
  cardGridProps?: { [key: string]: any };
  dataTestId?: string;
  itemProps?: { [key: string]: any };
}

export const CardsList: FC<CardsListProps> = ({
  list = [],
  card: Card,
  cardProps = {},
  cardGridProps = {},
  addCard: AddCard,
  dataTestId,
  itemProps,
}) => {
  const { registerModalList, getModalPayload } = useModal();

  useEffect(() => {
    registerModalList(
      [
        {
          name: 'removeItemConfirmModal',
          title: 'Вы уверены, что хотите удалить договор?',
          type: EModalType.Warning,
          id: 'removeItemConfirmModal',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Удалить',
            color: 'secondary',
            handler: () => getModalPayload().removeItemHandler(),
          },
          denyButton: {
            title: 'Отменить',
            color: 'default',
          },
        },
      ],
      'listingPageModals'
    );
  }, []);


  return (
    <CardsGridWrapper {...cardGridProps} data-test-id={dataTestId && `${dataTestId}-container`}>
      {list.map((item, index) => (
        <CardWrapper
          {...cardProps}
          isAdd={index === 0 && Boolean(AddCard)}
          key={uniqueId('cards-list')}
          data-test-id={dataTestId && `${dataTestId}-card`}
        >
          {index === 0 && AddCard ? (
            <AddCard {...item} dataTestId={dataTestId} />
          ) : (
            <Card {...item} dataTestId={dataTestId} {...itemProps} />
          )}
        </CardWrapper>
      ))}
    </CardsGridWrapper>
  );
};
