import { FC, HTMLAttributes, SVGProps } from 'react';
import { Well } from '@zendeskgarden/react-notifications';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { MD, XL } from '@zendeskgarden/react-typography';

import { ReactComponent as FileIcon } from '../../../../assets/images/agroid/colored/File.svg';

export interface NoDataProps extends HTMLAttributes<HTMLDivElement> {
  width?: number | string;
  height?: number | string;
  message?: string | FC;
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  textSize?: string;
  maxHeight?: string;
  className?: string;
  dataTestId?: string;
}

const MAX_HEIGHT = 'fit-content';
// const MAX_HEIGHT = 'calc(100vh - 260px)';

export const NoData: FC<NoDataProps> = ({
  height = MAX_HEIGHT,
  width = '100%',
  message = 'Нет данных',
  icon: Icon = FileIcon,
  textSize = 'MD',
  maxHeight,
  className,
  children,
  dataTestId = 'NoData',
}) => {
  const TextSize = textSize === 'MD' ? MD : XL;
  return (
    <Well
      className={className}
      style={{
        height,
        width,
        display: 'flex',
        alignItems: 'center',
        maxHeight: maxHeight ?? MAX_HEIGHT,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid>
        <Row justifyContent="center" alignItems="center">
          <Col textAlign="center" data-test-id={`${dataTestId}-no-data-img`}>
            {Icon ? <Icon /> : null}
            <div style={{ marginTop: '38px', marginBottom: '12px' }}>
              <TextSize style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span
                  data-test-id={`${dataTestId}-no-data-message`}
                  style={{ fontSize: '16px', fontWeight: '600' }}
                >
                  {message}
                </span>
              </TextSize>
            </div>
          </Col>
        </Row>
      </Grid>
      {children}
    </Well>
  );
};
