import { lazyInject, provide } from '../../../../../../utils';
import { displayModalWindow } from '../../../../configs/data/modalsList/modalsListData.config';
import { ModalsStore } from '../../stores/modals/Modals.store';

@provide.singleton()
export class ModalsController {
  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  openModal = (modalsGroupKey: string, uniqueKey: string) => {
    if (!this.modalsStore.hasModalByKey(uniqueKey)) {
      this.modalsStore.setModal(uniqueKey, displayModalWindow(modalsGroupKey, uniqueKey));
    }

    this.modalsStore.setActiveModal(uniqueKey);
  };

  closeModal = () => {
    this.modalsStore.setActiveModal(null);
  };
}
