import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {};

type TypeResponse = {};

export const verifyAccessToken: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/users/ping`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
