import { TListingPage } from '../../../../../../../configs/types/pages/listing';
import ListingPageContainer from '../../../../../containers/configs/pages/ListingPageContainer/ListingPageContainer';
import { createUniqueKeyByRouteName } from '../createUniqueKeyByRouteName';
import withListingPage from '../withListingPage/withListingPage.helper';

import { TCreateListingPage } from './createListingPage.helper.types';

const createListingPage: TCreateListingPage = config => {
  const uniqueKey = createUniqueKeyByRouteName(config.link.path);

  const listingPage: TListingPage = {
    uniqueKey,
    link: {
      ...config.link,
      uniqueKey,
    },
    header: {
      ...config.header,
    },
    dataList: {
      ...config.dataList,
    },
    handlerList: {
      ...config.handlerList,
    },
    customData: config?.customData,
    plugStyle: config?.plugStyle,
  } as TListingPage;

  if (config?.Component) {
    return {
      ...listingPage,
      Component: withListingPage(listingPage)(config.Component),
    };
  }

  return {
    ...listingPage,
    Component: withListingPage(listingPage)(ListingPageContainer),
  };
};

export default createListingPage;
