import { ApplicationStatus, TypeApplication2 } from '../models/applications.model';
import { InvitationType } from '../models/InvitationType';
import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

type TypeRequest = {
  status?: ApplicationStatus;
  nameOrInn?: string;
  page?: number;
  size?: number;
};

type TypeResponse = {
  content: Array<InvitationType>;
} & TypeResponsePageable;

export const getOrganizationApplicationsMine: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/organizations/MyApplicationlist`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
