import styled from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';

export const OrgLogoWrapper = styled.div`
  svg {
    width: 80px;
    height: 80px;
  }
  border-radius: ${({ theme: { borderRadii: r } }) => r.card};
  border: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  display: inline-block;
  padding: ${({ theme: { space: s } }) => s.sm};
`;

export const EditWrapper = styled.div`
  text-align: right;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    position: absolute;
    top: 0;
    right: 0;

    span {
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  height: 144px;
  width: 100%;

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    width: 144px;
    margin-right: 24px;
  }
`;

export const LogoRequisitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const RequisitesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const FieldWrapper = styled.div`
  margin-right: 0;
  align-self: flex-start;

  @media (min-width: 768px) {
    margin-right: 12px;
  }
  @media (min-width: 1024px) {
    margin-right: 24px;
  }

  label {
    margin-bottom: 4px;
  }

  [data-garden-id='forms.field'] {
    margin-bottom: 24px;

    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
`;

export const OrgNameWrapper = styled(FieldWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 0;

  @media (min-width: 1366px) {
    width: 570px;
    margin-right: 24px;
  }

  label span {
    color: #f29993;
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;

    &:after {
      content: '*';
    }
  }
`;

export const InnWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 12px) / 2);
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: calc((100% - 24px) / 2);
    margin-right: 24px;
  }

  @media (min-width: 1366px) {
    width: calc(100% - 24px - 570px);
    margin-right: 0;
  }
`;

export const OGRNWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 12px) / 2);
  }

  @media (min-width: 1024px) {
    width: calc((100% - 24px) / 2);
  }

  @media (min-width: 1366px) {
    width: calc((100% - 72px) / 4);
    margin-right: 24px;
  }
`;

export const OKVEDWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 24px) / 3);
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: calc((100% - 48px) / 3);
    margin-right: 24px;
  }

  @media (min-width: 1366px) {
    width: calc((100% - 72px) / 4);
  }
`;

export const OKPOWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 24px) / 3);
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: calc((100% - 48px) / 3);
    margin-right: 24px;
  }

  @media (min-width: 1366px) {
    width: calc((100% - 72px) / 4);
  }
`;

export const KPPWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 24px) / 3);
  }

  @media (min-width: 1024px) {
    width: calc((100% - 48px) / 3);
  }

  @media (min-width: 1366px) {
    width: calc((100% - 72px) / 4);
  }
`;

export const AddressJurWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  & > div > div {
    width: 100%;
  }
  width: 100%;
  margin-right: 0;
`;

export const AddressFactWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  & > div > div {
    width: 100%;
  }
  width: 100%;
  margin-right: 0;
`;

export const EmailWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 12px) / 2);
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: calc((100% - 24px) / 2);
    margin-right: 24px;
  }
`;

export const PhoneWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 12px) / 2);
  }

  @media (min-width: 1024px) {
    width: calc((100% - 24px) / 2);
  }
`;

export const ContactWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }
  width: 100%;
  margin-right: 0;

  label {
    margin-bottom: 4px;
  }
`;

export const OrgTypeWrapper = styled.div`
  & [data-garden-id='forms.faux_input'] {
    min-width: 86px;
  }
  & [data-garden-id='dropdowns.item'] {
    padding: 8px 15px;
  }
  & [data-garden-id='dropdowns.item_icon'] {
    display: none;
  }
  min-width: 79px;
`;

export const ErrorsFieldWrapper = styled.div`
  color: ${({ theme: { colors: c } }) => c.accentPink};
  font-size: 12px;
  margin-bottom: 8px;
  margin-top: -8px;
`;
