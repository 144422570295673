import styled from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';
import { Well } from '@zendeskgarden/react-notifications';
import { Button } from '@zendeskgarden/react-buttons';
import { TooltipModal } from '@zendeskgarden/react-modals';

import { Colors } from '../../../../../../constants/colors';

export const OrgLogoWrapper = styled.div`
  svg {
    width: 80px;
    height: 80px;
  }
  border-radius: ${({ theme: { borderRadii: r } }) => r.card};
  border: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  display: inline-block;
  padding: ${({ theme: { space: s } }) => s.sm};
`;

export const EditWrapper = styled.div`
  text-align: right;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    position: absolute;
    top: 0;
    right: 0;

    span {
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  height: 144px;
  width: 100%;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    width: 144px;
    margin-right: 20px;
  }
`;

export const LogoRequisitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const RequisitesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const FieldWrapper = styled.div`
  margin-right: 0;
  align-self: flex-start;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }

  & div[id|='uniforms'] {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: 0;
    color: ${({ theme: { colors: c } }) => c.accentPink};
  }

  [data-garden-id='forms.field'] {
    margin-bottom: 0;
  }
`;

export const OrgNameWrapper = styled(FieldWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 0;

  > label {
    margin-bottom: 4px;
  }

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
  }

  label span {
    color: #f29993;
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;

    &:after {
      content: '*';
    }
  }
`;

export const InnWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
  }
`;

export const AddressJurWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  & > div > div {
    width: 100%;
  }
  width: 100%;
  margin-right: 0;
`;

export const AddressFactWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  & > div > div {
    width: 100%;
  }

  width: 100%;
  margin-right: 0;
`;

export const OGRNWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 48px) / 4);
    margin-right: 16px;
  }
`;

export const OKVEDWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 48px) / 4);
    margin-right: 16px;
  }
`;

export const OKPOWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 48px) / 4);
    margin-right: 16px;
  }
`;

export const KPPWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 48px) / 4);
  }
`;

export const EmailWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
  }
`;

export const PhoneWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
  }
`;

export const ContactWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
    margin-right: 16px;
  }
`;

export const PositionWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  width: 100%;
  margin-right: 0;

  @media (min-width: 768px) {
    width: calc((100% - 16px) / 2);
  }
`;

export const NewOwnerWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  & > div > div {
    width: 100%;
    box-shadow: none;
  }

  width: 100%;
  margin-right: 0;
  margin-bottom: 0;
`;

export const ScoutWrapper = styled(FieldWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;

    input:disabled ~ label {
      &::before {
        background-color: #f2f1f4 !important;
      }
    }
  }

  & > div > div {
    width: 100%;
  }

  width: 100%;
  margin-right: 0;
  margin-bottom: 10px;
`;

export const ExperimentWrapper = ScoutWrapper;

export const OrgTypeWrapper = styled.div`
  & [data-garden-id='forms.faux_input'] {
    min-width: 86px;
  }
  & [data-garden-id='dropdowns.item'] {
    padding: 8px 15px;
  }
  & [data-garden-id='dropdowns.item_icon'] {
    display: none;
  }
  min-width: 79px;
`;

export const ButtonStyled = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  @media (max-width: 767px) {
    border-radius: 12px;
    height: 40px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
    height: 56px;
  }
`;

export const ButtonStyledSubmit = styled(ButtonStyled)`
  @media (min-width: 1024px) {
    width: 300px;
  }
`;

export const ButtonStyledCancel = styled(ButtonStyled)``;

export const ErrorsFieldWrapper = styled.div`
  color: ${({ theme: { colors: c } }) => c.accentPink};
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ErrorValueField = styled.div`
  color: ${({ theme: { colors: c } }) => c.accentPink};
  font-size: 12px;
  margin-top: 5px;
  // margin-bottom: 20px;
`;

export const WellStyled = styled(Well)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 24px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    padding-bottom: 24px;
  }
`;

export const WellBodyStyled = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme: { colors: c } }) => c.borderGray};
  margin-top: 16px;

  &.new-owner,
  &.manager {
    margin-top: 12px;
  }

  @media (max-width: 767px) {
    padding: 20px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 16px 24px 0 24px;
  }
`;

export const TooltipModalStyled = styled(TooltipModal)`
  width: 227px;
  padding: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 6px 8px rgba(0, 0, 0, 0.05),
    0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;

  &:before {
    width: 16px;
    height: 16px;
  }
`;

TooltipModalStyled.Body = styled(TooltipModal.Body)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #151f32;

  p {
    margin-bottom: 10px;
  }
`;

export const ResendInviteTooltipWrapper = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  &:before {
    content: '';
    right: 40px;
    position: absolute;
    top: 94px;
    background-color: ${Colors.white};
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    z-index: 2;
  }
  @media (max-width: 767px) {
    &:before {
      top: 79px;
      right: 31px;
    }
  }
`;

export const ResendInviteTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 24px;
  position: absolute;
  bottom: -99px;
  background-color: #fff;
  width: 227px;
  padding: 16px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  z-index: 1;
  @media (max-width: 767px) {
    bottom: -83px;
    right: 16px;
  }
`;

export const ResendInviteTooltipTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #151f32;
  margin-bottom: 10px;
`;

export const ResendInviteTooltipAction = styled.div`
  color: ${Colors.green};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
`;
