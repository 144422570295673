import React, { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { MaxWidthLayout, HeaderMenu, Footer, BodyStyled, ContentStyled } from './components';
import { TPageLink } from './configs/types/pages/elements';

type TAgroidWrapperProps = {
  linkList: TPageLink[];
};

export const AgroidWrapper: FC<TAgroidWrapperProps> = ({ linkList, children }) => (
  <BodyStyled hasFooter={isMobileOnly}>
    <ContentStyled>
      <HeaderMenu linkList={linkList} />
      <MaxWidthLayout>{children || `Agroid Initial`}</MaxWidthLayout>
      <Footer />
    </ContentStyled>
  </BodyStyled>
);
