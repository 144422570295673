import { TCreateContractRequest } from '../../../../../../../../api';
import { Axios, lazyInject, provide } from '../../../../../../../../utils';

@provide.singleton()
class ListingPageContractsContainerService {
  @lazyInject(Axios)
  private _axios: Axios;

  createContract = async (payload: TCreateContractRequest): Promise<boolean> => {
    try {
      await this._axios.api.createContract({ ...payload });

      return true;
    } catch (e) {
      console.error(e);

      return false;
    }
  };
}

export default ListingPageContractsContainerService;
