import { TypeApiRoute } from '../models/type.api.request';
import { TypeAddress } from '../models/contractor.model';

type TypeRequest = {
  name?: string;
  email?: string;
  phone?: string;
  contact?: string;
  logo?: string;
  addressJur?: TypeAddress;
  addressFact?: TypeAddress;
  orgTypeId?: number;
  newOwner?: string;
  representativePosition?: string;
  INN?: string;
  KPP?: string;
  OGRN?: string;
  OKPO?: string;
  OKVED?: string;
  manager?: string;
  isScout?: boolean;
  isExperiment?: boolean;
};

type TypeResponse = {};

type TypeError = {};

export const createContractor: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: '/api/da-profile/scouting/contractors/create',
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
