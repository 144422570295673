import { makeAutoObservable, runInAction } from 'mobx';
import { trim, isEmpty } from 'lodash';

import {
  TypeApiRequest,
  TypeApiResponse,
  lazyInject,
  provide,
  localizeValidationTitle,
} from '../../../utils';
import { Axios } from '../../../utils/axios2';
import {
  TypeContractor,
  TypeUserScout,
  FormValueError,
  EmptyType,
  FetchType,
} from '../../../api/models/contractor.model';
import { TypeApplication } from '../../../api/models/applications.model';
import { getSizeByInnerHeight } from '../modules/common/utils/helpers/configs/pages/getSizeByInnerHeight';

import { OrganizationStore } from './organization.store';

@provide.singleton()
export class ScoutsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationStore)
  organizationStore: OrganizationStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;
  optionsLoading = false;
  scoutName = '';
  emptyType: EmptyType = EmptyType.LIST;
  scouts: TypeContractor[] = [];
  scout: TypeContractor = {} as TypeContractor;

  formValuesErrors = {} as any;

  logoHashId = '';
  logoHashUrl = '';
  logoHashFileName = '';

  logoId = '';
  logoUrl = '';
  logoFileName = '';

  logoFile: File;

  page = 0;
  totalPages = 0;

  scoutApplications: Array<TypeApplication> = [];
  orgTypesOptions: [];
  scoutsOptions: [];

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get isOptionsLoading() {
    return this.optionsLoading;
  }

  clearScoutApplications = () => {
    this.scoutApplications = [];
  };

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setOptionsLoading = (v: boolean) => {
    runInAction(() => {
      this.optionsLoading = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setFormValueError = (error: FormValueError) => {
    runInAction(() => {
      this.formValuesErrors = {
        ...this.formValuesErrors,
        [error.source]: localizeValidationTitle(error.title),
      };
    });
  };

  setFormValuesErrors = (errors: FormValueError[]) => {
    runInAction(() => {
      errors.forEach(({ source, title }) => {
        this.formValuesErrors = {
          ...this.formValuesErrors,
          [source]: localizeValidationTitle(title),
        };
      });
    });
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };

  setEmptyType = (type: EmptyType) => {
    runInAction(() => {
      this.emptyType = type;
    });
  };

  setScoutName = (value: string) => {
    if (this.emptyType !== EmptyType.SEARCH) {
      this.setEmptyType(EmptyType.SEARCH);
    }

    runInAction(() => {
      if (this.page !== 0) {
        this.setPage(0);
      }

      this.scoutName = value;
    });
  };

  addScouts = (scouts: TypeContractor[], totalPages: number) => {
    runInAction(() => {
      this.scouts = scouts;
      this.totalPages = totalPages;
    });
  };

  updateScouts = (scouts: TypeContractor[], totalPages: number) => {
    runInAction(() => {
      this.scouts = [...this.scouts, ...scouts];
      this.totalPages = totalPages;
    });
  };

  addScout = (scout: TypeContractor) => {
    runInAction(() => {
      this.scout = scout;
    });
  };

  clearScouts = () => {
    this.addScouts([], 0);
    this.setScoutName('');
    this.setEmptyType(EmptyType.LIST);
    this.setLoaded(false);
    this.setLoading(false);
  };

  clearScout = () => {
    this.onResetLogo();
    this.addScout({} as TypeContractor);
    this.clearFormValuesErrors();
  };

  setOrgTypesOptions = options => {
    runInAction(() => {
      this.orgTypesOptions = options;
    });
  };

  setScoutsOptions = options => {
    runInAction(() => {
      this.scoutsOptions = options;
    });
  };

  fetchScouts = (type: FetchType = FetchType.ADD): Promise<TypeApiResponse<'getContractors'>> => {
    if (type === FetchType.ADD) {
      this.setLoading(true);
    }

    // Настраиваем количество подгружаемых элементов согласно высоте экрана
    const sizeByInnerHeight = getSizeByInnerHeight(50);

    const payload = {
      nameOrInn: trim(this.scoutName),
      isScout: true,
      size: sizeByInnerHeight,
      page: this.page,
    };

    return this.axios.api
      .getContractors(payload)
      .then(response => {
        if (!response) {
          return Promise.reject(response);
        }

        if (type === FetchType.ADD) {
          this.addScouts(response.content, response.totalPages);
        } else {
          this.updateScouts(response.content, response.totalPages);
        }

        return response;
      })
      .catch(e => {
        if (!e) {
          return Promise.reject(e);
        }
        console.error('fetchScouts e.message', e.message);
        this.addScouts([], 0);

        return Promise.reject(e);
      })
      .finally(() => {
        if (type === FetchType.ADD) {
          this.setLoading(false);
          this.setLoaded(true);
        }
      });
  };

  fetchScout = (
    data: TypeApiRequest<'getContractorById'>
  ): Promise<TypeApiResponse<'getContractorById'>> => {
    this.setLoading(true);

    return this.axios.api
      .getContractorById(data, { omit: ['contractorId'] })
      .then(contractor => {
        this.addScout({
          ...contractor,
          newOwner:
            this.scoutApplications[this.scoutApplications.length - 1]?.userEmail ||
            contractor.ownerEmail ||
            null,
        });

        if (!isEmpty(contractor.logo)) {
          this.onSaveLogo(
            contractor.logo.id,
            contractor.logo.downloadUrl,
            contractor.logo.fileName
          );
        }

        return contractor;
      })
      .catch(e => {
        console.error('fetchScout e.message', e.message);

        return Promise.reject(e);
      })
      .finally(() => {
        this.setLoading(false);
        this.setLoaded(true);
      });
  };

  getScoutWithOwnerEmail = async (data: TypeApiRequest<'getContractorById'>) => {
    await this.fetchScoutApplicationList({
      organizationId: data.contractorId,
      type: 'OWNER',
      status: 'SENT',
    });
    await this.fetchScout(data);
  };

  createScout = (payload: TypeApiRequest<'createContractor'>) => {
    return this.axios.api
      .createContractor(payload)
      .then((id: string) => {
        return id;
      })
      .catch(e => {
        const errorsArray = e.response?.data?.errors;
        const errorData = e.response?.data?.error;

        if (errorsArray) {
          this.setFormValuesErrors(errorsArray);
        } else {
          this.setFormValueError(errorData);
        }

        return Promise.reject(e);
      });
  };

  updateScout = (payload: TypeApiRequest<'updateContractorById'>) => {
    return this.axios.api
      .updateContractorById(payload, { omit: ['contractorId'] })
      .then(() => {
        return true;
      })
      .catch(e => {
        const errorsArray = e.response?.data?.errors;
        const errorData = e.response?.data?.error;

        if (errorsArray) {
          this.setFormValuesErrors(errorsArray);
        } else {
          this.setFormValueError(errorData);
        }

        return Promise.reject(e);
      });
  };

  fetchScoutApplicationList = (
    payload: TypeApiRequest<'getOrganizationApplications'>
  ): Promise<boolean> => {
    return this.axios.api
      .getOrganizationApplications(payload)
      .then(({ content }) => {
        this.scoutApplications = content;
        return true;
      })
      .catch(e => {
        console.error(e);
        return Promise.reject(e);
      });
  };

  setLogoHashId = (id: string) => {
    this.logoHashId = id;
  };

  setLogoHashUrl = (url: string) => {
    this.logoHashUrl = url;
  };

  setLogoHashFileName = (fileName: string) => {
    this.logoHashFileName = fileName;
  };

  onSaveLogo = (id: string, url: string, fileName: string) => {
    this.logoId = id;
    this.logoUrl = url;
    this.logoFileName = fileName;
  };

  onCommitLogo = () => {
    this.onSaveLogo(this.logoHashId, this.logoHashUrl, this.logoHashFileName);
  };

  onResetLogo = () => {
    this.logoHashId = '';
    this.logoHashUrl = '';
    this.logoHashFileName = '';
    this.logoId = '';
    this.logoUrl = '';
    this.logoFileName = '';
  };

  uploadFile = (file: File) => {
    this.logoFile = file;
    this.axios.api
      .uploadFile({
        ownerId: null,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.setLogoHashId(response.id);
    this.setLogoHashUrl(response.uploadUrl);
    this.setLogoHashFileName(this.logoFile.name);

    this.axios.api
      .uploadFileFinish({ data: this.logoFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
  };

  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };

  getUsersScouts = (
    fullName?: string,
    notCurrentUser?: boolean,
    notRoleId?: string,
    notOwnerOrganizationId?: string
  ): Promise<TypeUserScout[]> => {
    return this.axios.api
      .getUsersScouts({ size: 100, fullName, notCurrentUser, notRoleId, notOwnerOrganizationId })
      .then(response => {
        if (response) {
          return response.content;
        } else {
          return Promise.reject(response);
        }
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };

  fetchOptions = () => {
    this.setOptionsLoading(true);

    return Promise.all([this.getUsersScouts(), this.organizationStore.getOrgTypes()])
      .then(([scouts, orgTypes]) => {
        const scoutsOptions = scouts.map(item => ({
          label: item.fullName,
          value: item.id,
        }));

        const orgTypesOptions = orgTypes.map(item => ({
          label: item.OrgType,
          value: item.orgTypeId,
        }));

        this.setScoutsOptions(scoutsOptions);
        this.setOrgTypesOptions(orgTypesOptions);
      })
      .catch(e => {
        return Promise.reject(e);
      })
      .finally(() => {
        this.setOptionsLoading(false);
      });
  };

  resendOwnerInvitation = ({ invitationId }) => {
    return this.axios.api
      .resendOwnerInvitation({ invitationId })
      .then(response => true)
      .catch(error => Promise.reject(error));
  };
}
