import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  verificationHash: string;
};

type TypeResponse = {
  token: string;
};

export const verifyResetEmailToken: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/auth/verifyEmail?verificationHash=${request.verificationHash}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
