import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  id: string;
  employeeIds: string[];
};

type TypeResponse = {};

export const assignUsersToRole: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: request => `/api/da-profile/access/role/${request.id}/assign`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
