import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

import { useStore } from '../../../../utils';
import { ReactComponent as DATitleSvg } from '../../../../assets/images/da-title.svg';

import { SingUpStore, SignUpStepEnum } from './stores/SingUpStore';
import { FormWrapper, LogoWrapper } from './style';
import { FirstStepSignUp } from './components/FirstStepSignUp';
import { SecondStepSignUp } from './components/SecondStepSignUp';

type SignInPageProps = {
  systemId?: string | undefined;
};

export const SignUpPage: FC<SignInPageProps> = observer(({ systemId }) => {
  const { signUpStep, setSignUpStep } = useStore(SingUpStore);

  const {
    location: { search },
  } = useHistory();

  useEffect(() => {
    const step = Number(new URLSearchParams(search.slice(1)).get('step'));
    if (!isNaN(step)) {
      setSignUpStep(step === 2 ? SignUpStepEnum.SECOND : SignUpStepEnum.FIRST);
    }
  }, [search]);

  const getStepComponent = (v: SignUpStepEnum) => {
    const variants: Record<SignUpStepEnum, any> = {
      [SignUpStepEnum.FIRST]: <FirstStepSignUp />,
      [SignUpStepEnum.SECOND]: <SecondStepSignUp />,
    };

    return (
      <FormWrapper>
        {systemId && systemId.includes('farmlink') ? null : (
          <LogoWrapper>
            <DATitleSvg />
          </LogoWrapper>
        )}
        {variants[v]}
      </FormWrapper>
    );
  };

  return getStepComponent(signUpStep);
});
