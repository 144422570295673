import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';

import { useStore } from '../../../utils';
import { AuthpreloadController } from '../controllers/authpreload.controller';
import { AuthorizationRoutes } from '../routes';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../../shared/styles/dotsLoader';

type Props = {
  hideAuthPreload: () => void;
};

export const AuthPreload: FC<Props> = observer(({ hideAuthPreload }) => {
  const { systemId } = useParams<{ systemId?: string }>();
  const params: any = new URLSearchParams(location.search.substr(1));

  const {
    location: { pathname },
    push,
  } = useHistory();

  const authpreloadController = useStore(AuthpreloadController);

  const redirectToAuth = () => {
    authpreloadController.logout();
    hideAuthPreload();
  };

  const handleCheckUser = async () => {
    authpreloadController.setSystemId(systemId);
    try {
      await authpreloadController.checkIsUserLoginned();
    } catch (e) {
      // Редиректимся в форму логина, если пользователь не авторизован, чистим  токены из localStorage
      redirectToAuth();
      return Promise.reject(e);
    }
  };

  const handleCheckNeedAdditionalInfo = async () => {
    try {
      await authpreloadController.checkIsNoNeedAdditionalInfo();

      // Редиректим в целевой сервис
      await authpreloadController.makeRedirect();
    } catch (e) {
      if (e === 'true') {
        // Редиректим на второй шаг регистрации
        push('/sign_up/?step=2');
        hideAuthPreload();
      }
    }
  };

  useEffect(() => {
    if (params.get('externalInvite')) {
      redirectToAuth();
    }

    if (pathname === AuthorizationRoutes.logout) {
      redirectToAuth();
    } else if (
      pathname === AuthorizationRoutes.Agreement ||
      pathname.includes(AuthorizationRoutes.reset)
    ) {
      (async () => {
        try {
          await handleCheckUser();
          hideAuthPreload();
        } catch (e) {
          throw e;
        }
      })();
    } else {
      (async () => {
        try {
          await handleCheckUser();
          await handleCheckNeedAdditionalInfo();
        } catch (e) {
          throw e;
        }
      })();
    }
  }, []);

  return (
    <SpinnerLoaderWrapper>
      <SpinnerLoader data-test-id="dots-loader">
        <span />
      </SpinnerLoader>
    </SpinnerLoaderWrapper>
  );
});
