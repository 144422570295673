import styled from 'styled-components';

export const MaxWidthLayout = styled.div`
  @media (max-width: 767px) {
    padding: 0 0 68px 0;
  }

  @media (min-width: 768px) {
    padding: 0 12px 12px 12px;
  }

  @media (min-width: 1024px) {
    padding: 0 20px 20px 20px;
  }

  @media (min-width: 1366px) {
    padding-top: 0;
    padding-right: calc(50% - 600px);
    padding-bottom: 20px;
    padding-left: calc(50% - 600px);
  }

  &.header {
    @media (max-width: 767px) {
      padding: 0;
    }

    @media (min-width: 768px) {
      padding: 0 12px;
    }

    @media (min-width: 1024px) {
      padding: 0 20px;
    }

    @media (min-width: 1366px) {
      padding: 0 calc(50% - 600px);
    }
  }
`;
