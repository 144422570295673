import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils/IoC';
import { Axios } from '../../../utils/axios2';

type erqRedirectDataProps = {
  systemId: string;
  redirectUrl?: string;
  scope?: string;
};

@provide.singleton()
export class AuthStore {
  @lazyInject(Axios)
  protected axios: Axios;

  authKey: string;
  systemId: string;
  redirectUrl: string;

  constructor() {
    makeAutoObservable(this);

    this.setDefaultSystemId();
  }

  setDefaultSystemId = () => {
    this.systemId = 'agroid';
    this.redirectUrl = `${window.location.protocol}//${window.location.host}/agroid`;
  };

  setSystemId = (systemId: string) => {
    this.systemId = systemId;
    if (!this.systemId) {
      this.setDefaultSystemId();
    }
  };

  redirectWithAuth = () => {
    console.log('redirectWithAuth');
    if (this.authKey && localStorage.getItem('accessToken')) {
      window.location.href = `${this.redirectUrl}?authKey=${this.authKey}`;
    } else if (localStorage.getItem('accessToken')) {
      window.location.href = this.redirectUrl;
    }
    return null;
  };

  erqRedirectData = (redirectUrl?: string) => {
    if (this.systemId === 'agroid') {
      location.replace(`${window.location.protocol}//${window.location.host}/agroid`);
      return Promise.resolve();
    }
    const query: erqRedirectDataProps = {
      systemId: this.systemId,
    };
    if (redirectUrl) {
      query.redirectUrl = redirectUrl;
    }
    return this.axios.api
      .erqRedirectData(query)
      .then(response => {
        runInAction(() => {
          this.authKey = response.authKey;
          this.redirectUrl = response.redirectUrl;
        });
      })
      .catch((response?: { source: string; title: string }) => {
        if (!response.title) {
          return;
        }
        console.error('response.title', response.title);
      });
  };

  token = (code: string) => {
    return this.axios.api
      .erqToken({ code })
      .then(response => {
        runInAction(() => {
          console.error('erqToken response info:', response);
        });
      })
      .catch((response: { errors?: Array<{ source: string; title: string }> }) => {
        if (!response.errors) {
          return;
        }
        response.errors.forEach(field => {
          console.error('response.errors', field);
        });
      });
  };
}
