import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios } from '../../../../../utils/axios2';
import { getPurePhone } from '../../../../../utils/getPurePhone';
import { SMS_CODE_INCORRECT } from '../../../../../constants/error';
import { Field } from '../../../../../constants/field';
import { SessionStore } from '../../../stores/session.store';

const RESEND_CODE_SECONDS = 60;

@provide.singleton()
export class SignInViaPhoneStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SessionStore)
  protected session: SessionStore;

  needAdditionalInfo = true;

  phone: Field = {
    value: '',
    error: '',
  };
  code: Field = {
    value: '',
    error: '',
  };

  countryCode = '+7';
  operationId = '';

  seconds = RESEND_CODE_SECONDS;
  timer: any;
  isCodeWasValid = false;
  currentState: PhoneSignInState = 'first_step';

  constructor() {
    makeAutoObservable(this);
  }

  setFieldValue = (fieldName: 'phone' | 'code', externalSystemAuth?: boolean) => (
    value: string
  ) => {
    runInAction(() => {
      this[fieldName].value = value;
      if (fieldName === 'code') {
        this.code.error = '';
        if (this.code.value.length === 6) {
          this.verifyCode(externalSystemAuth);
        }
      }
    });
  };

  get isAllowedToRequestCode() {
    return getPurePhone(this.phone.value, this.countryCode).length === 10;
  }

  setTimer = () => {
    this.seconds = RESEND_CODE_SECONDS;
    this.timer = setInterval(() => {
      this.seconds -= 1;

      if (this.seconds === 0) clearInterval(this.timer);
    }, 1000);
  };

  sendCodeToPhone = () => {
    this.axios.api
      .loginByPhone({
        phoneNumber: getPurePhone(this.phone.value, this.countryCode),
        phoneCode: this.countryCode,
      })
      .then(response => {
        runInAction(() => {
          this.operationId = response.operId;
          this.currentState = 'second_step';
          this.setTimer();
        });
      })
      .catch(error => {
        console.error('sendCodeToPhone', error);
      });
  };

  get isVerifyCodeButtonAvailable() {
    return this.code.value.length === 6;
  }

  verifyCode = (externalSystemAuth?: boolean) => {
    if (externalSystemAuth) {
      console.error('by phone verifyCode', externalSystemAuth); // @depricated
    }
    this.axios.api
      .verifySmsCodeV2({
        code: this.code.value,
        operId: this.operationId,
      })
      .then(response => {
        if ((response as any).error) return;
        runInAction(() => {
          this.isCodeWasValid = true;
          this.needAdditionalInfo = response.needAdditionalInfo;
          localStorage.setItem('accessToken', response['access-token']);
          localStorage.setItem('refreshToken', response['refresh-token']);
          if (!this.session.isLoggedIn) {
            this.session.checkIsLoggedIn();
          }
        });
      })
      .catch(() => {
        console.error('error');
        this.code.error = SMS_CODE_INCORRECT;
      });
  };

  reset = () => {
    this.countryCode = '+7';
    runInAction(() => {
      this.phone = {
        value: '',
        error: '',
      };
      this.code = {
        value: '',
        error: '',
      };
      this.isCodeWasValid = false;
      this.currentState = 'first_step';
    });
  };

  setCurrentState = (v: PhoneSignInState) => {
    this.currentState = v;
  };
}

type PhoneSignInState = 'first_step' | 'second_step';
