import { FC } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import Success from '../../../../assets/images/success.svg';

import { ModalStyled, ModalBodyStyled, ModalTitleStyled, IconScg } from './styled';

interface ISuccessModalProps {
  onClose: () => void;
}

export const NewUserModallSuccess: FC<ISuccessModalProps> = ({ onClose }) => {
  return (
    <ModalStyled onClose={onClose} style={{ width: '440px' }}>
      <ModalBodyStyled>
        <Grid>
          <Row justifyContent="center">
            <Col textAlign="center" size={10}>
              <IconScg src={Success} alt="" />
              <ModalTitleStyled data-test-id="modal-dialog-title">
                Сотрудник приглашен
              </ModalTitleStyled>
              <Button isStretched isPrimary size="large" onClick={() => onClose()}>
                Продолжить
              </Button>
            </Col>
          </Row>
        </Grid>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
