import { useMemo, useEffect } from 'react';
import { Button, Input, Password, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { AuthorizationRoutes } from '../../../../routes';
import { useStore } from '../../../../../../utils';
import { AuthStore } from '../../../../stores/auth.store';
import { SessionStore } from '../../../../stores/session.store';
import { SignInViaEmailStore } from '../../stores/sign-in.via-email.store';
import { BackButton } from '../../../../components/BackButton';

import {
  Wrapper,
  HeaderWrapper,
  Heading,
  FormContent,
  FieldWrapper,
  ForgetPasswordWrapper,
  ButtonWrapper,
  FooterWrapper,
  FooterText,
} from './style';

type Props = {
  onChangeType: () => void;
};

export const EmailSignIn = observer(({ onChangeType }: Props) => {
  const {
    email,
    password,
    setFieldValue,
    attemptLogin,
    isFormButtonAvailable,
    reset,
    transferEmail,
  } = useStore(SignInViaEmailStore);
  const { isLoggedIn, checkIsLoggedIn } = useStore(SessionStore);
  const { erqRedirectData, authKey, redirectUrl, redirectWithAuth } = useStore(AuthStore);

  const setters = useMemo(
    () => ({
      setEmail: setFieldValue('email'),
      setPassword: setFieldValue('password'),
    }),
    []
  );

  const handleSumbit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await attemptLogin();
      await checkIsLoggedIn();

      if (response.needAdditionalInfo) {
        history.push(`/sign_up/?step=2&emailForTransfered=${email.value}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const history = useHistory();

  const handleSignUp = () => {
    history.push(AuthorizationRoutes.signUp);
  };

  const handleBackButton = () => {
    reset();
    onChangeType();
  };

  const handleForgetPassword = () => {
    history.push(AuthorizationRoutes.reset);
  };

  useEffect(() => {
    if (isLoggedIn && !transferEmail) {
      erqRedirectData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (authKey && redirectUrl && !transferEmail) {
      redirectWithAuth();
    }
  }, [authKey, redirectUrl]);

  return (
    <Wrapper>
      <BackButton onClick={handleBackButton} />
      <HeaderWrapper>
        <Heading>Вход по e-mail</Heading>
      </HeaderWrapper>
      <FormContent>
        <form onSubmit={handleSumbit}>
          <FieldWrapper>
            <Input
              value={email.value}
              error={email.error}
              onChange={setters.setEmail}
              placeholder="info@mail.com"
              label="E-mail"
              focus={false}
              dataTestId="login-email-input"
            />
          </FieldWrapper>
          <FieldWrapper>
            <Password
              label="Пароль"
              placeholder="Пароль"
              value={password.value}
              onChange={setters.setPassword}
              error={password.error}
              dataTestId="login-password-input"
            />
            <ForgetPasswordWrapper>
              <LinkFarmik
                color="default"
                underlined
                label="Забыли пароль?"
                onClick={handleForgetPassword}
                dataTestId="forgot-password-link"
              />
            </ForgetPasswordWrapper>
          </FieldWrapper>
          <ButtonWrapper>
            <Button
              color="primary"
              type="submit"
              disabled={!isFormButtonAvailable}
              dataTestId="login-byEmail-button"
            >
              Войти
            </Button>
          </ButtonWrapper>
        </form>
      </FormContent>

      <FooterWrapper>
        <FooterText>Не зарегистрированы?</FooterText>
        <LinkFarmik
          dataTestId="login-byEmail-registration-link"
          label="Регистрация"
          color="primary"
          onClick={handleSignUp}
          postfixIcon="arrow_right"
        />
      </FooterWrapper>
    </Wrapper>
  );
});
