import { TypeApiRoute } from '../models/type.api.request';
import { TypeModule } from '../models/module.model';

type TypeRequest = {
  organizationId?: string;
};

type TypeResponse = Array<TypeModule>;

export const getModuleAll: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/access/module/all/?organizationId=${request.organizationId}`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
