import { FC } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import CreatingIcon from '../../../../assets/images/agroid/colored/creating-success.svg';

import { ModalStyled, ModalBodyStyled, ModalTitleStyled, IconScg } from './styled';

export interface ISuccessModalProps {
  onClose: () => void;
}

export const NewOrgModalSuccess: FC<ISuccessModalProps> = ({ onClose }) => {
  return (
    <ModalStyled onClose={onClose} style={{ width: '440px' }}>
      <ModalBodyStyled>
        <IconScg src={CreatingIcon} alt="" />
        <ModalTitleStyled data-test-id="modal-dialog-title">
          Вы успешно создали организацию
        </ModalTitleStyled>

        <Grid>
          <Row justifyContent="center">
            <Col textAlign="center" size={10}>
              <Button isStretched isPrimary size="large" onClick={() => onClose()}>
                Хорошо
              </Button>
            </Col>
          </Row>
        </Grid>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
