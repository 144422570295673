// @ts-nocheck
import React, { FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, DEFAULT_THEME } from '@zendeskgarden/react-theming';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { DefaultTheme } from '@farmlink/farmik-ui';
import moment from 'moment';

import { useStore } from './utils';
import { ConfirmStore } from './modules/agroid/stores';
import GardenTheme from './assets/garden';
import { AuthorizationRouter } from './modules/auth/AuthorizationRouter';
import { AgroidRouter } from './modules/agroid/AgroidRouter';
import { theme } from './assets/theme';
import { ModalContainer } from './modules/agroid/modules/common/containers/configs/modals/ModalContainer';
import pageListData from './modules/agroid/configs/data/pages/pageListData.config';
import { FullScreenPreloader } from './modules/shared/components/FullScreenLoader/FullScreenPreloader';
import { UserInvitePage } from './modules/agroid/pages/UserInvitePage';
import { AgroidRoutes } from './modules/agroid/routes';
import PageNotFound from './modules/shared/components/UI/PageNotFound/PageNotFound';
import 'moment/locale/ru';
moment.locale('ru');

export const App: FC = () => {
  const { createConfirm } = useStore(ConfirmStore);

  const getUserConfirmation = (_, callback) => {
    const goToNextPage = callback.bind(null, true);
    const blockRouting = callback.bind(null, false);
    createConfirm(goToNextPage, blockRouting);
  };

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <HelmetProvider>
        <Helmet>
          <title>Digital Agro | AgroID</title>
        </Helmet>

        <ThemeProvider theme={{ ...GardenTheme(DEFAULT_THEME), ...theme }}>
          <StyledThemeProvider theme={DefaultTheme}>
            <Switch>
              <Route path={AgroidRoutes.PageNotFound} component={PageNotFound} />
              <Route path={AgroidRoutes.Index}>
                <AgroidRouter pageListData={pageListData} />
                <ModalContainer />
              </Route>
              <Route path={AgroidRoutes.ExternalInvite}>
                <UserInvitePage />
              </Route>
              <Route path="/" component={AuthorizationRouter} />
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </StyledThemeProvider>
          <FullScreenPreloader />
        </ThemeProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};
