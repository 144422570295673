import { FC, useState } from 'react';

import HoveredImagePlaceholder from './hoveredImagePlaceholder.svg';
import CameraSvg from './camera.svg';
import UserAvatarSvg from './user.svg';
import {
  Wrapper,
  Avatar,
  AvatarMock,
  AvatarWrapper,
  AvatarMockImgHovered,
  AvatarMockImgWrapper,
  StyledUserAvatarSvg,
  StyledCameraSvg,
  CameraSvgWrapper,
} from './style';
import { ImageUploadDialog } from './ImageUploadDialog';

type AvatarUploaderProps = {
  icon: string;
  fileName: string;
  onUpload: (v: File) => void;
  onCommitImg: () => void;
  onLogoReset: () => void;
};

export const AvatarUploader: FC<AvatarUploaderProps> = ({
  icon,
  fileName,
  onUpload,
  onCommitImg,
  onLogoReset,
}) => {
  const [avatarPreview, setAvatarPreview] = useState('');
  const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);

  const onFileUpload = (file, link) => {
    onUpload(file);
    console.log('link', link);
  };

  const onCommitAvaImage = (link: string) => {
    setAvatarPreview(link);
    setShowImageUploadDialog(false);
    onCommitImg();
  };

  const getAvatarMockImg = () => (
    <AvatarMockImgWrapper>
      <StyledUserAvatarSvg src={UserAvatarSvg} />
      <CameraSvgWrapper>
        <StyledCameraSvg src={CameraSvg} />
      </CameraSvgWrapper>
    </AvatarMockImgWrapper>
  );

  return (
    <Wrapper className={'avatar-wrapper'} data-test-id="avatar-uploader-wrapper">
      <AvatarWrapper
        className={'avatar-preview'}
        onClick={event => {
          event.stopPropagation();
          setShowImageUploadDialog(true);
        }}
        data-test-id="avatar-uploader-wrapper-preview"
      >
        {avatarPreview || icon ? (
          <Avatar src={avatarPreview || icon} data-test-id="avatar-uploader-image" />
        ) : (
          <AvatarMock data-test-id="avatar-uploader-mock">
            {getAvatarMockImg()}
            <AvatarMockImgHovered
              src={HoveredImagePlaceholder}
              alt=""
              data-test-id="avatar-uploader-mock-hovered"
            />
          </AvatarMock>
        )}
      </AvatarWrapper>
      {showImageUploadDialog ? (
        <ImageUploadDialog
          imgUrl={icon}
          fileName={fileName}
          initialAvatarPreview={avatarPreview}
          onClose={() => {
            setShowImageUploadDialog(false);
          }}
          onUpload={onFileUpload}
          onCommitImage={onCommitAvaImage}
          onLogoReset={onLogoReset}
        />
      ) : null}
    </Wrapper>
  );
};
