import { TypeContractor, InitialValues } from '../../../../../api/models/contractor.model';
import { TypeOrganization } from '../../../../../api/models/organization.model';

function getValuesEdit(entity: TypeContractor | TypeOrganization, isScout: boolean): InitialValues {
  return {
    orgTypeId: entity?.type?.orgTypeId || 1,
    logo: {
      downloadUrl: entity?.logo?.downloadUrl || '',
      fileName: entity?.logo?.fileName || '',
      id: entity?.logo?.id || '',
    },
    name: entity?.name || '',
    INN: entity?.INN || '',
    addressJur: entity?.addressJur?.addressfull || '',
    addressFact: entity?.addressFact?.addressfull || '',
    OGRN: entity?.OGRN || '',
    OKVED: entity?.OKVED || '',
    OKPO: entity?.OKPO || '',
    KPP: entity?.KPP || '',
    contact: entity?.contact || '',
    manager: entity?.manager?.id || '',
    email: entity?.email || '',
    phone: entity?.phone || '',
    newOwner: entity?.newOwner || '',
    representativePosition: entity?.representativePosition || '',
    isScout: isScout || entity?.isScout,
    isExperiment: entity?.isExperiment,
  };
}

export { getValuesEdit };
