import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import _ from 'lodash';

import { useStore } from '../../../../../../../../utils';
import { ListingPageStore } from '../../../../stores/configs';
import { ListingPageController } from '../../../../controllers/configs/pages';
import { CardsList, InfinityScroll, WellHeader } from '../../../../../../components';
import {
  // NoDataPlaceholder,
  // NoDataTitle,
  // StyledNoDataIcon,
  WellFilterStyled,
} from '../../../../../../pages/ScoutingPage/pages/styled';
import { WellStyled } from '../../../../../../pages/ScoutingPage/components/ContractorForm/styled';
import { ListingPageFilterContainer } from '../ListingPageFilterContainer';
import searchIconSvg from '../../../../../../../../assets/images/agroid/search.svg';
import { createParamsAsPayload } from '../../../../utils/helpers/configs/pages/createParamsAsPayload';
import { createNoSearchedDataDynamicText } from '../../../../utils/helpers/configs/pages/createNoSearchedDataDynamicText';
import { useCommonState } from '../../../../hooks/useCommonState';
import { CheckAccessStore } from '../../../../../../stores';
import { ProfileStore } from '../../../../../../../auth/stores/profile.store';
import { EListingPageFetchItemListType } from '../../../../controllers/configs/pages/ListingPage/ListingPage.controller';
import { Plug } from '../../../../../../../shared/components/UI/Plug';

import {
  TListingPageContainerProps,
  TListingPageContainerState,
  TListingPageParams,
} from './ListingPageContainer.types';

const ListingPageContainer: FC<TListingPageContainerProps> = observer(
  ({ listingPage: { uniqueKey, handlerList, header, dataList, customData, plugStyle } }) => {
    const listingPageStore = useStore(ListingPageStore);
    const {
      getItemList,
      filter,
      searchValue,
      currentPageNumber,
      totalPageNumber,
      clearStore,
    } = listingPageStore;

    const listingPageController = useStore(ListingPageController);
    const { fetchItemList, selectFilter, removeItem, changePage } = listingPageController;

    const { getUserHasAccessRule, accessRules } = useStore(CheckAccessStore);
    const { user } = useStore(ProfileStore);

    const params = useParams<TListingPageParams>();

    const [clearableFieldKey, setClearableFieldKey] = useState<string | null>(null);

    const [
      { isClickedOnActionButton, isShowedCustomComponent, isNeededToUpdateData, isOwner },
      setState,
    ] = useCommonState<TListingPageContainerState>({
      isClickedOnActionButton: false,
      isShowedCustomComponent: false,
      isNeededToUpdateData: false,
      isOwner: false,
    });

    const throttledFilterRequest = useRef(
      _.throttle(
        (fetchType: EListingPageFetchItemListType) => fetchItemListWithPayload(fetchType),
        700
      )
    );

    useEffect(() => {
      if (Boolean(clearableFieldKey)) {
        const clearableFilter = {
          ...filter,
          [clearableFieldKey]: '',
        };

        selectFilter({
          filter: clearableFilter,
          searchKey: header?.filter?.filterBy?.searchQuery?.key,
        });

        setClearableFieldKey(null);
      }
    }, [clearableFieldKey]);

    useEffect(() => {
      throttledFilterRequest.current(EListingPageFetchItemListType.Add);
    }, [filter]);

    // Следит за обновлением данных
    useEffect(() => {
      if (isNeededToUpdateData) {
        throttledFilterRequest.current(EListingPageFetchItemListType.Add);
        setState({ isNeededToUpdateData: false });
      }
    }, [isNeededToUpdateData]);

    useEffect(() => {
      return () => {
        clearStore();
      };
    }, []);

    // Текст, что появляется, когда не было ничего найдено
    const noDataText: string = useMemo(() => {
      if (!searchValue) {
        return dataList.noDataText;
      }

      const noSearchedDataText = header?.filter?.filterBy?.searchQuery?.noSearchedDataText;

      const searchQueryValue = filter?.[header?.filter?.filterBy?.searchQuery?.key];

      return createNoSearchedDataDynamicText({
        noSearchedDataText,
        searchQueryValue,
      });
    }, [header, filter, searchValue]);

    // Массив кнопок-действий
    const actionList = useMemo(() => {
      if (!header?.actionButtons?.length) {
        return [];
      }

      return header.actionButtons
        .map(actionItem => {
          if (actionItem.type === 'button') {
            return !actionItem.componentProps?.accessRulesForDisplay?.length ||
              actionItem.componentProps?.accessRulesForDisplay?.every(accessRule =>
                getUserHasAccessRule(accessRule)
              )
              ? {
                  ...actionItem,
                  actionName: isOwner ? null : actionItem.actionName,
                  component: isMobile && !isTablet ? IconButton : Button,
                  componentProps: {
                    ...actionItem.componentProps,
                    onClick: (event: MouseEvent): void => {
                      handleActionButtonClick(event);
                    },
                  },
                }
              : null;
          }

          return actionItem;
        })
        .filter(item => Boolean(item));
    }, [header?.actionButtons, isOwner, user, accessRules]);

    // Список элементов с обработчиком клика
    const itemListWithOnClick = useMemo(() => {
      if (dataList.item?.onClick) {
        return getItemList(uniqueKey).map(item => ({
          ...item,
          onClick: (event: MouseEvent): void => {
            event.preventDefault();

            if (dataList.item?.isNeededCustomComponent) {
              setState({
                isShowedCustomComponent: true,
              });
            }

            dataList.item?.onClick?.(item);
          },
        }));
      }

      return getItemList(uniqueKey);
    }, [getItemList(uniqueKey), dataList.item?.onClick, dataList.item?.isNeededCustomComponent]);

    const fetchItemListWithPayload = useCallback(
      async (fetchType: EListingPageFetchItemListType): Promise<void> => {
        await fetchItemList({
          uniqueKey,
          apiMethodName: handlerList.fetchItemList.apiName,
          fetchType,
          size: handlerList.fetchItemList?.rawParams?.size,
          payload: {
            ...handlerList.fetchItemList?.rawParams,
            ...createParamsAsPayload({
              apiParams: handlerList.fetchItemList?.paramsAsPayload,
              params,
            }),
          },
        });
      },
      [params]
    );

    const handleFilterChange = useCallback(
      (filterData): void => {
        selectFilter({
          filter: filterData,
          searchKey: header?.filter?.filterBy?.searchQuery?.key,
        });
      },
      [header?.filter?.filterBy?.searchQuery?.key]
    );

    const handleActionButtonClick = useCallback((event: MouseEvent): void => {
      event.preventDefault();

      setTimeout(() => {
        const contractFileInput = document.getElementById('contract-file-input');
        if (contractFileInput) contractFileInput.click();
      }, 300);

      setState({
        isClickedOnActionButton: true,
        isShowedCustomComponent: true,
      });
    }, []);

    const handleHidingCustomComponent = useCallback((): void => {
      setState({
        isClickedOnActionButton: false,
        isShowedCustomComponent: false,
      });
    }, []);

    const handleUpdateData = useCallback((): void => {
      setState({ isNeededToUpdateData: true });
    }, []);

    const fetchItemListWithPayloadToUpdate = useCallback(async (): Promise<void> => {
      await fetchItemListWithPayload(EListingPageFetchItemListType.Update);
    }, []);

    const handleRemoveItem = useCallback(async (itemId: string) => {
      if (!dataList.itemsWrapper?.removeItemHandler) return false;

      try {
        await removeItem(
          dataList.itemsWrapper?.removeItemHandler.paramAsPayload,
          itemId,
          dataList.itemsWrapper?.removeItemHandler.apiName
        );
        setState({ isNeededToUpdateData: true });
      } catch (e) {
        console.error(e);
      }
    }, []);

    const renderContent = () => {
      if (getItemList(uniqueKey).length) {
        return (
          <>
            <InfinityScroll
              page={currentPageNumber}
              totalPages={totalPageNumber}
              scrollOffset={100}
              onScroll={fetchItemListWithPayloadToUpdate}
              onPageChange={changePage}
            >
              <CardsList
                list={itemListWithOnClick}
                card={dataList.item.Component}
                cardProps={dataList?.cardProps}
                itemProps={{
                  handleRemoveItem: dataList.itemsWrapper?.removeItemHandler
                    ? handleRemoveItem
                    : null,
                }}
                dataTestId={uniqueKey}
              />
            </InfinityScroll>
          </>
        );
      }

      return (
        <Plug
          icon={dataList?.noDataIconUrl || searchIconSvg}
          title={noDataText}
          plugStyle={plugStyle}
        />
      );
    };

    return (
      <>
        <WellStyled>
          <WellHeader title={header.title} actions={actionList} dataTestId={uniqueKey} />
          {header?.filter ? (
            <WellFilterStyled className="listing-filter">
              <ListingPageFilterContainer
                onChange={handleFilterChange}
                filter={header?.filter}
                setClearableFieldKey={setClearableFieldKey}
                searchQueryValue={filter?.[header?.filter?.filterBy?.searchQuery?.key] || ''}
              />
            </WellFilterStyled>
          ) : null}
        </WellStyled>

        {renderContent()}

        {isShowedCustomComponent && customData?.CustomComponent ? (
          <customData.CustomComponent
            isClickedOnActionButton={isClickedOnActionButton}
            handleUpdateData={handleUpdateData}
            handleHidingCustomComponent={handleHidingCustomComponent}
          />
        ) : null}
      </>
    );
  }
);

ListingPageContainer.displayName = 'ListingPageContainer';

export default ListingPageContainer;
