import styled, { StyledComponentBase } from 'styled-components';
import { Grid } from '@zendeskgarden/react-grid';
import { Link } from 'react-router-dom';
import { LG } from '@zendeskgarden/react-typography';

import { ApplicationStatus } from '../../../../api/models/applications.model';

interface IAppCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
  Link?: any;
}

export const AvatarWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: ${({ theme: { space: s } }) => s.ssm};
  background-color: ${({ theme: { colors: c } }) => c.secondaryGray};
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  img,
  svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const AppCardStyled: IAppCardStyled = styled(Grid)`
  text-align: center;
  .logo {
    width: 80;
    height: 60;
    box-sizing: border-box;
    margin: 0 auto;
  }
  p {
    text-align: left;
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
    margin-top: 4px;
  }
`;

AppCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
`;

AppCardStyled.Text = styled.p`
  text-align: left;
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
`;

AppCardStyled.Link = styled(Link)<{ status?: ApplicationStatus }>`
  text-decoration: none !important;
  position: relative;
  display: block;
  padding: 12px;

  &::after {
    content: '';
    position: absolute;
    top: -16px;
    bottom: 0;
    right: 0px;
    height: calc(100% + 32px);
    border-radius: 24px;
    width: 100px;
    clip: rect(0, 10em, 100vh, 95px);

    ${({ theme: { colors: c }, status }) => {
      if (!status) {
        return `background-color: ${c.generalBlack};`;
      }

      if (status === 'SENT') {
        return `background-color: ${c.accentOrange};`;
      }

      if (status === 'ACCEPTED') {
        return `background-color: ${c.accentGreen};`;
      }

      if (status === 'DECLINED') {
        return `background-color: ${c.accentPink};`;
      }

      if (status === 'CANCELLED') {
        return `background-color: ${c.secondaryDarkGray};`;
      }
    }}
  }
`;
