import styled from 'styled-components';
import { Field } from '@zendeskgarden/react-forms';

export const RequireSign = styled.span`
  color: ${({ theme: { colors: c } }) => c.accentPink};
  font-size: 16px;
  font-weight: 600;
  line-height: 12px;

  &:after {
    content: '*';
  }
`;

export const FieldStyled = styled(Field)`
  input {
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
    }

    &.invalid {
      border-color: ${({ theme: { colors: c } }) => c.accentPink};
    }
  }

  &.invalid {
    & > div {
      border: 1px solid ${({ theme: { colors: c } }) => c.accentPink};
    }
  }
`;

export const imaskStyles = {
  appearance: 'none',
  // transition: 'border-color 0.25s ease-in-out,box-shadow 0.1s ease-in-out,background-color 0.25s ease-in-out,color 0.25s ease-in-out,z-index 0.25s ease-in-out',
  width: '100%',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  fontFamily: 'inherit',
  padding: '0.7142857142857143em 0.8571428571428571em',
  minHeight: '40px',
  lineHeight: '1.2857142857142858',
  fontSize: '14px',
  color: '#2f3941',
  border: '1px solid #E9E6F0',
  backgroundColor: '#FAF9FD',
  borderRadius: '12px',
};

export const MediaInputStyles = {
  border: 'none',
  backgroundColor: 'none',
  borderRadius: '0',
};
