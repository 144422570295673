import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-top: -24px;
`;

export const LogoWrapper = styled.div`
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1366px) {
    margin-bottom: 52px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 60px;
  }
`;
