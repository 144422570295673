import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${props => props.theme.Colors.generalLight};
  overflow: hidden;
  padding-top: 44px;
  padding-bottom: 28px;

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #e9e6f0;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  }
`;

export const TopLine = styled.div`
  height: 4px;
  width: 82.4px;
  background: ${props => props.theme.Colors.accentPositive};
  top: 0;
  left: 0;
  position: absolute;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 0.5px solid #e9e6f0;

  padding-bottom: 24px;
  margin-bottom: 20px;
`;

export const PhoneWrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

export const CodeWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;

  & > input {
    letter-spacing: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const CodeRepeatMock = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.Colors.secondaryDark};
  margin: 0;
`;

export const CodeDescription = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.Colors.secondaryDark};
  margin: 0;
`;

export const CodeSendAgain = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${props => props.theme.Colors.accentPositive};
`;

export const FormContent = styled.div`
  border-bottom: 0.5px solid #e9e6f0;
  padding-bottom: 27px;
  margin-bottom: 26px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const FooterText = styled.p`
  color: ${props => props.theme.Colors.secondaryDark};
  margin: 0;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
