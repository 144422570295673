import React, { FC, HTMLAttributes, SVGProps } from 'react';
import { Button } from '@zendeskgarden/react-buttons';

import { ServiceCardStyled } from './styled';

export interface ServiceCard extends HTMLAttributes<HTMLDivElement> {
  id: string;
  name: string;
  nameSvg?: FC<SVGProps<SVGSVGElement>>;
  icon?: FC<SVGProps<SVGSVGElement>>;
  text: string;
  pathname?: string;
  dataTestId?: string;
  onClick?: () => void;
}

export const ServiceCard: FC<ServiceCard> = ({
  id,
  name,
  nameSvg: NameSvg,
  icon: Icon,
  text,
  pathname,
  dataTestId,
  onClick,
}) => {
  return (
    <ServiceCardStyled key={id} data-test-id={dataTestId && `${dataTestId}-card-item`}>
      <ServiceCardStyled.Title tag="h2" data-test-id={dataTestId && `${dataTestId}-card-title`}>
        {Icon && <Icon className="logo" data-test-id={dataTestId && `${dataTestId}-card-logo`} />}
        {NameSvg ? <NameSvg data-test-id={dataTestId && `${dataTestId}-card-name`} /> : name}
      </ServiceCardStyled.Title>
      <ServiceCardStyled.Text data-test-id={dataTestId && `${dataTestId}-card-text`}>
        {text}
      </ServiceCardStyled.Text>
      {onClick ? (
        <ServiceCardStyled.Action
          onClick={onClick}
          data-test-id={dataTestId && `${dataTestId}-card-action`}
        >
          <Button isPrimary data-test-id={dataTestId && `${dataTestId}-card-button`}>
            Перейти
          </Button>
        </ServiceCardStyled.Action>
      ) : (
        <ServiceCardStyled.Link
          to={{ pathname }}
          target="_blank"
          data-test-id={dataTestId && `${dataTestId}-card-action`}
        >
          <Button isPrimary data-test-id={dataTestId && `${dataTestId}-card-button`}>
            Перейти
          </Button>
        </ServiceCardStyled.Link>
      )}
    </ServiceCardStyled>
  );
};
