import { FC, useState, useEffect, HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { isEmpty } from 'lodash';

import { useStore } from '../../../../utils';
import { ReactComponent as ContactSvg } from '../../../../assets/images/agroid/contact.svg';
import { OrganizationStore } from '../../stores/organization.store';
import { UserDetailsModal, BlockConfirmModal, UnBlockConfirmModal } from '../';

import { UsersBlockWrapper, UserBodyStyled, NoDataStyled, LoadderWrapper } from './styled';
import { UserRow } from './UserRow';

export interface RoleUsersProps extends HTMLAttributes<HTMLDivElement> {
  orgId: string;
  roleId: string;
}

export const RoleUsers: FC<RoleUsersProps> = observer(({ orgId, roleId }) => {
  const { isLoading, setFilter, getOrganizationUsers, usersList = [] } = useStore(
    OrganizationStore
  );
  const [userId, setUserId] = useState<string>(null);
  const [isVisible, setVisible] = useState<boolean | string>(false);
  const [isBlockConfirmationOpen, setIsBlockConfirmationOpen] = useState<boolean | string>(false);
  const [isUnBlockConfirmationOpen, setIsUnBlockConfirmationOpen] = useState<boolean | string>(
    false
  );

  const getDataTestId = (
    name: string,
    dynamicValue?: string | number
  ): { 'data-test-id': string } => {
    return {
      'data-test-id': `role-user-${name}${dynamicValue ? `-${dynamicValue}` : ''}`,
    };
  };

  const handleUserDetails = id => {
    // todo скрыл для демо
    setVisible(true);
    setUserId(id);
  };

  useEffect(() => {
    setFilter({
      organizationID: orgId,
      roleId,
    });
    getOrganizationUsers();
  }, [orgId, roleId]);

  if (isLoading) {
    return (
      <LoadderWrapper>
        <Skeleton height="12px" />
        <Skeleton height="12px" />
        <Skeleton height="12px" />
      </LoadderWrapper>
    );
  }

  return (
    <UsersBlockWrapper className="role-user-wrapper" {...getDataTestId('wrap')}>
      <UserBodyStyled {...getDataTestId('name')}>
        {!isEmpty(usersList) ? (
          usersList.map(user => <UserRow key={user.id} {...user} onClick={handleUserDetails} />)
        ) : (
          <NoDataStyled
            height="50vh"
            icon={ContactSvg}
            message="Вы ещё не добавили сотрудников на эту роль"
          />
        )}

        {isVisible && (
          <UserDetailsModal
            title="Сотрудник"
            userId={userId as string}
            orgId={orgId}
            onClose={result => {
              if (result === 'blockConfirm') {
                setIsBlockConfirmationOpen(userId);
              }

              if (result === 'unblockConfirm') {
                setIsUnBlockConfirmationOpen(userId);
              }

              setVisible(false);
            }}
            onSuccessedRoleChanging={() => {
              getOrganizationUsers();
              setVisible(false);
            }}
          />
        )}

        {isBlockConfirmationOpen && (
          <BlockConfirmModal
            userId={userId as string}
            orgId={orgId}
            onClose={() => {
              setIsBlockConfirmationOpen(false);
              getOrganizationUsers();
            }}
          />
        )}

        {isUnBlockConfirmationOpen && (
          <UnBlockConfirmModal
            userId={userId as string}
            onClose={() => {
              setIsUnBlockConfirmationOpen(false);
              getOrganizationUsers();
            }}
          />
        )}
      </UserBodyStyled>
    </UsersBlockWrapper>
  );
});
