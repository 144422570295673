import { EModalType, ModalContainer, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { ApplicationStatus } from '../../../../api/models/applications.model';
import { useStore } from '../../../../utils/IoC';
import { InviteStore } from '../../../auth/pages/invite/stores/invite.store';
import { AuthorizationRoutes } from '../../../auth/routes';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { EModalSize } from '../../modules/common/containers/configs/modals/Modal/styles';
import { AgroidRoutes } from '../../routes';
import { MyApplicationStore } from '../../stores';

import { ReactComponent as BackgroundSvg } from './assets/background.svg';
import { InviteNotAuthStatusSent } from './modals/InviteNotAuthStatusSent/InviteNotAuthStatusSent';
import { BackgroundBlock, NotConfirmedEmailDescr } from './style';

export enum UserInviteModalType {
  INVITE_NOT_AUTH_STATUS_SENT = 'inviteNotAuthStatusSent',
  INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC = 'inviteNotAuthStatusAccDecCanc',
  INVITE_AUTH_STATUS_SENT = 'inviteAuthStatusSent',
  INVITE_AUTH_STATUS_ACC_DEC_CANC = 'inviteNotAuthStatusAccDecCanc',
  LOGGED_BY_ANOTHER_USER = 'loggedByAnotherUser',
  NOT_CONFIRMED_EMAIL = 'notConfirmedEmail',
}

export const UserInvitePage: FC<{}> = observer(() => {
  const location = useLocation();
  const { fetchProfile, user } = useStore(ProfileStore);
  const { acceptUserInvite, declineUserInvite } = useStore(MyApplicationStore);
  const history = useHistory();

  const {
    fetchOrganizationInfo,
    userId,
    status,
    organizationName,
    logoUrl,
    invitationId,
    orgType,
    inviteEmail,
    inviteToken,
    isLoading,
    setInviteInfo,
    clearInviteInfo,
  } = useStore(InviteStore);

  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const queryParamStr = '?externalInvite=';

  const invitefullUrl = history.location.pathname + history.location.search;

  const isLogged = localStorage.getItem('accessToken');

  useEffect(() => {
    if (location.search.includes(queryParamStr)) {
      const token = location.search.substr(queryParamStr.length);
      if (inviteToken !== token) {
        fetchOrganizationInfo(token);
      }
      if (isLogged) {
        fetchProfile();
      }
    }
  }, []);

  useEffect(() => {
    if (!isLoading && status) {
      if (isLogged && user && user.email?.emailStatus?.statusId === 'REQUEST_SENT') {
        setInviteInfo({
          invitefullUrl,
          userId,
          token: inviteToken,
        });
        registerModalList(
          [
            {
              name: UserInviteModalType.NOT_CONFIRMED_EMAIL,
              title: 'Для доступа к организациям требуется подтвержденный e-mail',
              description: {
                value: () => (
                  <NotConfirmedEmailDescr>
                    Перейдите в профиль для подтверждения e-mail
                  </NotConfirmedEmailDescr>
                ),
              },
              type: EModalType.Warning,
              id: UserInviteModalType.NOT_CONFIRMED_EMAIL,
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Перейти в профиль',
                color: 'primary',
                handler: () => {
                  history.push(AgroidRoutes.Profile);
                },
              },
            },
          ],
          UserInviteModalType.NOT_CONFIRMED_EMAIL
        );
        openModalByModalId(UserInviteModalType.NOT_CONFIRMED_EMAIL);
      } else if (isLogged && user && user.email?.email !== inviteEmail) {
        console.log(user.email?.email);
        console.log(inviteEmail);
        setInviteInfo({
          invitefullUrl,
          userId,
          token: inviteToken,
        });
        registerModalList(
          [
            {
              name: UserInviteModalType.LOGGED_BY_ANOTHER_USER,
              title: 'Авторизуйтесь под e-mail, на который было направлено приглашение',
              type: EModalType.Warning,
              id: UserInviteModalType.LOGGED_BY_ANOTHER_USER,
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Сменить аккаунт',
                color: 'primary',
                handler: () => {
                  history.push(AuthorizationRoutes.logout);
                },
              },
              denyButton: {
                title: 'Остаться',
                color: 'default',
                handler: () => {
                  history.push(AgroidRoutes.Index);
                },
              },
            },
          ],
          UserInviteModalType.LOGGED_BY_ANOTHER_USER
        );
        openModalByModalId(UserInviteModalType.LOGGED_BY_ANOTHER_USER);
      } else if (!isLogged && status === ApplicationStatus.SENT) {
        registerModalList(
          [
            {
              name: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
              title: '',
              type: EModalType.Custom,
              id: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
              children: InviteNotAuthStatusSent,
              dataTestId: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
              styledProps: {
                $modalPaddings: '0',
                $size: EModalSize.Medium,
              },
            },
          ],
          UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT
        );
        openModalByModalId(UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT, {
          orgName: organizationName,
          orgLogo: logoUrl,
          orgType,
        });
      } else if (!isLogged && status !== ApplicationStatus.SENT) {
        registerModalList(
          [
            {
              name: UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC,
              title:
                'Упс! Кажется, приглашение больше не активно. Возможно, отправитель его отменил  ',
              type: EModalType.Warning,
              id: UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC,
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'На главную',
                color: 'primary',
                handler: () => history.push(AgroidRoutes.Index),
              },
            },
          ],
          UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC
        );
        openModalByModalId(UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC);
      } else if (isLogged && status === ApplicationStatus.SENT) {
        registerModalList(
          [
            {
              name: UserInviteModalType.INVITE_AUTH_STATUS_SENT,
              title: `Вас приглашают присоединиться к организации ${orgType} «${organizationName}»`,
              type: EModalType.Before_Success,
              id: UserInviteModalType.INVITE_AUTH_STATUS_SENT,
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Принять',
                color: 'primary',
                dataTestId: 'user-invite-accept',
                handler: async () => {
                  try {
                    await acceptUserInvite(invitationId);
                    history.push(AgroidRoutes.Invites);
                    clearInviteInfo();
                  } catch (e) {
                    console.error(e);
                    window.location.href = invitefullUrl;
                  }
                },
              },
              denyButton: {
                title: 'Отклонить',
                color: 'default',
                dataTestId: 'user-invite-decline',
                handler: async () => {
                  try {
                    await declineUserInvite(invitationId);
                    history.push(AgroidRoutes.Index);
                    clearInviteInfo();
                  } catch (e) {
                    console.error(e);
                    window.location.href = invitefullUrl;
                  }
                },
              },
              additionalAction: {
                title: 'Принять решение позднее',
                dataTestId: 'user-invite-accwpt-later',
                handler: () => {
                  clearInviteInfo();
                  history.push(AgroidRoutes.Index);
                  closeModal();
                },
              },
            },
          ],
          UserInviteModalType.INVITE_AUTH_STATUS_SENT
        );
        openModalByModalId(UserInviteModalType.INVITE_AUTH_STATUS_SENT);
      } else if (isLogged && status !== ApplicationStatus.SENT) {
        registerModalList(
          [
            {
              name: UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC,
              title:
                'Упс! Кажется, приглашение больше не активно. Возможно, отправитель его отменил',
              type: EModalType.Warning,
              id: UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC,
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'На главную',
                color: 'primary',
                handler: () => history.push(AgroidRoutes.Index),
              },
            },
          ],
          UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC
        );
        openModalByModalId(UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC);
      }
    }
  }, [userId, status, isLoading, user]);

  return (
    <BackgroundBlock>
      <ModalContainer />
    </BackgroundBlock>
  );
});
