import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../utils';
import { IModalConfig } from '../../containers/configs/modals/modalConfig';

@provide.singleton()
export class ModalsStore {
  private _uniqueKeyToModal: Map<string, IModalConfig> = new Map<string, IModalConfig>();

  private _activeModal: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getModal = (uniqueKey: string): IModalConfig => {
    return this._uniqueKeyToModal.get(uniqueKey);
  };

  setModal = (uniqueKey: string, modal: IModalConfig): void => {
    this._uniqueKeyToModal.set(uniqueKey, modal);
  };

  hasModalByKey = (uniqueKey: string): boolean => this._uniqueKeyToModal.has(uniqueKey);

  getActiveModal = (): string => this._activeModal;

  setActiveModal = (uniqueKey: string) => {
    this._activeModal = uniqueKey;
  };

  clearModal = (uniqueKey: string): void => {
    this._uniqueKeyToModal.delete(uniqueKey);
  };

  clearUniqueKeyToModal = (): void => {
    this._uniqueKeyToModal.clear();
  };
}
