import { TypeApiRoute } from '../../models/type.api.request';
import { AccessRule } from '../../models/user.model';

type TRequest = {
  organizationId: string;
};

type TResponse = AccessRule[];

export const getAllAvailableActionsForCurrentUser: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: `/api/da-profile/access/action/available`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
