import React, { FC, HTMLAttributes, useMemo, useEffect, useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { useStore } from '../../../../utils';
import { OrganizationsStore } from '../../stores';
import { Layout, SidebarLayout, OrgsMenu, NewOrgModal, NewOrgModalSuccess } from '../../components';

import NoDataIconSvg from './icons/noDataIcon.svg';
import {
  MobileAddOrgButtonWrapper,
  NoDataPlaceholder,
  NoDataTitle,
  StyledNoDataIcon,
} from './styled';
import { OrgsList } from './OrgsList';

export interface OrgsPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const OrgsPage: FC<OrgsPageProps> = observer(() => {
  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const { isLoading, organizationList, fetchOrganizations } = useStore(OrganizationsStore);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const submenu = useMemo(() => {
    return [
      {
        caption: (
          <Button isPrimary onClick={() => setVisible(true)}>
            Новая организация
          </Button>
        ),
        isNewOrgButton: true,
        hasBrandmark: true,
        slug: 'new-organization',
      },
    ];
  }, []);

  return (
    <>
      <SidebarLayout>
        <OrgsMenu submenu={submenu} />
        <Layout>
          {isLoading ? (
            <Well>
              <Skeleton height="24px" />
              <Skeleton height="24px" />
            </Well>
          ) : organizationList.length ? (
            <OrgsList list={organizationList} onAddOrgClick={() => setVisible(true)} />
          ) : (
            <NoDataPlaceholder>
              <StyledNoDataIcon src={NoDataIconSvg} />
              <NoDataTitle>Вы ещё не состоите ни в одной из организаций</NoDataTitle>
              <MobileAddOrgButtonWrapper>
                <Button isPrimary size="large" onClick={() => setVisible(true)}>
                  Новая организация
                </Button>
              </MobileAddOrgButtonWrapper>
            </NoDataPlaceholder>
          )}
        </Layout>
      </SidebarLayout>

      {visible && (
        <NewOrgModal
          onClose={result => {
            setVisible(false);

            if (result === true) {
              setVisibleSuccess(true);
            }
          }}
        />
      )}

      {visibleSuccess && (
        <NewOrgModalSuccess
          onClose={() => {
            setVisibleSuccess(false);
          }}
        />
      )}
    </>
  );
});
