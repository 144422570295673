import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { isMobileOnly } from 'react-device-detect';
import { ModelTransformMode } from 'uniforms';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { EModalType, useModal, Dropdown } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router';

import { SelectField } from '../../../../components/uniforms';
import { useStore, isFormValuesErrors, useQuery } from '../../../../../../utils';
import { ScoutsStore, ConfirmStore } from '../../../../stores';
import { InitialValues } from '../../../../../../api/models/contractor.model';
import { ReactComponent as Alert } from '../../../../../../assets/images/allert.svg';
import { WellHeader } from '../../../../components';
import { AvatarUploader } from '../../../../components/AvatarUploader/AvatarUploader';
import { MergedInputGroup } from '../../../../components/MergedInputGroup/MergedInputGroup';
import { EModalSize } from '../../../../modules/common/containers/configs/modals/Modal/styles';

import { bridge as schema, managerDefaultDropdownConfig } from './schema';
import {
  AddressFactWrapper,
  AddressJurWrapper,
  ContactWrapper,
  PositionWrapper,
  EmailWrapper,
  InnWrapper,
  KPPWrapper,
  LogoRequisitesWrapper,
  LogoWrapper,
  OGRNWrapper,
  OKPOWrapper,
  OKVEDWrapper,
  OrgNameWrapper,
  OrgTypeWrapper,
  PhoneWrapper,
  RequisitesWrapper,
  NewOwnerWrapper,
  ScoutWrapper,
  ExperimentWrapper,
  ErrorsFieldWrapper,
  ErrorValueField,
  WellStyled,
  WellBodyStyled,
  ButtonStyledSubmit,
  ButtonStyledCancel,
  ResendInviteTooltip,
  ResendInviteTooltipWrapper,
  ResendInviteTooltipTitle,
  ResendInviteTooltipAction,
} from './styled';

interface Option {
  label: string;
  value: string;
}

interface Props {
  title: string;
  values: InitialValues;
  orgTypesOptions: Option[];
  scoutsOptions: Option[];
  onSubmit: (values: InitialValues) => void;
  onCancel: () => void;
  buttonSubmitTitle?: string;
  buttonCancelTitle?: string;
  type?: 'create' | 'edit';
  dataTestId: string;
}

type ModelProps = {
  [key: string]: any;
};

export const ContractorForm: FC<Props> = observer(
  ({
    title,
    values,
    orgTypesOptions,
    scoutsOptions,
    onSubmit,
    onCancel,
    buttonSubmitTitle = 'Сохранить данные',
    buttonCancelTitle = 'Отменить',
    type = 'create',
    dataTestId,
  }) => {
    const {
      uploadFile,
      onCommitLogo,
      onResetLogo,
      formValuesErrors,
      setFormValueError,
      logoId,
      logoUrl,
      logoFileName,
      scoutApplications,
      resendOwnerInvitation,
    } = useStore(ScoutsStore);

    const [errorOGRN, setErrorOGRN] = useState(false);
    const [errorOKVED, setErrorOKVED] = useState(false);
    const [errorOKPO, setErrorOKPO] = useState(false);

    const { setIsChanged, addItemToDirtyInputSet, clearDirtyInputSet, dirtyInputSet } = useStore(
      ConfirmStore
    );

    const [showResendInviteModal, setShowResendInviteModal] = useState(false);

    const { search } = useLocation();
    const query = useQuery(search);
    const isNew = query.get('isNew');

    const { registerModalList, openModalByModalId, closeModal } = useModal();

    const invitationId = useMemo(() => {
      return scoutApplications[0] ? scoutApplications[0].invitationId : null;
    }, [scoutApplications]);

    const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
      setErrorOGRN(Boolean(model.OGRN.length) && model.OGRN.length !== 13);
      setErrorOKVED(
        Boolean(model.OKVED.length) && (model.OKVED.length < 2 || model.OKVED.length > 8)
      );
      setErrorOKPO(Boolean(model.OKPO.length) && model.OKPO.length !== 8);

      const resultModel = {
        ...model,
        logo: {
          ...model.logo,
          downloadUrl: logoUrl,
          fileName: logoFileName,
          id: logoId,
        },
      };

      // Не универсальное решение. Вызывает баги в связи с поздним сравнением.
      // setIsChanged(!isEqual(values, resultModel));

      setIsChanged(Boolean(dirtyInputSet.size));

      if (invitationId) {
        setFormValueError({
          source: 'newOwner',
          title: 'Владелец организации еще не принял приглашение',
        });
      }

      return resultModel;
    };

    const handleResendOwnerInvitation = async inviteId => {
      try {
        await resendOwnerInvitation({ invitationId: inviteId });
        registerModalList(
          [
            {
              name: 'resendOwnerInviteModalSuccess',
              title: 'Приглашение выслано на указанный e-mail',
              type: EModalType.Success,
              id: 'resendOwnerInviteModalSuccess',
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Продолжить',
                color: 'primary',
                handler: () => {
                  setShowResendInviteModal(false);
                  closeModal();
                },
              },
            },
          ],
          'resendOwnerInviteModalSuccess'
        );
        openModalByModalId('resendOwnerInviteModalSuccess');
      } catch (e) {
        console.error(e);
        registerModalList(
          [
            {
              name: 'resendOwnerInviteModalFail',
              title: 'Ошибка отправки приглашения на указанный e-mail',
              type: EModalType.Warning,
              id: 'resendOwnerInviteModalFail',
              styledProps: {
                $size: EModalSize.Medium,
              },
              successButton: {
                title: 'Продолжить',
                color: 'secondary',
                handler: () => {
                  setShowResendInviteModal(false);
                  closeModal();
                },
              },
            },
          ],
          'resendOwnerInviteModalFail'
        );
        openModalByModalId('resendOwnerInviteModalFail');
      }
    };

    const renderResendInviteTooltip = () => {
      return (
        <ResendInviteTooltipWrapper>
          <ResendInviteTooltip onClick={event => event.stopPropagation()}>
            <ResendInviteTooltipTitle>
              Владелец организации сменится после того как примет приглашение в организацию
            </ResendInviteTooltipTitle>
            <ResendInviteTooltipAction onClick={() => handleResendOwnerInvitation(invitationId)}>
              Отправить еще раз
            </ResendInviteTooltipAction>
          </ResendInviteTooltip>
        </ResendInviteTooltipWrapper>
      );
    };

    const handleFormChange = (key: string) => {
      addItemToDirtyInputSet(key);
    };

    useEffect(() => {
      setFormValueError({ source: 'newOwner', title: '' });
      return clearDirtyInputSet();
    }, []);

    const [formRef, setFormRef] = useState(null);

    const managerDropdownProps = useMemo(() => {
      return {
        ...managerDefaultDropdownConfig,
        field: {
          ...managerDefaultDropdownConfig.field,
          defaultValue: scoutsOptions?.find(({ value }) => value === values.manager),
          onChange: args => {
            formRef.change('manager', args);
          },
        },
        body: {
          optionList: scoutsOptions,
        },
      };
    }, [formRef]);

    return (
      <div onClick={() => setShowResendInviteModal(false)}>
        <AutoForm
          schema={schema}
          ref={ref => {
            console.log(ref);
            if (ref && !formRef) {
              setFormRef(ref);
            }
          }}
          model={values}
          onSubmit={onSubmit}
          modelTransform={handleModelTransform}
          validate="onChange"
          onChange={handleFormChange}
        >
          <WellStyled>
            <WellHeader title={title} dataTestId={dataTestId} />
            <WellBodyStyled data-test-id={`${dataTestId}-form`}>
              <LogoRequisitesWrapper data-test-id={`${dataTestId}-form-logo-wrapper`}>
                <LogoWrapper className="logo-wrapper">
                  <AvatarUploader
                    icon={logoUrl}
                    fileName={logoFileName}
                    onUpload={uploadFile}
                    onCommitImg={() => {
                      onCommitLogo();
                    }}
                    onLogoReset={onResetLogo}
                  />
                </LogoWrapper>
                <RequisitesWrapper data-test-id={`${dataTestId}-form-wrapper`}>
                  <OrgNameWrapper data-test-id={`${dataTestId}-form-org-name-wrapper`}>
                    <MergedInputGroup label="Название организации" required validateField="name">
                      <OrgTypeWrapper>
                        <AutoField
                          name="orgTypeId"
                          options={orgTypesOptions}
                          label={false}
                          isInGroupLeft={true}
                        />
                      </OrgTypeWrapper>
                      <AutoField name="name" label={false} />
                    </MergedInputGroup>
                    <ErrorsFieldWrapper data-test-id={`${dataTestId}-form-org-name-error`}>
                      <ErrorField name="orgTypeId" />
                      <ErrorField name="name" />
                    </ErrorsFieldWrapper>
                  </OrgNameWrapper>
                  <InnWrapper data-test-id={`${dataTestId}-form-inn`}>
                    <AutoField name="INN" />
                    <ErrorField name="INN" />
                    {isFormValuesErrors('INN', formValuesErrors) && (
                      <ErrorValueField>{formValuesErrors.INN}</ErrorValueField>
                    )}
                  </InnWrapper>
                  <AddressJurWrapper data-test-id={`${dataTestId}-form-addressJur`}>
                    <AutoField name="addressJur" />
                    <ErrorField name="addressJur" />
                  </AddressJurWrapper>
                  <AddressFactWrapper data-test-id={`${dataTestId}-form-addressFact`}>
                    <AutoField name="addressFact" />
                    <ErrorField name="addressFact" />
                  </AddressFactWrapper>
                  <OGRNWrapper data-test-id={`${dataTestId}-form-OGRN`}>
                    <AutoField name="OGRN" />
                    {errorOGRN && (
                      <ErrorValueField>ОГРН должен быть длиной 13 знаков</ErrorValueField>
                    )}
                  </OGRNWrapper>
                  <OKVEDWrapper data-test-id={`${dataTestId}-form-OKVED`}>
                    <AutoField name="OKVED" />
                    {errorOKVED && (
                      <ErrorValueField>ОКВЭД должен быть не менее 2 знаков</ErrorValueField>
                    )}
                  </OKVEDWrapper>
                  <OKPOWrapper data-test-id={`${dataTestId}-form-OKPO`}>
                    <AutoField name="OKPO" />
                    {errorOKPO && (
                      <ErrorValueField>ОКПО должен быть длиной 8 знаков</ErrorValueField>
                    )}
                  </OKPOWrapper>
                  <KPPWrapper data-test-id={`${dataTestId}-form-KPP`}>
                    <AutoField name="KPP" />
                    <ErrorField name="KPP" />
                    {isFormValuesErrors('KPP', formValuesErrors) && (
                      <ErrorValueField>{formValuesErrors.KPP}</ErrorValueField>
                    )}
                  </KPPWrapper>
                  <ContactWrapper data-test-id={`${dataTestId}-form-contact`}>
                    <AutoField name="contact" />
                    <ErrorField name="contact" />
                    {isFormValuesErrors('contact', formValuesErrors) && (
                      <ErrorValueField>{formValuesErrors.contact}</ErrorValueField>
                    )}
                  </ContactWrapper>
                  <PositionWrapper data-test-id={`${dataTestId}-form-representativePosition`}>
                    <AutoField name="representativePosition" />
                    <ErrorField name="representativePosition" />
                  </PositionWrapper>
                  <EmailWrapper data-test-id={`${dataTestId}-form-email`}>
                    <AutoField name="email" />
                    <ErrorField name="email" />
                    {isFormValuesErrors('email', formValuesErrors) && (
                      <ErrorValueField>{formValuesErrors.email}</ErrorValueField>
                    )}
                  </EmailWrapper>
                  <PhoneWrapper data-test-id={`${dataTestId}-form-phone`}>
                    <AutoField name="phone" />
                    <ErrorField name="phone" />
                    {isFormValuesErrors('phoneNumber', formValuesErrors) && (
                      <ErrorValueField>{formValuesErrors.phoneNumber}</ErrorValueField>
                    )}
                  </PhoneWrapper>
                  <ScoutWrapper data-test-id={`${dataTestId}-form-scout`}>
                    <AutoField name="isScout" disabled={type === 'create' || isNew} />
                    <ErrorField name="isScout" />
                  </ScoutWrapper>
                  {/* <ExperimentWrapper data-test-id={`${dataTestId}-form-experiment`}>
                    <AutoField name="isExperiment" disabled={type === 'create' || isNew} />
                    <ErrorField name="isExperiment" />
                    </ExperimentWrapper> */}
                </RequisitesWrapper>
              </LogoRequisitesWrapper>
            </WellBodyStyled>
          </WellStyled>
          {showResendInviteModal && renderResendInviteTooltip()}
          <WellStyled>
            <WellHeader title="Владелец организации" dataTestId={`${dataTestId}-new-owner`} />
            <WellBodyStyled data-test-id={`${dataTestId}-new-owner-body`} className="new-owner">
              <NewOwnerWrapper
                className={'new-owner-wrapper'}
                data-test-id={`${dataTestId}-form-new-owner`}
              >
                <AutoField
                  name="newOwner"
                  end={invitationId && Alert}
                  isError={Boolean(invitationId)}
                  onClickEndIcon={event => {
                    event.stopPropagation();
                    setShowResendInviteModal(true);
                  }}
                />
                {isFormValuesErrors('newOwner', formValuesErrors) && (
                  <>
                    <ErrorField name="newOwner" />
                    <ErrorsFieldWrapper>{formValuesErrors.newOwner}</ErrorsFieldWrapper>
                  </>
                )}
              </NewOwnerWrapper>
            </WellBodyStyled>
          </WellStyled>
          <WellStyled>
            <WellHeader title="Ответственный" dataTestId={`${dataTestId}-manager`} />
            <WellBodyStyled data-test-id={`${dataTestId}-form-manager`} className="manager">
              <AutoField
                name="manager"
                options={scoutsOptions}
                config={managerDropdownProps}
                isOpenAbove={true}
                component={Dropdown}
                marginBottomForSelectBody={'-20px'}
                placeholder={'Не указан'}
                icons={{
                  clear: {
                    options: {
                      valueAfterCleaning: { value: null, label: null },
                    },
                  },
                }}
              />
              <ErrorField name="manager" />
            </WellBodyStyled>
          </WellStyled>
          <Grid columns={12} style={{ marginTop: '12px' }}>
            <Row>
              {isMobileOnly && (
                <Col xs={6}>
                  <ButtonStyledCancel
                    type="button"
                    isStretched
                    isBasic
                    size="large"
                    onClick={onCancel}
                    data-test-id={`${dataTestId}-form-cancel`}
                  >
                    {!isMobileOnly ? buttonCancelTitle : 'Отменить'}
                  </ButtonStyledCancel>
                </Col>
              )}
              <Col xs={6}>
                <ButtonStyledSubmit
                  type="submit"
                  isStretched
                  isPrimary
                  size="large"
                  data-test-id={`${dataTestId}-form-save`}
                >
                  {isNew ? 'Сохранить данные' : buttonSubmitTitle}
                </ButtonStyledSubmit>
              </Col>
            </Row>
          </Grid>
        </AutoForm>
      </div>
    );
  }
);
