import React, { FC, HTMLAttributes, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { Prompt } from 'react-router-dom';

import { ProfileForm } from '../../components';
import { AgroidRoutes } from '../../routes';
import { useStore } from '../../../../utils/IoC';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { CompetentionWidget } from '../../components/CompetentionWidget/CompetentionWidget';
import { InteresWidget } from '../../components/InteresWidget/InteresWidget';
import { DialogModal } from '../../components/DialogModal/DialogModal';
import { checkIsName } from '../../../../utils';

import {
  MainLayout,
  ProfileStyled,
  ProfileFormHeader,
  ProfileBodyStyled,
  ActionsBlock,
  CancelButton,
  CompetentensionsInterestsBlock,
  MobileActionsBlock,
  MobileCancelButtonWrapper,
  MobileResetPasswordButton,
  MobileSaveProfileWrapper,
  ProfileFormTitle,
  SaveProfile,
} from './style';

export interface ProfilePageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const ProfilePage: FC<ProfilePageProps> = observer(() => {
  const history = useHistory();
  const profileStore = useStore(ProfileStore);

  const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false);
  const [showResetPasswordError, setShowResetPasswordError] = useState(false);
  const [showAction, setShowActions] = useState(false);

  const handleCancelClick = () => {
    history.push(AgroidRoutes.Index);
  };

  const handleSaveProfileClick = async () => {
    try {
      await profileStore.updatePersonalData(profileStore.userForProfileEdit);
      setShowActions(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetPasswordClick = () => {
    profileStore
      .resetUserPassword()
      .then(res => {
        setShowResetPasswordSuccess(true);
      })
      .catch(err => {
        console.error(err);
        setShowResetPasswordError(true);
      });
  };

  return (
    <MainLayout data-test-id="profile-page-main-layout">
      <ProfileStyled>
        <ProfileFormHeader data-test-id="profile-page-profile-header">
          <ProfileFormTitle data-test-id="profile-page-title">Ваши личные данные</ProfileFormTitle>
          {showAction ? (
            <ActionsBlock data-test-id="profile-page-actions-block">
              <CancelButton onClick={handleCancelClick} data-test-id="profile-page-action-cancel">
                Отменить
              </CancelButton>
              <SaveProfile
                onClick={handleSaveProfileClick}
                disabled={
                  !(
                    Boolean(profileStore.userForProfileEdit?.lastName) &&
                    checkIsName(profileStore.userForProfileEdit?.lastName) &&
                    Boolean(profileStore.userForProfileEdit?.firstName) &&
                    checkIsName(profileStore.userForProfileEdit?.firstName) &&
                    Boolean(profileStore.userForProfileEdit?.gender) &&
                    Boolean(profileStore.userForProfileEdit?.dateOfBirth)
                  )
                }
                data-test-id="profile-page-action-save"
              >
                Сохранить
              </SaveProfile>
            </ActionsBlock>
          ) : null}
        </ProfileFormHeader>
        <ProfileBodyStyled data-test-id="profile-page-profile-body">
          <ProfileForm onShowAction={() => setShowActions(true)} />
          <MobileActionsBlock data-test-id="profile-page-mobile-actions-block">
            <MobileCancelButtonWrapper data-test-id="profile-page-mobile-cancel-button-wrapper">
              <Button
                onClick={handleCancelClick}
                type="button"
                color={'default'}
                dataTestId="profile-page-mobile-action-cancel"
              >
                Отменить
              </Button>
            </MobileCancelButtonWrapper>
            <MobileSaveProfileWrapper data-test-id="profile-page-mobile-save-profile-wrapper">
              <Button
                onClick={handleSaveProfileClick}
                type="button"
                color={'primary'}
                disabled={
                  !(
                    Boolean(profileStore.userForProfileEdit?.lastName) &&
                    checkIsName(profileStore.userForProfileEdit?.lastName) &&
                    Boolean(profileStore.userForProfileEdit?.firstName) &&
                    checkIsName(profileStore.userForProfileEdit?.firstName) &&
                    showAction
                  )
                }
                dataTestId="profile-page-mobile-action-save"
              >
                Сохранить
              </Button>
            </MobileSaveProfileWrapper>
            <MobileResetPasswordButton
              onClick={handleResetPasswordClick}
              data-test-id="profile-page-mobile-action-reset-password"
            >
              Сбросить пароль
            </MobileResetPasswordButton>
          </MobileActionsBlock>
        </ProfileBodyStyled>
      </ProfileStyled>

      <CompetentensionsInterestsBlock data-test-id="profile-page-competentensions-interests-widget">
        <CompetentionWidget items={profileStore.user?.expertises} />
        <InteresWidget items={profileStore.user?.interests} />
      </CompetentensionsInterestsBlock>

      {showResetPasswordSuccess ? (
        <DialogModal
          title={'На ваш E-mail была отправлена ссылка на создание нового пароля'}
          status={'success'}
          accept={{
            handler: () => setShowResetPasswordSuccess(false),
            name: 'Хорошо',
            color: 'primary',
          }}
        />
      ) : null}
      {showResetPasswordError ? (
        <DialogModal
          title={'Ошибка выполнения запроса на сброс пароля'}
          status={'warning'}
          accept={{
            handler: () => setShowResetPasswordError(false),
            name: 'Закрыть',
            color: 'primary',
          }}
        />
      ) : null}
    </MainLayout>
  );
});
