import styled from 'styled-components';

import background from './assets/background.png';

export const BackgroundBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
`;

export const NotConfirmedEmailDescr = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
