import { makeAutoObservable } from 'mobx';

import { provide } from '../../../utils';

@provide.singleton()
export class FullscreenPreloaderService {
  constructor() {
    makeAutoObservable(this);
  }

  _isPreloaderOpen: boolean;

  get isPreloaderOpen() {
    return this._isPreloaderOpen;
  }

  displayPreloader = () => {
    this._isPreloaderOpen = true;
  };

  hidePreloader = () => {
    this._isPreloaderOpen = false;
  };
}
