import { useEffect } from 'react';
import { Button, Link as LinkFarmik, Separator } from '@farmlink/farmik-ui';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import { AuthorizationRoutes } from '../../routes';
import { useStore } from '../../../../utils';

import {
  BottomLineWrapper,
  BottomTextDescription,
  BottomTextWrapper,
  ButtonWrapper,
  Description,
  Heading,
  LogoWrapper,
  Logo,
  OrganizationName,
  Wrapper,
} from './style';
import { InviteStore } from './stores/invite.store';
import AvatarMock from './avatar-mock.svg';

export const InvitePage = withRouter(
  observer(({ location, history }: RouteComponentProps) => {
    const store = useStore(InviteStore);
    const { fetchOrganizationInfo, resetInviteStore, organizationName, isLoading } = store;
    useEffect(() => {
      const params: any = new URLSearchParams(location.search.substr(1));
      fetchOrganizationInfo(params.get('externalInvite'));
      return () => {
        resetInviteStore();
      };
    }, [fetchOrganizationInfo, location.search]);

    if (isLoading) {
      return null;
    }

    // if (!isAccept) {
    //   return <Redirect to={AuthorizationRoutes.signIn} />;
    // }

    return (
      <Wrapper data-test-id="invite-wrapper">
        <LogoWrapper data-test-id="invite-logo-wrapper">
          <Logo src={AvatarMock} data-test-id="invite-logo" />
        </LogoWrapper>
        <OrganizationName data-test-id="invite-organization-name">
          {organizationName}
        </OrganizationName>
        <Heading data-test-id="invite-heading">Вас приглашают присоединиться к организации</Heading>
        <Description data-test-id="invite-description">
          Создайте учетную запись AGRO-ID всего за 2 минуты!
        </Description>
        <ButtonWrapper data-test-id="invite-actions-wrapper">
          <Button
            color="primary"
            type="button"
            onClick={() => history.push(AuthorizationRoutes.signUp)}
            dataTestId="invite-action-sign-up"
          >
            Регистрация
          </Button>
        </ButtonWrapper>
        <BottomLineWrapper data-test-id="invite-bottom-line-wrapper">
          <Separator />
        </BottomLineWrapper>
        <BottomTextWrapper data-test-id="invite-bottom-text-wrapper">
          <BottomTextDescription data-test-id="invite-bottom-text-description">
            Уже есть учетная запись?
          </BottomTextDescription>
          <LinkFarmik
            onClick={() => history.push(AuthorizationRoutes.signIn)}
            label="Войти"
            color="primary"
            postfixIcon="arrow_right"
            dataTestId="invite-action-sign-in"
          />
        </BottomTextWrapper>
      </Wrapper>
    );
  })
);
