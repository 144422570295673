// @ts-nocheck
import React, { FC, SVGProps, Ref, useRef, useLayoutEffect, useMemo } from 'react';
import { HTMLFieldProps, connectField } from 'uniforms';
import { isEmpty, isFunction } from 'lodash';
// eslint-disable-next-line import/order
import { Label, MediaInput } from '@zendeskgarden/react-forms';
import { VALIDATION } from '@zendeskgarden/react-dropdowns/dist/typings/utils/validation';

import { RequireSign, FieldStyled, MediaInputStyles } from '../styled';

export type MediaTextFieldProps = HTMLFieldProps<
  string,
  HTMLDivElement,
  {
    inputRef?: Ref<HTMLInputElement>;
    start?: FC<SVGProps<SVGSVGElement>>;
    end?: FC<SVGProps<SVGSVGElement>>;
    onClickEndIcon?: () => void;
    hideEndIconEmptyValue?: boolean;
    regexonchange?: RegExp;
    dataTestId?: string;
    isError?: boolean;
    isWithoutValidation?: boolean;
    isHideClearIcon?: boolean;
  }
>;

function MediaText({
  autoComplete,
  disabled,
  id,
  start: StartIconComponent,
  end: EndIconComponent,
  onClickEndIcon,
  hideEndIconEmptyValue = false,
  label,
  name,
  onChange,
  placeholder,
  readOnly,
  type,
  max,
  required,
  error,
  isError,
  value,
  regexonchange,
  dataTestId,
  ...props
}: MediaTextFieldProps) {
  const { field: { uniforms = {} } = {} } = props;
  const ref = useRef<HTMLInputElement>();

  useLayoutEffect(() => {
    const curRef = ref.current;
    curRef.setAttribute('maxlength', String(max));
  });

  const handleChange = event => {
    let newValue = event.target.value;
    if (type === 'number' && newValue.toString().length > max) {
      newValue = value;
    }

    if (regexonchange && regexonchange.test(newValue)) {
      onChange(newValue);
      return;
    }

    if (regexonchange && !regexonchange.test(newValue)) {
      return;
    }

    onChange(newValue);
  };

  const inputProps = {
    autoComplete,
    id,
    name,
    readOnly,
    ref,
    type,
    disabled,
  };

  const getStartIconComponent = () => {
    if (isEmpty(StartIconComponent)) {
      return null;
    }

    return <StartIconComponent data-test-id={'start-icon'} />;
  };

  const getEndIconComponent = (v: string) => {
    if (props?.isHideClearIcon) {
      return null;
    }

    if (isEmpty(EndIconComponent)) {
      return null;
    }

    if (hideEndIconEmptyValue && !v) {
      return null;
    }

    if (isFunction(onClickEndIcon)) {
      return (
        <EndIconComponent
          style={{ cursor: 'pointer' }}
          onClick={onClickEndIcon}
          data-test-id={dataTestId && `${dataTestId}-media-input-end-icon`}
        />
      );
    }

    return <EndIconComponent data-test-id={dataTestId && `${dataTestId}-media-input-end-icon`} />;
  };

  const startIcon = useMemo(() => getStartIconComponent(), []);
  const endIcon = useMemo(() => getEndIconComponent(value), [value]);

  let validation: VALIDATION;

  if (Boolean(error)) {
    validation = 'error';
  }

  return (
    <FieldStyled className={isError ? 'invalid' : ''}>
      {label && (
        <Label data-test-id={dataTestId && `${dataTestId}-media-label`}>
          {label} {Boolean(required) && <RequireSign />}
        </Label>
      )}

      <MediaInput
        {...inputProps}
        className={'styled-media-input'}
        validation={!props?.isWithoutValidation ? validation : null}
        onChange={handleChange}
        placeholder={placeholder || uniforms.placeholder}
        value={value ?? ''}
        start={startIcon}
        end={endIcon}
        style={MediaInputStyles}
        data-test-id={dataTestId && `${dataTestId}-media-input`}
      />
    </FieldStyled>
  );
}

MediaText.defaultProps = { type: 'text' };

export const MediaInputField = connectField<MediaTextFieldProps>(MediaText, { kind: 'leaf' });
