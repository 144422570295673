import { TypeApiRoute } from '../models/type.api.request';
import { TypeOrganization } from '../models/organization.model';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = TypeOrganization;

export const getOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/organizations/${request.organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
