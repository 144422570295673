import { FC, memo } from 'react';
import { Button } from '@farmlink/farmik-ui';

import { IPlugProps } from './Plug.types';
import Styled from './Plug.styles';
import PlugHelpers from './utils/helpers/Plug.helpers';

const Plug: FC<IPlugProps> = ({ icon, title, description, button, plugStyle }) => {
  const { getDataTestId } = PlugHelpers;

  return (
    <Styled.Wrapper {...getDataTestId('wrap')} plugStyle={plugStyle}>
      <Styled.Icon src={icon} {...getDataTestId('icon')} />
      {title ? <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title> : null}
      {description ? (
        <Styled.Description {...getDataTestId('description')}>{description}</Styled.Description>
      ) : null}
      {button ? <Button {...button} dataTestId={'plug-action-button'} /> : null}
    </Styled.Wrapper>
  );
};

Plug.displayName = 'Plug';

export default memo(Plug);
