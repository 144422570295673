import React, { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import { SidebarMenu, ISidebarMenuItem } from '../';
import { useStore } from '../../../../utils';
import { MyApplicationStore } from '../../stores/my.applicaions.store';
import { AgroidRoutes } from '../../routes';

export interface OrgsMenuProps {
  submenu?: any[];
}

export const OrgsMenu: FC<OrgsMenuProps> = observer(({ submenu = [] }) => {
  const { invitations, fetchApplications, resetInvitationsPage } = useStore(MyApplicationStore);
  useEffect(() => {
    resetInvitationsPage();
    fetchApplications();
  }, []);

  const menuItems: ISidebarMenuItem[] = useMemo(
    () => [
      {
        caption: 'Мои организации',
        slug: 'my-organizations',
        link: AgroidRoutes.Organizations,
      },
      {
        caption: 'Приглашения',
        slug: 'invitations',
        link: AgroidRoutes.Invites,
      },
      ...submenu,
    ],
    [invitations]
  );

  return <SidebarMenu items={menuItems} />;
});
