import React, { FC, HTMLAttributes, ReactElement, SVGProps } from 'react';
import { isEmpty } from 'lodash';

import { ControlAccessRulesWrapper } from '../AccessRulesWrapper/ControlAccessRulesWrapper';
import {
  AccessRuleVisibility,
  AccessRuleUserActionName,
  EAccessRuleScoutingName,
} from '../../types';

import { ModalHeaderStyled } from './styled';

export interface IModalHeaderAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
  mode?: AccessRuleVisibility;
  actionName?: AccessRuleUserActionName | EAccessRuleScoutingName;
  dataTestIdAction?: string;
}

export interface IModalHeader extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  actions?: any[];
  children?: ReactElement;
  dataTestId?: string;
}

export const ModalHeader: FC<IModalHeader> = ({
  title,
  actions = [],
  children,
  dataTestId = 'modal',
}) => {
  return (
    <ModalHeaderStyled data-test-id={dataTestId && `${dataTestId}-wrapper`}>
      <ModalHeaderStyled.Container data-test-id={dataTestId && `${dataTestId}-container`}>
        <ModalHeaderStyled.Title data-test-id={dataTestId && `${dataTestId}-title`}>
          {title}
        </ModalHeaderStyled.Title>
        {!isEmpty(actions) && (
          <ModalHeaderStyled.Actions data-test-id={dataTestId && `${dataTestId}-actions`}>
            {actions.map(
              (
                {
                  icon: Icon,
                  caption,
                  component: Component,
                  componentProps,
                  mode,
                  actionName,
                  dataTestIdAction = '',
                },
                index
              ) => (
                <ControlAccessRulesWrapper
                  mode={mode}
                  actionName={actionName}
                  dataTestId={`${dataTestIdAction}-wrapper`}
                  key={`${caption}-${index}`}
                >
                  <Component data-test-id={`${dataTestIdAction}-action`} {...componentProps}>
                    {Icon && <Icon />}
                    {caption}
                  </Component>
                </ControlAccessRulesWrapper>
              )
            )}
          </ModalHeaderStyled.Actions>
        )}
      </ModalHeaderStyled.Container>
      {!isEmpty(children) && (
        <ModalHeaderStyled.Content data-test-id={dataTestId && `${dataTestId}-contant`}>
          {children}
        </ModalHeaderStyled.Content>
      )}
    </ModalHeaderStyled>
  );
};
