import { FC } from 'react';

import { OrgLogo } from '../OrgLogo';

import { UserRowWrapper } from './styled';

export interface UserRowProps {
  userName: string;
  id: string;
  onClick: (userId: string) => void;
  avatar?: any;
  }

const getInitials = userName => {
  return userName
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .substr(0, 2);
};

export const UserRow: FC<UserRowProps> = ({ userName, id, onClick, avatar }) => {
  return (
    <UserRowWrapper key={id} onClick={() => onClick(id)} data-test-id="user-row-wrapper">
      <UserRowWrapper.Avatar data-test-id="user-row-avatar-wrapper" >
        {avatar && avatar.downloadUrl ? <OrgLogo {...avatar} dataTestId="user-row-avatar-img"/> : getInitials(userName)}
      </UserRowWrapper.Avatar>
      <UserRowWrapper.Details data-test-id="user-row-name">{userName}</UserRowWrapper.Details>
      <UserRowWrapper.Icon data-test-id="user-row-icon"/>
    </UserRowWrapper>
  );
};
