import { Children, ReactElement } from 'react';
import { Context, useForm } from 'uniforms';

type DisplayIfProps<T> = {
  children: ReactElement;
  condition: (context: Context<T>) => boolean;
};

export function DisplayIf<T = any>({ children, condition }: DisplayIfProps<T>) {
  const uniforms = useForm<T>();
  return condition(uniforms) ? Children.only(children) : null;
}
