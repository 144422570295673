import React, { FC, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';

import { menuItems, MenuItemProps } from '../';

import { MenuItemText, FooterItemWrapper, FooterWrapper } from './styled';

const MenuItem: FC<MenuItemProps> = ({ name, link, icon: Icon }) => (
  <FooterItemWrapper title={link}>
    <NavLink exact to={link}>
      {Icon ? <Icon /> : null}
      <MenuItemText>{name}</MenuItemText>
    </NavLink>
  </FooterItemWrapper>
);
export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const Footer: FC<FooterProps> = ({ param }) => {
  return (
    <FooterWrapper title={param}>
      {Object.entries(menuItems).map(([key, item]) => (
        <MenuItem key={key} {...item} />
      ))}
    </FooterWrapper>
  );
};
