import styled, { StyledComponentBase } from 'styled-components';
import { Link } from 'react-router-dom';
import { LG } from '@zendeskgarden/react-typography';

interface IOrgCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
  Link?: any;
}

export const OrgCardStyled: IOrgCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;

  .logo {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: ${({ theme: { space: s } }) => s.md} auto;
  }
`;

OrgCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 52px;
  @media (min-width: 768px) {
    -webkit-line-clamp: 2;
    max-height: 32px;
  }
  margin-top: 20px;
`;

OrgCardStyled.Text = styled.p`
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`;

OrgCardStyled.Link = styled(Link)`
  grid-column-start: span 1;
  display: block;
  text-decoration: none !important;
  background: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  padding: 32px 16px 20px;
  height: 188px;
`;
export const OrgCardLogoWrap = styled.div`
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  margin: 0;
`;

export const LogoOrgNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
