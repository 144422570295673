import React, { FC, HTMLAttributes } from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { MD } from '@zendeskgarden/react-typography';
import { observer } from 'mobx-react';

import { ReactComponent as StarIcon } from '../../../../assets/images/agroid/colored/Star.svg';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { useStore } from '../../../../utils/IoC';

import { CompetencyCard } from './CompetencyCard';
import { ExpertiseListWrapper } from './style';

export interface CompetenciesListProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const CompetenciesList: FC<CompetenciesListProps> = observer(({ param }) => {
  const { user } = useStore(ProfileStore);

  return user?.expertises?.length ? (
    <Grid title={param}>
      <ExpertiseListWrapper title={param}>
        {user?.expertises.map(expertise => (
          <CompetencyCard competency={expertise} />
        ))}
      </ExpertiseListWrapper>
    </Grid>
  ) : (
    <Grid title={param}>
      <Row justifyContent="center" alignItems="center">
        <Col textAlign="center">
          <StarIcon />
          <MD>
            У вас ещё нет компетенций, <br />
            но вы можете их добавить прямо сейчас
          </MD>
        </Col>
      </Row>
    </Grid>
  );
});
