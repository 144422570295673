import { createGlobalStyle } from 'styled-components';

import ProximaNovaBoldTTf from '../assets/fonts/ProximaNova-Bold.ttf';
import ProximaNovaBoldEOT from '../assets/fonts/ProximaNova-Bold.eot';
import ProximaNovaBoldWOFF from '../assets/fonts/ProximaNova-Bold.woff';
import ProximaNovaSemiboldTTf from '../assets/fonts/ProximaNova-Semibold.ttf';
import ProximaNovaSemiboldEOT from '../assets/fonts/ProximaNova-Semibold.eot';
import ProximaNovaSemiboldWOFF from '../assets/fonts/ProximaNova-Semibold.woff';
import ProximaNovaRegularTTf from '../assets/fonts/ProximaNova-Regular.ttf';
import ProximaNovaRegularEOT from '../assets/fonts/ProximaNova-Regular.eot';
import ProximaNovaRegularWOFF from '../assets/fonts/ProximaNova-Regular.woff';
import ProximaNovaLightTTf from '../assets/fonts/ProximaNova-Light.ttf';
import ProximaNovaLightEOT from '../assets/fonts/ProximaNova-Light.eot';
import ProximaNovaLightWOFF from '../assets/fonts/ProximaNova-Light.woff';
import ProximaNovaBlackTTF from '../assets/fonts/ProximaNova-Black.ttf';
import ProximaNovaBlackEOT from '../assets/fonts/ProximaNova-Black.eot';
import ProximaNovaBlackWOFF from '../assets/fonts/ProximaNova-Black.woff';

export default createGlobalStyle`
  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaRegularEOT});
    src: url(${ProximaNovaRegularEOT}) format('embedded-opentype'),
    url(${ProximaNovaRegularWOFF}) format('woff'),
    url(${ProximaNovaRegularTTf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaBlackEOT});
    src: url(${ProximaNovaBlackEOT}) format('embedded-opentype'),
    url(${ProximaNovaBlackWOFF}) format('woff'),
    url(${ProximaNovaBlackTTF}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaSemiboldEOT});
    src: url(${ProximaNovaSemiboldEOT}) format('embedded-opentype'),
    url(${ProximaNovaSemiboldWOFF}) format('woff'),
    url(${ProximaNovaSemiboldTTf}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaLightEOT});
    src: url(${ProximaNovaLightEOT}) format('embedded-opentype'),
    url(${ProximaNovaLightWOFF}) format('woff'),
    url(${ProximaNovaLightTTf}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaBoldEOT});
    src: url(${ProximaNovaBoldEOT}) format('embedded-opentype'),
    url(${ProximaNovaBoldWOFF}) format('woff'),
    url(${ProximaNovaBoldTTf}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`;
