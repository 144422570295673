import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Step = styled.p`
  font-weight: bold;
  font-size: 36px;
  line-height: 16px;
  color: ${props => props.theme.Colors.generalDark};

  & > span {
    margin-left: 5px;
    color: ${props => props.theme.Colors.secondaryDark};
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
`;
