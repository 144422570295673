export enum TypeUserRoleEnum {
  OWNER = 'Владелец',
  EMPLOYEE = 'Сотрудник',
  MANAGER = 'Менеджер',
}

export type TypeUserRoleInfo = {
  id: string;
  organizationId: string;
  name: string;
  isOwner: boolean;
  isDefault: boolean;
  creationDate: string;
  isScoutForUser?: boolean;
  isScoutForOrganization?: boolean;
};

export type TypeUserRole = TypeUserRoleInfo;
export type TypeUserStatus = 'ACTIVE' | 'FIRED';

export const TypeUserStatusLabels = {
  ACTIVE: 'Активен',
  FIRED: 'Исключен',
};

export type GenderType = {
  genderEnum: string;
  genderShort: string;
  genderName: string;
};

export type UserInfoType = {
  id: string;
  gender?: GenderType;
  lastName?: string;
  firstName?: string;
  secondName?: string;
  dateOfBirth?: string;
  phone: {
    phoneNumber: string;
    phoneNumberCode: string;
    phoneNumberStatus: {
      statusId?: string;
      statusName: string;
    };
  };
  roleInfo: TypeUserRole;
  email?: {
    email: string;
    emailStatus: {
      statusId?: string;
      statusName: string;
    };
  };
  interests: Array<UserInterestType>;
  expertises: Array<UserExpertiseType>;
  specialization?: {
    id: number;
    name: string;
    priority?: number;
    description: string;
  };
  publicId: string;
  avatar?: {
    downloadUrl: string;
    fileContentType: string;
    fileName: string;
    fileSize: number;
    id: string;
  };
  scout: boolean;
};

export type UserExpertiseType = {
  expertiseId: string;
  expertiseName: string;
  confirmations?: number;
  confirmedByMe?: boolean;
};

export type UserInterestType = {
  interestId: string;
  interestName: string;
};

export type TypeUser = {
  roleInfo: TypeUserRole;
  email?: string;
  status: TypeUserStatus;
  userName: string;
  birthDate?: string;
  id: string;
  organizationJoinDate: string;
  phoneNumber: string;
  employeeId?: any;
};

export interface AccessRule {
  id: string;
  code: string;
  name: string;
  isScoutForOrganization?: boolean;
  isScoutForUser?: boolean;
}
