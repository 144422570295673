import { isMobile, isTablet } from 'react-device-detect';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import { OrganizationStatusLabels } from '../../../../../../../../api/models/applications.model';
import { AppCard } from '../../../../../../components';
import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';
import { AccessRuleUserActionName, AccessRuleVisibility } from '../../../../../../types';
import { InvitesModalContainer } from '../../../../../common/containers/ListingPage/InvitesModalContainer';
import { createListingPage } from '../../../../../common/utils/helpers/configs/pages/createListingPage';
import noAppData from '../../../../../common/assets/images/logos/no-applications-50x50.svg';

const filters =
  isMobile && !isTablet
    ? {
        searchQuery: {
          key: 'fullName',
          required: true,
          noSearchedDataText: 'Приглашения не найдены. Попробуйте изменить условия поиска',
          placeholder: 'Поиск по ФИО',
          isClearable: true,
        },
      }
    : {
        searchQuery: {
          key: 'fullName',
          required: true,
          noSearchedDataText: 'Приглашения не найдены. Попробуйте изменить условия поиска',
          placeholder: 'Поиск по ФИО',
          isClearable: true,
        },
        status: {
          key: 'status',
          optionList: OrganizationStatusLabels,
        },
        role: {
          key: 'roleId',
        },
      };

const organizationInvitesListingPage = createListingPage({
  link: {
    label: 'Приглашения',
    path: EAgroidRoute.OrganizationInvites,
  },
  plugStyle: {
    mobileHeight: 'calc(100vh - 336px)',
    desktopHeight: 'calc(100vh - 302px)',
  },
  header: {
    title: 'Приглашения',
    filter: {
      filterBy: {
        ...filters,
      },
    },
    actionButtons: [
      {
        type: 'button',
        caption: isMobile && !isTablet ? false : 'Добавить',
        icon: isMobile && !isTablet && PlusIcon,
        componentProps: { isPrimary: true, isBasic: false },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.manageInvites,
        dataTestIdAction: 'invites-add-btn',
      },
    ],
  },
  dataList: {
    noDataIconUrl: noAppData,
    noDataText: 'Вы еще никого не пригласили в свою организацию',
    item: {
      Component: AppCard,
      onClick: app => {
        if (!app.invitationId) {
          return;
        }

        sessionStorage.setItem('selectedInvitationId', app.invitationId);
      },
      isNeededCustomComponent: true,
    },
  },
  handlerList: {
    fetchItemList: {
      apiName: 'getOrganizationApplications',
      paramsAsPayload: {
        organizationId: 'orgId',
      },
      rawParams: {
        size: 20,
      },
    },
  },
  customData: {
    CustomComponent: InvitesModalContainer,
  },
});

export default organizationInvitesListingPage;
