import { useStore } from '../../../utils';
import { ModalsController } from '../modules/common/controllers/modals/Modals.controller';
import { ModalsStore } from '../modules/common/stores/modals/Modals.store';

export const useModal = () => {
  const modalsStore = useStore(ModalsStore);
  const modalsController = useStore(ModalsController);

  const activeModal = modalsStore.getActiveModal();

  const openModal = (modalsGroupKey: string, uniqueKey: string) => {
    modalsController.openModal(modalsGroupKey, uniqueKey);
  };

  const closeModal = () => {
    modalsController.closeModal();
  };

  return { openModal, activeModal, closeModal };
};
