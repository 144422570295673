export function num2str(n: number, text_forms: string[]) {
  const nN = Math.abs(n) % 100;
  const n1 = nN % 10;
  if (nN > 10 && nN < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
}
