import React, { FC } from 'react';

import { ReactComponent as PlusLogo } from '../../../../assets/images/agroid/plus.svg';

import { OrgCardStyled } from './styled';

export const AddOrgCard: FC<{ onClick: () => void; className?: string }> = ({
  onClick,
  className,
}) => {
  return (
    <OrgCardStyled.Link to={'#'} onClick={onClick} className={className}>
      <OrgCardStyled key="addOrg">
        <PlusLogo className="logo" />
        <OrgCardStyled.Text>Новая организация</OrgCardStyled.Text>
      </OrgCardStyled>
    </OrgCardStyled.Link>
  );
};
