import React, { FC, HTMLAttributes } from 'react';

import { HeroUser, ServicesList } from '../../components';

import { MainLayout } from './styled';

export interface IndexPageProps extends HTMLAttributes<HTMLDivElement> {
  param?: string;
}

export const IndexPage: FC<IndexPageProps> = () => {
  return (
    <MainLayout>
      <HeroUser />
      <ServicesList />
    </MainLayout>
  );
};
