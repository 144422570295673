import _ from 'lodash';

import { ApiNames, lazyInject, provide } from '../../../../../../../../utils';
import { ListingPageService } from '../../../../services/configs/pages';
import { ListingPageStore } from '../../../../stores/configs';
import { getSizeByInnerHeight } from '../../../../utils/helpers/configs/pages/getSizeByInnerHeight';

export enum EListingPageFetchItemListType {
  Add = 'New',
  Update = 'Update',
}

@provide.singleton()
class ListingPageController {
  @lazyInject(ListingPageStore)
  protected listingPageStore: ListingPageStore;

  @lazyInject(ListingPageService)
  protected listingPageService: ListingPageService;

  get isLoading() {
    return this.listingPageService.isLoading;
  }

  fetchItemList = async (config: {
    uniqueKey: string;
    apiMethodName: string;
    fetchType: EListingPageFetchItemListType;
    payload?: any;
    size?: number;
  }): Promise<any> => {
    const {
      filter,
      getItemList,
      currentPageNumber,
      setTotalPageNumber,
      setItemList,
    } = this.listingPageStore;

    const { fetchItemList } = this.listingPageService;

    const isAddType: boolean = config.fetchType === EListingPageFetchItemListType.Add;

    /**
     * Обнуляем пагинацию, если мы обновляем листинг данных,
     * например после удаления или добавления элемента
     */
    if (isAddType) {
      this.clearPaginationData();
    }

    // Настраиваем количество подгружаемых элементов согласно высоте экрана
    const sizeByInnerHeight = getSizeByInnerHeight(config?.size);

    const validatedFilter = _.omitBy(
      filter ?? {},
      val => _.isEmpty(val) || val === 'Все роли' || val === 'Все статусы'
    );

    const response = await fetchItemList({
      ...config,
      payload: {
        ...(config.payload || {}),
        ...validatedFilter,
        size: sizeByInnerHeight,
        page: isAddType ? 0 : currentPageNumber,
      },
    });

    if (response?.content) {
      if (isAddType) {
        setItemList(config.uniqueKey, response.content);
      } else {
        setItemList(config.uniqueKey, [...getItemList(config.uniqueKey), ...response.content]);
      }

      setTotalPageNumber(response?.totalPages ?? 0);

      return response;
    }

    if (response?.length) {
      setItemList(config.uniqueKey, response.content);

      return response;
    }
  };

  selectFilter = (config: { filter: { [key: string]: string }; searchKey: string }): void => {
    const {
      filter,
      currentPageNumber,
      setFilter,
      setSearchValue,
      setCurrentPageNumber,
    } = this.listingPageStore;

    const isEqualToThePrevious = _.isEqual(config.filter, filter);

    if (!isEqualToThePrevious && currentPageNumber !== 0) {
      setCurrentPageNumber(0);
    }

    if (config.filter?.[config.searchKey] || config.filter?.[config.searchKey] === '') {
      setSearchValue(config.filter[config.searchKey]);
    }
    setFilter(config.filter);
  };

  uploadFile = async (file: File): Promise<string> => {
    const { id, uploadUrl } = await this.listingPageService.uploadFileDescription(file);

    if (!id || !uploadUrl) {
      return;
    }

    const success = await this.listingPageService.uploadFile(file, uploadUrl);

    if (success) {
      return id;
    }
  };

  removeItem = async (paramName: string, itemId: string, apiName: ApiNames) => {
    await this.listingPageService.deleteItem(paramName, itemId, apiName);
  };

  changePage = (): void => {
    const { currentPageNumber, setCurrentPageNumber } = this.listingPageStore;

    setCurrentPageNumber(currentPageNumber + 1);
  };

  clearPaginationData = (): void => {
    const { clearCurrentPageNumber, clearTotalPageNumber } = this.listingPageStore;

    clearCurrentPageNumber();
    clearTotalPageNumber();
  };
}

export default ListingPageController;
