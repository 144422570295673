import styled from 'styled-components';

export const MainLayout = styled.div`
  @media (max-width: 767px) {
    padding: 0 8px;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;
