import { Button, Input, PhoneInput } from '@farmlink/farmik-ui';
import styled from 'styled-components';
import { Grid } from '@zendeskgarden/react-grid';

export const ProfileFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const GridStyled = styled(Grid)`
  padding: 0;

  @media (max-width: 767px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;

export const BlockedEmailInput = styled(Input)`
  pointer-events: none;
`;

export const BlockedPhoneInput = styled(PhoneInput)`
  pointer-events: none;
`;

export const FieldsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
  
  @media (min-width: 768px) {
    width: calc(33.333333% - 12px);
    margin-bottom: 24px;
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: calc(25% - 24px);
    margin-bottom: 24px;
    margin-right: 24px;
  }
}
  & p, & > div > div {
    text-align: left;
  }
`;

export const FieldsBlock768 = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LastNameWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    order: 1;
  }
  @media (min-width: 1024px) {
    order: 1;
  }
`;

export const FirstNameWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    order: 1;
  }
  @media (min-width: 1024px) {
    order: 1;
  }
`;

export const SecondNameWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    order: 1;
  }
  @media (min-width: 1024px) {
    order: 1;
  }
`;

export const GenderSelectWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    order: 6;
  }
  @media (min-width: 1024px) {
    order: 1;
  }
`;

export const DateOfBirthWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    order: 7;
  }
  @media (min-width: 1024px) {
    order: 1;
  }

  & .react-datepicker__input-container svg {
    min-height: 21px;
  }
`;

export const PhoneInputWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    width: calc(50% - 12px);
    order: 4;
  }
  @media (min-width: 1024px) {
    order: 1;
    width: calc(25% - 24px);
  }
`;

export const EmailWrapper = styled(FieldWrapper)`
  @media (min-width: 768px) {
    width: calc(50% - 12px);
    margin-right: 0;
    order: 5;
  }
  @media (min-width: 1024px) {
    order: 1;
    width: calc(25% - 24px);
    margin-right: 24px;
  }
`;

export const ResetPasswordWrapper = styled(FieldWrapper)`
  height: 40px;
  & > button {
    justify-content: center;
    height: 40px;
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    order: 8;
  }
  @media (min-width: 1024px) {
    order: 1;
  }
`;

export const StyledResetPasswordButton768 = styled(Button)`
  color: #F29993;
  border: 1px solid #F29993;
  background: #FFFFFF;
  border-radius: 12px;

  &:hover, &:focus {
    color: #FFFFFF;
    background: #F29993;
  }

  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  cursor: pointer;
}
`;

export const AvatarBlock = styled.div`
  align-self: flex-start;
  height: 144px;
  width: 144px;

  @media (max-width: 767px) {
    margin: 0 auto 12px auto;
  }

  @media (min-width: 768px) {
    margin-right: 24px;
  }
`;
