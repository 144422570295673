import { TCreateNoSearchedDataDynamicText } from './createNoSearchedDataDynamicText.helper.type';

const createNoSearchedDataDynamicText: TCreateNoSearchedDataDynamicText = ({
  noSearchedDataText,
  searchQueryValue,
}) => {
  return noSearchedDataText.replace(/\?:/g, searchQueryValue || '');
};

export default createNoSearchedDataDynamicText;
