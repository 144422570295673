import { useMemo } from 'react';

import { ISidebarMenuItem } from '../../components';
import { AgroidRoutes } from '../../routes';

export const useContractorsMenu = () => {
  const menuItems: ISidebarMenuItem[] = useMemo(
    () => [
      {
        caption: 'Контрагенты',
        slug: 'contractors',
        link: AgroidRoutes.Contractors,
      },
    ],
    []
  );

  return menuItems;
};
