import { Button, EModalType, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useHistory } from 'react-router';

import { UserInviteModalType } from '../..';
import { useStore } from '../../../../../../utils/IoC';
import { InviteStore } from '../../../../../auth/pages/invite/stores/invite.store';
import { SingUpStore } from '../../../../../auth/pages/sign-up/stores/SingUpStore';
import { AuthorizationRoutes } from '../../../../../auth/routes';
import { ProfileStore } from '../../../../../auth/stores/profile.store';
import { EModalSize } from '../../../../modules/common/containers/configs/modals/Modal/styles';

import {
  ActionsBlock,
  GoToSignIn,
  GotoSignInAction,
  GoToSignInBlock,
  GoToSignInText,
  GoToSignupButtonWrapper,
  InviteNotAuthStatusSentWrapper,
  OrgLogo,
  OrgName,
  PlaceHolderWrapper,
  RightArrow,
  SeparatorLine,
  Title1,
  Title2,
  TopBlock,
} from './style';

export const InviteNotAuthStatusSent: FC = observer(() => {
  const history = useHistory();
  const { getModalPayload } = useModal();
  const { orgType, orgName, orgLogo } = getModalPayload();
  const { setInviteInfo, inviteEmail, inviteToken } = useStore(InviteStore);
  const { setIsInvitedRegistration } = useStore(SingUpStore);

  const setCurrentInviteInfo = () => {
    const invitefullUrl = history.location.pathname + history.location.search;
    setInviteInfo({ invitefullUrl, inviteEmail, token: inviteToken });
  };

  const getInitials = name => {
    if (!name) return '';
    return name
      .split(' ')
      .map(i => i.trim()[0])
      .join('')
      .substr(0, 1);
  };

  return (
    <InviteNotAuthStatusSentWrapper>
      <TopBlock>
        {orgLogo ? (
          <OrgLogo src={orgLogo} data-test-id="invite-logo-wrapper" />
        ) : (
          <PlaceHolderWrapper>{getInitials(orgName)}</PlaceHolderWrapper>
        )}
        <OrgName data-test-id="invite-organization-name">{`${orgType} «${orgName}»`}</OrgName>
      </TopBlock>
      <Title1 data-test-id="invite-heading">
        Вас приглашают присоединиться
        <br /> к организации
      </Title1>
      <Title2 data-test-id="invite-description">
        Создайте учетную запись AGRO-ID
        <br /> всего за 2 минуты!
      </Title2>
      <ActionsBlock data-test-id="invite-actions-wrapper">
        <GoToSignupButtonWrapper>
          <Button
            color="primary"
            type={'button'}
            onClick={() => {
              setCurrentInviteInfo();
              setIsInvitedRegistration(true);
              history.push(AuthorizationRoutes.signUp);
            }}
            dataTestId="invite-action-sign-up"
          >
            Регистрация
          </Button>
        </GoToSignupButtonWrapper>
        <SeparatorLine />
        <GoToSignInBlock data-test-id="invite-bottom-text-wrapper">
          <GoToSignInText data-test-id="invite-bottom-text-description">
            Уже есть учетная запись?
          </GoToSignInText>
          <GotoSignInAction
            onClick={() => {
              setCurrentInviteInfo();
              history.push(AuthorizationRoutes.signIn);
            }}
            data-test-id="invite-action-sign-in"
          >
            <GoToSignIn>Войти</GoToSignIn>
            <RightArrow>{'>'}</RightArrow>
          </GotoSignInAction>
        </GoToSignInBlock>
      </ActionsBlock>
    </InviteNotAuthStatusSentWrapper>
  );
});
