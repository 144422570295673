import { memo } from 'react';

import { DummyTester } from '../../../../../components/Dummy/DummyTester';

const withCustomModalComponent = data => Component =>
  memo(() => {
    return <DummyTester {...data} />;
  });

export default withCustomModalComponent;
